import React, { Component } from "react";
import { Grid, Paper, Box, Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";
import { ScrollBarStyle } from "../../../AppStyles";
import Button from "@mui/material/Button";
import MapIcon from "@material-ui/icons/Map";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getCompareClusterStats,
  clearLegendData,
} from "../../../actions/dashboardActions";
import { connect } from "react-redux";
import LatencyGraph from "../LatencyGraph";
import ClusterFlow from "./ClusterFlow";

const Container = styled(Paper)`
  position: absolute;
  top: 2.5%;
  right: 15%;
  width: 70%;
  /* height: 95%; */
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${ScrollBarStyle}
`;
const Header = styled.div`
  width: 100%;
  height: 10%;
  background-color: #12153e;
  border-radius: 1rem 1rem 0 0;
  ${ScrollBarStyle}
`;
const ContentContainer = styled(Grid)`
  padding: 1rem;
  margin: 0;

  height: 300px;
  ${ScrollBarStyle}
`;

class LatencyChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      intervalId: null,
      cluster1:
        this.props.clusters.cluster1.target.dataItem.dataContext.clusters[0],
      cluster2:
        this.props.clusters.cluster2.target.dataItem.dataContext.clusters[0],
    };
  }
  componentDidMount() {
    this.startPollingStats();
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  startPollingStats = async () => {
    const { cluster1, cluster2 } = this.state;
    if (cluster1.clusterId == cluster2.clusterId) return;
    let ipoc = await this.props.getCompareClusterStats(
      cluster1.clusterId,
      cluster2.clusterId
    );
    // ipoc = data();
    if (ipoc !== undefined) {
      this.setState({
        graphData: [
          ...this.state.graphData,
          {
            slices: ipoc.slices,
            time: new Date().getTime(),
          },
        ],
      });
    }

    if (!this.state.intervalId) {
      const intervalId = setInterval(async () => {
        const { cluster1, cluster2 } = this.state;
        if (cluster1.clusterId == cluster2.clusterId) return;
        let ipoc = await this.props.getCompareClusterStats(
          cluster1.clusterId,
          cluster2.clusterId
        );
        // ipoc = data();

        if (ipoc !== undefined) {
          let currentData = this.state.graphData;
          if (currentData.length >= 5) {
            //only saves data of last 5 occurence
            currentData.shift();
          }
          this.setState({
            graphData: [
              ...currentData,
              {
                slices: ipoc.slices,
                time: new Date().getTime(),
              },
            ],
          });
        }
      }, 5000);
      this.setState({ intervalId });
    }
  };

  render() {
    const { cluster1, cluster2 } = this.state;
    const sliceCount = Object.keys(cluster1.attachedSlices).length;
    const cluster1Options =
      this.props.clusters.cluster1.target.dataItem.dataContext.clusters;
    const cluster2Options =
      this.props.clusters.cluster2.target.dataItem.dataContext.clusters;
    return (
      <Container>
        <Header>
          <Grid
            item
            xs
            container
            justifyContent="flex-start"
            alignItems="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Grid
              container
              item
              alignItems="center"
              style={{ color: "white", paddingLeft: "1rem" }}
            >
              <div style={{ minHeight: "2em", paddingTop: "5px" }}>
                Clusters
              </div>
            </Grid>
          </Grid>
        </Header>
        <ContentContainer container item xs columnGap={2}>
          <Autocomplete
            sx={{ flex: 1 }}
            options={cluster1Options}
            getOptionLabel={(option) => option.clusterName}
            value={cluster1}
            disableClearable
            onChange={(e, newValue) =>
              this.setState({ cluster1: newValue, graphData: [] })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cluster 1"
                size="small"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            sx={{ flex: 1 }}
            options={cluster2Options}
            getOptionLabel={(option) => option.clusterName}
            value={cluster2}
            disableClearable
            onChange={(e, newValue) =>
              this.setState({ cluster2: newValue, graphData: [] })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={
                  cluster1.clusterId == cluster2.clusterId
                    ? "Please select a different cluster!"
                    : ""
                }
                label="Cluster 2"
                size="small"
                variant="outlined"
              />
            )}
          />
        </ContentContainer>
        <ContentContainer
          container
          item
          xs
          columnGap={2}
          flexWrap="nowrap"
          // direction="column"
          // wrap="nowrap"
        >
          {/* <Grid item direction="column">  */}
          {/* <Grid item direction="row"> */}
          <Grid item xs={7} alignItems="center">
            <Paper style={{ width: "100%", height: "300px" }}>
              <ClusterFlow cluster1={cluster1} cluster2={cluster2} />
            </Paper>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={5} alignItems="center">
            <Paper style={{ width: "100%", height: "300px" }}>
              <Grid item style={{ alignSelf: "flex-start" }}>
                <div>Response Time (ms)</div>
              </Grid>

              <Grid
                item
                style={{
                  width: "100%",
                  // border: "solid silver 1px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  // borderRadius: "0.5rem",
                  marginTop: "1rem",
                  marginRight: "2rem",
                }}
              >
                <ResponsiveContainer height={250} width="100%">
                  <LatencyGraph data={this.state.graphData} />
                </ResponsiveContainer>
              </Grid>
            </Paper>
            {/* </Grid> */}
          </Grid>
        </ContentContainer>
        <ContentContainer
          container
          item
          xs
          mt={1}
          columnGap={2}
          // direction="column"
          wrap="nowrap"
        >
          <Grid item xs={12} alignItems="center">
            <Box p={1.5}>
              <Grid container item xs justifyContent="space-between">
                <Grid item xs>
                  No Of Slices
                </Grid>
                <Grid item>{sliceCount}</Grid>
              </Grid>
            </Box>
            {/* <Box p={1.5}>
              <Grid container item xs justifyContent="space-between">
                <Grid item xs>
                  Inactive Nodes
                </Grid>
                <Grid item>05</Grid>
              </Grid>
            </Box>
            <Box p={1.5}>
              <Grid container item xs justifyContent="space-between">
                <Grid item xs>
                  Inactive Onboarded Namespaces
                </Grid>
                <Grid item>05</Grid>
              </Grid>
            </Box> */}
            <Box>
              <Grid container item xs justifyContent="space-between">
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<MapIcon />}
                  onClick={this.props.onCancel}
                  style={{ background: "#12153e", color: "white" }}
                >
                  Back to Map View
                </Button>
              </Grid>
            </Box>
          </Grid>
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = {};
export default connect(null, { getCompareClusterStats, clearLegendData })(
  LatencyChart
);
