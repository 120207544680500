import React from "react";
import ReactFlow, {
  Controls,
  ReactFlowProvider,
} from "react-flow-renderer";

export default class AppTopology extends React.Component {
  constructor() {
    super();
    this.state = {
      elements: [],
    };
  }

  componentDidMount() {
    const { gateway, appList } = this.props;
    // const { gateway } = this.props;

    // console.log("app pod", gateway, appList);

    const Graph = [
      {
        id: "0",
        data: { label: `Gateway-${gateway.sliceGatewayId}` },
        position: { x: 300, y: 10 },
        type: "input",
        style: { width: 100 },
      },
    ];

    const xGap = 140;

    const yGap = 40;
    // //data manipulation for demo purposes

    const graphXOffset = (appList.length / 2) * xGap;

    const graphStartX = 350 - graphXOffset;

    appList.forEach((app, index) => {
      Graph.push({
        id: (index + 1).toString(),
        data: { label: app.podName },
        position: { x: (graphStartX + (index * xGap)) + (xGap-100)/2, y: 200 },
        type: "output",
        style: { background: '#ffcc50', width: 100 },
      });
      Graph.push({
        id: `e0-${index+1}`,
        source: "0",
        target: String(index+1),
        style: { stroke: "#fff",transform: 'translateX(14px)' },
      })
    });

    this.setState({
      elements: Graph,
    });
  }

  render() {

    return (
      <ReactFlowProvider>
        <ReactFlow
          elements={this.state.elements}
          onlyRenderVisibleElements={false}
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    );
  }
}
