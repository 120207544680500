import {
  AppBar,
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import YAML from "yaml";
import styled from "styled-components";
import brace from "brace";
import AceEditor from "react-ace";
import "brace/mode/yaml";
import "brace/theme/tomorrow";
import Close from "@material-ui/icons/Close";
import DialogHeader from "../Body/DialogHeader";
const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    overflow: hidden !important;
    /* overflow-y: auto !important; */
    /* padding: 2rem; */
  }
`;
const Preview = styled.div``;

export default class NetworkPolicyYaml extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCluster: "",
      generatedYaml: "",
      fileName: "",
    };
  }

  filterNamespaces = (namespaces) => {
    const { selectedCluster } = this.state;
    return namespaces.filter((namespace) => {
      let cluster = namespace.split(":")[0];
      if (cluster == selectedCluster.clusterName || cluster == "*") return true;
      return false;
    });
  };
  trimNamespaces = (namespaces) => {
    return namespaces.map((namespace) => namespace.split(":")[1]);
  };
  generateYaml = () => {
    const { sliceName, namespaceIsolationProfile } = this.props.slice;
    let allowedNamespaces = [],
      applicationNamespaces = [];
    if (namespaceIsolationProfile) {
      ({ allowedNamespaces, applicationNamespaces } =
        namespaceIsolationProfile);
    }
    const _applicationNamespaces = this.trimNamespaces(
      this.filterNamespaces(applicationNamespaces)
    );
    const _allowedNamespaces = this.trimNamespaces(
      this.filterNamespaces(allowedNamespaces)
    );
    const yamlJson = (applicationNamespace, allowedNamespaces) => ({
      apiVersion: "networking.k8s.io/v1",
      kind: "NetworkPolicy",
      metadata: {
        name: `${sliceName}-${applicationNamespace}`,
        namespace: applicationNamespace,
      },
      spec: {
        egress: [
          {
            to: [
              {
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/slice": sliceName,
                  },
                },
              },
              ...allowedNamespaces.map((namespace) => ({
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/namespace": namespace,
                  },
                },
              })),
            ],
          },
        ],
        ingress: [
          {
            from: [
              {
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/slice": sliceName,
                  },
                },
              },
              ...allowedNamespaces.map((namespace) => ({
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/namespace": namespace,
                  },
                },
              })),
            ],
          },
        ],
        podSelector: {},
        policyTypes: ["Ingress", "Egress"],
      },
    });
    let result = "";
    _applicationNamespaces.forEach((namespace, index) => {
      debugger;
      const doc = new YAML.Document();
      doc.contents = yamlJson(namespace, _allowedNamespaces);
      result += doc.toString();
      if (index != _applicationNamespaces.length - 1) result += "---\n";
    });
    this.setState({ generatedYaml: result });
  };
  handleDownloadYaml = () => {
    const { generatedYaml } = this.state;
    const blob = new Blob([generatedYaml]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState({ fileDownloadUrl }, () => {
      this.dofileDownload.click();
      URL.revokeObjectURL(fileDownloadUrl);
      this.setState({ fileDownloadUrl: "" });
    });
  };
  handleClose = () => {
    this.setState({ selectedCluster: "", generatedYaml: "", fileName: "" });
    this.props.onClose();
  };
  render() {
    const { open, onClose } = this.props;
    const { selectedCluster, generatedYaml, fileName } = this.state;
    return (
      <Dialog open={open} onClose={this.handleClose} maxWidth="xl">
        <DialogHeader>
          <IconButton edge="start" color="inherit" onClick={this.handleClose}>
            <Close />
          </IconButton>
          <Typography variant="h6">Network Policy YAML</Typography>
        </DialogHeader>
        {/* <DialogTitle>Network Policy YAML</DialogTitle> */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={3}
          alignItems="center"
          style={{
            minWidth: "40vw",
            overflow: "hidden",
            padding: "2rem",
            height: "75vh",
          }}
        >
          <Grid item>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Select Cluster
            </InputLabel>
            <Select
              value={selectedCluster}
              label="Select Cluster"
              onChange={(e) =>
                this.setState(
                  { selectedCluster: e.target.value },
                  this.generateYaml
                )
              }
              inputProps={{
                name: "Cluster",
                id: "uncontrolled-native",
              }}
              style={{ width: "40vw", color: "black" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(this.props.slice?.attachedClusters).map(
                (cluster) => (
                  <MenuItem value={cluster}>{cluster.clusterName}</MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid item xs>
            <AceEditor
              mode="yaml"
              theme="tomorrow"
              readOnly
              name="editor"
              value={generatedYaml}
              fontSize={14}
              editorProps={{ $blockScrolling: true }}
              style={{ width: "40vw", height: "100%" }}
              showGutter={false}
            />
          </Grid>
          <Grid item>
            <TextField
              label="File Name"
              onChange={(e) => this.setState({ fileName: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">.yaml</InputAdornment>
                ),
              }}
              style={{ width: "40vw" }}
            />
          </Grid>
          <Grid item>
            <a
              style={{ display: "none" }}
              download={fileName + ".yaml"}
              href={this.state.fileDownloadUrl}
              ref={(e) => (this.dofileDownload = e)}
            >
              download it
            </a>
            <Button
              variant="contained"
              onClick={this.handleDownloadYaml}
              disableElevation
              disabled={!fileName || !selectedCluster}
            >
              Download Network Policy Yaml
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
