import { UPDATE_MENU } from './types';

export const updateMenu = (menu) => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_MENU,
            payload: menu
        })
    }
};
