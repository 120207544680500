import { SET_ACTIONS, SET_POLICIES, SELECT_POLICY, CLEAR_SELECTED_POLICY, ACTION_LOGOUT_INITIATED } from "./../actions/types";
const initialState = {
  policies: [],
  actions: [],
  selectedPolicy: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_POLICIES:
      return { ...state, ...payload };
    case SET_ACTIONS:
      return { ...state, actions: { ...payload } };
    case SELECT_POLICY:
      return { ...state, selectedPolicy: { ...payload } };
    case CLEAR_SELECTED_POLICY:
      return { ...state, selectedPolicy: null };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
