import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_SLICES, SET_SLICE_GATEWAYS, CLEAR_SLICE_GATEWAYS } from "./types";
const meshBackend = getMeshBackend();

export const getAllSlices = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllSlices(getState(), dispatch);
    if (response) dispatch({ type: SET_SLICES, payload: response });
  };
};
export const getAllGatewaysForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllGatewaysForSlice(getState(), dispatch, sliceName);
    if (response) dispatch({ type: SET_SLICE_GATEWAYS, payload: response });
  };
};
export const clearGatewaysForSlice = () => ({
  type: CLEAR_SLICE_GATEWAYS,
});

export const createSlice = (sliceDetails) => {
  return async (dispatch, getState) => {
    await meshBackend.postSlice(getState(), dispatch, sliceDetails);
  };
};
export const deleteSlice = (sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.deleteSlice(getState(), dispatch, sliceName);
    dispatch(getAllSlices());
  };
};

export const addClusters = (sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.attachCluster(getState(), dispatch, sliceName);
  };
};

export const getSliceGateway = (sliceId, clusterId, gatewayId) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getSliceGateway(getState(), dispatch, sliceId, clusterId, gatewayId);
    console.log(response);
    return response;
  };
};

export const getSliceServices = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getSliceServices(getState(), dispatch, sliceName);
    return response;
  };
};

export const updateSlice = (sliceName, sliceData) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.updateSlice(getState(), dispatch, sliceName, sliceData);
    dispatch(getAllSlices());
    return response;
  };
};
