import { SET_SLICES, ACTION_LOGOUT_INITIATED, SET_SLICE_GATEWAYS,CLEAR_SLICE_GATEWAYS } from "../actions/types";

const initialState = {
  sliceList: [],
  sliceGateways: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SLICES:
      return {
        ...state,
        sliceList: action.payload.slices === undefined ? [] : action.payload.slices,
      };
    case SET_SLICE_GATEWAYS:
      return {
        ...state,
        sliceGateways: action.payload,
      };
    case CLEAR_SLICE_GATEWAYS:
      return {
        ...state,
        sliceGateways: [],
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
