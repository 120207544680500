import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import styled from "styled-components";
import PodImg from "./../../pod.png";
import PodBorderImg from "./../../pod_border.png";
import { connect } from "react-redux";
import { getSliceGateway } from "./../../actions/SliceActions";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import "./MeshSelectorNode.css";
import AppTopology from "./AppTopology";
import { rgb } from "d3";
import { Paper } from "@material-ui/core";

const PodCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  background-color: lightblue;
  height: 100%;
  justify-content: space-evenly;
`;

const PodImgCon = styled.img`
  height: ${(props) => (props.highlight ? "60px" : "40px")};
  align-self: center;
  margin: 1rem;

  &:hover {
    /* border: solid black 1px; */
    cursor: pointer;
  }
`;

const GatewayCon = styled.div`
  background: transparent;

  /* &:hover {
    border: solid black 1px;
    cursor: pointer;
  } */
  /* margin: 0.5rem 0rem;
  padding: 0.5rem 0.2rem; */
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border-top: 1px dashed grey; */
  text-align: center;
  align-self: center;
`;

const ClusterHead = styled.div`
  align-self: center;
  font-weight: 500;
`;

const ClusterCon = styled.div`
  background-color: lightblue;
  min-height: 10rem;
  width: 12rem;
  box-shadow: ${(props) => (props.highlight ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)" : null)};
  /* border: ${(props) => (props.highlight ? "solid blue 1px" : null)}; */
  &:hover {
    box-shadow: 0px 5px 10px 1px grey;
  }
`;

const AppName = styled.div`
  font-weight: bold;
`;
const AppCon = styled.div`
  text-align: center;
  font-style: italic;
`;

const AppLayoutCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 45rem;
  height: 20rem;
  background-color: rgba(0, 0, 0, 0.7);
`;

const StyledHandle = styled(Handle)`
  right: 50%;
  visibility: hidden;
`;
class MeshSelectorNode extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      gateways: [],
      applist: [],
      selectedGateway: {},
      selectedGatewayApps: [],
      popoverAnchorEl: null,
    };
  }

  getData = async () => {
    let { data } = this.props;
    let { sliceId, clusterId } = data;
    console.log("props", this.props);
    let gatewayKeys = Object.keys(data.gateways);

    return Promise.all(
      gatewayKeys.map((id) => {
        return this.props.getSliceGateway(sliceId, clusterId, id);
      })
    );
  };
  componentDidMount() {
    let { data } = this.props;
  }
  handleGatewaySelect = (gateway, appList, event) => {
    event.persist();
    this.setState({ selectedGateway: gateway, selectedGatewayApps: appList }, () => {
      this.setState({ popoverAnchorEl: event.target });
    });
  };

  handlePopoverOpen = (event) => {
    this.setState({ popoverAnchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchorEl: null });
  };
  isSelectedGateway = (data, gatewayKey) => {
    return data.highlight == data.gateways[gatewayKey].sliceGatewayId;
  };
  render() {
    let { data } = this.props;
    const { applist, gateways } = data.nodeData;
    let gatewayKeys = Object.keys(data.gateways);
    let Fragment =
      data.gateways !== undefined ? (
        <ClusterCon highlight={data.highlight}>
          <StyledHandle
            type="target"
            position={data.orientation}
            onConnect={(params) => console.log("handle onConnect", params)}
            style={{ background: "#fff" }}
          />
          <StyledHandle type="source" position={data.orientation} id="a" style={{ background: "#fff" }} />
          <PodCard>
            <ClusterHead>{data.label}</ClusterHead>
            <ClusterHead>{data.site}</ClusterHead>
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
              {gatewayKeys.map((gatewayKey, index) => {
                const isSelected = this.isSelectedGateway(data, gatewayKey);
                return (
                  <GatewayCon
                    onClick={(e) => {
                      this.handleGatewaySelect(data.gateways[gatewayKey], applist[index], e);
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <PodImgCon highlight={isSelected} src={isSelected ? PodBorderImg : PodImg} />
                    </div>
                  </GatewayCon>
                );
              })}
            </div>
          </PodCard>
        </ClusterCon>
      ) : (
        <ClusterCon highlight={data.highlight}>
          <StyledHandle
            type="target"
            position={data.orientation}
            onConnect={(params) => console.log("handle onConnect", params)}
            style={{ background: "#fff" }}
          />
          <StyledHandle type="source" position={data.orientation} id="a" style={{ background: "#fff" }} />
          <PodCard>
            <ClusterHead>{data.label}</ClusterHead>
            <ClusterHead>{data.site}</ClusterHead>
            <div>
              <GatewayCon>No Gateways Attached</GatewayCon>
            </div>
          </PodCard>
        </ClusterCon>
      );

    const openPopover = Boolean(this.state.popoverAnchorEl);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {" "}
        <div>
          {Fragment}

          <Popover
            id="mouse-over-popover"
            open={openPopover}
            anchorEl={this.state.popoverAnchorEl}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            onClose={this.handlePopoverClose}
            disableRestoreFocus
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            <AppLayoutCon>
              <AppTopology gateway={this.state.selectedGateway} appList={this.state.selectedGatewayApps} />
            </AppLayoutCon>
          </Popover>
        </div>
      </div>
    );
  }
}

export default connect(null, { getSliceGateway })(MeshSelectorNode);
