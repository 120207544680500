import { UPDATE_FILTER, ACTION_LOGOUT_INITIATED } from "../actions/types";
import { keyMap } from "./../utils/keyMap";

const initialState = {
  sliceQosProfile: {
    label: "Slice QOS Profiles",
    values: {
      LOW_PRIORITY_PROFILE: true,
      MED_PRIORITY_PROFILE: true,
      HIGH_PRIORITY_PROFILE: true,
    },
  },
  // sliceGatewayType: {
  //   label: "Slice Gateway Type",
  //   values: {
  //     [keyMap["sliceGatewayType"]["SLICE_GATEWAY_TYPE_OPEN_VPN"]]: true,
  //   },
  // },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_FILTER:
      return { ...state, ...payload };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
