import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const Container = styled.div`
  width: 95%;
  display: flex;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: fit-content;
  :hover {
    cursor: pointer;
  }
`;
const Details = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  min-width: 20rem;
  height: fit-content;
`;
const IconContainer = styled.div`
  /* padding: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
`;

function OperatorCard(props) {
  const [policies, setPolicies] = useState("");

  useEffect(() => {
    setPolicies(filterNames(filterItems(props.group?.policyIds, props.policies, "policyId"), "policyName")?.join(", "));
  }, [props.group, props.policies]);

  const filterItems = (ids, list, key) => {
    if (!Array.isArray(ids) || !Array.isArray(list)) return [];
    let result = list.filter((item) => ids.includes(item[key]));
    return result;
  };

  const filterNames = (list, key) => {
    return list.map((item) => item[key]);
  };

  return (
    <Container onClick={props.onClick} data-cy={`av-operatorGroup-${props.index + 1}`}>
      <Details>
        <h4 style={{ margin: 5, color: "green" }}>{props?.group?.operatorGroupName}</h4>
        <h5 style={{ margin: 5, color: "gray" }}>{/* {props?.slice?.policy_name} */}</h5>
      </Details>
      <Details>
        <h5 style={{ margin: 5, color: "gray", paddingBottom: "2px" }}>{policies}</h5>
      </Details>
      <IconContainer>
        <ArrowForwardIosIcon
          style={{
            fontSize: "30px",
            color: "gray",
          }}
        />
      </IconContainer>
    </Container>
  );
}
const mapStateToProps = ({ PolicyReducer: { policies } }) => ({ policies });
export default connect(mapStateToProps, {})(withRouter(OperatorCard));
