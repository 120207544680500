import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_USER_DETAILS } from "./types";
const meshBackend = getMeshBackend();

export const getOperatorDetails = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getOperatorDetails(getState(), dispatch);
    if (response) dispatch({ type: SET_USER_DETAILS, payload: response });
  };
};
