import React, { Component } from "react";
import PopUp from "../../Commons/popUtils";
import RoleForm from "./RoleForm";
import TableCard from "../TableCard";
import People from "@material-ui/icons/People";
import { connect } from "react-redux";
import { getAllRoles } from "../../../actions/RolesActions";

class Roles extends Component {
  constructor() {
    super();
    this.state = {
      showForm: false,
      roles: [],
      config: {
        columns: [
          {
            id: "role",
            name: "Role Name",
            icon: true,
            iconPath: (
              //todo: refactor to img source
              <People
                color="primary"
                style={{ marginRight: "10px", alignSelf: "center" }}
              />
            ),
          },
          {
            id: "permissions",
            name: "Permissions",
            icon: false,
          },
          {
            id: "environment",
            name: "Environment",
            icon: false,
          },
        ],
      },
    };
  }
  componentDidMount() {
    this.props.getAllRoles();
  }
  render() {
    const { showForm,  config } = this.state;
    const { heading,roles } = this.props;

    return (
      <>
        <PopUp open={showForm}>
          <RoleForm cancelListener={() => this.setState({ showForm: false })} />
        </PopUp>
        <TableCard
          addListener={() => this.setState({ showForm: !showForm })}
          heading={heading || "Roles"}
          config={config}
          data={roles}
          editable={true}
          deleteEnable={true}
        />
      </>
    );
  }
}
const mapStateToProps=(store)=>{
  return{
    roles:store.roles.roles,
  }
}
export default connect(mapStateToProps,{getAllRoles})(Roles)