import { SET_ENFORCEMENT_POINTS, SET_ENFORCEMENT_POINT_SLICE, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  enforcementSlice: null,
  enforcementPointsList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ENFORCEMENT_POINTS:
      return {
        ...state,
        enforcementPointsList: action?.payload?.enforcementPoints ? action.payload.enforcementPoints : [],
      };
    case SET_ENFORCEMENT_POINT_SLICE:
      return {
        ...state,
        enforcementSlice: action.payload.enforcementPoint,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
