import React, { Component } from "react";
import MetricField from "./MetricField";
export default class MetricForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleMetricChange = (value, index) => {
    let metrics = [...this.props.metrics];
    metrics[index] = { ...metrics[index], value };
    this.props.onChange(metrics);
  };
  render() {
    const { service, metrics } = this.props;
    const { handleMetricChange } = this;
    return (
      <div>
        <p>
          Service: <b>{service}</b>
        </p>
        {metrics.map((metric, index) => {
          return <MetricField metric={metric} onChange={(value) => handleMetricChange(value, index)} />;
        })}
      </div>
    );
  }
}
