import { ADD_SESSION, ACTION_LOGOUT_INITIATED, CHANGE_PASSWORD,  } from "../actions/types";
import history from "./../utils/history";

const initialState = {
  session_token: null,
  login: false,
  changePassword: false,
  customerId: null,
  refreshToken:null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SESSION:
      history.push("/");
      return {
        ...state,
        session_token: action.payload.session_token,
        login: true,
        changePassword: false,
        customerId: action.payload.customerId,
        refreshToken: action.payload.refreshToken,
      };
      break;
    case CHANGE_PASSWORD:
      return {
        ...state,
        session_token: action.payload.session_token,
        login: false,
        changePassword: true,
      };
      break;
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
      break;
    case "PURGE_STATE":
      return {};
      break;
    
    default:
      return state;
  }
}
