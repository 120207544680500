import React, { Component } from "react";
import LogTable from "./LogTable/index";
import ModuleList from "./ModuleList/index";
import QueryBox from "./QueryBox/index";
import { BodyRoot } from "./../Body/BodyStyles";
import styled from "styled-components";
import { connect } from "react-redux";
import { getLogs } from "../../actions/accessLogsActions";
import { getAllOperators } from "./../../actions/OperatorActions";
import { getAllOperatorGroups } from "./../../actions/OperatorGroupActions";
import { getAllPolicies } from "./../../actions/PolicyActions";
import { getAllSlices } from "../../actions/SliceActions";
import DetailsDialog from "./DetailsDialog";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;
const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moduleList: {},
      selectedModule: {},
      selectedObjectId: null,
      selectedModuleType: "slice",
      openDialog: false,
      dialogDataType: null,
      dialogData: null,
    };
  }

  componentDidMount() {
    this.fetchModules();
    this.createModuleList();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.logs != prevProps.logs) {
      this.handleSelectedModule();
    }
  }
  fetchModules = () => {
    const { getAllOperatorGroups, getAllOperators, getAllPolicies, getAllSlices } = this.props;
    getAllOperatorGroups();
    getAllOperators();
    getAllPolicies();
    getAllSlices();
  };
  handleSelectedModule = () => {
    const { logs } = this.props;
    // const { sliceName, sliceSubnet, sliceType, sliceQosProfile } = logs[0].object;
    let selectedModule = logs?.length && logs.map((item, index) => ({ ...item.object, id: index }));
    // this.setState({ selectedModule: { sliceName, sliceSubnet, sliceType, sliceQosProfile } });
    this.setState({ selectedModule });
  };

  getLogData = (params) => {
    this.props.getLogs(params);
  };

  createModuleList = () => {
    const { sliceList, policies, operators, operatorGroups } = this.props;
    let moduleList = {
      slices: sliceList?.map(({ sliceName, sliceId }) => ({ label: sliceName, objectId: sliceId })) || [],
      // qosProfiles: Object.keys(qos_profiles).map((qosProfile) => ({ label: qosProfile, objectId: null })),
      policies: policies?.map(({ policyName, policyId }) => ({ label: policyName, objectId: policyId })) || [],
      operators: operators?.map(({ name, operatorId }) => ({ label: name, objectId: operatorId })) || [],
      operatorGroups:
        operatorGroups?.map(({ operatorGroupName, operatorGroupId }) => ({ label: operatorGroupName, objectId: operatorGroupId })) || [],
    };
    this.setState({ moduleList });
  };
  handleItemClick = ({ type, objectId }) => {
    this.setState({ selectedObjectId: objectId, selectedModuleType: type });
    this.getLogData({ objectId });
  };
  handleQueryParams = ({ startTime, endTime, reverse, batchSize }) => {
    let params = { reverse };
    if (startTime) params = { ...params, startTime };
    if (endTime) params = { ...params, endTime };
    if (batchSize) params = { ...params, batchSize };
    this.getLogData({ objectId: this.state.selectedObjectId, ...params });
  };
  render() {
    // const { sliceList, qos_profiles } = this.props;
    const { moduleList, selectedModule, selectedModuleType, openDialog, dialogDataType, dialogData } = this.state;
    return (
      <BodyRoot>
        <DetailsDialog open={openDialog} handleClose={() => this.setState({ openDialog: false })} type={dialogDataType} data={dialogData} />
        <MainContainer>
          <ModuleList moduleList={moduleList} onItemClick={this.handleItemClick} />
          <RightSection>
            <QueryBox onSubmit={this.handleQueryParams} />
            <LogTable
              data={selectedModule}
              type={selectedModuleType}
              openDialog={(type, data) => this.setState({ openDialog: true, dialogDataType: type, dialogData: data })}
            />
          </RightSection>
        </MainContainer>
      </BodyRoot>
    );
  }
}

const mapStateToProps = ({
  slices: { sliceList },
  qosProfiles: { qos_profiles },
  AccessLogsReducer: { logs, paginationResponse },
  OperatorReducer: { operators },
  OperatorGroupReducer: { operatorGroups },
  PolicyReducer: { policies },
}) => ({
  sliceList,
  qos_profiles,
  logs,
  paginationResponse,
  policies,
  operators,
  operatorGroups,
});

export default connect(mapStateToProps, { getAllSlices, getLogs, getAllOperators, getAllOperatorGroups, getAllPolicies })(Body);
