import {
  ADD_TRAINING_EVENTS,
  SET_TRAINING_EVENTS,
  SET_SELECTED_TRAINING_EVENT,
  CLEAR_SELECTED_TRAINING_EVENT,
  ACTION_LOGOUT_INITIATED,
} from "../actions/types";

const initialState = {
  selectedTrainingEvent: null,
  events: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRAINING_EVENTS:
      return { ...state, ...payload };
    case ADD_TRAINING_EVENTS:
      return { ...state, events: [...state.events, ...payload.events], paginationResponse: payload.paginationResponse };
    case SET_SELECTED_TRAINING_EVENT:
      return {
        ...state,
        selectedTrainingEvent: { ...payload },
      };
    case CLEAR_SELECTED_TRAINING_EVENT:
      return { ...state, selectedTrainingEvent: null, events: [] };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
