import styled from "styled-components";
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 100%;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 1rem;
`;
