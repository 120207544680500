import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid'
import { InputLabel, MenuItem, Select, FormControl } from "@material-ui/core";
import { connect } from "react-redux";
import { getNodeCapacityDetail,getNamespaceCapacityDetail } from "../../actions/dashboardActions";
import TreeMapChart from './TreeMapChart'
import styled from 'styled-components';

const Square = styled.div`
    height: 1rem;
    width: 1rem;
    border-radius: 4px;
    background-color: ${props => props.color};
    `
const LabelText = styled.div`
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
`

const NodesView = (props)=> {
  // state and variable declarations
  const [data, setData] = useState([]);
  const [clusterId,setClusterId] = useState('');
  const {clusterCapacity, getNodeCapacityDetail, getNamespaceCapacityDetail} = props


  //useEffect
  useEffect( () => {
    const setTreeMapData = async (clusterId) => {
      if(clusterId.length>0){
        const treeMapData = await generateTreeMapData(clusterId);
        setData(treeMapData);
      }
      
   }
   if(clusterCapacity?.length>0){
      setClusterId(clusterCapacity[0]?.clusterId)
      setTreeMapData(clusterCapacity[0]?.clusterId);
    }
  }, []);




  //generate tree map data

  const generateTreeMapData = async (clusterId)=>{
    let selectedCluster = clusterCapacity.find(o => o.clusterId === clusterId); 
    try {
    
    const nodeCapacity = await getNodeCapacityDetail(clusterId)
    let treeMapData = []
    if(nodeCapacity?.nodes?.length){
      for( let node of nodeCapacity.nodes){
        const nameSpaceCapacity = await getNamespaceCapacityDetail(clusterId,node.nodeName);
        if(nameSpaceCapacity.namespaces.length){
          let children = [];
          for( let namespace of nameSpaceCapacity.namespaces){ 
            children.push({
              name: namespace.namespaceName,
              path: `${selectedCluster.clusterName}/${node.nodeName}/${namespace.namespaceName}`,
              value: namespace.cpuUtilization,

              itemStyle: {
                color: node.nodeType === "NODE_TYPE_AVESHA_MANAGED" ? "#a7cbff":"#f5d29e" 
              },
            })   
          }
          treeMapData.push({
            name: node.nodeName,
              path: `${selectedCluster.clusterName}/${node.nodeName}`,
              value: node.cpuUtilization,
              itemStyle: {
                color: node.nodeType === "NODE_TYPE_AVESHA_MANAGED" ? "#a7cbff":"#f5d29e" 
              },
              children
          })

        }
        
      }
    }
      return [
              treeMapData,
              selectedCluster?.clusterName
          ];
    }
  catch(err){
    //have to add message here
  }
}

  // on cluster select
  const onClusterSelect = async (e)=>{
    e.preventDefault();
    setClusterId(e.target.value);
    const treeMapData = await generateTreeMapData(e.target.value);
    setData(treeMapData); 
  }

  return (
    <>
    {clusterCapacity?.length>0 ?
    <div>
       <Grid container  >
       {/* <Box mr={20}> */}
       <Grid item xs={12} sm={6} md={9}>
         <div style={{ color:"#12153E", fontSize: '16px',fontWeight:'normal',lineHeight:'20px',display: "flex", alignItems: "left",marginTop:'16px',marginLeft:'20px'}}>
         Node utilisation
        </div>
        </Grid>
         <Grid item xs={12} sm={6} md={3}>
        {
          clusterCapacity?.length>0 &&
       <FormControl style={{minWidth:'175px'}}>
          <InputLabel id="cluster-select-label">Cluster</InputLabel>
          <Select
            labelId="cluster-select"
            id="cluster-select"
            value={clusterId}
            label="Cluster"
            autoWidth
            onChange={onClusterSelect}
          >
            
            {clusterCapacity?.length>0 && clusterCapacity.map((cluster)=>
                <MenuItem key={cluster.clusterId} value={cluster.clusterId}>{cluster.clusterName}</MenuItem>
            )}
          </Select>
        </FormControl>
        }
        </Grid>
       
        {/* </Box> */}
        </Grid>
        {data && data.length> 0 && data[0].length>0 ? 
        (<div>
        
      <div style={{height:'100%',width:'100%'}}>
      
      <TreeMapChart data={data}/>
      <div style={{display:'flex',marginTop:'10px'}}>
       <Square color='#a7cbff'/>
         <LabelText>
           Avesha managed Nodes
         </LabelText>

         <Square color='#f5d29e' style={{marginLeft:'15px'}}/>
         <LabelText>
           Non-Avesha managed Nodes
         </LabelText>

       </div>
       </div>
       
       </div>)
       :
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        No node data available</div>
         }
        
     
     </div>

:

<div style={{
 position: 'absolute',
 left: '50%',
 top: '50%',
 transform: 'translate(-50%, -50%)'
}}>
 No cluster detail available</div>
}

     </>
  );
}



const mapStateToProps = (state)=>{
  return {
      clusterCapacity:state.dashboard.clusterCapacityDetail
  }
}

export default connect(mapStateToProps,{getNodeCapacityDetail,getNamespaceCapacityDetail})(NodesView)
