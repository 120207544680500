import React from "react";
import styled from "styled-components";
//import PlayArrow from "@material-ui/icons/PlayArrow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withRouter } from "react-router-dom";
const Container = styled.div`
  width: 95%;
  display: flex;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: fit-content;
  :hover {
    cursor: pointer;
  }
`;
const Details = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  min-width: 20rem;
  height: fit-content;
`;
const IconContainer = styled.div`
  /* padding: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
`;
function OperatorCard({ profile, ...props }) {
  return (
    <Container onClick={props.onEdit} data-cy={`av-qosProfile-${props.index + 1}`}>
      <Details>
        <h4
          style={{
            margin: 5,
            color: "green",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "12rem",
          }}
        >
          {profile.name}
        </h4>
        <h5 style={{ margin: 5, color: "gray" }}>{profile.queueType}</h5>
      </Details>
      <Details>
        <h5 style={{ margin: 5, color: "gray", paddingBottom: "2px" }}>
          {profile.bandwidthCeilingKbps} Kbps
        </h5>
        <h5 style={{ margin: 5, color: "gray" }}>
          {profile.bandwidthGuaranteedKbps} Kbps
        </h5>
      </Details>
      <Details>
        <h5 style={{ margin: 5, color: "gray", paddingBottom: "2px" }}>
          {profile.priority}
        </h5>
        <h5 style={{ margin: 5, color: "gray" }}>{profile.dscpClass}</h5>
      </Details>
      <IconContainer>
        <ArrowForwardIosIcon
          style={{
            fontSize: "30px",
            color: "gray",
          }}
        />
      </IconContainer>
    </Container>
  );
}
export default withRouter(OperatorCard);
