export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const LightenDarkenColor = (colorCode, amount) => {
  var usePound = false;

  if (colorCode[0] == "#") {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  var num = parseInt(colorCode, 16);

  var r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const createSearchRegex = (query) => {
  let _query = "";
  // const specialCharacters = ["\\", "^", "$", ".", "|", "?", "*", "+", "(", ")"];
  const specialCharactersMap = {
    "\\": 1,
    "^": 1,
    $: 1,
    ".": 1,
    "|": 1,
    "?": 1,
    "*": 1,
    "+": 1,
    "(": 1,
    ")": 1,
  };
  for (let i in query) {
    let char = query[i];
    if (specialCharactersMap[char] == 1) {
      _query += "\\" + char;
    } else {
      _query += char;
    }
  }
  return _query;
};

export const verifyNamespaceFormat = (namespace) => {
  return typeof namespace == "string" && namespace.split(":").length == 2;
};

export const verifyHierarchyNamespaceFormat = (namespace) => {
  return typeof namespace == "string" && namespace.split(":").length <= 3;
};