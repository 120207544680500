import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { Dialog } from "../SliceStyles";
import Header from "./Header";
import Content from "./Content";
class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "filter",
      groupBy: "aplhabetically",
      groupOrder: "ascending",
      filterCategory: null,
      filterBy: [],
    };
  }
  componentDidMount() {
    this.setState({ filterBy: this.props.filters });
  }
  handleChangeFilterBy = (option) => {
    const { filterCategory, filterBy } = this.state;
    this.setState({
      filterBy: {
        ...filterBy,
        [filterCategory]: {
          ...filterBy[filterCategory],
          values: {
            ...filterBy[filterCategory].values,
            [option]: !filterBy[filterCategory].values[option],
          },
        },
      },
    });
  };
  handleSubmit = () => {
    this.props.updateFilter(this.state.filterBy);
    this.props.onClose();
  };
  render() {
    const { type, filterCategory, groupBy, groupOrder, filterBy } = this.state;
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "50vh",
            minWidth: "20vw",
          }}
        >
          <div
            style={{
              flex: 1,
              width: "100%",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            {!filterCategory && (
              <Header onTypeChange={(type) => this.setState({ type })} />
            )}
            <Content
              type={type}
              groupBy={groupBy}
              groupOrder={groupOrder}
              filterBy={filterBy || []}
              filterCategory={filterCategory}
              changeGroupOrder={(groupOrder) => this.setState({ groupOrder })}
              changeGroupBy={(groupBy) => this.setState({ groupBy })}
              changeFilterBy={this.handleChangeFilterBy}
              changeFilterCategory={(filterCategory) =>
                this.setState({ filterCategory })
              }
            />
          </div>
          <div
            style={{
              padding: "8px",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button onClick={this.handleSubmit}>Ok</Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default Filters;
