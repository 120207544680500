import styled from "styled-components";
import {
  AppBar as MuiAppBar,
  InputBase as MuiInputBase,
  Toolbar as MuiToolbar,
  Dialog as MuiDialog,
} from "@material-ui/core";
export const InputBase = styled(MuiInputBase)`
  border: solid grey 1px;
  width: 50%;
  padding: 4px;
  border-radius: 5px;
`;

export const AppBar = styled(MuiAppBar)`
  background-color: white;
`;
export const Toolbar = styled(MuiToolbar)`
  /* border: solid black 1px; */
  justify-content: space-between;
`;
export const Dialog = styled(MuiDialog)``;
export const FilterCategory = styled.div`
  padding: 4px;
  padding-left: 1.5rem;
  :hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;
