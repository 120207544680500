import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getAllSliceNamespaces } from "../../../actions/SliceNamespaceActions";
import { ScrollBarStyle } from "../../../AppStyles";
import { LightenDarkenColor } from "../../../utils/functions";
import SliceNamespace from "./SliceNamespace";
const Container = styled(Grid)`
  overflow-x: auto;
  max-height: 70vh;
  column-gap: 1.5rem;
  padding: 1.5rem;
  ${ScrollBarStyle}
`;
const FallbackContainer = styled(Grid)`
  min-width: 30vw;
  background: ${({ color }) => LightenDarkenColor(color, 200)};
  border-radius: 1rem;
  overflow-y: auto;
  position: relative;
  max-height: 50vh;
  min-height: 30vh;
  justify-content: center;
  align-items: center;
`;
class SliceNamespaces extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slices: [],
    };
  }

  componentDidMount() {
    this.initializeSlices();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.slices !== this.props.slices) this.initializeSlices();
  }
  initializeSlices = () => {
    let slices = Object.values(this.props.slices);
    slices.sort(
      (slice1, slice2) =>
        Object.keys(slice2.sliceNamespace).length -
        Object.keys(slice1.sliceNamespace).length
    );
    this.setState({ slices });
  };
  render() {
    const colors = ["#2A2D57", "#432F57",  "#DD9F82"];
    const { slices } = this.state;
    return (
      <Container container direction="row" spacing={3} wrap="nowrap">
        {slices?.length ? (
          slices.map((slice, index) => (
            <SliceNamespace color={colors[index % 3]} slice={slice} />
          ))
        ) : (
          <FallbackContainer color={colors[0]} container>
            <Typography variant="h5">No Slices Available</Typography>
          </FallbackContainer>
        )}
      </Container>
    );
  }
}
const mapStateToProps = ({
  dashboard: { slices },
  // SliceNamespaces: { slices },
  // slices: { sliceList },
}) => ({
  slices,
  // , sliceList
});

const mapDispatchToProps = {
  getAllSliceNamespaces,
};
export default connect(mapStateToProps, mapDispatchToProps)(SliceNamespaces);
