import { getMeshBackend } from "../backend/MeshBackendProxy";
import {ADD_TRAINING_EVENTS, SET_TRAINING_EVENTS, SET_SELECTED_TRAINING_EVENT, CLEAR_SELECTED_TRAINING_EVENT } from "./types";
const meshBackend = getMeshBackend();

export const getTrainingEvents = (sliceName,canonicalName,params) => {
    return async (dispatch, getState) => {
      let response = await meshBackend.getTrainingEvents(getState(), dispatch, sliceName,canonicalName,params);
      dispatch({ type: SET_TRAINING_EVENTS, payload: response });
    };
  };
export const addTrainingEvents = (sliceName, canonicalName, params) => {
  return async (dispatch, getState) => {
    const paginationResponse = getState().trainingEventsReducer.paginationResponse;
    let paginationParams = {};
    for (let i in paginationResponse) {
      if (paginationResponse[i] || paginationResponse[i] === 0) {
        paginationParams[i] = paginationResponse[i];
      }
    }

    let response = await meshBackend.getTrainingEvents(getState(), dispatch, sliceName, canonicalName, { ...params, ...paginationParams });
    dispatch({ type: ADD_TRAINING_EVENTS, payload: response });
    console.log(response);
  };
};

export const selectTrainingEvent = (payload) => ({
  type: SET_SELECTED_TRAINING_EVENT,
  payload,
});

export const clearSelectedTrainingEvent = () => ({
  type: CLEAR_SELECTED_TRAINING_EVENT,
});
