import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import SessionReducer from "./SessionReducer";
import MenuReducer from "./MenuReducer";
import SliceReducer from "./SliceReducer";
import SpinnerReducer from "./SpinnerReducer";
import QOSProfileReducer from "./QOSProfileReducer";
import DashboardReducer from "./DashboardReducer";
import ErrorReducer from "./ErrorReducer";
import ErrorSnackReducer from "./ErrorSnackReducer";
import RolesReducer from "./RolesReducer";
import ProfileReducer from "./ProfileReducer";
import SliceFilterReducer from "./SliceFilterReducer";
import QOSProfileFilterReducer from "./QOSProfileFilterReducer";
import PolicyReducer from "./PolicyReducer";
import OperatorReducer from "./OperatorReducer";
import OperatorGroupReducer from "./OperatorGroupReducer";
import EnforcementPointsReducer from "./EnforcementPointsReducer";
import SlicePolicyReducer from "./SlicePolicyReducer";
import trainingEventsReducer from "./trainingEventsReducer";
import AccessLogsReducer from "./AccessLogsReducer";
import EnvironmentReducer from "./EnvironmentReducer";
import UserReducer from "./UserReducer";
import PrometheusReducer from "./PrometheusReducer";
import popupReducer from "./popupReducer";
import SliceNamespaceReducer from "./SliceNamespaceReducer";
import HeirarchicalNamespaceReducer from "./HeirarchicalNamespaceReducer";

const config = {
  key: "root",
  storage,
  blacklist: [
    "SliceFilterReducer",
    "SpinnerReducer",
    "ErrorReducer",
    "ErrorSnackReducer",
  ],
};
export default persistCombineReducers(config, {
  SliceNamespaces: SliceNamespaceReducer,
  popup: popupReducer,
  AccessLogsReducer,
  EnvironmentReducer,
  trainingEventsReducer,
  SlicePolicyReducer,
  OperatorGroupReducer,
  OperatorReducer,
  PolicyReducer,
  ProfileReducer,
  SessionReducer,
  MenuReducer,
  slices: SliceReducer,
  SpinnerReducer,
  qosProfiles: QOSProfileReducer,
  dashboard: DashboardReducer,
  ErrorReducer,
  ErrorSnackReducer,
  roles: RolesReducer,
  SliceFilterReducer,
  QOSProfileFilterReducer,
  enforcementPoints: EnforcementPointsReducer,
  UserReducer,
  PrometheusReducer,
  hnc: HeirarchicalNamespaceReducer,
});
