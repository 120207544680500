import React from "react";
import TocIcon from "@material-ui/icons/Toc";
import { Grid } from "@material-ui/core";
export default function Marker() {
  return (
    <Grid
      container
      justifyContent="space-between"
      style={{ background: "#2868F3", padding: "0.5rem", borderRadius: "30px" }}
    >
      <pre style={{ color: "white", margin: 0,textAlign:"center" }}>{`{clusterCount}`}</pre>
    </Grid>
  );
}
