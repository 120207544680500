import React from "react";
import { BodyRoot } from "./Body/BodyStyles";
import styled from "styled-components";

const Heading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 600;
  color:grey;
`;
export default function NotAvailable() {
  return (
    <BodyRoot>
      <Heading>Feature not available yet</Heading>
    </BodyRoot>
  );
}
