import React, { useEffect, useState } from 'react';
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { TreemapChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from "echarts/components";

import {
  CanvasRenderer
} from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TreemapChart,
  CanvasRenderer
]);


const getOption = (treeMap) => {

  return {
    title: {
      left: "center"
      // text: "Test Header",
      // subtext: "Growth > 0: green; Growth < 0: red; Growth = 0: grey"
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow"
      },
      formatter: (params) => {
        
        return (
          '<p style="font-size:.9em;font-weight: 600; align:left; display:inline">' +
          "CPU utilisation" +
          "</p>" +
          '<div style="display:flex; justify-content: space-between;">' +
          '<p style="font-size:.8em;font-weight: 600; align:left; display:inline">' +
          Math.round((params.data.value + Number.EPSILON) * 100) / 100 + 
           
          "% used" +
          "</p>" +
          '<p style="font-size:.8em;font-weight: 300;  align:left; display:inline">' +
          Math.round(((100-params.data.value) + Number.EPSILON) * 100) / 100 + 
          "% free"+
          "</p>" +
          "</div>" +
          '<progress id="file" value="'+params.data.value+'" max="100"/>'
        );
      }
    },
    series: [
      {
        name: treeMap[1],
        type: "treemap",
        visibleMin: 300,
        data: treeMap[0],
        breadcrumb: {
          show: true,
          left: 'center',
          top: '10px',
          itemStyle: {
            fontSize: 16,
            color: '#2868f2'
          },
          
        },
        top:35,
        leafDepth: 1,
        roam: 'move',
        label: {
          fontSize:16,
          lineHeight:20,
          fontWeight:'normal',
          fontFamily: 'Bai Jamjuree',
          color: 'black',
          show: true,
          formatter: "{b}",
          ellipsis: true
        },
        levels: 
        [
          {
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
              gapWidth: 1
            },
           
          },
          {
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
              gapWidth: 1
            },

          },
          {
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
              gapWidth: 1
            },       
           },
          {
            colorSaturation: [0.3, 0.5]
          }
        ]
      }
    ]
  };
};
export default function TreeMapChart({data}) {
    const [treeMapConfigData, setTreeMapConfigData] = useState({});
   
    useEffect(()=>{
        setTreeMapConfigData(getOption(data));
        // setTreeMapData(data);
    },[data])
  return (
      <ReactEChartsCore
        style={{height:'45vh'}}
        echarts={echarts}
        option={treeMapConfigData}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
  );
}