import * as ApiEndpoint from "./ApiEndpoints";
import BaseBackend from "./BaseBackend";
import * as HttpMethod from "./HTTPMethodConstants";
import { API_HOST, API_KEY } from "./api-config";
import {
  updateSnackbar,
  updateSnackbarSuccess,
} from "./../actions/SnackbarActions";

const parmaterize = (params) => {
  let result = "?";
  for (let param in params) {
    if (params[param] || params[param] == 0)
      result += param + "=" + params[param] + "&";
  }
  result = result.slice(0, result.length - 1);
  return result;
};

const handleError = async (e, errMsg, dispatch) => {
  if (e.bodyUsed) return;
  let msg = "";
  if (e?.text) msg = await e.text();

  if (msg !== undefined) msg = JSON.parse(msg);
  msg = msg?.message;

  if (msg === undefined) msg = errMsg;

  dispatch(updateSnackbar(true, msg));
  throw e;
};

export default class MeshBackend extends BaseBackend {
  constructor() {
    super(API_HOST, API_KEY);
  }

  async getEnvVariables(state, dispatch) {
    let Endpoint = "";
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      null,
      dispatch
    );
    return response;
  }

  async getSample(state, dispatch) {
    let Endpoint = ApiEndpoint.SAMPLE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.session.session_token,
      dispatch
    );
    return response;
  }

  async getAuditLogs(state, dispatch, params) {
    let Endpoint = ApiEndpoint.AUDIT_LOGS + parmaterize(params);
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => handleError(e, "Unable to fetch logs", dispatch));
    return response;
  }

  getClusterMetaData(state, dispatch) {
    let eventCount = ApiEndpoint.EVENT_COUNT;
    let entityCount = ApiEndpoint.ENTITY_COUNT;
    let promiseEventCount = this._fetch(HttpMethod.GET, eventCount, null, state.SessionReducer.session_token, dispatch)
    let promiseEntityCount = this._fetch(HttpMethod.GET, entityCount, null, state.SessionReducer.session_token, dispatch)
    
    return Promise.allSettled([promiseEventCount, promiseEntityCount])
      .then(values => {
        let response = {};
        response.eventCount = values[0].status === 'fulfilled' ? values[0].value : null;
        response.entityCount = values[1].status === 'fulfilled' ? values[1].value : null;
        return response;
      })
      .catch(e => {
        handleError(e, "Unable to fetch Cluster Metadata", dispatch)  
      });
  }

  async getAllSlices(state, dispatch) {
    let Endpoint = ApiEndpoint.SLICE + "/?getSlicePolicy=true";
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => handleError(e, "Unable to fetch Slices", dispatch));
    return response;
  }
  async getAllGatewaysForSlice(state, dispatch, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + "/" + sliceName + ApiEndpoint.SLICE_GATEWAYS;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => handleError(e, "Unable to fetch Slice Gateways", dispatch));
    return response;
  }

  async getOperatorDetails(state, dispatch) {
    let Endpoint = ApiEndpoint.PROFILE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) =>
      handleError(e, "Unable to fetch Operator Details", dispatch)
    );
    return response;
  }

  async postSlice(state, dispatch, sliceDetails) {
    let Endpoint = ApiEndpoint.SLICE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        { slice: sliceDetails },
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, "Slice Created Successfully"));
          response = res;
        })
        .catch((e) => handleError(e, "Unable to Create Slice!", dispatch));
    } catch (e) {
      handleError(e, "Unable to Create Slice!", dispatch);
      throw e;
    }
    return response;
  }

  async deleteSlice(state, dispatch, sliceName) {
    let Endpoint = ApiEndpoint.SLICE + "/" + sliceName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.DELETE,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, "Slice Deleted Successfully"));
          response = res;
        })
        .catch((e) => handleError(e, "Unable to Delete Slice!", dispatch));
    } catch (e) {
      handleError(e, "Unable to delete Slice!", dispatch);
    }
    return response;
  }

  async deleteSlicePolicy(state, dispatch, sliceName, slicePolicyId) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      "/" +
      slicePolicyId;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.DELETE,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      );
    } catch (e) {
      handleError(e, "Unable to delete Slice Policy!", dispatch);
    }
    return response;
  }

  async attachCluster(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/health`;
    let body = {
      clusterName: "cluster-1",
      clusterId: "12nsadfvdsdf",
      siteName: "us-east-1",
    };
    let response = await this._fetch(
      HttpMethod.POST,
      Endpoint,
      body,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getSliceGateway(
    state,
    dispatch,
    sliceName,
    clusterId,
    gatewayId,
    showSpinner = true
  ) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${ApiEndpoint.GATEWAY}/${clusterId}/${gatewayId}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch,
      showSpinner
    );
    return response;
  }

  async getSliceServices(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/svc-import`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async updateSliceNamespaceIsolationProfile(state, dispatch, sliceName, sliceData) {
    // debugger;
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}`;
    let response = null;
    try {
      let { sliceQosProfile, namespaceIsolationProfile } = sliceData;
      let body = {
        sliceQosProfile,
        namespaceIsolationProfile,
      };
     response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        body,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, "Namespace Isolation Profile Updated Successfully"));
          response = res;
        })
        .catch((e) => handleError(e, "Unable to update Namespace Isolation Profile!", dispatch));
    } catch (e) {
      handleError(e, "Unable to update Namespace Isolation Profile!", dispatch);
    }
    return response;
  }
  async updateSlice(state, dispatch, sliceName, sliceData) {
    // debugger;
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}`;
    let response = null;
    try {
      let { sliceQosProfile, namespaceIsolationProfile } = sliceData;
      let body = {
        sliceQosProfile,
        namespaceIsolationProfile,
      };
       response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        body,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, "Slice Updated Successfully"));
          response = res;
        })
        .catch((e) => handleError(e, "Unable to update Slice!", dispatch));
    } catch (e) {
      handleError(e, "Unable to update Slice!", dispatch);
    }
    return response;
  }

  async getQOSProfiles(state, dispatch) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getCompareClusterStats(state, dispatch, cluster1, cluster2) {
    let Endpoint = ApiEndpoint.CLUSTER_COMPARE;
    let response = await this._fetch(
      HttpMethod.POST,
      Endpoint,
      {
        clusters: [cluster1, cluster2],
      },
      state.SessionReducer.session_token,
      dispatch,
      false
    );
    return response;
  }

  async getAllPolicies(state, dispatch) {
    let Endpoint = ApiEndpoint.POLICY;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getAllActions(state, dispatch) {
    let Endpoint = ApiEndpoint.ACTION;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async postPolicy(state, dispatch, policyDetails) {
    let Endpoint = ApiEndpoint.POLICY;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        policyDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, "Policy Created Successfully"));
          response = res;
        })
        .catch((e) => handleError(e, "Unable to Create Policy!", dispatch));
    } catch (e) {
      handleError(e, "Unable to Create Policy!", dispatch);
    }
    return response;
  }

  async editPolicy(state, dispatch, policyDetails, policyId) {
    let Endpoint = `${ApiEndpoint.POLICY}/${policyId}`;
    try {
      let response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        policyDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(updateSnackbarSuccess(true, "Policy Updated Successfully"));
      return response;
    } catch (e) {
      handleError(e, "Unable to edit Policy!", dispatch);
    }
  }

  async getAllOperators(state, dispatch) {
    let Endpoint = ApiEndpoint.OPERATOR;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getAllSliceNamespaces(state, dispatch,sliceName) {
    try{
    let Endpoint = ApiEndpoint.SLICE+'/'+sliceName+ApiEndpoint.NAMESPACE;
    let response = await this._fetch(HttpMethod.GET, Endpoint, null, state.SessionReducer.session_token, dispatch);
    return response;
    }catch (e) {
      handleError(e, "Unable to get all slice namespaces!", dispatch);
    }
  }

  async postOperator(state, dispatch, operatorDetails) {
    let Endpoint = ApiEndpoint.OPERATOR;
    let response = null;

    try {
      await this._fetch(
        HttpMethod.POST,
        Endpoint,
        operatorDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Operator Created Successfully")
          );
          response = res;
        })
        .catch((e) => handleError(e, "Unable to Create Policy!", dispatch));
    } catch (e) {
      handleError(e, "Unable to Create Policy!", dispatch);
    }
    return response;
  }

  async editOperator(state, dispatch, operatorDetails, operatorId) {
    let Endpoint = `${ApiEndpoint.OPERATOR}/${operatorId}`;

    try {
      let response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        operatorDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(updateSnackbarSuccess(true, "Operator Updated Successfully"));
      return response;
    } catch (e) {
      handleError(e, "Unable to edit Operator!", dispatch);
    }
  }

  async deleteOperator(state, dispatch, operatorId) {
    let Endpoint = ApiEndpoint.OPERATOR + "/" + operatorId;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.DELETE,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      );
    } catch (e) {
      handleError(e, "Unable to delete Operator!", dispatch);
    }
    return response;
  }

  async getAllOperatorGroups(state, dispatch) {
    let Endpoint = ApiEndpoint.OPERATORGROUPS;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async postOperatorGroup(state, dispatch, operatorGroupDetails) {
    let Endpoint = ApiEndpoint.OPERATORGROUPS;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        operatorGroupDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Operator Group Created Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to Create Operator Group!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to Create Operator Group!", dispatch);
    }
    return response;
  }

  async editOperatorGroup(state, dispatch, operatorGroupDetails, operatorId) {
    let Endpoint = `${ApiEndpoint.OPERATORGROUPS}/${operatorId}`;

    try {
      let response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        { operatorGroup: operatorGroupDetails },
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(
        updateSnackbarSuccess(true, "Operator Group Updated Successfully")
      );
      return response;
    } catch (e) {
      handleError(e, "Unable to edit Operator Group!", dispatch);
      throw e;
    }
  }

  async postSlicePolicy(state, dispatch, slicePolicyDetails, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + "/" + sliceName + ApiEndpoint.SLICE_POLICY;
    try {
      await this._fetch(
        HttpMethod.POST,
        Endpoint,
        slicePolicyDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(
        updateSnackbarSuccess(true, "Slice Policy Created Successfully")
      );
    } catch (e) {
      handleError(e, "Unable to Create Slice Policy!", dispatch);
    }
  }
  async editSlicePolicy(
    state,
    dispatch,
    slicePolicyDetails,
    sliceName,
    slicePolicyId
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      "/" +
      slicePolicyId;
    try {
      await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        slicePolicyDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(
        updateSnackbarSuccess(true, "Slice Policy Updated Successfully")
      );
    } catch (e) {
      handleError(e, "Unable to Update Slice Policy!", dispatch);
    }
  }

  async postQosProfile(state, dispatch, qosProfileDetails) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        qosProfileDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "QOS Profile Created Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to Create QOS Profile!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to Create QOS Profile!", dispatch);
    }
    return response;
  }

  async attachSlicePolicy(state, dispatch, sliceName, slicePolicyId) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      "/" +
      slicePolicyId +
      "/attach";
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Slice Policy attached Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to attach Slice Policy!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to attach Slice Policy!", dispatch);
    }
    return response;
  }

  async detachSlicePolicy(state, dispatch, sliceName, value) {
    let Endpoint = ApiEndpoint.SLICE + "/" + sliceName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        value,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Slice Policy Detached Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to detach Slice Policy!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to detach Slice Policy!", dispatch);
    }
    return response;
  }

  async editQosProfile(state, dispatch, qosProfileDetails) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        qosProfileDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "QOS Profile Updated Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to Update QOS Profile!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to Edit QOS Profile!", dispatch);
    }
    return response;
  }

  async getAllPoliciesForSlice(state, dispatch, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + "/" + sliceName + ApiEndpoint.LIMITED_SLICE_POLICY;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async resetPassword(state, dispatch, operatorId) {
    try {
      let Endpoint = `${ApiEndpoint.OPERATOR}/${operatorId}/reset-password`;
      let response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch,
        false
      );
      dispatch(
        updateSnackbarSuccess(
          true,
          "Password Changed Successfully Your new password is " +
            response.tempPassword
        )
      );
      return response;
    } catch (e) {
      handleError(e, "Unable to change Password!", dispatch);
    }
  }
  async updateCustomerOverlay(state, dispatch, sliceName, canonicalName, data) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      "/" +
      canonicalName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Customer Overlay updated Successfully")
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to update Customer Overlay!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to update Customer Overlay!", dispatch);
    }
    return response;
  }
  async toggleEnforcementPoint(
    state,
    dispatch,
    sliceName,
    canonicalName,
    data
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      "/" +
      canonicalName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(
              true,
              `Enforcement Point ${
                data.enabled == "true" ? "Enabled" : "Disabled"
              } Successfully`
            )
          );
          response = res;
        })
        .catch((e) =>
          handleError(
            e,
            `Unable to ${
              data.enabled == "true" ? "Enabled" : "Disabled"
            } Enforcement Point`,
            dispatch
          )
        );
    } catch (e) {
      handleError(
        e,
        `Unable to ${
          data.enabled == "true" ? "Enabled" : "Disabled"
        } Enforcement Point`,
        dispatch
      );
    }
    return response;
  }
  async updateEnforcementPointMetric(
    state,
    dispatch,
    sliceName,
    canonicalName,
    data
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      "/" +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      "/" +
      canonicalName +
      "/metrics";
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(
              true,
              "Enforcement Metrics updated Successfully"
            )
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, "Unable to update Enforcement Metrics!", dispatch)
        );
    } catch (e) {
      handleError(e, "Unable to update Enforcement Metrics!", dispatch);
    }
    return response;
  }
  async getEnforcementPoints(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/enforcement-point`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getMetricsUrl(state, dispatch) {
    let Endpoint = ApiEndpoint.METRICS_URL;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getEnforcementByCanonical(state, dispatch, sliceName, canonicalName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/enforcement-point/${canonicalName}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getTrainingEvents(state, dispatch, sliceName, canonicalName, params) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${
      ApiEndpoint.ENFORCEMENT_POINTS
    }/${canonicalName}/minified-events/${parmaterize(params)}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async postTelemetry(state, dispatch, sliceName, clusterId, telemetryDetails) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${ApiEndpoint.TELEMETRY}/${clusterId}`;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        telemetryDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, "Telemetry Created Successfully")
          );
          response = res;
        })
        .catch((e) => handleError(e, "Unable to Create Telemetry!", dispatch));
    } catch (e) {
      handleError(e, "Unable to Create Telemetry!", dispatch);
    }
    return response;
  }

  async getClusterDetail(state,dispatch){
    let Endpoint = ApiEndpoint.CLUSTER;
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch cluster details",dispatch)
    });
    return response;
  }
        
  async getClusterCapacityDetail(state,dispatch){
    let Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.CAPACITY}`;
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch cluster capacity details",dispatch)
    });
    return response;
  }

  async getNodeCapacityDetail(state,dispatch,clusterId){
    let Endpoint = `${ApiEndpoint.CLUSTER}/${clusterId}${ApiEndpoint.NODE}`
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch node capacity details",dispatch)
    });
    return response;
  }

  async getNamespaceCapacityDetail(state,dispatch,clusterId,nodeName){
    let Endpoint = `${ApiEndpoint.CLUSTER}/${clusterId}${ApiEndpoint.NODE}/${nodeName}${ApiEndpoint.NAMESPACE}`
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch namespace capacity details",dispatch)
    });
    return response;
  }

  async getAllServiceDependencyRequest(state,dispatch,clusterId,sliceName,params){
        //remove the mili seconds part from timestamp
        if(params.endTime && params.startTime){
            params.endTime = Math.ceil(params.endTime/1000);
            params.startTime = Math.ceil(params.startTime/1000);
        }
     let Endpoint = `${ApiEndpoint.SERVICE_DEPENDENCY_GRAPH}${ApiEndpoint.SLICE}/${sliceName}${ApiEndpoint.CLUSTER}/${clusterId}${parmaterize(params)}`
     let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
       handleError(e, "Unable to fetch namespace capacity details",dispatch)
     });
     return response;
  }

  async getSystemEvent(state,dispatch,params){
    let Endpoint = `${ApiEndpoint.SYSTEM_EVENT}${parmaterize(params)}`
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch namespace capacity details",dispatch)
    });
    return response;
  }

  async getKubernetesDashboard(state,dispatch,clusterId){
    let Endpoint = `${ApiEndpoint.CLUSTER}/${clusterId}/kubernetes-dashboard`
    let response = await this._fetch(HttpMethod.GET,Endpoint,null,state.SessionReducer.session_token,dispatch).catch((e)=>{
      handleError(e, "Unable to fetch kubernetes dashboard",dispatch)
    });
    return response;
  }
}
