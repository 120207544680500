import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import { ExpandMore } from "@material-ui/icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FormContainer } from "../FormStyles";
import { FormCheck, FormSelectN } from "./../../Commons/formUtils";
import ActionList from "./Actions/ActionList";
import ResourceList from "./ResourceList";
import { connect } from "react-redux";
import Close from "@material-ui/icons/Close";
import { AccordianHeader, AccordionSummary } from "./PermissionStyles";

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      services: [],
      actionCategories: {},
      selectedService: null,
      resources: [],
      allResources: false,
      allActions: false,
      permissionNumber: null,
      admin: false,
      actions: {},
    };
  }
  handleAccordians = (acc) => {
    this.setState({ expanded: this.state.expanded == acc ? null : acc });
  };

  componentDidMount() {
    this.setState({ permissionNumber: this.props.index, admin: this.isAllAccess() }, this.getServices);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (Object.keys(prevState.actionCategories).length == 0 && Object.keys(this.state.actionCategories).length > 0) ||
      this.props.permission != prevProps.permission
    ) {
      this.parsePolicyData();
    }
    if (this.state.admin && !prevState.admin) {
      this.getActions();
    }
    if (
      (this.props.actions?.services || prevProps.actions?.services) &&
      Object.keys(this.props.actions?.services || {}).length != Object.keys(prevProps.actions?.services || {}).length
    ) {
      this.getServices();
    }
  }

  isAllAccess = () => {
    const { permission } = this.props;
    if (permission?.action?.length > 0 && (permission.action[0] == ".*" || permission.action[0] == "*")) {
      return true;
    }
    return false;
  };

  getServices = () => {
    let services = [];
    let { actions, permission } = this.props;

    let newActions = {};
    if (actions?.services && Object.keys(actions.services)?.length > 0) {
      newActions = { services: {} };
      for (let serviceType in actions.services) {
        let actionCategory = Object.keys(actions.services[serviceType].actionCategories)[0];
        let service = actions.services[serviceType].actionCategories[actionCategory].actions[0].split(":")[0];
        newActions.services = { ...newActions.services, [service]: { ...actions.services[serviceType] } };
        services.push(service);
      }
    }
    let selectedService = services[0];

    if (permission?.action?.length > 0 && services.indexOf(permission.action[0].split(":")[0]) != -1) {
      selectedService = permission.action[0].split(":")[0];
    }
    let allResources = false,
      resources = [];
    if (permission?.resources?.length) {
      if (permission.resources[0] == ".*") {
        allResources = true;
      } else {
        resources = [...permission.resources];
      }
    }
    this.setState({ services, selectedService, actions: newActions, allResources, resources }, this.getActions);
  };

  getActions = async () => {
    const { actions } = this.state;
    const { selectedService, admin } = this.state;
    if (!selectedService) return null;
    let flag = admin ? true : false;
    let actionCategories = actions?.services[selectedService]?.actionCategories;
    let newActionCategories = {};
    for (let cat in actionCategories) {
      let newActions = {};
      actionCategories[cat].actions.forEach((action) => (newActions[action] = flag));
      newActionCategories[cat] = { actions: { ...newActions }, all: flag };
    }
    this.setState({ actionCategories: newActionCategories }, () => {
      this.props.onChange(this.formatData());
    });
  };
  parsePolicyData = () => {
    const { permission } = this.props;
    const { services } = this.state;
    let allActions = this.state.allActions;
    let admin = false;
    if (permission) {
      if (permission?.action?.length > 0) {
        //check if all actions
        let action = permission.action[0];
        let actionCategories = { ...this.state.actionCategories };
        let selectedService;
        if (action == ".*") {
          //all actions
          admin = true;
        } else {
          //find service
          action = permission.action[0].split(":");
          selectedService = action[0];

          //check if all actions
          if (action[1] == ".*") {
            //all actions
            allActions = true;
            for (let cat in actionCategories) {
              actionCategories[cat].all = allActions;
              for (let action in actionCategories[cat].actions) {
                actionCategories[cat].actions[action] = allActions;
              }
            }
          } else {
            const categories = actionCategories ? Object.keys(actionCategories) : [];
            permission.action.forEach((action) => {
              let temp = action.split(":");
              console.log("Selected Service", this.state.selectedService, " and action service ", temp[0]);
              if (this.state.selectedService == temp[0]) {
                let cat = temp[1];
                if (cat[cat.length - 1] == "*") {
                  //all actions in this category
                  if (cat.substr(cat.length - 2) == ".*") {
                    cat = cat.substr(0, cat.length - 2);
                  } else cat = cat.substr(0, cat.length - 1);
                  if (actionCategories[cat]) {
                    actionCategories[cat].all = true;
                    //mark all actions in this category as checked
                    for (let act in actionCategories[cat].actions) {
                      actionCategories[cat].actions[act] = true;
                    }
                  }
                } else {
                  //find category of action
                  const category = categories.find((category) => {
                    let reg = new RegExp(category + "+", "i");
                    return reg.test(cat);
                  });
                  if (!category) {
                    //category not found
                  } else {
                    //mark action as true
                    actionCategories[category].actions[action] = true;
                  }
                }
              }
            });
          }
        }

        //checks if all resources
        let allResources = false;
        if (permission?.resources?.length > 0 && permission.resources[0] == ".*") {
          //all resources
          allResources = true;
        }

        this.setState({
          admin,
          actionCategories,
          selectedService,
          resources: allResources ? [] : permission.resources,
          allResources,
          allActions,
        });
      }
    }
  };

  formatData = () => {
    const { selectedService, resources, actionCategories, allActions, allResources, admin } = this.state;
    let actions = [];
    if (admin) {
      actions.push(".*");
    } else if (allActions) {
      actions.push(`${selectedService}:*`);
    } else {
      for (let cat in actionCategories) {
        if (actionCategories[cat].all) {
          actions.push(`${selectedService}:${cat}*`);
        } else {
          for (let action in actionCategories[cat].actions) {
            if (actionCategories[cat].actions[action]) {
              actions.push(action);
            }
          }
        }
      }
    }
    return {
      action: actions,
      resources: allResources ? [".*"] : resources,
    };
  };

  handleServiceChange = (e) => {
    const { services } = this.state;
    this.setState({ selectedService: services[e.target.value] }, this.getActions);
  };

  handleActionChange = (category, action) => {
    let actionCategories = { ...this.state.actionCategories };
    actionCategories[category].actions[action] = !actionCategories[category].actions[action];
    this.setState({ actionCategories }, () => this.props.onChange(this.formatData()));
  };

  handleAllCategoryChange = (category) => {
    debugger;
    let actionCategories = { ...this.state.actionCategories };
    let checked = !actionCategories[category].all;
    actionCategories[category].all = checked;
    for (let action in actionCategories[category].actions) {
      actionCategories[category].actions[action] = checked;
    }
    this.setState({ actionCategories }, () => this.props.onChange(this.formatData()));
  };

  handleAllActionsChange = () => {
    const { allActions } = this.state;
    let actionCategories = { ...this.state.actionCategories };
    for (let cat in actionCategories) {
      actionCategories[cat].all = !allActions;
      for (let action in actionCategories[cat].actions) {
        actionCategories[cat].actions[action] = !allActions;
      }
    }
    this.setState({ allActions: !allActions, actionCategories }, () => this.props.onChange(this.formatData()));
  };

  addToResource = (resource) => {
    this.setState(
      {
        resources: [...this.state.resources, resource],
      },
      () => this.props.onChange(this.formatData())
    );
  };

  deleteResource = (resource) => {
    let resources = [...this.state.resources];
    resources = resources.filter((res) => res != resource);
    this.setState({ resources }, () => this.props.onChange(this.formatData()));
  };

  handleAdmin = () => {
    const { admin } = this.state;
    this.setState({ admin: !admin, allActions: !admin }, this.getServices);
  };

  render() {
    const { expanded, services, actionCategories, resources, allResources, allActions, selectedService, permissionNumber, admin } = this.state;
    return (
      <Accordion style={{ width: "45vw", alignSelf: "center" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <AccordianHeader>
            <div style={{ color: "grey", flex: 1 }}>Permission {permissionNumber + 1} </div>
            <div style={{ color: "grey", flex: 3 }}>{selectedService} </div>
            <Close style={{ marginLeft: "auto", color: "grey" }} onClick={() => this.props.deletePermission()} />
          </AccordianHeader>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormContainer
            style={{
              padding: 0,
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", width: "40vw" }}>
              <FormCheck label="All Access" checked={admin} onChange={this.handleAdmin} />
            </div>
            <div>
              <FormSelectN
                width="40vw"
                label="Service"
                options={services}
                value={services.indexOf(selectedService)}
                onChange={this.handleServiceChange}
                disabled={admin}
              />
            </div>
            <Accordion
              expanded={!admin && expanded == "actions"}
              onChange={() => this.handleAccordians("actions")}
              disabled={admin}
              style={{
                width: "40vw",
                alignSelf: "center",
                boxShadow: "0px 0px 0px 0px",
                borderTop: "0px",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>Actions</div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <ActionList
                  actions={actionCategories}
                  onChange={this.handleActionChange}
                  onAllCategoryClick={this.handleAllCategoryChange}
                  onAllClick={this.handleAllActionsChange}
                  allActions={allActions}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={!admin && expanded == "resources"}
              onChange={() => this.handleAccordians("resources")}
              disabled={admin}
              style={{
                width: "40vw",
                alignSelf: "center",
                boxShadow: "0px 0px 0px 0px",
                borderTop: "0px",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>Resources</div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <ResourceList
                  resources={resources}
                  addToResource={this.addToResource}
                  allResources={allResources}
                  handleAllClick={() => this.setState({ allResources: !allResources }, () => this.props.onChange(this.formatData()))}
                  deleteResource={this.deleteResource}
                />
              </AccordionDetails>
            </Accordion>
          </FormContainer>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = ({ PolicyReducer: { actions } }) => ({
  actions,
});

export default connect(mapStateToProps, {})(Permission);
