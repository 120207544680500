import React from "react";
import { ReactComponent as LogoWithName } from "../../assets/images/LogoWithName.svg";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  :hover {
    cursor: pointer;
  }
`;
export default function AveshaBanner({ open, handleDrawerToggle }) {
  return <Container onClick={handleDrawerToggle}>{open ? <LogoWithName /> : <Logo />}</Container>;
}
