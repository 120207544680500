import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_SLICE_NAMESPACE_COUNT } from "./types";
const meshBackend = getMeshBackend();

export const getAllSliceNamespaces = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllSliceNamespaces(
      getState(),
      dispatch,
      sliceName
    );
    if (response)
      dispatch({ type: SET_SLICE_NAMESPACE_COUNT, payload: response });
  };
};
