import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { FormField } from "../../Commons/formUtils";
import { usePrevious } from "../../Commons/hooksUtils";
import { FormContainer } from "../FormStyles";
import { connect } from "react-redux";
import { createOperator, clearSelectedOperator, editOperator, resetPassword, deleteOperator } from "../../../actions/OperatorActions";
import List from "./List";
import { PermissionForm } from "../Policy/PolicyFormStyles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddOperatorDialog(props) {
  //creating css classes from jss
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [policies, setPolicies] = useState([]);
  const [operatorGroupIds, setOperatorGroupIds] = useState([]);
  const [policyNames, setPolicyNames] = useState([]);
  const [operatorGroupNames, setOperatorGroupNames] = useState([]);

  const prevOpen = usePrevious(props.open);
  const prevPolicies = usePrevious(policies);
  const prevOperatorGroupIds = usePrevious(operatorGroupIds);

  const [errorNameMes, setErrorNameMes] = useState("");
  const [errorEmailMes, setErrorEmailMes] = useState("");
  const [errorPoliciesMes, setErrorPoliciesMes] = useState("");
  const [errorOperatorGroupMes, setErrorOperatorGroupMes] = useState("");

  useEffect(() => {
    if (!prevOpen && props.open) {
      setErrorPoliciesMes("");
      setErrorEmailMes("");
      setErrorNameMes("");
      setErrorOperatorGroupMes("");
      handleOperatorData();
    }
    if (prevPolicies != policies || prevPolicies?.length != policies?.length) {
      setPolicyNames(filterNames(props.policies, policies, "policyId", "policyName"));
    }
    if (prevOperatorGroupIds != operatorGroupIds || prevOperatorGroupIds?.length != operatorGroupIds?.length) {
      setOperatorGroupNames(filterNames(props.operatorGroups, operatorGroupIds, "operatorGroupId", "operatorGroupName"));
    }
  });

  const clearData = () => {
    setName("");
    setEmail("");
    setPolicies([]);
    setOperatorGroupIds([]);
    setOperatorGroupNames([]);
  };

  const addToPolicy = (index) => {
    const policy = props.policies.find((item) => item.policyName == index);
    if (policies.indexOf(policy.policyId) == -1) setPolicies([...policies, policy.policyId]);
  };

  const deletePolicy = (index) => {
    setPolicies([...policies.slice(0, index), ...policies.slice(parseInt(index) + 1)]);
  };

  const addToGroup = (index) => {
    const group = props.operatorGroups.find((item) => item.operatorGroupName == index);
    if (operatorGroupIds.indexOf(group.operatorGroupId) == -1) setOperatorGroupIds([...operatorGroupIds, group.operatorGroupId]);
  };

  const deleteGroup = (index) => {
    setOperatorGroupIds([...operatorGroupIds.slice(0, index), ...operatorGroupIds.slice(parseInt(index) + 1)]);
  };

  const handleSave = () => {
    if (!name.trim()) {
      setErrorNameMes("Name is required");
    }
    if (!email.trim()) {
      setErrorEmailMes("Email is required");
    }
    if (!policies.length && !operatorGroupNames.length) {
      setErrorPoliciesMes("Policies is required");
      setErrorOperatorGroupMes("Operator Group Name is required");
    }
    if ((operatorGroupNames.length || policies.length) && email && name) {
      const { selectedOperator } = props;
      if (selectedOperator?.operatorId) {
        let value = {
          policyIds: policies,
          operatorGroupIds,
        };
        props.editOperator(selectedOperator.operatorId, value);
      } else {
        let value = {
          operatorName: name,
          operatorEmail: email,
          policyIds: policies,
          groupIds: operatorGroupIds,
        };
        props.createOperator(value);
      }
      clearData();
      props.handleClose();
      props.clearSelectedOperator();
    }
  };
  const handleOperatorData = () => {
    const { selectedOperator } = props;
    if (selectedOperator) {
      setName(selectedOperator.name);
      setEmail(selectedOperator.email);
      setPolicies(selectedOperator.policyIds);
      setOperatorGroupIds(selectedOperator.operatorGroupIds);
    }
  };
  const filterNames = (list, ids, idKey, nameKey) => {
    let names = [];
    ids.forEach((id) => {
      for (let i = 0; i < list.length; i++) {
        if (list[i][idKey] == id) {
          names.push(list[i][nameKey]);
          break;
        }
      }
    });
    return names;
  };
  const getNames = (list1, list2, idKey, nameKey) => {
    return list2.map((item) => item[nameKey]);
  };

  const handleResetPassword = async () => {
    const { selectedOperator, resetPassword } = props;
    if (selectedOperator?.operatorId) await resetPassword(selectedOperator?.operatorId);
  };

  const handleDeleteOperator = async () => {
    const { selectedOperator } = props;
    if (selectedOperator?.operatorId) await props.deleteOperator(selectedOperator?.operatorId);
    clearData();
    props.handleClose();
    props.clearSelectedOperator();
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
          props.clearSelectedOperator();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage: "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                clearData();
                props.handleClose();
                props.clearSelectedOperator();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.selectedOperator?.operatorId ? "Edit" : "New"} Operator
            </Typography>
            {props.selectedOperator?.operatorId && (
              <Button variant="contained" autoFocus color="secondary" onClick={handleDeleteOperator} style={{ marginRight: "20px" }}>
                Delete Operator
              </Button>
            )}
            {props.selectedOperator?.operatorId && (
              <Button variant="contained" autoFocus color="secondary" onClick={handleResetPassword} style={{ marginRight: "20px" }}>
                Reset Password
              </Button>
            )}
            <Button variant="contained" autoFocus color="secondary" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              borderRight: "solid black 1px",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <h3>{props.selectedOperator?.operatorId ? "Edit an existing" : "Create a New"} Operator</h3>
              <h4>General Details</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormField
                  error={errorNameMes && !name}
                  helperText={!name && errorNameMes}
                  disabled={props.selectedOperator?.operatorId}
                  label="Operator Name"
                  value={name}
                  onChange={(e) => setName(e.nativeEvent.target.value)}
                />
                <FormField
                  error={errorEmailMes && !email}
                  helperText={!email && errorEmailMes}
                  disabled={props.selectedOperator?.operatorId}
                  label="Operator Email"
                  value={email}
                  onChange={(e) => setEmail(e.nativeEvent.target.value)}
                />
              </div>
            </FormContainer>
          </div>
          <PermissionForm>
            <List
              options={getNames(policies, props.policies, "policyId", "policyName")}
              label="Policy"
              list={policyNames}
              addToList={addToPolicy}
              deleteItem={deletePolicy}
              error={errorPoliciesMes && !policies.length}
              helperText={!policies.length && errorPoliciesMes}
            />
            <List
              options={getNames(operatorGroupIds, props.operatorGroups, "operatorGroupId", "operatorGroupName")}
              label="Operator Group"
              list={operatorGroupNames}
              addToList={addToGroup}
              deleteItem={deleteGroup}
              error={errorOperatorGroupMes && !operatorGroupNames.length}
              helperText={!operatorGroupNames.length && errorOperatorGroupMes}
            />
          </PermissionForm>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({ OperatorReducer: { selectedOperator }, PolicyReducer: { policies }, OperatorGroupReducer: { operatorGroups } }) => ({
  selectedOperator,
  policies,
  operatorGroups,
});
export default connect(mapStateToProps, {
  deleteOperator,
  createOperator,
  editOperator,
  clearSelectedOperator,
  resetPassword,
})(AddOperatorDialog);
