import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
const NavBarItemContainer = styled.div`
  background-color: ${(props) =>
    props.selected ? "#2868F3 !important" : "transparent"};
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 12px;
  height: 4rem;
  align-items: center;
`;
const WhiteBlob = styled.div`
  width: 8px;
  height: 62.5%;
  background: ${(props) =>
    props.selected ? "white !important" : "transparent"};
  margin-left: -3px;
  margin-right: 22px;
  border-radius: 10px;
`;
export function NavBarItem({
  selected,
  Icon,
  label,
  onClick = () => {},
  open = true,
}) {
  return (
    <NavBarItemContainer selected={selected} onClick={onClick} open={open}>
      {<WhiteBlob selected={selected} />}
      <Icon width="22px" style={{ color: selected ? "white" : "#757D8A" }} />
      {open && (
        <Typography fontSize="14px" color={selected ? "white" : "#757D8A"}>
          {label}
        </Typography>
      )}
    </NavBarItemContainer>
  );
}
const SubItemContainer = styled(NavBarItemContainer)`
  padding: 1rem;
  height: 2rem;
  width: unset;
  padding-left: 25%;
`;
export const NavBarSubItem = ({
  selected,
  label,
  Icon,
  onClick,
  open = true,
}) => {
  return (
    <SubItemContainer selected={selected} onClick={onClick} open={open}>
      {Icon}
      {open && (
        <Typography fontSize="14px" color={selected ? "white" : "#757D8A"}>
          {label}
        </Typography>
      )}
    </SubItemContainer>
  );
};
