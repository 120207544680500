import React from "react";
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
const Label = styled.div`
  background-color: #fcfdfd;
  padding: 1rem;
`;
const StyledJSONInput = styled(JSONInput)`
  && {
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      /* display:none; */
    }
    ::-webkit-scrollbar-thumb {
      background-color: #e3e3e3;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: grey;
    }
  }
`;
export default function JsonEditor({ label, ...otherProps }) {
  return (
    <Paper style={{ width: otherProps.width || "40rem", marginTop: "1rem" }}>
      <Label>{label}</Label>
      <StyledJSONInput
        theme="light_mitsuketa_tribute"
        locale={locale}
        colors={{
          default:"black",
          string: "#DAA520", // overrides theme colors with whatever color value you want
        }}
        height={otherProps.height || "10rem"}
        waitAfterKeyPress={3000}
        width="100%"
        {...otherProps}
      />
    </Paper>
  );
}
