import React, { Component } from "react";
import styled from "styled-components";
import { DataGrid } from "@material-ui/data-grid";
import { connect } from "react-redux";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import moment from "moment";
const Container = styled.div`
  display: flex;
  flex: 8;
`;
const Open = styled(OpenInNewIcon)`
  align-self: center;
  :hover {
    cursor: pointer;
  }
`;
class LogTable extends Component {
  getColumns = () => {
    const { type, policies, operatorGroups } = this.props;
    const sliceColumns = [
      { field: "sliceName", headerName: "Name", flex: 1 },
      { field: "sliceSubnet", headerName: "Subnet", flex: 1 },
      { field: "sliceType", headerName: "Type", flex: 1 },
      {
        field: "sliceQosProfile",
        headerName: "QoS Profile",
        flex: 1,
      },
    ];
    const operatorGroupColumns = [
      { field: "operatorGroupName", headerName: "Group Name", flex: 1 },
      {
        field: "policies",
        headerName: "Policies",
        flex: 1,
        valueGetter: (params) =>
          `${
            params
              .getValue(params.id, "policyIds")
              ?.map((id) => policies.find((policy) => policy.policyId === id).policyName)
              .join(", ") || ""
          }`,
      },
    ];
    const operatorColumns = [
      { field: "name", headerName: "Name", flex: 1 },
      { field: "email", headerName: "Email", flex: 1 },
      {
        field: "policies",
        headerName: "Policies",
        flex: 1,
        valueGetter: (params) =>
          `${
            params
              .getValue(params.id, "policyIds")
              ?.map((id) => policies.find((policy) => policy.policyId === id).policyName)
              .join(", ") || ""
          }`,
      },
      {
        field: "operator_groups",
        headerName: "Operator Groups",
        flex: 1,
        valueGetter: (params) =>
          `${
            params
              .getValue(params.id, "operatorGroupIds")
              ?.map((id) => operatorGroups.find((group) => group.operatorGroupId === id).operatorGroupName)
              .join(", ") || ""
          }`,
      },
    ];
    const accessPolicyColumns = [
      { field: "policyName", headerName: "Name", flex: 1 },
      {
        field: "updated",
        headerName: "Updated At",
        flex: 1,
        valueGetter: (params) => {
          const val = params.getValue(params.id, "updatedAtTime");
          return moment(val).format("MM/DD/YYYY kk:mm ");
        },
      },
      {
        field: "permission",
        headerName: "Permissions",
        flex: 1,
        renderCell: (params) => (
          <Open
            onClick={() => {
              this.props.openDialog("permission", params.getValue(params.id, "permissions"));
            }}
          />
        ),
      },
    ];

    switch (type) {
      case "slice":
        return sliceColumns;
        break;
      case "operator_groups":
        return operatorGroupColumns;
        break;
      case "operators":
        return operatorColumns;
        break;
      case "access_policy":
        return accessPolicyColumns;
        break;
      default:
        return sliceColumns;
        break;
    }
  };
  render() {
    const { data } = this.props;

    return (
      <Container>
        <DataGrid rows={data?.length ? data : []} columns={this.getColumns()} pageSize={5} />
      </Container>
    );
  }
}

const mapStateToProps = ({ PolicyReducer: { policies }, OperatorGroupReducer: { operatorGroups } }) => ({
  policies,
  operatorGroups,
});

export default connect(mapStateToProps, {})(LogTable);
