import { SHOW_ERROR, REMOVE_ERROR, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return { ...state, errorMessage: action.payload.message };
      break;
    case REMOVE_ERROR:
      return { ...state, errorMessage: null };
      break;
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
