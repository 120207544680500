import { SET_QOS_PROFILES, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  qos_profiles: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_QOS_PROFILES:
      return {
        ...state,
        qos_profiles: action.payload?.qosProfiles,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
