import React, { Component } from "react";
import { connect } from "react-redux";
import PolicyCard from "./OperatorCard";
import styled from "styled-components";
import { Toolbar, InputBase, AppBar } from "./OperatorStyles";
import { Button } from "@material-ui/core";
import AddPolicyDialog from "./AddOperatorDialog";
import { BodyRoot } from "./../../Body/BodyStyles";
import Filters from "./../../Slices/Filters/Filters";
import { FilterButton } from "./../../Commons/IconButtons";
import { getAllOperators, selectOperator, clearSelectedOperator } from "./../../../actions/OperatorActions";
import { getAllPolicies } from "../../../actions/PolicyActions";
import { getAllOperatorGroups } from "../../../actions/OperatorGroupActions";
import { createSearchRegex } from "../../../utils/functions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

class OperatorList extends Component {
  constructor() {
    super();

    this.state = {
      showFilters: false,
      openOperatorDialog: false,
      searchQuery: "",
      filters: null,
      policyMap: {},
      groupMap: {},
    };
  }
  componentDidMount() {
    this.props.getAllOperators();
    this.props.getAllPolicies();
    this.props.getAllOperatorGroups();
    this.setState({ filters: this.getFilters() },this.getMaps);
  }
  componentDidUpdate(prevProps) {
    if (this.props.policyList?.length != prevProps.policyList?.length || this.props.operatorGroups?.length != prevProps.operatorGroups?.length) {
      this.setState(
        {
          filters: this.getFilters(),
        },
        this.getMaps
      );
    }
  }
  getMaps = () => {
    let policyMap = {},
      groupMap = {};
    if (this.props.policyList?.length) this.props.policyList.forEach(({ policyName, policyId }) => (policyMap[policyId] = policyName));
    if (this.props.operatorGroups?.length)
      this.props.operatorGroups.forEach(({ operatorGroupName, operatorGroupId }) => (groupMap[operatorGroupId] = operatorGroupName));
    this.setState({ policyMap, groupMap });
  };
 
  getFilters = () => {
    let values = {};
    let filters = {};
    if (this.props.policyList?.length) {
      this.props.policyList.forEach((policy) => {
        values[policy?.policyName] = true;
      });
      filters = { policies: { label: "Access Policies", values: { ...values } } };
    }
    if (this.props.operatorGroups?.length) {
      values = {};
      this.props.operatorGroups.forEach((group) => {
        values[group?.operatorGroupName] = true;
      });
      filters = { ...filters, groups: { label: "Operator Groups", values: { ...values } } };
    }
    
    return filters;
  };
  filterOperator = () => {
    const { policyMap, groupMap } = this.state;
    let operatorList = [...this.props.operatorList];
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    operatorList = operatorList.filter((operator) => search.test(operator?.name));
    if (this.state.filters) {
      const filterCategories = Object.keys(this.state.filters);
      for (let i = 0; i < filterCategories.length; i++) {
        for (let filter in this.state.filters[filterCategories[i]].values) {
          if (this.state.filters[filterCategories[i]].values[filter] == false) {
            if (filterCategories[i] === "policies") {
              operatorList = operatorList.filter((operator) => {
                return !operator.policyIds.filter((id) => policyMap[id] == filter)?.length;
              });
            }
            if (filterCategories[i] === "groups") {
              operatorList = operatorList.filter((operator) => {
                return !operator.operatorGroupIds.filter((id) => groupMap[id] == filter).length;
              });
            }
          }
        }
      }
    }
    return operatorList;
  };
  render() {
    const { showFilters, openOperatorDialog,filters } = this.state;
    const operatorList = this.filterOperator();
    return (
      <BodyRoot>
        <AddPolicyDialog open={openOperatorDialog} handleClose={() => this.setState({ openOperatorDialog: false })} />
        {filters && <Filters
          open={showFilters}
          onClose={() => this.setState({ showFilters: false })}
          filters={filters}
          updateFilter={(filters) => this.setState({ filters: { ...filters } })}
        />}
        <AppBar position="static">
          <Toolbar>
            <InputBase placeholder="Search" onChange={(e) => this.setState({ searchQuery: e?.nativeEvent?.target?.value })} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton style={{ color: "black", marginRight: 20 }} onClick={() => this.setState({ showFilters: true })} />
              <Button variant="outlined" onClick={() => this.setState({ openOperatorDialog: true })}>
                Create Operator
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        {operatorList?.length > 0 ? (
          <Container>
            {operatorList.map((operator, index) => (
              <PolicyCard
                operator={operator}
                index={index}
                onClick={() => {
                  this.props.selectOperator(operator);
                  this.setState({ openOperatorDialog: true });
                }}
              />
            ))}
          </Container>
        ) : (
          <div style={{ textAlign: "center", margin: "50px", color: "gray" }}>
            <h3>No Operators Present. Create New Operator</h3>
          </div>
        )}
      </BodyRoot>
    );
  }
}

const mapStateToProps = (state) => ({
  operatorList: state.OperatorReducer.operators,
  policyList: state.PolicyReducer.policies,
  operatorGroups: state.OperatorGroupReducer.operatorGroups,
  filters: state.SliceFilterReducer,
});
const mapDispatchToProps = {
  getAllOperatorGroups,
  getAllPolicies,
  getAllOperators,
  selectOperator,
  clearSelectedOperator,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorList);
