import React, { Component } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import PermissionDialog from "./PermissionDialog";
import styled from "styled-components";
import { ScrollBarStyle } from "../../../AppStyles";
const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    min-width: 50vw;
    min-height: 50vh;
    overflow: auto;
    ${ScrollBarStyle}
  }
`;
export default class DetailsDialog extends Component {
  render() {
    const { handleClose, open, type, data } = this.props;
    let SelectedDialog;
    switch (type) {
      case "permission":
        SelectedDialog = <PermissionDialog data={data} />;
        break;

      default:
        SelectedDialog = <div>Invalid Data</div>;
        break;
    }
    return (
      <Dialog onClose={handleClose} maxWidth="lg" open={open}>
        {SelectedDialog}
      </Dialog>
    );
  }
}
