import { UPDATE_QOS_FILTER, ACTION_LOGOUT_INITIATED } from "../actions/types";
import { keyMap } from "../utils/keyMap";

const valueData = {};

Object.keys(keyMap.dscpClass).forEach((ele) => {
  valueData[ele] = true;
});

const initialState = {
  dscpClass: {
    label: "DSCP Class",
    values: {
      ...valueData,
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_QOS_FILTER:
      return { ...state, ...payload };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
