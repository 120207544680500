import React, { Component } from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  min-height: 30vh;
  /* min-width: 50vw; */
  flex-direction: column;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const PermissionRow = styled.div`
  display: flex;
  /* flex-direction: column; */
  padding: 0.5rem 1rem;
  border-bottom: solid #e3e3e3 1px;
`;
const Label = styled.h4`
  margin: 0.5rem;
`;
const Value = styled.p`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  margin: 0.5rem;
`;
const DialogHeading = styled.h3`
  margin: 1rem;
`;
export default class PermissionDialog extends Component {
  render() {
    const { data } = this.props;

    return (
      <Container>
        <DialogHeading>Permissions</DialogHeading>
        {data.map((permission) => (
          <PermissionRow>
            <Column>
              <Label>Actions</Label>
              <Value>{permission?.action?.join(", ")}</Value>
            </Column>
            <Column>
              <Label>Resources</Label>
              <Value>{permission?.resources?.join(", ")}</Value>
            </Column>
          </PermissionRow>
        ))}
      </Container>
    );
  }
}
