import React, { Component } from "react";
import { connect } from "react-redux";
import PolicyCard from "./OperatorCard";
import styled from "styled-components";
import {
  Toolbar,
  InputBase,
  AppBar,
} from "./OperatorStyles";
import { Button } from "@material-ui/core";
import AddPolicyDialog from "./AddOperatorDialog";
import { BodyRoot } from "../../Body/BodyStyles";
import Filters from "../../Slices/Filters/Filters";
import { FilterButton } from "../../Commons/IconButtons";
import {
  getAllOperatorGroups,
  selectOperatorGroup,
  clearSelectedOperatorGroup,
} from "./../../../actions/OperatorGroupActions";
import { getAllPolicies } from './../../../actions/PolicyActions';
import { createSearchRegex } from "../../../utils/functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

class OperatorGroupList extends Component {
  constructor() {
    super();

    this.state = {
      showFilters: false,
      openSliceDialog: false,
      searchQuery: "",
      filters:null,
      policyMap: {},
    };
  }

  componentDidMount() {
    this.props.getAllOperatorGroups();
    this.props.getAllPolicies();
    this.setState({ filters: this.getFilters() },this.getMaps);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.policyList?.length != prevProps.policyList?.length ) {
      this.setState(
        {
          filters: this.getFilters(),
        },
        this.getMaps
      );
    }
  }

  getMaps = () => {
    let policyMap = {}
    if (this.props.policyList?.length) this.props.policyList.forEach(({ policyName, policyId }) => (policyMap[policyId] = policyName));
    this.setState({ policyMap });
  };

  getFilters = () => {
    let values = {};
    let filters = {};
    if (this.props.policyList?.length) {
      this.props.policyList.forEach((policy) => {
        values[policy?.policyName] = true;
      });
      filters = { policies: { label: "Access Policies", values: { ...values } } };
    }
    
    return filters;
  };

  filterSlice = () => {
    const { policyMap } = this.state;
    let operatorGroupList = [...this.props.operatorGroupList];
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    operatorGroupList = operatorGroupList.filter((operator) =>
      search.test(operator?.operatorGroupName)
    );
    if (this.state.filters) {
      const filterCategories = Object.keys(this.state.filters);
      for (let i = 0; i < filterCategories.length; i++) {
        for (let filter in this.state.filters[filterCategories[i]].values) {
          if (this.state.filters[filterCategories[i]].values[filter] == false) {
            if (filterCategories[i] === "policies") {
              operatorGroupList = operatorGroupList.filter((operator) => {
                return !operator.policyIds.filter((id) => policyMap[id] == filter)?.length;
              });
            }
            
          }
        }
      }
    }
    return operatorGroupList;
  };

  render() {
    const { showFilters, openSliceDialog,filters } = this.state;
    const operatorGroupList = this.filterSlice();
    return (
      <BodyRoot>
        <AddPolicyDialog
          open={openSliceDialog}
          data={operatorGroupList}
          handleClose={() => this.setState({ openSliceDialog: false })}
        />
        {filters && <Filters
          open={showFilters}
          onClose={() => this.setState({ showFilters: false })}
          filters={filters}
          updateFilter={(filters) => this.setState({ filters: { ...filters } })}
        />}
        <AppBar position="static">
          <Toolbar>
            <InputBase
              placeholder="Search"
              onChange={(e) =>
                this.setState({ searchQuery: e?.nativeEvent?.target?.value })
              }
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton
                style={{ color: "black", marginRight: 20 }}
                onClick={() => this.setState({ showFilters: true })}
              />
              <Button
                variant="outlined"
                onClick={() => this.setState({ openSliceDialog: true })}
              >
                Create Operator Group
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        {operatorGroupList?.length > 0 ? (
          <Container>
            {operatorGroupList.map((group, index) => (
              <PolicyCard
              key={group.operatorGroupName}
              index={index}
                group={group}
                onClick={() => {
                    this.props.selectOperatorGroup(group);
                  this.setState({ openSliceDialog: true });
                }}
              />
            ))}
          </Container>
        ) : (
          <div style={{ textAlign: "center", margin: "50px", color: "gray" }}>
            <h3>No Operators Present. Create New Operator</h3>
          </div>
        )}
      </BodyRoot>
    );
  }
}

const mapStateToProps = (state) => ({
  operatorGroupList: state.OperatorGroupReducer.operatorGroups,
  filters: state.SliceFilterReducer,
  policyList: state.PolicyReducer.policies,
});

const mapDispatchToProps = {
  getAllPolicies,
  getAllOperatorGroups,
  selectOperatorGroup,
  clearSelectedOperatorGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorGroupList);
