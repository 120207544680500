import { CLOSE_POPUP, OPEN_POPUP } from "../actions/types";

const initialState = {
  popup: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_POPUP:
      return { ...state, popup: payload.type };
    case CLOSE_POPUP:
      return { ...initialState };
    default:
      return state;
  }
};
