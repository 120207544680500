import { SET_NAMESPACE_LIST } from '../actions/types'
const initialState = {
  namespaceList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NAMESPACE_LIST:
      return { ...state, namespaceList: payload };
    default:
      return state;
  }
};
