import React from "react";
import { BodyRoot } from "./BodyStyles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import QosForm from "./QosProfileForm";
import PopUp from "../Commons/popUtils";
import { Toolbar, InputBase, AppBar } from "./ListStyles";
import QoSProfileCard from "./QoSProfileCard";
import { Button } from "@material-ui/core";
import Filters from "./../Slices/Filters/Filters";
import { FilterButton } from "../Commons/IconButtons";
import { updateQosFilter } from "../../actions/QosProfileFilterActions";
import { createSearchRegex } from "../../utils/functions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

class TcBody extends React.Component {
  constructor() {
    super();
    this.state = {
      tcDetails: {},
      tcName: "",
      addButtonHeight: 0,
      showForm: false,
      selectedProfile: null,
      showFilters: false,
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const tcId = query.get("id");
    this.setState({ tcDetails: this.props.qosProfiles[tcId], tcName: tcId });
  }

  componentDidUpdate() {
    const query = new URLSearchParams(this.props.location.search);
    const tcId = query.get("id");

    if (tcId !== this.state.tcName) {
      this.setState({ tcDetails: this.props.qosProfiles[tcId], tcName: tcId });
    }
  }

  filterProfiles = () => {
    let profileList = { ...this.props.qosProfiles };
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    let filteredProfiles = Object.keys(profileList).filter((profile) => search.test(profile));
    let result = {};
    for (let i = 0; i < filteredProfiles.length; i++) {
      for (let category in this.props.filters) {
        if (this.props.filters[category].values[profileList[filteredProfiles[i]][category]]) {
          result[filteredProfiles[i]] = profileList[filteredProfiles[i]];
        }
      }
    }
    return result;
  };

  getProfiles = () => {
    let result = [];
    let profiles = this.filterProfiles();
    for (var i in profiles) {
      let profile = { name: i, ...profiles[i] };
      result.push(<QoSProfileCard profile={profile} index={result.length} onEdit={() => this.setState({ showForm: true, selectedProfile: profile })} />);
    }
    return result;
  };
  render() {
    const { showForm, selectedProfile, showFilters } = this.state;
    return (
      <BodyRoot>
        <Filters
          filters={this.props.filters}
          updateFilter={this.props.updateQosFilter}
          open={showFilters}
          onClose={() => this.setState({ showFilters: false })}
        />
        <PopUp open={showForm}>
          <QosForm
            qosProfiles={this.props.qosProfiles}
            selectedProfile={selectedProfile}
            cancelListener={() => this.setState({ showForm: false, selectedProfile: null })}
          />
        </PopUp>
        <AppBar position="static">
          <Toolbar>
            <InputBase placeholder="Search" onChange={(e) => this.setState({ searchQuery: e?.nativeEvent?.target?.value })} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton style={{ color: "black", marginRight: 20 }} onClick={() => this.setState({ showFilters: true })} />
              <Button variant="outlined" onClick={() => this.setState({ showForm: true })}>
                Create QoS Profile
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Container>{this.getProfiles()}</Container>
      </BodyRoot>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    qosProfiles: store.qosProfiles.qos_profiles,
    filters: store.QOSProfileFilterReducer,
  };
};

export default withRouter(connect(mapStateToProps, { updateQosFilter })(TcBody));
