import { ACTION_LOGOUT_INITIATED, START_SPINNER, STOP_SPINNER } from "./types";
import { Auth } from "aws-amplify";
import history from "./../utils/history";

export const logoutUser = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: START_SPINNER });
      await Auth.signOut();
      dispatch({
        type: ACTION_LOGOUT_INITIATED,
        payload: { session_token: "" },
      });
      localStorage.clear();
      dispatch({ type: STOP_SPINNER });
      history.push("/");
    } catch (error) {
      dispatch({ type: STOP_SPINNER });
      console.log("error signing out: ", error);
    }
  };
};
