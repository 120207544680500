import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_KIALI_URL } from "./types";

const meshBackend = getMeshBackend();

export const getPrometheusUrls = (sliceName) => {
  return async (dispatch, getState) => {
    const response = await meshBackend.getMetricsUrl(getState(), dispatch);
    const payload = response.metricProviders?.prometheus?.metrics?.sliceGateways?.sliceUrls[sliceName];
    if (response) dispatch({ type: SET_KIALI_URL, payload });
  };
};
