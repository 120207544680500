import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import NotificationCard from "./NotificationCard";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItem, ListItemAvatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import { connect } from "react-redux";
import EventTable from "./EventTable";
import moment from "moment";
import styled from "styled-components";
const CountBadge = styled.div`
  position: absolute;
  font-size: 10px;
  border-radius: 100%;
  right: 1%;
  top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: red;
  aspect-ratio: 1;
  min-width: 16px;
  padding: 2px;
`;
const Notification = ({ systemEvents }) => {
  const [openEvent, setOpenEvent] = React.useState(false);
  const handleEventOpen = () => {
    setOpenEvent(true);
  };
  const handleEventClose = () => {
    setOpenEvent(false);
  };
  const notificationCount = systemEvents.length;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div style={{ textAlign: "right", flex: "1", paddingTop: "5px" }}>
        <IconButton onClick={handleEventOpen}>
          <Badge color="error" badgeContent={notificationCount}>
            <NotificationsIcon style={{ fill: "#12153e" }} fontSize="large" />
          </Badge>
          {/* <CountBadge>12</CountBadge>
          <NotificationsIcon style={{ fill: "#12153e" }} fontSize="large" /> */}
        </IconButton>
      </div>
      {/* <Popover
        // id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 100, left: '80%' }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* <Typography className={classes.typography}>The content of the Popover.</Typography> 

        <List
          // anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          // transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          // anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          sx={{
            paddingTop: "20px",
            maxHeight: "60vh",
            width: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                lineHeight: "24px",
                color: "#000000",
              }}
            >
              Notifications
            </ListSubheader>
          }
        >
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <span
                  style={{
                    fontSize: 12,
                    color: "#000000",
                    opacity: "0.6",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    lineHeight: "16px",
                  }}
                >
                  Recent
                </span>
              }
            />
          </ListItem>
          {systemEvents.length > 0 ? (
            <>
              {systemEvents.map((event, index) => (
                <NotificationCard
                  key={index}
                  open={openEvent}
                  setOpen={setOpenEvent}
                  handleClickOpen={handleEventOpen}
                  description={event.description}
                  timeStamp={moment(event.createdAtTime).format("LLL")}
                  location=""
                />
              ))}

               <ListItem>
        <ListItemText
          disableTypography
          primary={<span style={{ fontSize: 12 }}>Past</span>}
        />
      </ListItem>
      <ListItemButton>
        <ListItemAvatar>
        <Avatar style={{backgroundColor:'#FCE8E8',color:'#e31a1a'}}>
            <WarningIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div style={{
              // padding: "5px 0 5px 0",
              fontStyle:'normal',
              fontWeight:'normal',
              lineHeight:'20px',
              fontSize:'16px'
            }}>
                 New Slice: slice103 is been added in edge_outpost cluster
            </div>
           }
          secondary={
            <div>
             
             
              <div>
                <span style={{
                  fontStyle:'normal',
                  fontWeight:'normal',
                  lineHeight:'16px',
                  fontSize:'12px',
                  opacity: '0.6',
                  color:'#000'
                }}>Created at:</span> 
                <span style={{
                  paddingLeft:'5px',
                  fontStyle:'normal',
                  fontWeight:'normal',
                  lineHeight:'16px',
                  fontSize:'12px',
                  color:'#000'
                }}>01/12/2021, 11:09:56 PM PST</span>

                </div>
                <div>
                <span style={{
                  fontStyle:'normal',
                  fontWeight:'normal',
                  lineHeight:'16px',
                  fontSize:'12px',
                  opacity: '0.6',
                  color:'#000'
                }}>Location:</span> 
                <span style={{
                  paddingLeft:'5px',
                  fontStyle:'normal',
                  fontWeight:'normal',
                  lineHeight:'16px',
                  fontSize:'12px',
                  color:'#000'
                }}>Chennai, India</span>

                </div>
             
            </div>
          }
        />
      </ListItemButton>
      <Divider style={{borderStyle:'none'}}component="li" variant="middle" /> 
            </>
          ) : (
            <ListItem>
              <div
                style={{
                  // height: '200px',
                  // position: 'relative',
                  marginLeft: "20%",
                  opacity: 0.5,

                  // transform: 'translate(-50%, -50%)'
                }}
              >
                No events available
              </div>
            </ListItem>
          )}
        </List>
      </Popover> */}
      <EventTable
        open={openEvent}
        setOpen={setOpenEvent}
        handleClickOpen={handleEventOpen}
        handleClose={handleEventClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    systemEvents: state.dashboard.systemEvents,
  };
};

export default connect(mapStateToProps)(Notification);
