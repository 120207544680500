import React, { Component } from "react";
import { Tile } from "./DashboardStyles";
import Graph from "./Graph";
import { GraphLegendName } from "../Commons/dashboardUtils";
import { connect } from "react-redux";
import { setLegendData } from "./../../actions/dashboardActions";

class LatencyGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredSlices: {},
      data: [],
      filter: {},
      isValueSet: false,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data?.length !== this.props.data?.length) {
      if (!this.props.data?.length) this.setState({ data: [] }, this.parseData);
      else this.parseData();
    }
  }

  getKey = (data, slice, type) => {
    try {
      if (data?.slices[slice]?.stats[type]?.latency === undefined) return null;
    } catch (e) {
      return null;
    }

    return Number(data?.slices[slice]?.stats[type]?.latency);
  };

  handleLegenderFormatter = (value, entry, index) => {
    const { filteredSlices } = this.state;
    try {
      if (filteredSlices[value]) {
        return <span style={{ textDecoration: "line-through" }}>{value}</span>;
      } else {
        return <span>{value}</span>;
      }
    } catch (e) {
      return <span>{value}</span>;
    }
  };
  handleFilterData = (sliceName, flag) => {
    let data = [...this.props.data];
    let filter = { ...this.state.filter };
    filter[sliceName] = flag;
    let filterSlices = Object.keys(filter).filter((slice) => !filter[slice]);
    data = data.map((row) => {
      let { slices } = row;
      filterSlices.forEach((slice) => {
        slices[slice].stats = [];
      });
      row.slices = { ...slices };
      return { ...row, slices };
    });
    this.setState({ filter, data });
  };
  parseData = () => {
    const { data } = this.props;
    const { filter } = this.state;
    let filterSlices = Object.keys(filter).filter((slice) => !filter[slice]);
    let row = data[data.length - 1];
    if (!row) return;
    let { slices } = row;
    filterSlices.forEach((slice) => {
      slices[slice].stats = [];
    });
    row.slices = { ...slices };
    let currentData = this.state.data;
    if(this.state.data.length>5){ //only saves data of last 5 occurence
      currentData.shift()
    }
    this.setState({ data: [...currentData, row] });
  };
  render() {
    const { filteredSlices } = this.state;
    return (
      // <Tile style={{ width: "57%" }}>
        <Graph
          // height={200}
          data={this.state.data}
          XLabel="Time"
          getKey={this.getKey}
          getName={GraphLegendName}
          YLabel="Latency (ms)"
          legend={(props) => {
            if (props.payload.length > 0 && !this.props.isValueSet) {
              this.props.setLegendData(props, { data: this.props.data, filter: this.state.filter });
            }
            
          }}
          onItemClick={(e) => {
            this.setState({ filteredSlices: { ...filteredSlices, [e.value]: !filteredSlices[e.value] || true } });
          }}
          legendFormatter={this.handleLegenderFormatter}
        />
      // </Tile>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    legend: store.dashboard,
    isValueSet: store.dashboard.isValueSet,
  };
};

export default connect(mapStateToProps, { setLegendData })(LatencyGraph);
