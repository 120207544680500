import React, { memo } from "react";

import { Handle } from "react-flow-renderer";

export default memo(({ data }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "grey",
          minHeight: "20px",
          minWidth: "200px",
          // borderLeft:'solid #4285f4 8px',
          borderRadius: "14px",
          padding: "4px",
          backgroundColor: "#2868f3",
          color: "#fff",
          cursor: "default",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <Handle
          type="target"
          position="right"
          style={{ left: 0, background: "#555", opacity: 0 }}
        />
        <pre style={{margin:0}}>{data.clusterName}</pre>

        <Handle
          type="source"
          position="bottom"
          id="b"
          style={{ top: 20, opacity: 0, marginBottom: "-5px" }}
        />
      </div>
    </>
  );
});
