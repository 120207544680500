import styled from "styled-components";
import Theme from "./../../Theme/index";
import Colors from "../../Theme/colors.json";
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 100%;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 1rem;
`;
export const Label = styled.div`
  color: grey;
  font-size: 12px;
`;
export const CheckList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  align-self: center;
  height: 10rem;
  background-color: #e8e8e8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.5rem;
  padding-left: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1rem;

  border-bottom: solid #999999 1px;
`;

export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x:hidden;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
