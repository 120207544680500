import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ServiceLatency from "./ServiceLatency";
import SvgClusterMap from "./SvgClusterMap";
import ClusterLogicalView from "./ClusterLogicalView";
import NodesView from "./NodesView";
import SliceNamespaces from "./SliceNamespaces";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import styled from "styled-components";
import { ReactFlowProvider } from "react-flow-renderer";

const serviceStyle = {
  position: "relative",
  textAlign: "center",
  minHeight: "52vh",
  width: "100%",
};
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#2868F3",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "normal !important",
    fontSize: "16px !important",
    marginRight: "1rem",
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#000000 !important",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabPanelDashboard({ value, onChange }) {
  const classes = useStyles();
  const [logicalView, setlogicalView] = useState(false);
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={serviceStyle}>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ padding: "0rem 16px" }}
      >
        <StyledTabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
          <StyledTab
            style={{ textTransform: "none" }}
            label="Clusters"
            {...a11yProps(0)}
          />
          <StyledTab
            style={{ textTransform: "none" }}
            label="Slices"
            {...a11yProps(1)}
          />
          <StyledTab
            style={{ textTransform: "none" }}
            label="Nodes"
            {...a11yProps(2)}
          />
          <StyledTab
            style={{ textTransform: "none" }}
            label="Services Dependency Graph"
            {...a11yProps(3)}
          />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container direction="column" alignItems="flex-end">
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={logicalView}
                onChange={() => setlogicalView(!logicalView)}
              />
            }
            label={`Switch to ${logicalView ? "map" : "logical"} view`}
          />
          <Grid item xs style={{ width: "100%" }}>
            {logicalView ? (
              <div style={serviceStyle}>
                <ReactFlowProvider>
                  <ClusterLogicalView />
                </ReactFlowProvider>
              </div>
            ) : (
              <SvgClusterMap />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SliceNamespaces />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={serviceStyle}>
          <NodesView />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div style={serviceStyle}>
          <ServiceLatency />
        </div>
      </TabPanel>
    </div>
  );
}
