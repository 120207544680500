import styled from "styled-components";
import MuiClose from "@material-ui/icons/Close";
export const Close = styled(MuiClose)`
  :hover {
    cursor: pointer;
  }
`;
export const Scrollable = styled.div`
  max-height: 10rem;
  width: fit-content;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;

export const Resource = styled.div`
  margin-bottom: 0.4rem;
  /* background-color: #e8e8e8; */
  width: 40rem;
  max-width: 40rem;
  padding: 8px;
  /* padding-left: 1rem; */
  border-bottom: 1px grey solid;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;
