import React, { Component } from "react";
import { FormCheck, FormCheckList } from "../../../Commons/formUtils";
import "./ActionCategory.css";
export default class ActionCategory extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   allChecked: false,
    // };
  }
  render() {
    const { label, options ,allChecked} = this.props;
    // const {  } = this.state;
    return (
      <>
        <div className="CategoryLabelContainer">
          <div className="CategoryLabel">{label}</div>
          <FormCheck
            label="All"
            checked={allChecked}
            onChange={() => {
              this.setState({ allChecked: !allChecked });
              this.props.onAllClick(label);
            }}
          />
        </div>
        <div className="CheckContainer">
          {Object.keys(options).map((option) => (
            <FormCheck
              label={option}
              checked={options[option]}
              onChange={() => this.props.onChange(option)}
            />
          ))}
        </div>
      </>
    );
  }
}
