export const ADD_SESSION = "ADD_SESSION";
export const ACTION_LOGOUT_INITIATED = "ACTION_LOGOUT_INITIATED";
export const UPDATE_MENU = "UPDATE_MENU";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_SLICES = "SET SLICES";
export const SET_SLICE_GATEWAYS = "SET_SLICE_GATEWAYS";
export const CLEAR_SLICE_GATEWAYS = "CLEAR_SLICE_GATEWAYS";
export const SET_QOS_PROFILES = "SET_QOS_PROFILES";
export const SET_DASHBOARD_CLUSTER_DATA = "SET_DASHBOARD_CLUSTER_DATA";
export const START_SPINNER = "START_SPINNER";
export const STOP_SPINNER = "STOP_SPINNER";
export const SHOW_ERROR = "SHOW_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const UPDATE_SNACKBAR_ERROR = "UPDATE_SNACKBAR_ERROR";
export const UPDATE_SNACKBAR_SUCCESS = "UPDATE_SNACKBAR_SUCCESS";
export const SET_USERS = "SET_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_USER_GROUPS = "SET_USER_GROUPS";
export const SHOW_USER_DETAILS = "SHOW_USER_DETAILS";
export const HIDE_USER_DETAILS = "HIDE_USER_DETAILS";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const SET_POLICIES = "SET_POLICIES";
export const SET_ACTIONS = "SET_ACTIONS";
export const SELECT_POLICY = "SELECT_POLICY";
export const CLEAR_SELECTED_POLICY = "CLEAR_SELECTED_POLICY";
export const SET_OPERATORS = "SET_OPERATORS";
export const SELECT_OPERATOR = "SELECT_OPERATOR";
export const CLEAR_SELECTED_OPERATOR = "CLEAR_SELECTED_OPERATOR";
export const SET_OPERATOR_GROUPS = "SET_OPERATOR_GROUPS";
export const CLEAR_SELECTED_OPERATOR_GROUP = "CLEAR_SELECTED_OPERATOR_GROUP";
export const SELECT_OPERATOR_GROUP = "SELECT_OPERATOR_GROUP";
export const UPDATE_QOS_FILTER = "UPDATE_QOS_FILTER";
export const CLEAR_SELECTED_SLICE_POLICY = "CLEAR_SELECTED_SLICE_POLICY";
export const SET_SELECTED_SLICE_POLICY = "SET_SELECTED_SLICE_POLICY";
export const SET_SLICE_POLICIES = "SET_SLICE_POLICIES";
export const SET_ENFORCEMENT_POINT_SLICE = "SET_ENFORCEMENT_POINT_SLICE";
export const SET_ENFORCEMENT_POINTS = "SET_ENFORCEMENT_POINTS";
export const SET_TRAINING_EVENTS = "SET_TRAINING_EVENTS";
export const ADD_TRAINING_EVENTS = "ADD_TRAINING_EVENTS";
export const CLEAR_SELECTED_TRAINING_EVENT = "CLEAR_SELECTED_TRAINING_EVENT";
export const SET_SELECTED_TRAINING_EVENT = "SET_SELECTED_TRAINING_EVENT";
export const SET_LOGS = "SET_LOGS";
export const SET_LOADING = "SET_LOADING";
export const SET_LEGEND_DATA = "SET_LEGEND_DATA";
export const CLEAR_LEGEND_DATA = "CLEAR_LEGEND_DATA";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_KIALI_URL = "SET_KIALI_URL";
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const SET_CLUSTER_DETAIL = "SET_CLUSTER_DETAIL";
export const SET_CLUSTER_METADATA = "SET_CLUSTER_METADATA";
export const SET_CLUSTER_CAPACITY_DETAIL = "SET_CLUSTER_CAPACITY_DETAIL"
export const SET_SLICE_NAMESPACE_COUNT = "SET_SLICE_NAMESPACE_COUNT"
export const SET_SYSTEM_EVENT = "SET_SYSTEM_EVENT"
export const CLEAR_SYSTEM_EVENT = "CLEAR_SYSTEM_EVENT"
export const SET_NAMESPACE_LIST = "SET_NAMESPACE_LIST"
export const SET_CLUSTER_NAME = "SET_CLUSTER_NAME"