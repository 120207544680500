import { HIDE_USER_DETAILS, SHOW_USER_DETAILS } from "./types";

export const showUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_USER_DETAILS,
    });
  };
};

export const hideUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_USER_DETAILS,
    });
  };
};
