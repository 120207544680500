import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_LEGEND_DATA, CLEAR_LEGEND_DATA, SET_CLUSTER_DETAIL, SET_CLUSTER_METADATA, SET_CLUSTER_CAPACITY_DETAIL, SET_SLICE_NAMESPACE_COUNT, SET_SYSTEM_EVENT, CLEAR_SYSTEM_EVENT} from "./types";
const meshBackend = getMeshBackend();

export const getCompareClusterStats = (cluster1, cluster2) => {

  return async (dispatch, getState) => {
    let response = await meshBackend.getCompareClusterStats(getState(), dispatch, cluster1, cluster2);
    return response;
  };
};

export const setLegendData = (data, filteredData) => {
  return (dispatch) => {
    dispatch({
      type: SET_LEGEND_DATA,
      payload: {
        data,
        filteredData,
      },
    });
  };
};
export const clearLegendData = (data) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LEGEND_DATA,
    });
  };
};

export const getClusterDetail=()=>{
  return async (dispatch,getState)=>{
    let response = await meshBackend.getClusterDetail(getState(),dispatch);
    if (response) dispatch({ type: SET_CLUSTER_DETAIL, payload: response });
  }
};

export const getClusterMetaData = () => {
  return (dispatch,getState) => {
     meshBackend.getClusterMetaData(getState(),dispatch)
    .then(response => {
      response && dispatch({ type: SET_CLUSTER_METADATA, payload: response });
    });
  }
}

export const getClusterCapacityDetail = ()=>{
  
  return async (dispatch,getState)=>{
    let response = await meshBackend.getClusterCapacityDetail(getState(),dispatch);
    if(response) dispatch({type:SET_CLUSTER_CAPACITY_DETAIL,payload:response})
  }
}

export const getNodeCapacityDetail = (clusterId)=>{

  return async(dispatch,getState)=>{
    let response = await meshBackend.getNodeCapacityDetail(getState(),dispatch,clusterId);
    if(response) return response.clusterNodes;
  }
}

export const getNamespaceCapacityDetail = (clusterId,nodeName)=>{

  return async(dispatch,getState)=>{
    let response = await meshBackend.getNamespaceCapacityDetail(getState(),dispatch,clusterId, nodeName);
    if(response) return response.clusterNodeNamespace;
  }
}

export const getAllSliceNamespaces = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllSliceNamespaces(
      getState(),
      dispatch,
      sliceName
    );
    if (response)
      dispatch({ type: SET_SLICE_NAMESPACE_COUNT, payload: response });
  };
};

export const getAllServiceDependencyRequest = (clusterId,sliceName,params) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllServiceDependencyRequest(
      getState(),
      dispatch,
      clusterId,
      sliceName,
      params
    );
    if (response)
      return response;
  };
};

export const getSystemEvent = (params) =>{
  return async (dispatch, getState) => {
    let response = await meshBackend.getSystemEvent(
      getState(),
      dispatch,
      params
    );
    if (response)
      dispatch({ type: SET_SYSTEM_EVENT, payload: response });
  };
}

export const clearSystemEvent = () =>{
  return (dispatch) => {
    dispatch({ type: CLEAR_SYSTEM_EVENT, payload: [] });
  };
}

export const getKubernetesDashboard = (clusterId) =>{
  return async (dispatch, getState) => {
    let response = await meshBackend.getKubernetesDashboard(
      getState(),
      dispatch,
      clusterId
    );
    if (response)
      return response
  };
}

