import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { LightenDarkenColor } from "../../../utils/functions";
import { ScrollBarStyle } from "../../../AppStyles";
const Container = styled(Grid)`
  min-width: 30vw;
  background: ${({ color }) => LightenDarkenColor(color, 200)};
  border-radius: 1rem;
  overflow-y: auto;
  position: relative;
  height: 50vh;
  ${ScrollBarStyle}
  ::-webkit-scrollbar-thumb {
    background-color: ${({ color }) => LightenDarkenColor(color, 150)};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: ${({ color }) => LightenDarkenColor(color, 125)};
  }
`;
const NamespaceContainer = styled(Grid)`
  height: 5rem;
`;
const PodContainer = styled(Grid)`
  background-color: ${({ color, index }) =>
    LightenDarkenColor(color, index * 10)};
  color: white;
  height: 115%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  z-index: ${({ index }) => index};
`;
const LeftStick = styled.div`
  position: absolute;
  left: 0;
  height: 70%;
  width: 4px;
  background: ${({ color, index }) => LightenDarkenColor(color, index * 10)};
  border-radius: 3rem;
`;
const FallbackMsg = styled.h5`
  /* position: absolute;
  top: 50%;
  left: 30%; */
`;
const Namespace = ({ index, color, namespace, count }) => (
  <NamespaceContainer
    item
    container
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid
      item
      xs={6}
      container
      direction="column"
      justifyContent="center"
      spacing={1}
      style={{
        height: "90%",
        position: "relative",
        paddingLeft: "1rem",
      }}
    >
      <LeftStick color={color} index={index} />
      <Typography fontSize="12px" mb="6px">
        Onboarded
      </Typography>
      <Typography fontWeight="bold" fontSize="14px">
        {namespace}
      </Typography>
    </Grid>
    <PodContainer
      item
      xs={4}
      container
      justifyContent="center"
      alignItems="center"
      color={color}
      index={index}
    >
      <Typography fontSize="14px" fontWeight="bold" color="white">
        {count} pods
      </Typography>
    </PodContainer>
  </NamespaceContainer>
);
export default class SliceNamespace extends Component {
  render() {
    const { color, slice } = this.props;
    const { sliceNamespace } = slice;
    return (
      <Container
        color={color}
        container
        item
        xs
        direction="column"
        wrap="nowrap"
      >
        <Grid item>
          <div style={{
            paddingTop:30,
            paddingLeft:30,
            fontColor: '#000000',
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 'bold',
            fontStyle: 'normal'
          }}>{slice.sliceName}</div>
        </Grid>
        {Object.keys(sliceNamespace).length ? (
          <Grid item xs container direction="column" justifyContent="flex-end">
            {Object.keys(sliceNamespace).map((namespace, index) => (
              <Namespace
                index={index}
                color={color}
                namespace={namespace}
                count={sliceNamespace[namespace]}
              />
            ))}
          </Grid>
        ) : (
          <Grid
            item
            xs
            container
            item
            justifyContent="center"
            alignItems="center"
          >
            <FallbackMsg>No Namespaces Available</FallbackMsg>
          </Grid>
        )}
      </Container>
    );
  }
}
