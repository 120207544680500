import React, { memo } from "react";

import { Handle } from "react-flow-renderer";

export default memo(({ data }) => {
  return (
    <>
      <div
        style={{
          height: "75px",
          width: "75px",
          lineHeight: "75px",
          textAlign: "center",
          verticalAlign: "middle",
          borderRadius: "50%",
          background: `${data.backgroundColor}`,
          overflow: "hidden",
          fontSize: "10px",
          color: '#fff'
        }}
      >
        {/* <Handle
        type="target"
        position="left"
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
       
      /> */}
        <div>{data.sliceName}</div>

        <Handle
          type="target"
          position="bottom"
          id="a"
          style={{ top: 25, background: "#555", opacity: "0" }}
        />
        {/* <Handle
        type="source"
        position="right"
        id="b"
        style={{ bottom: 10, top: "auto", background: "#555" }}
      
      /> */}
      </div>
    </>
  );
});
