import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { CardHeader } from "@material-ui/core";
import { Handle } from "react-flow-renderer";
import { LinearProgress, Grid, Link } from "@material-ui/core";
import SliceNode from "./SliceNodes";
import { getKubernetesDashboard } from "../../actions/dashboardActions";
import { connect } from "react-redux";
import { Typography, Badge } from "@mui/material";
import {styled} from '@mui/material';

const StyledBadge = styled(Badge)(({statuscolor})=>({
  "& .MuiBadge-badge": { 
                  backgroundColor: statuscolor,
                  fontSize: 9,
                  marginTop:'5px',
                  marginRight:'5px',
                  height:'9px',
                  width:'6px'
                }
}))

const useStyles = makeStyles((theme) => ({
  title:{
    fontWeight:600
  },
  subheader:{
    fontWeight:300
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  barroot: {
    background: "linear-gradient(90deg, #2868F3 0%, #70A9FF 100%)",
    opacity: "1",
  },
  linearProgressRoot: {
    backgroundColor: "#fbf7f7",
    height: "7px",
    borderRadius: "5px",
  },
}));

 function ClusterLogicalNodeView({ data, getKubernetesDashboard }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleLink = (e)=>{
    e.preventDefault();
    getKubernetesDashboard(data.clusterId)
    .then((res)=>{
      const {redirectUrl} = res;
      if(redirectUrl)
        {  
          const newWindow = window.open(redirectUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    })
    .catch((e)=>{
      //error raise here
    })
    ;
  }
  return (
    <>
      <div>
        <Card
          style={{
            minHeight: "100px",
            width: "250px",
            borderLeft: "solid #2868F3 8px",
            borderRadius: "7px",
            borderColor: "#2868F3",
            backgroundColor: "#FFFFFF",
            cursor: "default",
          }}
        >
          <CardHeader
          classes = {{
                  title: classes.title,
                  subheader: classes.subheader
              }}
            title={data.clusterName}
            subheader={data.place}
            action={
              <StyledBadge 
                statuscolor = {data.status}
                variant="dot" 
                invisible={false}>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Show more"
                >
                  <ArrowDropDownIcon
                    style={{
                      color: "black",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "100%",
                    }}
                  />
                </IconButton>
              </StyledBadge>
            }
          />

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ paddingTop: "4px" }}>
              <Grid
                container
                justify="space-between"
                style={{ marginBottom: "4px" }}
              >
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  align="left"
                  display="inline"
                >
                  {data.progress}% used
                </Typography>
                <Typography
                  style={{ fontWeight: 100 }}
                  variant="body2"
                  align="right"
                  display="inline"
                >
                  {100 - data.progress}% free
                </Typography>
              </Grid>
              <LinearProgress
                classes={{
                  barColorPrimary: classes.barroot,
                }}
                className={classes.linearProgressRoot}
                variant="determinate"
                value={data.progress}
              />
              <Grid
                style={{ marginTop: "12px" }}
                container
                justify="space-between"
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={ handleLink }
                >
                  View details &gt;
                </Link>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>

        {/* Display all the slice nodes  */}
        <SliceNode slices={data.slices} />

        <Handle
          type="target"
          position="left"
          id="ta"
          style={{ top: "50px", visibility: "hidden", left: "5px" }}
        ></Handle>
        <Handle
          type="source"
          position="right"
          id="sa"
          style={{ top: "50px", visibility: "hidden", right: "5px" }}
        ></Handle>
      </div>
    </>
  );
}

const mapStateToProps = {};

export default connect(null, {
  getKubernetesDashboard
})(ClusterLogicalNodeView);
