import MUIDialog from "@material-ui/core/Dialog";
import React from "react";
import styled from "styled-components";
import { FormField } from "./FormFields";

const StyledFormField = styled(FormField)`
  flex-direction: row;
  justify-content: center;
  min-width: 33rem;
  width: auto;
`;
const InnerContainer = styled.div`
  padding: 16px;
  display: flex;
  overflow: visible;
`;
const FieldList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
`;

const AboutUs = (props) => {
  const companyDetails = 'Avesha makes the infrastructure “autonomous” using AI & RL ensuring easy scale out of cloud applications. Avesha’s “application slice” technology helps isolate pods based on policies resulting in a higher velocity of feature deployments and lower time to resolution of incidents. Avesha’s “load balancing reinvented” offers intelligent & dynamic workload placement across any cloud, any edge anywhere resulting in better application performance and lower cloud costs.';

  return (
    <MUIDialog style={{ zIndex: 3000, overflow: "visible" }} open={props.open} onClose={props.closeContactModal}>
      <InnerContainer>
        <FieldList>
          <StyledFormField color="black" heading="Company Details" content={companyDetails} />
          <StyledFormField color="black" heading="Version" content={process.env.REACT_APP_VERSION_UI} />
        </FieldList>
      </InnerContainer>
    </MUIDialog>
  );
}

export default AboutUs;
