import React, { Component } from "react";
import { connect } from "react-redux";
import { updateMenu } from "./../../actions/MenuActions";
import { getAllSlices } from "./../../actions/SliceActions";
import { getAllQOSProfiles } from "./../../actions/QOSProfileActions";
import { getAllOperators } from "./../../actions/OperatorActions";
import { getAllOperatorGroups } from "./../../actions/OperatorGroupActions";
import { getAllPolicies } from "./../../actions/PolicyActions";
import { createSlice } from "./../../actions/SliceActions";
import Drawer from "@material-ui/core/Drawer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ListItem,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./MenuItemsStyle";
import PolicyIcon from "@material-ui/icons/Policy";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import {
  openDrawerWidth,
  closedDrawerWidth,
} from "../../constants/styleConstants";
import AveshaBanner from "./AveshaBanner";
import { closePopUp, openPopUp } from "../../actions/PopUpActions";
import { logoutUser } from "../../actions/logoutAction";
import { withRouter } from "react-router";
import { NavBarItem, NavBarSubItem } from "./MenuItemUtils";
import { ReactComponent as Connect } from "../../assets/navbarIcons/Connect.svg";
import { ReactComponent as Dashboard } from "../../assets/navbarIcons/Dashboard.svg";
import { ReactComponent as Protect } from "../../assets/navbarIcons/Protect.svg";
import { ReactComponent as Roles } from "../../assets/navbarIcons/Roles.svg";
import { ReactComponent as Settings } from "../../assets/navbarIcons/Settings.svg";
import { ReactComponent as Smart } from "../../assets/navbarIcons/Smart.svg";
import { ReactComponent as Logout } from "../../assets/icons/Logout.svg";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ABOUT_US, PROFILE } from "../Body/Popups/types";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InfoIcon from '@mui/icons-material/Info';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#12153E",
    padding: "1rem",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#12153E",
  },
}));

const styles = (theme) => ({
  drawer: {
    "& div": {
      backgroundColor: "#12153E",
    },
    "& ul": {
      backgroundColor: "#12153E",
    },
    "& li": {
      backgroundColor: "#12153E",
    },
    width: openDrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: openDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "visible",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "visible",
    width: closedDrawerWidth,
  },
  accordionIcon: {
    //backgroundColor: '#2868F3 !important',
    "& div.MuiIconButton-edgeEnd": {
      marginRight: 0,
      backgroundColor: "transparent",
    },
  },
  countStyle: {
    padding: "5px 3px",
    textAlign: "right",
    color: "#228222",
    margin: 0,
    "& span": {
      fontWeight: "700",
    },
  },
  countCollapse: {
    padding: "5px 0",
    margin: 0,
    "& span": {
      fontWeight: "900",
      color: "#228222",
      fontSize: "11px",
      position: "absolute",
      top: "27px",
      left: "31px",
    },
  },
});

class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: "",
      openSliceDialog: false,
      role: "Admin",
      selectedItem: "/slices",
      expanded: "connect",
    };
  }

  async componentDidMount() {
    await this.getAllMenuData();

    const route = String(this.props.location.pathname);

    const query = new URLSearchParams(this.props.location.search);
    if (route === "/slices") {
      this.setState({ selectedSlice: true, selectedItem: "slices" });
    } else if (route === "/tc_config") {
      const tcId = query.get("id");
      this.setState({
        selectedTc: tcId,
        selectedQOSProfiles: true,
        selectedItem: "qosProfiles",
      });
    } else if (route === "/dashboard") {
      this.setState({ dashboardSelected: true, selectedItem: "dashboard" });
    } else if (route === "/roles") {
      this.setState({ rolesSelected: true, selectedItem: "roles" });
    } else if (route === "/operators") {
      this.setState({ operatorsSelected: true, selectedItem: "operators" });
    } else if (route === "/groups") {
      this.setState({ operatorGroupsSelected: true, selectedItem: "groups" });
    } else if (route === "/services") {
      this.setState({ servicesSelected: true, selectedItem: "services" });
    } else if (route === "/accessLogs") {
      this.setState({ selectedAccessLogs: true, selectedItem: "accessLogs" });
    }
  }

  componentDidUpdate(prevProps) {
    const route = String(this.props.location.pathname);
    const prevRoute = String(prevProps.location.pathname);
    const query = new URLSearchParams(this.props.location.search);

    if(route !== prevRoute) {
      if (
        ["/slice", "/slices"].includes(route) &&
        this.state.selectedItem != "/slices"
      ) {
        this.setState({
          selectedItem: route,
        });
      } else if (
        route === "/tc_config" &&
        this.state.selectedItem != "qosProfiles"
      ) {
        const tcId = query.get("id");
        this.setState({
          selectedTc: tcId,
          selectedItem: "qosProfiles",
        });
      } else if (
        route === "/dashboard" &&
        this.state.selectedItem != "/dashboard"
      ) {
        this.setState({
          selectedItem: route,
        });
      } else if (route === "/roles" && this.state.selectedItem != "/roles") {
        this.setState({
          selectedItem: route,
        });
      } else if (
        route === "/operators" &&
        this.state.selectedItem != "/operators"
      ) {
        this.setState({
          selectedItem: route,
        });
      } else if (route === "/groups" && this.state.selectedItem != "/groups") {
        this.setState({
          selectedItem: route,
        });
      } else if (
        route === "/services" &&
        this.state.selectedItem != "/services"
      ) {
        this.setState({
          selectedItem: route,
        });
      } else if (
        route === "/accessLogs" &&
        this.state.selectedItem != "/accessLogs"
      ) {
        this.setState({
          selectedItem: route,
        });
      }  
    }
  }

  getAllMenuData = () => {
    const {
      getAllSlices,
      getAllOperatorGroups,
      getAllOperators,
      getAllPolicies,
      getAllQOSProfiles,
    } = this.props;

    getAllSlices();
    getAllQOSProfiles();
    getAllOperatorGroups();
    getAllOperators();
    getAllPolicies();
  };

  handleRedirect = (redirect_uri) => {
    this.props.history.push(redirect_uri);
  };

  render() {
    const { open, classes } = this.props;
    const { selectedItem, expanded } = this.state;
    const MenuConfig = {
      Slices: this.props.sliceList ? this.props.sliceList : [],
      Profiles: this.props.qosProfiles,
      policies: this.props.policies,
      operators: this.props.operators,
      operatorGroups: this.props.operatorGroups,
    };

    const connectItems = [
      {
        name: "Slices",
        selected: selectedItem == "/slices",
        redirect: "slices",
        count: MenuConfig.Slices.length,
        icon: (
          <LocalPizzaIcon
            style={{ color: selectedItem == "/slices" ? "white" : "#757D8A" }}
          />
        ),
      },
      {
        name: "QOS Profiles",
        selected: selectedItem == "qosProfiles",
        redirect: "tc_config",
        count: Object.keys(MenuConfig.Profiles)?.length || 0,
        icon: (
          <AccountCircleIcon
            style={{
              color: selectedItem == "qosProfiles" ? "white" : "#757D8A",
            }}
          />
        ),
      },
    ];
    const settingItems = [
      {
        name: "Access Logs",
        selected: selectedItem == "/accessLogs",
        redirect: "accessLogs",
        icon: (
          <DescriptionIcon
            style={{
              color: selectedItem == "/accessLogs" ? "white" : "#757D8A",
            }}
          />
        ),
      },
      {
        name: "User Profile",
        selected: selectedItem === "/user-profile",
        redirect: null,
        popUp: true,
        popUpType: PROFILE,
        icon: (
          <ManageAccountsIcon
            style={{
              color: selectedItem == "/user-profile" ? "white" : "#757D8A",
            }}
          />
        ),
      },
      {
        name: "About Us",
        selected: selectedItem === "/about-us",
        redirect: null,
        popUp: true,
        popUpType: ABOUT_US,
        icon: (
          <InfoIcon
            style={{
              color: selectedItem == "/about-us" ? "white" : "#757D8A",
            }}
          />
        ),
      },
    ];
    const roleItems = [
      {
        name: "Access policies",
        selected: selectedItem == "/roles",
        redirect: "roles",
        count: MenuConfig.policies.length,
        icon: (
          <PolicyIcon
            style={{ color: selectedItem == "/roles" ? "white" : "#757D8A" }}
          />
        ),
      },
      {
        name: "Operator Groups",
        selected: selectedItem == "/groups",
        redirect: "groups",
        count: MenuConfig.operatorGroups.length,
        icon: (
          <PeopleIcon
            style={{ color: selectedItem == "/groups" ? "white" : "#757D8A" }}
          />
        ),
      },
      {
        name: "Operators",
        selected: selectedItem == "/operators",
        redirect: "operators",
        count: MenuConfig.operators.length,
        icon: (
          <PersonIcon
            style={{
              color: selectedItem == "/operators" ? "white" : "#757D8A",
            }}
          />
        ),
      },
    ];
    const protectItems = [
      // {
      //   name: "Coming Soon",
      //   selected: false,
      //   redirect: "",
      //   count: 0,
      //   icon: <AccessTimeIcon style={{ color: "white" }}/>,
      // },
    ];
    const smartItems = [
      // {
      //   name: "Coming Soon",
      //   selected: false,
      //   redirect: "",
      //   count: 0,
      //   icon: <AccessTimeIcon style={{ color: "white" }}/>,
      // },
    ];

    const displayItems = ({
      selected,
      name,
      redirect,
      icon,
      popUp,
      popUpType,
    }) =>
      popUp === true ? (
        <NavBarSubItem
          selected={selected}
          label={name}
          onClick={() => this.props.openPopUp({ type: popUpType })}
          Icon={icon}
          open={open}
        />
      ) : (
        <NavBarSubItem
          selected={selected}
          label={name}
          onClick={() => this.handleRedirect(redirect)}
          Icon={icon}
          open={open}
        />
      );

    return (
      <Drawer
        style={{ overflow: "visible", backgroundColor: "#12153E" }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <List>
            <ListItem style={{ marginBottom: "3rem" }}>
              <AveshaBanner
                open={open}
                handleDrawerToggle={this.props.handleDrawerToggle}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={Dashboard}
                label="Dashboard"
                selected={selectedItem == "/dashboard"}
                onClick={() => this.handleRedirect("dashboard")}
                open={open}
              />
            </ListItem>
            <ListItem>
              <Accordion
                expanded={expanded == "connect"}
                onChange={() =>
                  this.setState({
                    expanded: expanded == "connect" ? null : "connect",
                  })
                }
              >
                <AccordionSummary
                  className={classes.accordionIcon}
                  selected={["/slices", "qosProfiles"].includes(selectedItem)}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: ["/slices", "qosProfiles"].includes(selectedItem)
                          ? "white"
                          : "#757D8A",
                        marginRight: 0,
                      }}
                    />
                  }
                >
                  <NavBarItem
                    Icon={Connect}
                    label="Connect"
                    selected={["/slices", "qosProfiles"].includes(selectedItem)}
                    open={open}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: "0px" }}>
                    {connectItems.map(displayItems)}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <Tooltip title="Coming Soon" arrow placement="right">
              <ListItem>
                <NavBarItem
                  Icon={Protect}
                  label="Protect"
                  selected={[].includes(selectedItem)}
                  open={open}
                />
              </ListItem>
            </Tooltip>
            <Tooltip title="Coming Soon" arrow placement="right">
              <ListItem>
                <NavBarItem
                  Icon={Smart}
                  label="Smart"
                  selected={[].includes(selectedItem)}
                  open={open}
                />
              </ListItem>
            </Tooltip>
            <ListItem>
              <Accordion
                expanded={expanded == "roles"}
                onChange={() =>
                  this.setState({
                    expanded: expanded == "roles" ? null : "roles",
                  })
                }
              >
                <AccordionSummary
                  className={classes.accordionIcon}
                  selected={["/roles", "/operators", "/groups"].includes(
                    selectedItem
                  )}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: ["/roles", "/operators", "/groups"].includes(
                          selectedItem
                        )
                          ? "white"
                          : "#757D8A",
                        marginRight: 0,
                      }}
                    />
                  }
                >
                  <NavBarItem
                    Icon={Roles}
                    label="Roles"
                    selected={["/roles", "/operators", "/groups"].includes(
                      selectedItem
                    )}
                    open={open}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: "0px" }}>
                    {roleItems.map(displayItems)}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <Accordion
                expanded={expanded == "settings"}
                onChange={() =>
                  this.setState({
                    expanded: expanded == "settings" ? null : "settings",
                  })
                }
              >
                <AccordionSummary
                  className={classes.accordionIcon}
                  selected={["/accessLogs"].includes(selectedItem)}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: ["/accessLogs"].includes(selectedItem)
                          ? "white"
                          : "#757D8A",
                        marginRight: 0,
                      }}
                    />
                  }
                >
                  <NavBarItem
                    Icon={Settings}
                    label="Settings"
                    selected={["/accessLogs"].includes(selectedItem)}
                    open={open}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: "0px" }}>
                    {settingItems.map(displayItems)}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </List>
          <ListItem style={{ bottom: "1rem" }}>
            <NavBarItem
              Icon={Logout}
              label="Logout"
              //selected={selectedItem == "/dashboard"}
              onClick={() => {
                this.props.logoutUser();
                this.handleRedirect("");
              }}
              open={open}
            />
          </ListItem>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sliceList: store.slices.sliceList,
    qosProfiles: store.qosProfiles.qos_profiles,
    policies: store.PolicyReducer.policies,
    operators: store.OperatorReducer.operators,
    operatorGroups: store.OperatorGroupReducer.operatorGroups,
    popUp: store.popup.popup,
  };
};
const mapDispatchToProps = {
  updateMenu,
  getAllSlices,
  createSlice,
  getAllQOSProfiles,
  getAllOperators,
  getAllOperatorGroups,
  getAllPolicies,
  openPopUp,
  closePopUp,
  logoutUser,
};
export default withRouter(
  compose(
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, mapDispatchToProps)
  )(MenuItems)
);
