import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import moment from 'moment'
import { Chip } from "@mui/material";

const columns = [
  { 
    id: "eventId", 
    label: "Event ID", 
    minWidth: 80 
  },
  { 
    id: "entityId", 
    label: "Entity ID", 
    minWidth: 80 
  },
  {
    id: "severity",
    label: "Severity",
    minWidth: 60,
    align: "right",
    // format: (value) => value.toLocaleString("en-US")
  },
  {
    id: "source",
    label: "Source",
    minWidth: 100,
    align: "right",
    // format: (value) => value.toLocaleString("en-US")
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "right",
   // format: (value) => "test datra"
  },
  {
    id: "creationDate",
    label: "Creation date",
    minWidth: 80,
    align: "right",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
    align: "right",
    // format: (value) => value.toFixed(2)
  }
];

// function createData(eventId, entityId, severity, source, status, description) {
  
//   return { eventId, entityId, severity, source, status, description };
// }

// const rows = [
//   createData("India", "IN", 1324171354, 3287263),
//   createData("China", "CN", 1403500365, 9596961),
//   createData("Italy", "IT", 60483973, 301340),
//   createData("United States", "US", 327167434, 9833520),
//   createData("Canada", "CA", 37602103, 9984670),
//   createData("Australia", "AU", 25475400, 7692024),
//   createData("Germany", "DE", 83019200, 357578),
//   createData("Ireland", "IE", 4857000, 70273),
//   createData("Mexico", "MX", 126577691, 1972550),
//   createData("Japan", "JP", 126317000, 377973),
//   createData("France", "FR", 67022000, 640679),
//   createData("United Kingdom", "GB", 67545757, 242495),
//   createData("Russia", "RU", 146793744, 17098246),
//   createData("Nigeria", "NG", 200962417, 923768),
//   createData("Brazil", "BR", 210147125, 8515767)
// ];

const TableComponent = ({ tableHeight, onClickHandle, systemEvents, selectedId, setSelectecId, setSlide  })=> {
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows] = React.useState([])

  React.useEffect(()=>{
    if(systemEvents && systemEvents.length>0){
      let data = systemEvents.map((event)=>{
        return {
          eventId: event.eventId,
          entityId: event.entityId,
          severity: handleSeverity(event.severity), 
          source: event.source,
          status: handleStatus(event.status),
          creationDate: moment(event.createdAtTime).format("LLL"),
          description: event.description

        }
      })
      if(data && data.length>0){
        setRows(data);
      }
    }
  },[systemEvents])

  const handleSeverity = (severity)=>{

    switch(severity){
      case "SEVERITY_INVALID":
        return <Chip label="Invalid" color="default" />
      case 'SEVERITY_INFO':
        return <Chip label="Info" color="info"/>
      case 'SEVERITY_WARNING':
        return <Chip label="Warning" color="secondary"/>
      case "SEVERITY_CRITICAL":
        return <Chip label="Critical" color="error" />
      case "SEVERITY_ERROR":
        return <Chip label="Error" color="warning"/>
      default:
        return ""
    }
  }

  const handleStatus = (status)=>{

    switch(status){
      case "STATUS_INVALID":
        return <Chip label="Invalid" color="default" />
      case "STATUS_OPEN":
        return <Chip label="Open" color="secondary"/>
      case "STATUS_IN_PROGRESS":
        return <Chip label="In Progress" color="info"/>
      case "STATUS_RESOLVED":
        return <Chip label="Resolved" color="success"/>
      case "STATUS_DISCARDED":
        return <Chip label="Discarded" color="warning"/>
      case "STATUS_IGNORED":
        return <Chip label="Ignored" color="error"/>
      default:
        return ""
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ minHeight:'40vh',width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: tableHeight }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.eventId}
                    onClick={() => {
                      if(row.eventId === selectedId){
                        setSelectecId(null)
                        // setSlide(false)
                      }
                      else{
                        setSelectecId(row.eventId);
                        // setSlide(false)
                      }
                      
                    }}
                    selected={selectedId === row.eventId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          onClick={onClickHandle}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{bottom:0}}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
    </Paper>
  );
}


const mapStateToProps = (state)=>{
  return {
      systemEvents:state.dashboard.systemEvents  }
}


export default connect(mapStateToProps)(TableComponent)
