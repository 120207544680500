import React, { Component } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import MuiButton from "@material-ui/core/Button";
import { FormControlLabel, Slider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: solid #e3e3e3 1px;
  padding: 1rem;
`;
const MainContainer = styled(Container)`
  flex-direction: column;
  align-items: unset;
  padding: 1rem;
`;
const LabeledInput = styled(FormControlLabel)`
  span {
    min-width: fit-content;
    align-self: flex-start;
  }
`;
const SortButton = styled(MuiButton)`
  min-width: 11rem;
  justify-content: space-between;
`;
export default class QueryBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: null,
      endTime: null,
      reverse: false,
      batchSize: null,
    };
  }
  handleSubmit = () => {
    //debugger;
    const { startTime, endTime, reverse, batchSize } = this.state;
    this.props.onSubmit({ startTime, endTime, reverse, batchSize });
  };
  render() {
    const { startTime, endTime, reverse, batchSize } = this.state;
    const { onSubmit } = this.props;
    return (
      <MainContainer>
        <Container>
          <TextField
            label="Start Time"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => this.setState({ startTime: new Date(e.target.value).getTime() })}
          />
          <TextField
            label="End Time"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => this.setState({ endTime: new Date(e.target.value).getTime() })}
          />
          {reverse ? (
            <SortButton variant="outlined" onClick={() => this.setState({ reverse: !reverse })}>
              <VerticalAlignTopIcon />
              Earliest First{" "}
            </SortButton>
          ) : (
            <SortButton variant="outlined" onClick={() => this.setState({ reverse: !reverse })}>
              <VerticalAlignBottomIcon />
              Latest First
            </SortButton>
          )}
          {/* <FormControlLabel control={<Checkbox checked={reverse} onChange={(e) => this.setState({ reverse: !reverse })} />} label="Reverse" /> */}
        </Container>
        <Container>
          <LabeledInput
            style={{ flex: 1 }}
            label="Batch Size"
            labelPlacement="top"
            control={
              <Slider
                defaultValue={50}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={200}
                onChangeCommitted={(e, val) => this.setState({ batchSize: val })}
              />
            }
          />
          <MuiButton variant="outlined" onClick={this.handleSubmit}>
            Search
          </MuiButton>
        </Container>
      </MainContainer>
    );
  }
}
