import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllGatewaysForSlice,
  getSliceGateway,
} from "../../actions/SliceActions";
import { DataGrid } from "@material-ui/data-grid";

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "health",
    headerName: "Health",
    flex: 1,
  },
  { field: "clusterInfo", headerName: "Cluster", flex: 1 },
  {
    field: "namespace",
    headerName: "NameSpace",
    flex: 1,
    // valueGetter: (params) => `${params.getValue(params.id, "sliceClusterNamespace")}`,
  },
];
class PodTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gatewayData: null,
    };
  }
  componentDidMount() {
    this.getData();
    this.getGateWays();
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.props.slice.sliceId!==prevProps.slice.sliceId){
      this.getData()
    }
    if (prevProps.gateways?.length != this.props.gateways?.length) {
      this.getGateWays();
    }
  }
  getData = async () => {
    let sliceId = this.getSliceId();
    let { sliceList } = this.props;
    const sliceName = sliceList.find(
      (slice) => slice.sliceId == sliceId
    )?.sliceName;
    if (sliceName != -1) {
      debugger;
      this.props.getAllGatewaysForSlice(sliceName);
    }
  };
  getGateWays = () => {
    const result = this.props.gateways;
    if (!result?.length) {
      return;
    }
    console.log("gateway data", result);
    let gatewayData = [];
    let appPodsMap = {};
    for (let i = 0; i < result.length; i++) {
      if (result[i].sliceGatewayRemotePodIp) {
        let siteName = null;
        if (
          result[i].sliceName &&
          result[i].sliceGatewayRemoteClusterId &&
          result[i].sliceGatewayRemoteGatewayId
        ) {
          siteName = this.props.gateways.find(
            (gateway) =>
              gateway.sliceName == result[i].sliceName &&
              gateway.sliceGatewayRemoteClusterId ==
                result[i].sliceGatewayRemoteClusterId &&
              gateway.sliceGatewayRemoteGatewayId ==
                result[i].sliceGatewayRemoteGatewayId
          );
        }
        if (!siteName?.sliceSiteName) {
          debugger;
        }
        gatewayData.push({
          name: result[i]?.sliceGatewayName,
          health: result[i]?.health?.podStatus || "Not Available",
          clusterInfo:
            this.props.slice?.attachedClusters[result[i]?.sliceClusterId]
              ?.clusterName,
          type: "Gateway",
          namespace: result[i]?.sliceClusterNamespace || "Not Available",
        });
        if (result[i]?.health?.appStatus?.length) {
          let appPods = result[i]?.health?.appStatus;
          for (let appPod in appPods) {
            if (!appPodsMap[appPods[appPod].podName]) {
              appPodsMap[appPods[appPod].podName] = true;
              gatewayData.push({
                name: appPods[appPod].podName,
                health: "Not Available",
                clusterInfo:
                  this.props.slice?.attachedClusters[result[i]?.sliceClusterId]
                    ?.clusterName,
                type: "Application",
                namespace: appPods[appPod].namespace || "Not Available",
              });
            }
          }
        }
      }
    }

    this.setState({ gatewayData });
  };

  getSliceId = () => {
    return this.props.slice.sliceId
  };
  render() {
    const { gatewayData } = this.state;
    const data =
      gatewayData?.map((data, index) => ({ ...data, id: index.toString() })) ||
      [];
    debugger;
    return <DataGrid style={{height:"60vh"}} rows={data} columns={columns} pageSize={10} />;
  }
}

const mapStateToProps = ({
  slices: {
    sliceList,
    sliceGateways: { gateways },
  },
}) => ({ sliceList, gateways });

const mapDispatchToProps = { getSliceGateway, getAllGatewaysForSlice };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PodTable)
);
