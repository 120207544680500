import { SET_OPERATOR_GROUPS, SELECT_OPERATOR_GROUP, CLEAR_SELECTED_OPERATOR_GROUP, ACTION_LOGOUT_INITIATED } from "./../actions/types";
const initialState = {
  operatorGroups: [],
  selectedOperatorGroup: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPERATOR_GROUPS:
      return { ...state, ...payload };
    // case SET_ACTIONS:
    //   return { ...state, actions: { ...payload } };
    case SELECT_OPERATOR_GROUP:
      return { ...state, selectedOperatorGroup: { ...payload } };
    case CLEAR_SELECTED_OPERATOR_GROUP:
      return { ...state, selectedOperatorGroup: null };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
