import React, { Component } from "react";
import { FormCheck, FormField, FormSelectN as MuiFormSelect, useFormField } from "../../Commons/formUtils";
import { Scrollable } from "../FormStyles";
import styled from "styled-components";
import MuiClose from "@material-ui/icons/Close";
const Close = styled(MuiClose)`
  :hover {
    cursor: pointer;
  }
`;
const FormSelect = styled(MuiFormSelect)`
  && {
    align-self: flex-start;
  }
`;
const Policy = styled.div`
  margin-bottom: 0.4rem;
  width: 30rem;
  margin-left: 1rem;
  /* background-color: #e8e8e8; */
  padding: 8px;
  /* padding-left: 1rem; */
  border-bottom: 1px grey solid;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;
export const CustomScrollable = styled(Scrollable)``;
export default class PolicyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      options: [],
    };
  }
  componentDidMount() {
    this.setState({ options: this.props.options });
  }

  selectOption = (e) => {
    console.log(e?.target?.value, "values");
    let index = e?.target?.value;
    this.props.addToList(this.state.options[index]);
    let options = [...this.state.options.slice(0, index), ...this.state.options.slice(1 + parseInt(index))];
    this.setState({ options });
  };
  render() {
    const { value, options } = this.state;
    const { list, addToList, label, error, helperText } = this.props;
    return (
      <div>
        <FormSelect 
          error={error} 
          helperText={helperText} 
          label={label + " Name"} 
          value="" 
          options={options} 
          onChange={this.selectOption} 
        />
        <div style={{ maxHeight: "10rem", maxWidth: "fit-content" }}>
          {list.map((policy, index) => (
            <Policy>
              <div style={{ color: "grey" }}>{policy}</div>
              <Close
                color="grey"
                onClick={() => {
                  this.props.deleteItem(index);
                  this.setState({ options: [...options, policy] });
                }}
              />
            </Policy>
          ))}
        </div>
      </div>
    );
  }
}
