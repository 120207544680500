import { getMeshBackend } from "../backend/MeshBackendProxy";
import {
  SET_OPERATORS,
  SELECT_OPERATOR,
  CLEAR_SELECTED_OPERATOR,
} from "./types";

const meshBackend = getMeshBackend();

export const getAllOperators = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllOperators(getState(), dispatch);
    if (response) dispatch({ type: SET_OPERATORS, payload: response });
  };
};

export const createOperator = (operatorDetails) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.postOperator(
      getState(),
      dispatch,
      operatorDetails
    );
    if (response) {
      dispatch(getAllOperators());
    }
  };
};

export const editOperator = (operatorId, operatorDetails) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.editOperator(getState(), dispatch, operatorDetails, operatorId);
    if (response) {
      dispatch(getAllOperators());
    }
  };
};

export const selectOperator = (payload) => ({
  type: SELECT_OPERATOR,
  payload,
});

export const clearSelectedOperator = () => ({
  type: CLEAR_SELECTED_OPERATOR,
});

export const resetPassword = (operatorId) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.resetPassword(
      getState(),
      dispatch,
      operatorId
    );
    if (response) {
      return response;
    }
  };
};

export const deleteOperator = (operatorId) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.deleteOperator(
      getState(),
      dispatch,
      operatorId
    );
    dispatch(getAllOperators())
  };
};