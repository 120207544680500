import React from "react";
import { RootContainer } from "./AppStyles";
import Login from "./Components/Login/Login";
import { Theme, MuiTheme } from "./Theme";
import { MuiTheme as MaterialTheme } from "./Theme/MaterialTheme";
import { connect } from "react-redux";
import { NavBar } from "./Components/NavBar";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { CircleLoader } from "react-spinners";
import TcBody from "./Components/Body/TcBody";
import { BodyRoot } from "./Components/Body/BodyStyles";
import Dashboard from "./Components/Dashboard/Dashboard";
import RoleManager from "./Components/RoleManager/RoleManager";
import NotAvailable from "./Components/NotAvailable";
import Snackbar from "@material-ui/core/Snackbar";
import {
  updateSnackbar,
  updateSnackbarSuccess,
} from "./actions/SnackbarActions";
import MuiAlert from "@material-ui/lab/Alert";
import SliceList from "./Components/Slices/SliceList";
import SliceBody from "./Components/Slices/SliceBody";
import PolicyList from "./Components/RoleManager/Policy/PolicyList";
import OperatorList from "./Components/RoleManager/Operators/OperatorList";
import GroupList from "./Components/RoleManager/Groups/OperatorGroupList";
import SlicePolicyList from "./Components/SlicePolicy/SlicePolicyList";
import ServiceRouteDetails from "./Components/ServiceDiscovery/ServiceDetails";
import Body from "./Components/AccessLogs/Body";
import { getEnvVariables } from "./actions/loginAction";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import Popups from "./Components/Body/Popups";
import { StylesProvider } from "@material-ui/core/styles";
import { closedDrawerWidth, openDrawerWidth } from "./constants/styleConstants";
import { logoutUser } from "./actions/logoutAction";

require("dotenv").config();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const fillAvailable = ()=>{
  var isFirefox = typeof InstallTrigger !== 'undefined';
  return isFirefox ? '-moz-available':'fill-available';
}


const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "row",
  },
  contentRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  expand: {
    margin: `0 0 0 ${openDrawerWidth}`,
    width: fillAvailable,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  collapse: {
    margin: `0 0 0 ${closedDrawerWidth}`,
    width: fillAvailable,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerRight: {
    padding: "10px",
    display: "flex",
    marginTop: "72px",
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      menuOpen: true,
    };
  }

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (this.props.snackSeverity == "error")
      this.props.updateSnackbar(false, null);
    else this.props.updateSnackbarSuccess(false, null);
  };

  componentDidMount() {
    this.props.getEnvVariables();
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  };

  render() {
    let Content = <Login />;
    if (this.props.loading) {
      return null;
    }
    if (this.props.loginStatus) {
      const { classes } = this.props;
      const { menuOpen } = this.state;

      Content = (
        <>
          <Popups />
          <div className={classes.content}>
            <NavBar
              menuOpen={menuOpen}
              handleDrawerToggle={this.handleDrawerToggle}
            />
            <div className={menuOpen ? classes.expand : classes.collapse}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/slice">
                  <SliceBody />
                </Route>
                <Route path="/slices">
                  <SliceList />
                </Route>
                <Route path="/tc_config">
                  <TcBody />
                </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/roles">
                  <PolicyList />
                </Route>
                <Route path="/operators">
                  <OperatorList />
                </Route>
                <Route path="/groups">
                  <GroupList />
                </Route>

                <Route path="/service_details">
                  <ServiceRouteDetails />
                </Route>
                <Route path="/slicePolicy">
                  <SlicePolicyList />
                </Route>
                <Route path="/accessLogs">
                  <Body />
                </Route>
                <Route path="/settings">
                  <RoleManager />
                </Route>
                <Route path="/workInProgress">
                  <NotAvailable />
                </Route>
                <Route>
                  <BodyRoot>
                    <h3>Invalid Route!</h3>
                  </BodyRoot>
                </Route>
              </Switch>
            </div>
            {/* </div> */}
          </div>
        </>
      );
    }
    return (
      <StylesProvider injectFirst>
        <Theme>
          <MuiTheme>
            <MaterialTheme>
              <LoadingOverlay
                active={Boolean(this.props.spinner)}
                spinner={
                  <div
                    style={{
                      background: "white",
                      padding: "3rem",
                      borderRadius: "2rem",
                      opacity: "0.7",
                    }}
                  >
                    <CircleLoader color={"#00048D"} />
                  </div>
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgb(0, 0, 0,0.5)",
                    zIndex: "10000",
                    dispay: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }),
                  wrapper: (base) => ({
                    ...base,
                    dispay: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }),
                  content: (base) => ({
                    ...base,
                    height: "100%",
                    paddingTop: window.pageYOffset,
                    marginTop: "45vh",
                  }),
                }}
              >
                <Snackbar
                  style={{ zIndex: 100000 }}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={this.props.snackShow}
                  autoHideDuration={3000}
                  onClose={this.handleSnackClose}
                >
                  <Alert
                    onClose={this.handleSnackClose}
                    severity={this.props.snackSeverity || "error"}
                  >
                    {this.props.snackMessage}
                  </Alert>
                </Snackbar>
                <HashRouter>
                  <RootContainer>{Content}</RootContainer>
                </HashRouter>
              </LoadingOverlay>
            </MaterialTheme>
          </MuiTheme>
        </Theme>
      </StylesProvider>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    loginStatus: store.SessionReducer.login,
    spinner: store.SpinnerReducer.spinner,
    snackShow: store.ErrorSnackReducer.show,
    snackMessage: store.ErrorSnackReducer.message,
    snackSeverity: store.ErrorSnackReducer.severity,
    loading: store.EnvironmentReducer.loading,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStoreToProps, {
    updateSnackbarSuccess,
    updateSnackbar,
    getEnvVariables,
    logoutUser,
  })
)(App);
