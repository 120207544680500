import styled, { css } from "styled-components";

export const RootContainer = styled.div`
  min-width: 100vw;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ScrollBarStyle = css`
  scrollbar-width: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display:none; */
  }
  ::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
