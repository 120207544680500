import React, { useEffect, useState } from "react";
import Table from "../Commons/Tables";
import styled from "styled-components";
import { FilterButton } from "../Commons/IconButtons";
import CollapseTable from "./collapsibleTable";
import TableFilters from "./filters";
import PodTable from "./PodTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { FieldContainer, FieldContent, FieldHeading } from "../Body/BodyStyles";
import { ScrollBarStyle } from "../../AppStyles";

const Slices = styled.div`
  margin: 20px 10px;
  /* padding: 20px; */
  /* box-shadow: 1px 1px 4px rgba(71, 56, 128, 0.5); */
  width: 25%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  /* border-right: solid silver 1px; */
  padding: 0rem 1rem;
  ${ScrollBarStyle}
`;

const SliceData = styled(Paper)`
  margin: 0 0 20px 0;
  padding: 5px;
  cursor: pointer;
  background: ${({ active }) =>
    active
      ? "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)"
      : "none"};
  color: ${({ active }) => (active ? "white" : "black")};
`;

const SliceName = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const SliceInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  /* width: 100%; */
`;

const SliceInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  margin: 5px 0;
  justify-content: space-between;
`;

const SliceInfoKey = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const SliceInfoValue = styled.span`
  font-size: 14px;
`;

const ClusterDataWrapper = styled(Paper)`
  padding: 0.5rem;
  margin: 2rem;
  /* border: 1px solid; */
`;

const ClusterData = styled.div``;

const NoCluster = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Clusters = styled.div`
  width: 75%;
  margin: 20px 10px;
  /* padding: 5px; */
  box-shadow: 1px 1px 4px rgba(71, 56, 128, 0.5);
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const FilterButtonWrapper = styled.div`
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
`;

const ClearFilter = styled.span`
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;
const TableTitle = styled.div`
  font-weight: bold;
  /* color: #a9a9a9; */
`;

const SliceTable = (props) => {
  const [clusterData, setClusterData] = useState([]);

  const [filters, setFilters] = useState({});

  const [showFilter, setShowFilter] = useState(false);

  const [serviceFilters, setServiceFilters] = useState({});

  const [showServiceFilter, setShowServiceFilter] = useState(false);

  const [endpointFilters, setEndpointFilters] = useState({});

  const [showEndpointFilter, setShowEndpointFilter] = useState(false);

  const [portFilters, setPortFilters] = useState({});

  const [showPortFilter, setShowPortFilter] = useState(false);

  const [serviceAPIData, setServiceAPIData] = useState([]);

  const [serviceDiscIndex, setServiceDiscIndex] = useState(0);

  const [serviceData, setServiceData] = useState({
    services: [],
    endpoints: [],
    ports: [],
  });

  const changeIndex = (index, id) => {
    async function getServiceData() {
      const { services } = await props.getSliceServices(
        props.sliceList[index].sliceName
      );
      setServiceAPIData(services);
    }
    getServiceData();
    setClusterData(
      Object.values(props?.sliceList?.[index]?.attachedClusters || [])
    );
    // async function callGetData() {
    //   await getData(data[index]);
    // }
    // callGetData();
    const { setIndex } = props;
    setIndex(index, id);
  };

  const setServiceDiscData = () => {
    const { services, endpoints, ports } = serviceData;
    const serData = {
      services: [],
      endpoints: [],
      ports: [],
    };
    serviceAPIData.forEach((ele, index) => {
      serData.services = [...serData.services, ele];
      if (index === serviceDiscIndex) {
        serData.endpoints = [...serData.endpoints, ...ele.endpoints];
        serData.ports = [...serData.ports, ...ele.ports];
      }
    });
    setServiceData({
      services,
      ports,
      endpoints,
      ...serData,
    });
  };

  useEffect(() => {
    setServiceDiscData(serviceAPIData);
  }, [serviceDiscIndex, serviceAPIData]);

  useEffect(() => {
    setServiceFiltersFunc();
  }, [serviceData]);

  const setServiceFiltersFunc = () => {
    const serviceFilters = getUnique(serviceData.services, {
      namespace: "Name_Space",
      loadBalancingPolicy: "Load_Balancing_Policy",
    });
    setServiceFilters(serviceFilters);
    const endpointFilters = getUnique(serviceData.endpoints, {
      clusterId: "Cluster_Id",
    });
    setEndpointFilters(endpointFilters);
    const portFilters = getUnique(serviceData.ports, {
      protocol: "Protocol",
    });
    setPortFilters(portFilters);
  };

  const getUnique = (data, filterObj) => {
    const result = {};
    Object.keys(filterObj).forEach((ele) => {
      const filterType = [...new Set(data.map((eleData) => eleData[ele]))];
      result[filterObj[ele]] = { selected: [], values: filterType };
    });
    return result;
  };

  const getData = async (slice) => {
    //fetches slice id from url
    //stores cluster id
    let clusterId;
    //stores promises returned by gateway api
    let gatewayPromises = [];
    //extracts the chosen slice from slice list
    //stores slice name for gateway api
    let sliceName = slice.sliceName;
    let attachedClusters = slice.attachedClusters;
    //for every cluster in slice
    for (var cluster in attachedClusters) {
      clusterId = cluster;
      //checks if slice gateways exists
      if (attachedClusters[cluster]?.sliceGateways) {
        let gateways = Object.keys(attachedClusters[cluster]?.sliceGateways);
        //hits gateway api for each gateway and stores promise in array
        gateways.forEach((gateway) => {
          gatewayPromises.push(
            props.getSliceGateway(sliceName, clusterId, gateway)
          );
        });
      }
    }

    //when all gateways are fetched, saves it to state
    Promise.all(gatewayPromises)
      .then(async (result) => {
        let appStatus = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].gateway?.sliceGatewayRemotePodIp) {
            const clusterName =
              slice?.attachedClusters[result[i].gateway?.sliceClusterId]
                ?.clusterName;
            let gatewayStatus = {};
            gatewayStatus = {
              name: result[i].gateway?.sliceGatewayName,
              type: "Gateway",
              clusterName,
              nameSpace: result[i].gateway?.sliceClusterNamespace,
              health: result[i].gateway?.health?.podStatus,
            };
            let gatewayAppStatus = [];
            if (result[i].gateway.health?.appStatus.length > 0) {
              gatewayAppStatus = result[i].gateway.health.appStatus.map(
                (ele) => ({
                  name: ele.podName,
                  type: "Application",
                  clusterName,
                  nameSpace: ele.namespace,
                })
              );
            }
            appStatus = [...appStatus, ...gatewayAppStatus, gatewayStatus];
          }
        }
        const filters = getUnique(appStatus, {
          type: "Type",
          clusterName: "Cluster_Name",
        });
        console.log(appStatus, "showMainFilter");
        setFilters(filters);
        setClusterData(Object.values(appStatus));
      })
      .catch((e) => console.log("error", e));
  };

  const setSelectedFilter = (type, filter) => {
    const selectedFilterData = showFilter
      ? filters
      : showServiceFilter
      ? serviceFilters
      : showEndpointFilter
      ? endpointFilters
      : showPortFilter
      ? portFilters
      : {};
    const data = [...selectedFilterData[type].selected];
    const index = data.indexOf(filter);
    if (index > -1) data.splice(index, 1);
    else data.push(filter);
    const filterData = {
      ...selectedFilterData,
      [type]: {
        ...selectedFilterData[type],
        selected: data,
      },
    };
    const res = showFilter
      ? setFilters(filterData)
      : showServiceFilter
      ? setServiceFilters(filterData)
      : showEndpointFilter
      ? setEndpointFilters(filterData)
      : showPortFilter
      ? setPortFilters(filterData)
      : null;
  };

  const data = props.sliceList;

  const sliceInfoData = {
    Type: "Application",
    "Slice GW type": "open VPN",
    "Slice QOS Profile": "High priority profile",
  };

  const formatText = (filter) => {
    let fil = filter.split("_");
    fil.forEach((ele, index) => {
      if (index === 0) fil[index] = ele.toLowerCase();
      else fil[index] = ele.charAt(0).toUpperCase() + ele.slice(1);
    });
    fil = fil.join("");
    if (fil === "nameSpace") fil = "namespace";
    return fil;
  };

  const getFilteredData = (data, filterObj) => {
    console.log(filterObj, "gatewaydata", data);
    return data.filter((ele) => {
      const data = Object.keys(filterObj).map((filter) => {
        const fil = formatText(filter);
        if (filterObj[filter].selected.length === 0) return true;
        if (filterObj[filter].selected.includes(ele[fil])) return true;
        return false;
      });
      if (data.every((ele) => ele === true)) return ele;
    });
  };

  const getFilterApplied = (filData) => {
    return Object.keys(filData)
      .map((filter) => {
        if (filData[filter].selected.length > 0) return true;
        return false;
      })
      .some((ele) => ele === true);
  };

  const clusters = getFilteredData(clusterData, filters);

  const filterApplied = getFilterApplied(filters);

  const clearFilters = (clearfilterData, setData) => {
    const data = Object.keys(clearfilterData).map((filter) => ({
      [filter]: { selected: [], values: clearfilterData[filter].values },
    }));
    let filtersData = {};
    data.forEach((ele) => {
      filtersData = { ...filtersData, ...ele };
    });
    setData(filtersData);
  };

  const services = getFilteredData(serviceData.services, serviceFilters);
  const serviceFilterApplied = getFilterApplied(serviceFilters);

  let showMainFilter;
  if (showFilter || showServiceFilter || showPortFilter || showEndpointFilter) {
    showMainFilter = true;
  }

  let mainFilterData = showFilter
    ? filters
    : showServiceFilter
    ? serviceFilters
    : showPortFilter
    ? portFilters
    : showEndpointFilter
    ? endpointFilters
    : {};

  const setAllFilters = () => {
    setShowServiceFilter(false);
    setShowEndpointFilter(false);
    setShowPortFilter(false);
    setShowFilter(false);
  };

  const nameSpace = clusters;
  const config = {
    columns: [
      {
        id: "name",
        name: "Name",
        default: "Name not available",
      },
      {
        id: "type",
        name: "Type",
        default: "Type not available",
      },
      {
        id: "health",
        name: "Health",
        default: "Health not available",
      },
      {
        id: "clusterName",
        name: `Cluster(${nameSpace.length})`,
        default: "Cluster not available",
      },
      {
        id: "nameSpace",
        name: "Namespace",
        default: "Namespace not available",
      },
    ],
  };
  function compare(a, b) {
    if (a.clusterName < b.clusterName) {
      return -1;
    }
    if (a.clusterName > b.clusterName) {
      return 1;
    }
    return 0;
  }
  if (nameSpace.length > 0) {
    nameSpace.sort(compare);
  }

  return (
    <>
      {showMainFilter && (
        <TableFilters
          showMainFilter={showMainFilter}
          setAllFilters={setAllFilters}
          mainFilterData={mainFilterData}
          setSelectedFilter={setSelectedFilter}
        />
      )}
      <div
        style={{
          display: "flex",
          "flex-wrap": "nowrap",
          "flex-direction": "row",
          height: "85%",
        }}
      >
        <Slices>
          {data &&
            data.map((slice, index) => (
              <SliceData
                active={props.indexSelected.id === slice.sliceId}
                onMouseDown={() => changeIndex(index, slice.sliceId)}
                elevation={4}
                data-cy={`av-serviceDiscovery-${index + 1}`}
              >
                <SliceName>{slice.sliceName || "No Name"}</SliceName>
                <SliceInfo>
                  {Object.keys(sliceInfoData).map((sliceInfo) => (
                    // <SliceInfoWrapper>
                    //   <SliceInfoKey>{ele} :</SliceInfoKey>
                    //   <SliceInfoValue>
                    //     &nbsp; {sliceInfoData[ele]}
                    //   </SliceInfoValue>
                    // </SliceInfoWrapper>
                    <FieldContainer>
                      <FieldHeading
                        color={
                          props.indexSelected.id === slice.sliceId
                            ? "#d6e3f7"
                            : "grey"
                        }
                      >
                        {sliceInfo}
                      </FieldHeading>
                      <FieldContent>{sliceInfoData[sliceInfo]}</FieldContent>
                    </FieldContainer>
                  ))}
                </SliceInfo>
              </SliceData>
            ))}
        </Slices>
        <Clusters>
          {clusters.length ? (
            <>
              <ClusterDataWrapper>
                <FilterButtonWrapper>
                  <TableTitle>Slice PODs</TableTitle>
                  {/* <div>
                    <FilterButton
                      fill={filterApplied ? "blue" : "black"}
                      onMouseDown={() => setShowFilter(true)}
                      style={{
                        margin: "0 8px",
                      }}
                    />
                    {filterApplied && (
                      <ClearFilter
                        onMouseDown={() => clearFilters(filters, setFilters)}
                      >
                        clear
                      </ClearFilter>
                    )}
                  </div> */}
                </FilterButtonWrapper>
                <ClusterData>
                  <PodTable slice={data[props.indexSelected.index]} />
                </ClusterData>
              </ClusterDataWrapper>
              <ClusterDataWrapper>
                <FilterButtonWrapper>
                  <TableTitle>Service Imports & Exports</TableTitle>
                  <div>
                    <FilterButton
                      fill={serviceFilterApplied ? "blue" : "black"}
                      onMouseDown={() => setShowServiceFilter(true)}
                      style={{
                        margin: "0 8px",
                      }}
                    />
                    {serviceFilterApplied && (
                      <ClearFilter
                        onMouseDown={() =>
                          clearFilters(serviceFilters, setServiceFilters)
                        }
                      >
                        clear
                      </ClearFilter>
                    )}
                  </div>
                </FilterButtonWrapper>
                <ClusterData>
                  <CollapseTable data={services}></CollapseTable>
                </ClusterData>
              </ClusterDataWrapper>
            </>
          ) : (
            <NoCluster>No attached clusters</NoCluster>
          )}
        </Clusters>
      </div>
    </>
  );
};
const mapStateToProps = ({
  slices: {
    sliceList,
    sliceGateways: { gateways },
  },
}) => ({ sliceList, gateways });

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SliceTable);
