import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { connect } from "react-redux";

// import List from "./List";
import { FormCheck, FormField, FormSelect } from "../../Commons/formUtils";
import { FormContainer } from "../../RoleManager/FormStyles";
import JsonEditor from "../../SlicePolicy/JsonEditor";
import styled from "styled-components";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MuiPopper from "@material-ui/core/Popper";
import { clearSelectedTrainingEvent } from "../../../actions/trainingEventsActions";
import { FieldContainer, FieldHeading, FieldContent } from "../../Body/BodyStyles";

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const CustomFieldContainer = styled(FieldContainer)`
  width: 100%;
`;
const CustomFieldContent = styled(FieldContent)`
  width: inherit;
`;
const Field = ({ label, value }) => {
  return (
    <CustomFieldContainer>
      <FieldHeading>{label}</FieldHeading>
      <CustomFieldContent>{value}</CustomFieldContent>
    </CustomFieldContainer>
  );
};
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const SaveButton = styled(Button)`
  :disabled {
    background-color: rgba(47, 167, 103, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddEnforcementPointDialog({ selectedTrainingEvent: trainingEvent, ...props }) {
  //creating css classes from jss
  const classes = useStyles();

  const clearData = () => {
    debugger;
    props.clearSelectedTrainingEvent();
  };

  //   const handleSave = () => {
  //     debugger;
  //     let enforcementPoint = {
  //       enforcementPoint: {
  //         kind,
  //         description,
  //         canonicalName,
  //         spec: algorithmConfig,
  //       },
  //     };
  //     // props.createEnforcement(enforcementPoint, props.sliceName);
  //     props.handleClose();
  //   };

  //   useEffect(() => {
  //     if (props.selectedEnforcement) {
  //       // debugger;
  //       handleSelectedEnforcement();
  //     }
  //   }, [props.selectedEnforcement]);

  //   const handleSelectedEnforcement = () => {
  //     // debugger;
  //     const { selectedEnforcement } = props;
  //     if (selectedEnforcement) {
  //       setKind(selectedEnforcement.kind);
  //       setDescription(selectedEnforcement.description);
  //       setCanonicalName(selectedEnforcement.canonicalName);
  //       setAlgorithmConfig(selectedEnforcement.spec);
  //     }
  //   };

  //   const isSelected = props.selectedEnforcement;

  return (
    <div>
      <Dialog
        style={{ zIndex: 4000 }}
        fullScreen
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage: "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                  props.handleClose();
                clearData();
                // props.clearSelectedOperator();
                // clearData();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Training Event
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              borderRight: "solid black 1px",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <h3>Training Event</h3>
              {/* <div className="TextContainer">
                A operator defines the permissions that you can assign to a user, group, or role. You can create and edit a operator in the
                visual editor
              </div>  */}
              <h4>General Details</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Field label="Base S3 URL" value={trainingEvent?.baseS3Url} />
                <div style={{ display: "flex" }}>
                  <Field label="Previous Step" value={trainingEvent?.previousStep} />
                  <Field label="Current Step" value={trainingEvent?.currentStep} />
                </div>
                <div style={{ display: "flex" }}>
                  <Field label="Previous Checkpoint" value={trainingEvent?.previousCheckpoint} />
                  <Field label="Next Potential Checkpoint" value={trainingEvent?.nextPotentialCheckpoint} />
                </div>
                <div style={{ display: "flex" }}>
                  <Field label="Previous Checkpoint Step" value={trainingEvent?.previousCheckpointStep} />
                </div>
                <div style={{ display: "flex" }}>
                  <Field label="Metric Start Time" value={new Date(trainingEvent?.metricStartTime).toLocaleString()} />
                  <Field label="Metric End Time" value={new Date(trainingEvent?.metricEndTime).toLocaleString()} />
                </div>
                <div style={{ display: "flex" }}>
                  <Field label="Previous Checkpoint Updated at time" value={new Date(trainingEvent?.previousCheckpointUpdatedAtTime).toLocaleString()} />
                </div>
                <div style={{ display: "flex" }}>
                  <Field label="Status" value={trainingEvent?.status} />
                  <Field label="Is Enforcement Action" value={trainingEvent?.isEnforcementAction?"True":"False"} />
                </div>
                
              </div>
            </FormContainer>
          </div>
          <Form>
            <JsonEditor placeholder={null} label="Spec" />
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({ trainingEventsReducer: { selectedTrainingEvent } }) => ({
  selectedTrainingEvent,
});

export default connect(mapStateToProps, {
  clearSelectedTrainingEvent,
})(AddEnforcementPointDialog);
