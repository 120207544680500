import { getMeshBackend } from "../backend/MeshBackendProxy";
import {
  SET_OPERATOR_GROUPS,
  SELECT_OPERATOR_GROUP,
  CLEAR_SELECTED_OPERATOR_GROUP,
} from "./types";
const meshBackend = getMeshBackend();

export const getAllOperatorGroups = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllOperatorGroups(getState(), dispatch);
    if (response) dispatch({ type: SET_OPERATOR_GROUPS, payload: response });
  };
};

export const createOperatorGroup = (operatorDetails) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.postOperatorGroup(getState(), dispatch, {
      ...operatorDetails,
    });
    dispatch(getAllOperatorGroups());
  };
};

export const editOperatorGroup = (operatorGroupId, operatorGroupDetails) => {
  return async (dispatch, getState) => {
    const customerId = getState().SessionReducer.customerId;
    let response = await meshBackend.editOperatorGroup(
      getState(),
      dispatch,
      { ...operatorGroupDetails, customerId },
      operatorGroupId
    );
    dispatch(getAllOperatorGroups());
  };
};

export const selectOperatorGroup = (payload) => ({
  type: SELECT_OPERATOR_GROUP,
  payload,
});

export const clearSelectedOperatorGroup = () => ({
  type: CLEAR_SELECTED_OPERATOR_GROUP,
});
