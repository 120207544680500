import {
  ADD_SESSION,
  CHANGE_PASSWORD,
  SHOW_ERROR,
  START_SPINNER,
  STOP_SPINNER,
  REMOVE_ERROR,
  SET_LOADING,
  CHANGE_PASSWORD_ERROR,
  ACTION_LOGOUT_INITIATED,
} from "./types";
import Amplify, { Auth } from "aws-amplify";
import { getMeshBackend } from "../backend/MeshBackendProxy";
import { getOperatorDetails } from "./UserActions";
const backend = getMeshBackend();
export const getEnvVariables = () => {
  return async (dispatch, getState) => {
    let response = await backend.getEnvVariables(getState(), dispatch);
    const { cognitoIdentityPoolId, awsRegion, cognitoUserPoolId, cognitoClientId } = response;
    Amplify.configure({
      Auth: {
        identityPoolId: cognitoIdentityPoolId,
        region: awsRegion,
        identityPoolRegion: awsRegion,
        userPoolId: cognitoUserPoolId,
        userPoolWebClientId: cognitoClientId,
      },
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };
};

// You can get the current config object
const currentConfig = Auth.configure();
export const removeError = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_ERROR });
  };
};
export const loginUser = (userCredentials) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTION_LOGOUT_INITIATED,
        payload: { session_token: "" },
      });
      dispatch({ type: START_SPINNER });
      const user = await Auth.signIn(userCredentials.username, userCredentials.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: { session_token: user.Session },
        });
      } else {
        const refreshToken = (await Auth.currentSession()).getRefreshToken().getToken();
        dispatch({
          type: ADD_SESSION,
          payload: {
            session_token: user.signInUserSession.idToken.jwtToken,
            customerId: user.attributes["custom:customerId"],
            refreshToken,
          },
        });
        dispatch(getOperatorDetails());
      }
      dispatch({ type: STOP_SPINNER });
    } catch (error) {
      dispatch({ type: STOP_SPINNER });
      dispatch({ type: SHOW_ERROR, payload: { message: error.message } });
    }
  };
};

export const changeUserPassword = (userCredentials) => {
  return (dispatch, getState) => {
    try {
      if (userCredentials.newPassword == userCredentials.confirmNewPassword) {
        Auth.signIn(userCredentials.username, userCredentials.password).then((user) => {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            userCredentials.newPassword // the new password
            )
            .then(async (user) => {
              //debugger
              // at this time the user is logged in if no MFA required
              const refreshToken = (await Auth.currentSession()).getRefreshToken().getToken();
              dispatch({
                type: ADD_SESSION,
                payload: {
                  session_token: user.signInUserSession.idToken.jwtToken,
                  // customerId: user.attributes["custom:customerId"],
                  refreshToken,
                },
              });
              dispatch(getOperatorDetails());
            })
            .catch((e) => {
              console.log(e);
              dispatch({ type: SHOW_ERROR, payload: { message: e.message } });
            });
        });
      } else {
        dispatch({ type: SHOW_ERROR, payload: { message: "Passwords do not match" } });
      }
    } catch (error) {
      console.log("error signing in", error);
    }
  };
};
