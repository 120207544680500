import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

export const useFormField = (initialValue = "") => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback((e) => setValue(e.target.value), []);
  return { value, onChange, setValue };
};

export const FormField = styled(({ ...otherProps }) => (
  <TextField {...otherProps} size="small" variant="filled" color="primary" />
))`
  && {
    margin: 1rem;
    min-width: 30rem;
    align-self: center;
  }
`;

export const FormSelect = (props) => {
  const options = [];
  for (let item in props.options) {
    options.push(
      <MenuItem disabled={props.options[item].disabled} value={item}>
        {props.options[item].name}
      </MenuItem>
    );
  }
  return (
    <FormControl
      style={{
        width: props.width || "20rem",
        alignSelf: "center",
        margin: "1rem",
      }}
      size="small"
      variant="outlined"
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        inputProps={{
          name: props.label,
          id: props.label,
        }}
      >
        {options}
      </Select>
    </FormControl>
  );
};

export const GraphLegendName = (data, slice, type) => {
  try {
    if (data?.slices[slice]?.stats[type]?.sliceDisplayName === undefined || data?.slices[slice]?.stats[type]?.frameOfReferenceClusterName === undefined) {
      return slice;
    }
  } catch (e) {
    return slice;
  }

  return String(data?.slices[slice]?.stats[type]?.sliceDisplayName + "/" + data?.slices[slice]?.stats[type]?.frameOfReferenceClusterName);
};
