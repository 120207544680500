import React from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { SankeyChart } from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";

import {
  CanvasRenderer,
  // SVGRenderer,
} from "echarts/renderers";
import { useEffect } from "react";
import { useState } from "react";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  SankeyChart,
  CanvasRenderer,
]);

// const data = {
//   nodes: [
//     { name: "service1" },
//     // { name: "service13", depth:3},
//     { name: "service2" },
//     { name: "service3" },
//     { name: "service4" },
//     { name: "GW5" },
//     { name: "GW6" },
//     { name: "GW7" },
//     { name: "GW8" },
//     { name: "service9" },
//     { name: "service10" },
//     { name: "service11" },
//     { name: "service12" },
//     { name: "service5kajsfbkjasbfkjabgjabgljabglagblagbslagblasbglagbsa"},
//     { name: "service6"},
//     { name: "GW9kajbfajbfasjbfgslabgflsajbgslabgslabgslabgla"},
//     { name: "egress-gateway"},
//     { name: "ingress-gateway"},
//     { name: "service7"},
//     { name: "service20" }
//   ],
//   links: [
//     {
//       source: "service5kajsfbkjasbfkjabgjabgljabglagblagbslagblasbglagbsa",
//       target: "GW9kajbfajbfasjbfgslabgflsajbgslabgslabgslabgla",
//       value: 14
//     },
//     {
//       source: "GW9kajbfajbfasjbfgslabgflsajbgslabgslabgslabgla",
//       target: "egress-gateway",
//       value: 14
//     },
//     {
//       source: "egress-gateway",
//       target: "ingress-gateway",
//       value: 14
//     },
//     {
//       source: "ingress-gateway",
//       target: "service6",
//       value: 14
//     },
//     { source: "service1",
//       target: "GW5",
//       value: 0,
//       connections: 1
//     },
//     { source: "GW5",
//       target: "GW7",
//       value: 35,
//       connections: 1
//     },
//     {
//       source: "GW6",
//       target: "service9",
//       value: 25,
//       connections: 1
//     },
//     {
//       source: "GW6",
//       target: "GW8",
//       value: 25,
//       connections: 1
//     },
//     {
//       source: "service2",
//       target: "GW5",
//       value: 20,
//       connections: 1
//     },
//     {
//       source: "service3",
//       target: "GW6",
//       value: 20,
//       connections: 1
//     },
//     {
//       source: "service4",
//       target: "GW6",
//       value: 5,
//       connections: 1
//     },
//     {
//       source: "GW7",
//       target: "service9",
//       value: 30,
//       connections: 1
//     },
//     {
//       source: "GW7",
//       target: "service10",
//       value: 5,
//       connections: 1
//     },
//     {
//       source: "GW8",
//       target: "service11",
//       value: 20,
//       connections: 1
//     },
//     {
//       source: "GW8",
//       target: "service12",
//       value: 5,
//       connections: 1
//     },
//     {
//       source: "service7",
//       target: "service20",
//       value: 3
//     }
//   ]
// };

const getHeads = (link, node) => {
  const notHeadSet = new Set();
  link.forEach((each) => {
    notHeadSet.add(each.target);
  });
  const result = new Set(
    node
      .filter((item) => !Array.from(notHeadSet).includes(item.name))
      .map((each) => each.name)
  );
  return result;
};

const getTails = (link, node) => {
  const notTailSet = new Set();
  link.forEach((each) => {
    notTailSet.add(each.source);
  });
  const result = new Set(
    node
      .filter((item) => !Array.from(notTailSet).includes(item.name))
      .map((each) => each.name)
  );
  return result;
};

const setColoursOfNodes = (nodes, head, tail) => {
  const nodesData = [];
  nodes.forEach((eachNode) => {
    const data = {};
    data.name = eachNode.name;
    //if head
    if (eachNode.type == "service") {
      data.itemStyle = {
        color: "#12153E",
      };
    }
    else if (eachNode.type == "ingress") {
      data.itemStyle = {
        color: "#06cd99",
      };
    } else if (eachNode.type == "egress") {
      data.itemStyle = {
        color: "#69bdfa",
      };
    } else if (eachNode.type == "ns-ew-egress") {
      data.itemStyle = {
        color: "#DD9F82",
      };
    } else if (eachNode.type == "ns-ew-ingress") {

      data.itemStyle = {
        color: "#614d75",
      };
    }
    else {
      data.itemStyle = {
        color: "#2868F3",
      };
    }
    data.label = {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: 18,
      fontFamily: "Bai Jamjuree",
    };
    nodesData.push(data);
  });
  return nodesData;
};

const setColoursOfLinks = (links, head, tail) => {
  const linksData = [];
  links.forEach((eachLink) => {
    if (eachLink?.value > 0) {
      const data = { ...eachLink };
      //if head
      if (head.has(eachLink.source)) {
        data.lineStyle = {
          color: "#D3D3D3",
          opacity: 0.5,
        };
      }
      //if tail
      else if (tail.has(eachLink.target)) {
        data.lineStyle = {
          color: "#D3D3D3",
          opacity: 0.5,
        };
      }
      //else
      else {
        data.lineStyle = {
          color: "#C8D9FF",
          opacity: 1,
        };
      }
      linksData.push(data);
    }
  });
  return linksData;
};

export default function Sankey({ data }) {
  const [configData, setConfigData] = useState({});

  useEffect(() => {
    if (data?.links.length > 0 && data?.nodes.length > 0) {
      const head = getHeads(data.links, data.nodes);
      const tail = getTails(data.links, data.nodes);
      //filter out nodes which has RPS zero
      //
      const filteredNodes = getFilterNodes(data.nodes, data.links);
      const modifiedData = {
        nodes: setColoursOfNodes(filteredNodes, head, tail),
        links: setColoursOfLinks(data.links, head, tail),
      };

      setConfigData(getOption(modifiedData));
    } else {
      setConfigData(
        getOption({
          nodes: [],
          links: [],
        })
      );
    }
  }, [data]);

  const getFilterNodes = (nodes, links) => {
    const newSet = {};
    links.forEach((eachLink) => {
      if (eachLink.value > 0) {
        newSet[eachLink.source] = true;
        newSet[eachLink.target] = true;
      }
    });

    const result = nodes.filter((item) => newSet[item.name]);
    return result;
  };

  const getOption = (data) => {
    return {
      title: {
        // text: "Sankey Diagram"
      },
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: (params) => {
          if (!params.data.name)
            return (
              "<div style='position:relative;background:#fff; padding:10px;width:250px;height:50px'>" +
              "<div style='padding-left:10px;font-style: normal;font-weight: normal; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 25%; left: 0; position: absolute; box-sizing: border-box;overflow: hidden; display: flex;'>" +
              "Source:" +
              "</div>" +
              "<div style='font-style: normal;font-weight: 600; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 75%; right: 0; position: absolute; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'>" +
              params?.data?.source +
              "</div>" +
              "<br/>" +
              "<div style='padding-left:10px;font-style: normal;font-weight: normal; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 25%; left: 0; position: absolute'>" +
              "Target:" +
              "</div>" +
              "<div style='font-style: normal;font-weight: 600; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 75%; right: 0; position: absolute; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'>" +
              params?.data?.target +
              "</div>" +
              "<br/>" +
              "<div style='padding-left:10px;font-style: normal;font-weight: normal; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 25%; left: 0; position: absolute'>" +
              "RPS:" +
              "</div>" +
              "<div style='font-style: normal;font-weight: 600; font-size: 10px;line-height: 16px;text-align:left; color: #12153E; width: 75%; right: 0; position: absolute'>" +
              params?.data?.value +
              "</div>" +
              "</div>"
            );
        },
      },
      series: [
        {
          type: "sankey",
          data: data.nodes,
          links: data.links,
          label: {
            width: 120,
            overflow: "truncate",
          },
          labelLayout: {
            hideOverlap: true,
            //   moveOverlap: 'shiftY',
            // moveOverlap: 'shiftX'
          },
          // nodeAlign: "left",
          emphasis: {
            focus: "adjacency",
          },
          // levels: [
          //   {
          //     depth: 0,
          //     itemStyle: {
          //       color: "#12153E"
          //     },
          //     lineStyle: {
          //       color: "#D3D3D3",
          //       opacity: .5,
          //     },

          //   },
          //   {
          //     depth: 1,
          //     itemStyle: {
          //       color: "#2868F3"
          //     },
          //     lineStyle: {
          //       color: "#C8D9FF",
          //       opacity: 1
          //     }
          //   },
          //   {
          //     depth: 2,
          //     itemStyle: {
          //       color: "#0050FF"
          //     },
          //     lineStyle: {
          //       color: "#D3D3D3",
          //       opacity: .5,
          //     }
          //   },
          //   {
          //     depth: 3,
          //     itemStyle: {
          //       color: "#12153E"
          //     },
          //     lineStyle: {
          //       color: "source",
          //       opacity: 0.6
          //     }
          //   }
          // ],
          lineStyle: {
            curveness: 0.5,
          },
        },
      ],
    };
  };

  return (
    <ReactEChartsCore
      style={{ height: "50vh" }}
      echarts={echarts}
      option={configData}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      // onChartReady={this.onChartReadyCallback}
      // onEvents={EventsDict}
      // opts={}
    />
  );
}
