import { SET_KIALI_URL } from '../actions/types';

const initialState = {
  clusterUrls: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_KIALI_URL:
      return { ...payload };
    default:
      return state;
  }
};
