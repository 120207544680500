import React from "react";
import styled from "styled-components";

const FormOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  color: ${(props) => (props.color ? props.color : "white")};
  margin: 1rem;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "10rem")};
`;
const FormHeading = styled.div`
  font-weight: 300;
  font-size: 0.75rem;
  margin-right: 1rem;
  flex: 1;
`;
const FormContent = styled.div`
  font-weight: 500;
  font-size: 0.85rem;
  word-wrap: break-word;
  flex: 4;
`;

export const FormField = (props) => {
  return (
    <FormOuter  className={props.className} color={props.color} style={props.style} minWidth={props.minWidth}>
      <FormHeading>{props.heading}</FormHeading>
      <FormContent>{props.content}</FormContent>
    </FormOuter>
  );
};

export const isValidValue = (val, fallback) => {
  if (val || val === 0) {
    return val;
  }
  return fallback;
};
