import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { styled } from "@mui/styles";
import { connect } from "react-redux";
import { cluster } from "d3";

const StyledNode = styled("div")({
  overflow: "hidden",
  padding: "5px",
  borderRadius: "5px",
  display: "inline-block",
  border: "1px solid coral"
});

const MyComponent = styled("div")({
  overflow:'hidden',
  color: "darkslategray"
  // backgroundColor: "aliceblue",
});



export const NamespaceTopology = ({sliceName,selectedCluster, clusterName,attachedNamespaces}) => {
  
  const [chartData, setChartData] = React.useState({});
  React.useEffect(()=>{
    if(sliceName && selectedCluster )
      setChartData(generateTreeStructure(sliceName,selectedCluster,attachedNamespaces))
    else
      setChartData({})
    },[sliceName,selectedCluster,attachedNamespaces])
  

  const generateTreeStructure = (sliceName,clusterName,attachedNamespaces) =>{
      const list = []
      const currentNamespace = new Set()
      const parentNamespaceList = new Set()
      list.push({
          id:sliceName,
          parentId:null
      })
      currentNamespace.add(sliceName);
      attachedNamespaces.forEach(each=>{
      const data = each.split(":");
      
      if(data.length === 1){
        currentNamespace.add(data[0]);
          list.push({
              id: data[0],
              parentId: sliceName
          })
      }
      else if(data.length ===2){
          list.push({
              id:data[1],
              parentId:data[0]
          })
          parentNamespaceList.add(data[0])
          currentNamespace.add(data[1]);
      }
      else if(data.length ===3){
       
          if(clusterName === data[0]){
              list.push({
                  id:data[2],
                  parentId:data[1]
              })
              parentNamespaceList.add(data[1])
              currentNamespace.add(data[2]);
          }
      }
    })
    Array.from(parentNamespaceList).forEach(eachNamespace =>{
        if(!currentNamespace.has(eachNamespace)){
            list.push({
              id: eachNamespace,
              parentId: sliceName
          })
        }
    })
    const idMapping = list.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
    }, {});
    let root;
    list.forEach(el => {
    // Handle the root element
    if (el.parentId === null) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = list[idMapping[el.parentId]];
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el];
    });
    return root;
  }

  const Node = ({ title }) => {
    return (
      <div style={{
        overflow: "hidden",
        padding: "5px",
        borderRadius: "5px",
        display: "inline-block",
        border: "1px solid coral"
      }}>
        <div style={{
          overflow:'hidden',
          color: "darkslategray"
        }}>{title}</div>
      </div>
    );
  };

  const Child = ({ data }) => {
    return data.map((eachTree) =>
      eachTree?.children?.length > 0 ? (
        <TreeNode key={eachTree.id} label={
          <div style={{
            overflow: "hidden",
            padding: "5px",
            borderRadius: "5px",
            display: "inline-block",
            border: "1px solid coral"
          }}>{eachTree.id}</div>}>
          {/* { eachTree?.children?.length > 0 && ( */}
          <Child key={eachTree.children.length} data={eachTree.children} />
          {/* } */}
        </TreeNode>
      ) : (
        <TreeNode label={<div style={{
          overflow: "hidden",
          padding: "5px",
          borderRadius: "5px",
          display: "inline-block",
          border: "1px solid coral"
        }}>{eachTree.id}</div>} />
      )
    );
  };
  const DisplayTree = ({ treeData }) => {
    return <div style={{height:'100%',width:'100%'}}>
      {
        treeData && Object.keys(treeData).length > 0 && 
        <Tree
        lineWidth={"2px"}
        lineColor={"blue"}
        lineBorderRadius={"10px"}
        label={<Node title={treeData.id}></Node>}
      >
        {treeData?.children?.length > 0 && <Child data={treeData.children} />}
      </Tree>
      }
      </div>
    ;
  };
  return (
    <DisplayTree treeData={chartData} />
  );
};


const mapStateToProps = (state) => ({
  attachedNamespaces: state.hnc.namespaceList,
  clusterName: state.hnc.clusterName
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NamespaceTopology);
