import React, { Component } from "react";
import { FormField, FormSelect } from "../Commons/formUtils";
import Button from "@material-ui/core/Button";
import { ButtonContainer, FormContainer, Title } from "./FormStyles";
import { connect } from "react-redux";
import { createQosProfile, editQosProfile } from "../../actions/QOSProfileActions";
import { keyMap } from "./../../utils/keyMap";
class QosForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      errorMesName: "",
      errorMesQueue_type: "",
      errorMesBandwidthCeiling: "",
      errorMesBandwidthGuaranteed: "",
      errorMesPriority: "",
      errorMesDscpClass: "",
      errorMesCopiedFrom: "",
      name: "",
      queueType: "",
      bandwidthCeiling: "",
      bandwidthGuaranteed: "",
      priority: "",
      type: "New",
      copiedFrom: "",
      dscpClass: "",
    };
  }
  arrToObj = (arr) => {
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
      obj[arr[i]] = arr[i];
    }
    return obj;
  };
  //submitForm = () => {
  //let value = { ...this.state.value };
  //alert(value);
  //};
  //setValue = (key, val) => {
  //this.setState({ value: { ...this.state.value, [key]: val } });
  validateInput = () => {
    let error = false;
    if (!this.state.name.trim()) {
      this.setState({ errorMesName: "Name is required" });
      error = true;
    }
    if (!this.state.queueType.trim()) {
      this.setState({ errorMesQueue_type: "Queue Type is required" });
      error = true;
    }
    if (!this.state.bandwidthCeiling.trim()) {
      this.setState({
        errorMesBandwidthCeiling: "Bandwidth Ceiling is required",
      });
      error = true;
    }
    if (!this.state.bandwidthGuaranteed.trim()) {
      this.setState({
        errorMesBandwidthGuaranteed: "Bandwidth Guaranteed is required",
      });
      error = true;
    }
    if (!this.state.priority.trim()) {
      this.setState({ errorMesPriority: "priority is required" });
      error = true;
    }

    if (!this.state.dscpClass.trim()) {
      this.setState({ errorMesDscpClass: "DSCP is required" });
      error = true;
    }
    return !error;
  };

  submitForm = () => {
    if (this.validateInput()) {
      const { name, bandwidthCeiling, bandwidthGuaranteed, priority, queueType, dscpClass } = this.state;
      let value = {
        name,
        qosProfile: {
          queueType,
          bandwidthCeilingKbps: bandwidthCeiling,
          bandwidthGuaranteedKbps: bandwidthGuaranteed,
          priority,
          dscpClass,
        },
      };
      if (this.state.type == "New") this.props.createQosProfile(value);
      else this.props.editQosProfile(value);
      this.props.cancelListener();
    }
  };

  prefillValues = (profile) => {
    const { name, bandwidthCeilingKbps, bandwidthGuaranteedKbps, priority, queueType, dscpClass } = profile;
    this.setState({
      name,
      bandwidthCeiling: bandwidthCeilingKbps,
      bandwidthGuaranteed: bandwidthGuaranteedKbps,
      priority,
      queueType,
      dscpClass: dscpClass == "QOS_PROFILE_DSCP_CLASS_INVALID" ? "" : dscpClass,
    });
  };
  componentDidMount() {
    if (this.props.selectedProfile && Object.keys(this.props.selectedProfile).length > 0) {
      this.setState({ type: "Edit" }, () => this.prefillValues(this.props.selectedProfile));
    }
  }
  handleCopyProfile = (e) => {
    const qosProfileNames = Object.keys(this.props.qosProfiles);
    this.setState({ copiedFrom: qosProfileNames[e.nativeEvent.target.value] });
    this.prefillValues(this.props.qosProfiles[qosProfileNames[e.nativeEvent.target.value]]);
  };
  // setValue = (key, val) => {
  //   this.setState({ value: { ...this.state.value, [key]: val } });
  // };
  render() {
    const { cancelListener } = this.props;
    const {
      type,
      permissionOptions,
      value,
      name,
      errorMesName,
      queueType,
      errorMesQueue_type,
      bandwidthCeiling,
      bandwidthGuaranteed,
      errorMesBandwidthCeiling,
      errorMesBandwidthGuaranteed,
      priority,
      errorMesPriority,
      copiedFrom,
      errorMesDscpClass,
      errorMesCopiedFrom,
      dscpClass,
    } = this.state;
    let CopySelectValue = Object.keys(this.props.qosProfiles).indexOf(copiedFrom);
    CopySelectValue = CopySelectValue == -1 ? "" : CopySelectValue;
    return (
      <FormContainer>
        <Title>{type == "New" ? "New" : "Edit"} Profile</Title>
        {type == "New" && (
          <FormSelect
            error={errorMesCopiedFrom && !copiedFrom}
            helperText={!copiedFrom && errorMesCopiedFrom}
            label="Copy From"
            value={CopySelectValue}
            options={Object.keys(this.props.qosProfiles)}
            onChange={this.handleCopyProfile}
          />
        )}
        <FormField
          disabled={type == "Edit"}
          error={errorMesName && !name}
          helperText={!name && errorMesName}
          label="Name"
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value, errorMesName: "" })}
        />
        <FormField
          error={errorMesQueue_type && !queueType}
          helperText={!queueType && errorMesQueue_type}
          label="Queue Type"
          options={{
            QOS_QUEUE_TYPE_INVALID: "Invalid",
            QOS_QUEUE_TYPE_HTB: "HTB",
          }}
          value={this.state.queueType}
          onChange={(e) => this.setState({ queueType: e.target.value, errorMesQueue_type: "" })}
        />
        <FormField
          error={errorMesBandwidthCeiling && !bandwidthCeiling}
          helperText={!bandwidthCeiling && errorMesBandwidthCeiling}
          label="Bandwidth Ceiling (Kbps)"
          value={this.state.bandwidthCeiling}
          onChange={(e) =>
            this.setState({
              bandwidthCeiling: e.target.value,
              errorMesBandwidthCeiling: "",
            })
          }
        />
        <FormField
          error={errorMesBandwidthGuaranteed && !bandwidthGuaranteed}
          helperText={!bandwidthGuaranteed && errorMesBandwidthGuaranteed}
          label="Bandwidth Guaranteed (Kbps)"
          value={this.state.bandwidthGuaranteed}
          onChange={(e) =>
            this.setState({
              bandwidthGuaranteed: e.target.value,
              errorMesBandwidthGuaranteed: "",
            })
          }
        />
        <FormField
          error={errorMesPriority && !priority}
          helperText={!priority && errorMesPriority}
          label="Priority"
          value={this.state.priority}
          onChange={(e) => this.setState({ priority: e.target.value, errorMesPriority: "" })}
        />
        <FormSelect
          error={errorMesDscpClass && !dscpClass}
          helperText={!dscpClass && errorMesDscpClass}
          label="DSCP Class"
          options={keyMap.dscpClass}
          value={dscpClass || ""}
          onChange={(e) => this.setState({ dscpClass: e.nativeEvent.target.value })}
        />
        <ButtonContainer>
          <Button variant="contained" color="primary" onClick={this.submitForm}>
            Submit
          </Button>
          <Button variant="contained" color="secondary" onClick={cancelListener}>
            Cancel
          </Button>
        </ButtonContainer>
      </FormContainer>
    );
  }
}

export default connect(null, { createQosProfile, editQosProfile })(QosForm);
