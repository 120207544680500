import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {
  useFormField,
  FormField,
  FormSelect,
  FormMaskField,
} from "./../Commons/formUtils";
import { usePrevious } from "./../Commons/hooksUtils";
import { keyMap } from "./../../utils/keyMap";
import { connect } from "react-redux";
import {
  Close,
  Resource,
  Scrollable,
  CustomFormField,
} from "../RoleManager/Policy/styles";
import { verifyNamespaceFormat, verifyHierarchyNamespaceFormat } from "../../utils/functions";

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const selectOptions = keyMap;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddSliceDialog(props) {
  //creating css classes from jss
  const classes = useStyles();
  //form fields
  const sliceName = useFormField(props.slice?.sliceName);
  const sliceDisplayName = useFormField(props.slice?.sliceDisplayName);
  const sliceId = useFormField(props.slice?.sliceId);
  const sliceSubnet = useFormField(props.slice?.sliceSubnet);
  const sliceType = useFormField(props.slice?.sliceType);
  const sliceGWType = useFormField(
    props.slice?.sliceGatewayProvider?.sliceGatewayType
  );
  const sliceCAType = useFormField(
    props.slice?.sliceGatewayProvider?.sliceCaType
  );
  const sliceQOSProfile = useFormField(props.slice?.sliceQosProfile);
  const sliceIDP = useFormField();
  const sliceIPAMType = useFormField();
  const clusterSubnetSize = useFormField();
  const [maxClusterNumber, setClusterNumber] = useState(5);
  const [sliceNameErrorMes, setSliceNameErrorMes] = useState("");
  const [sliceDisplayNameErrorMes, setSliceDisplayNameErrorMes] = useState("");
  const [sliceQOSProfileErrorMes, setSliceQOSProfileErrorMes] = useState("");
  const [maxClusterNumberErrorMes, setMaxClusterNumberErrorMes] = useState("");
  const [sliceSubnetErrorMes, setSliceSubnetErrorMes] = useState("");
  const [sliceIDPErrorMes, setSliceIDPErrorMes] = useState("");
  const [sliceTypeErrorMes, setSliceTypeErrorMes] = useState("");
  const [sliceIPAMTypeErrorMes, setSliceIPAMTypeErrorMes] = useState("");
  const [sliceGWTypeErrorMes, setSliceGWTypeErrorMes] = useState("");
  const [clusterSubnetSizeErrorMes, setClusterSubnetSizeErrorMes] =
    useState("");
  const [sliceCATypeErrorMes, setSliceCATypeErrorMes] = useState("");
  const [applicationNamespaceErrorMes, setApplicationNamespaceErrorMes] =
    useState("");
  const [allowedNamespaceErrorMes, setAllowedNamespaceErrorMes] = useState("");
  const [sliceNamespaceHierarchyErrorMes,setSliceNamespaceHierarchyErrorMes] = useState("")
  const applicationNamespaceValue =
    props.slice?.namespaceIsolationProfile?.applicationNamespaces || [];
  const allowedNamespaceValue =
    props.slice?.namespaceIsolationProfile?.allowedNamespaces || [];
  const [applicationNamespaces, setApplicationNamespaces] = useState(
    applicationNamespaceValue
  );
  const [allowedNamespaces, setAllowedNamespaces] = useState(
    allowedNamespaceValue
  );
  const [sliceNamespaceHierarchy,setSliceNamespaceHierarchy] = useState([])
  const [currentApplicationNamespace, setCurrentApplicationNamespace] =
    useState("");
  const [currentAllowedNamespace, setCurrentAllowedNamespace] = useState("");
  const [currentSliceNamespaceHierarchy,setCurrentSliceNamespaceHierarchy] = useState("")

  const [qosProfileOptions, setQosProfileOptions] = useState({});
  useEffect(() => {
    let qosProfileOptions = getQosProfileOptions();
    setQosProfileOptions(qosProfileOptions);
  }, [props.qos_profiles]);

  const getQosProfileOptions = () => {
    let options = {};
    Object.keys(props.qos_profiles).forEach((profile) => {
      options[profile] = profile;
    });
    return options;
  };

  const handleClusterNUmberChange = (e) => {
    if (e > 255) setClusterNumber(255);
    else if (e < 0) setClusterNumber(0);
    else setClusterNumber(e);
  };

  const filterPlaceholderFromSubnetValue = (addr) => {
    let _addr = "";
    for (let i in addr) {
      if (addr[i] !== "_") _addr += addr[i];
    }
    return _addr;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.edit) {
      let sliceDetails = {
        sliceQosProfile: sliceQOSProfile.value,
        namespaceIsolationProfile: {
          ...props?.slice?.namespaceIsolationProfile,
          applicationNamespaces,
          allowedNamespaces,
          sliceNamespaceHierarchy
        },
      };
      props.handleSubmit(sliceDetails);
      props.handleClose();
      return true;
    }

    const sliceNameTest = /^[a-z0-9]+([a-z0-9-]*[a-z0-9]){0,62}$/;
    const sliceDisplayNameTest = /^[a-zA-Z0-9 ]{1,63}$/;
    if (!sliceName.value.trim()) {
      setSliceNameErrorMes("Slice Name is required");
      return;
    } else if (!sliceNameTest.test(sliceName.value)) {
      setSliceNameErrorMes("Slice Name is invalid");
      return;
    } else if (!sliceDisplayName.value.trim()) {
      setSliceDisplayNameErrorMes("Slice Display Name is required");
      return;
    } else if (!sliceDisplayNameTest.test(sliceDisplayName.value)) {
      setSliceDisplayNameErrorMes("Slice Display Name is invalid");
      return;
    } else if (!sliceQOSProfile.value.trim()) {
      setSliceQOSProfileErrorMes("Slice QOS Profile is required");
      return;
    } else if (!maxClusterNumber) {
      setMaxClusterNumberErrorMes("Max Cluster Number is required");
      return;
    } else if (!filterPlaceholderFromSubnetValue(sliceSubnet.value).trim()) {
      setSliceSubnetErrorMes("Slice Subnet is required");
      return;
    } else if (
      !testIPAddress(filterPlaceholderFromSubnetValue(sliceSubnet.value))
    ) {
      setSliceSubnetErrorMes("Invalid Slice Subnet Address");
      return;
    } else if (!sliceIDP.value.trim()) {
      setSliceIDPErrorMes("Slice IDP is required");
      return;
    } else if (!sliceType.value.trim()) {
      setSliceTypeErrorMes("Slice Type is required");
      return;
    } else if (!sliceIPAMType.value.trim()) {
      setSliceIPAMTypeErrorMes("Slice IPAM Type is required");
      return;
    } else if (!sliceGWType.value.trim()) {
      setSliceGWTypeErrorMes("Slice GW Type is required");
      return;
    } else if (!clusterSubnetSize.value.trim()) {
      setClusterSubnetSizeErrorMes("Cluster Subnet Size is required");
      return;
    } else if (!sliceCAType.value.trim()) {
      setSliceCATypeErrorMes("Slice CA Type is required");
      return;
    }
    if (
      sliceName.value &&
      sliceDisplayName.value &&
      sliceQOSProfile.value &&
      maxClusterNumber &&
      sliceSubnet.value &&
      sliceIDP.value &&
      sliceType.value &&
      sliceIPAMType.value &&
      sliceGWType.value &&
      clusterSubnetSize.value &&
      sliceCAType.value
    ) {
      let gatewayProvider = {
        sliceGatewayType: sliceGWType.value,
        sliceCaType: sliceCAType.value,
        sliceIdp: sliceIDP.value,
        maxGateways: Number(maxClusterNumber),
        gatewaySubnetSize: Number(clusterSubnetSize.value),
      };

      const getSubnet = (value) => {
        const data = value.split(".").map((ele) => ele.trim());
        return data.join(".");
      };

      let sliceDetails = {
        sliceName: sliceName.value,
        sliceDisplayName: sliceDisplayName.value,
        sliceSubnet: getSubnet(
          filterPlaceholderFromSubnetValue(sliceSubnet.value)
        ),
        sliceType: sliceType.value,
        sliceGatewayProvider: gatewayProvider,
        sliceQosProfile: sliceQOSProfile.value,
        sliceIpamType: sliceIPAMType.value,
        namespaceIsolationProfile: {
          applicationNamespaces,
          allowedNamespaces,
          sliceNamespaceHierarchy
        },
      };

      await props.handleSubmit(sliceDetails);
      //close dialog after submitting form
      props.handleClose();
    }
  };

  const handleClose = () => {
    setSliceNameErrorMes("");
    setSliceDisplayNameErrorMes("");
    setSliceQOSProfileErrorMes("");
    setMaxClusterNumberErrorMes("");
    setSliceSubnetErrorMes("");
    setSliceIDPErrorMes("");
    setSliceTypeErrorMes("");
    setSliceIPAMTypeErrorMes("");
    setSliceGWTypeErrorMes("");
    setClusterSubnetSizeErrorMes("");
    setSliceCATypeErrorMes("");
  };
  const formCleanup = () => {
    sliceName.setValue("");
    sliceDisplayName.setValue("");
    sliceId.setValue("");
    sliceSubnet.setValue("");
    sliceType.setValue("");
    sliceGWType.setValue("");
    sliceCAType.setValue("");
    sliceQOSProfile.setValue("");
    sliceIPAMType.setValue("");
    sliceIDP.setValue("");
    setCurrentAllowedNamespace("");
    setCurrentApplicationNamespace("");
    setAllowedNamespaces([]);
    setApplicationNamespaces([]);
    setSliceNamespaceHierarchy([])
  };

  const prevOpen = usePrevious(props.open);

  useEffect(() => {
    if (prevOpen === undefined || prevOpen === false) {
      formCleanup();
      handleClose();
    }
  }, [prevOpen]);

  useEffect(() => {
    if (props.open) {
      setAllowedNamespaces(
        props?.slice?.namespaceIsolationProfile?.allowedNamespaces || []
      );
      setApplicationNamespaces(
        props?.slice?.namespaceIsolationProfile?.applicationNamespaces || []
      );
      setSliceNamespaceHierarchy(
        props?.slice?.namespaceIsolationProfile?.sliceNamespaceHierarchy || []
      )
    }
    return () => {
      setApplicationNamespaces([]);
      setAllowedNamespaces([]);
      setSliceNamespaceHierarchy([]);
    };
  }, [props.open]);

  const testIPAddress = (addr) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(16)$/.test(
        addr
      )
    ) {
      return true;
    }
    return false;
  };

  const handleOnChange = (e, value, errorMsg, errorMsgHandler) => {
    if (errorMsg) errorMsgHandler("");
    value.onChange(e);
  };
  const handleAddNamespace = (listHook, currentHook, e, errorHook, state) => {
    if (e.key === "Enter") {
      const value = e.nativeEvent.target.value;
      if (verifyNamespaceFormat(value)) {
        listHook([...state, value]);
        currentHook("");
      } else {
        errorHook("Invalid Format");
      }
    }
  };
  const handleAddApplicationNamespace = (e) => {
    handleAddNamespace(
      setApplicationNamespaces,
      setCurrentApplicationNamespace,
      e,
      setApplicationNamespaceErrorMes,
      applicationNamespaces
    );
  };

  const handleAddSliceNamespaceHierarchy = (e) => {
    if (e.key === "Enter") {
      const value = e.nativeEvent.target.value;
      if (verifyHierarchyNamespaceFormat(value)) {
        setSliceNamespaceHierarchy([...sliceNamespaceHierarchy, value]);
        setCurrentSliceNamespaceHierarchy("");
      } else {
        setSliceNamespaceHierarchyErrorMes("Invalid Format");
      }
    }
  };
  const handleAddAllowedNamespace = (e) => {
    handleAddNamespace(
      setAllowedNamespaces,
      setCurrentAllowedNamespace,
      e,
      setAllowedNamespaceErrorMes,
      allowedNamespaces
    );
  };
  return (
    <div>
      <Dialog
        style={{ zIndex: 4000 }}
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage: "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.edit ? `Edit Slice` : `New Slice`}
            </Typography>
            <Button
              variant="contained"
              autoFocus
              color="secondary"
              onClick={handleSubmit}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <form
          style={{
            margin: "1rem",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            overflow: "auto",
            alignItems: "center",
          }}
        >
          <FormElement>
            <FormField
              error={sliceNameErrorMes}
              helperText={sliceNameErrorMes}
              label="Slice Name"
              disabled={props.edit}
              {...sliceName}
              onChange={(e) =>
                handleOnChange(
                  e,
                  sliceName,
                  sliceNameErrorMes,
                  setSliceNameErrorMes
                )
              }
            />
          </FormElement>
          <FormElement>
            <FormField
              error={sliceDisplayNameErrorMes}
              helperText={sliceDisplayNameErrorMes}
              label="Slice Display Name"
              disabled={props.edit}
              {...sliceDisplayName}
              onChange={(e) =>
                handleOnChange(
                  e,
                  sliceDisplayName,
                  sliceDisplayNameErrorMes,
                  setSliceDisplayNameErrorMes
                )
              }
            />
          </FormElement>
          <FormElement>
            <FormMaskField
              label="Slice Subnet"
              value={sliceSubnet.value}
              error={sliceSubnetErrorMes}
              helperText={sliceSubnetErrorMes}
              disabled={props.edit}
              {...sliceSubnet}
              onChange={(e) =>
                handleOnChange(
                  e,
                  sliceSubnet,
                  sliceSubnetErrorMes,
                  setSliceSubnetErrorMes
                )
              }
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceTypeErrorMes && !sliceType.value}
              helperText={!sliceType.value && sliceTypeErrorMes}
              label="Slice Type"
              disabled={props.edit}
              {...sliceType}
              options={selectOptions.sliceType}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceGWTypeErrorMes && !sliceGWType.value}
              helperText={!sliceGWType.value && sliceGWTypeErrorMes}
              label="Slice GW Type"
              disabled={props.edit}
              {...sliceGWType}
              options={selectOptions.sliceGatewayType}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceCATypeErrorMes && !sliceCAType.value}
              helperText={!sliceCAType.value && sliceCATypeErrorMes}
              label="Slice CA Type"
              disabled={props.edit}
              {...sliceCAType}
              options={selectOptions.sliceCAType}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceQOSProfileErrorMes && !sliceQOSProfile.value}
              helperText={!sliceQOSProfile.value && sliceQOSProfileErrorMes}
              label="Slice QOS Profile"
              {...sliceQOSProfile}
              options={qosProfileOptions}
            />
          </FormElement>
          <FormElement>
            <FormField
              error={maxClusterNumberErrorMes && !maxClusterNumber.value}
              helperText={!maxClusterNumber.value && maxClusterNumberErrorMes}
              label="Max Clusters"
              value={maxClusterNumber}
              disabled={props.edit}
              onChange={(e) => {
                handleClusterNUmberChange(e.target.value);
              }}
              type="number"
              InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceIDPErrorMes && !sliceIDP.value}
              helperText={!sliceIDP.value && sliceIDPErrorMes}
              label="Slice IDP"
              disabled={props.edit}
              {...sliceIDP}
              options={selectOptions.sliceIDP}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={sliceIPAMTypeErrorMes && !sliceIPAMType.value}
              helperText={!sliceIPAMType.value && sliceIPAMTypeErrorMes}
              label="IPAM Type"
              disabled={props.edit}
              {...sliceIPAMType}
              options={selectOptions.sliceIPAMType}
            />
          </FormElement>
          <FormElement>
            <FormSelect
              error={clusterSubnetSizeErrorMes && !clusterSubnetSize.value}
              helperText={!clusterSubnetSize.value && clusterSubnetSizeErrorMes}
              label="Cluster Subnet Size"
              disabled={props.edit}
              {...clusterSubnetSize}
              options={selectOptions.clusterSubnetSize}
            />
          </FormElement>
          <FormElement>
            <CustomFormField
              value={currentApplicationNamespace}
              label="Application Namespace"
              onChange={(e) => {
                setCurrentApplicationNamespace(e.target.value);
                setApplicationNamespaceErrorMes("");
              }}
              onKeyDown={handleAddApplicationNamespace}
              error={applicationNamespaceErrorMes}
              helperText={applicationNamespaceErrorMes}
            />
            <Scrollable>
              {applicationNamespaces.map((namespace) => (
                <Resource  key={namespace} style={{ minWidth: "30rem", width: "auto" }}>
                  <div style={{ color: "grey" }}>{namespace}</div>
                  <Close
                    color="grey"
                    onClick={() =>
                      setApplicationNamespaces(
                        applicationNamespaces.filter(
                          (item) => item !== namespace
                        )
                      )
                    }
                  />
                </Resource>
              ))}
            </Scrollable>
          </FormElement>
          <FormElement>
            <CustomFormField
              value={currentAllowedNamespace}
              label="Allowed Namespace"
              onChange={(e) => {
                setCurrentAllowedNamespace(e.target.value);
                setAllowedNamespaceErrorMes("");
              }}
              onKeyDown={handleAddAllowedNamespace}
              error={allowedNamespaceErrorMes}
              helperText={allowedNamespaceErrorMes}
            />
            <Scrollable>
              {allowedNamespaces.map((namespace) => (
                <Resource style={{ minWidth: "30rem", width: "auto" }}>
                  <div style={{ color: "grey" }}>{namespace}</div>
                  <Close
                    color="grey"
                    onClick={() =>
                      setAllowedNamespaces(
                        allowedNamespaces.filter((item) => item !== namespace)
                      )
                    }
                  />
                </Resource>
              ))}
            </Scrollable>
          </FormElement>
          <FormElement>
            <CustomFormField
              value={currentSliceNamespaceHierarchy}
              label="Namespace Hierarchy"
              onChange={(e) => {
                setCurrentSliceNamespaceHierarchy(e.target.value);
                setSliceNamespaceHierarchyErrorMes("");
              }}
              onKeyDown={handleAddSliceNamespaceHierarchy}
              error={sliceNamespaceHierarchyErrorMes}
              helperText={sliceNamespaceHierarchyErrorMes}
            />
            <Scrollable>
              {sliceNamespaceHierarchy.map((namespace) => (
                <Resource style={{ minWidth: "30rem", width: "auto" }}>
                  <div style={{ color: "grey" }}>{namespace}</div>
                  <Close
                    color="grey"
                    onClick={() =>
                      setSliceNamespaceHierarchy(
                        sliceNamespaceHierarchy.filter(
                          (item) => item !== namespace
                        )
                      )
                    }
                  />
                </Resource>
              ))}
            </Scrollable>
          </FormElement>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ qosProfiles: { qos_profiles } }) => ({
  qos_profiles,
});

export default connect(mapStateToProps, {})(AddSliceDialog);
