import React, { Component } from "react";
import { render } from "react-dom";
import { EditorView, EditorState, basicSetup } from "@codemirror/basic-setup";
import { StreamLanguage } from "@codemirror/stream-parser";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import styled from "styled-components";
import {
  Button,
  Grid,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import YAML from "yaml";
import {
  Tooltip,
  showTooltip,
  hoverTooltip,
  tooltips,
} from "@codemirror/tooltip";
import { StateField } from "@codemirror/state";
import { renderToString } from "react-dom/server";
import SliceInfo from "./SliceInfo";

const EditorContainer = styled.div`
  border: ${(props) => (props.error ? "solid red 1px" : null)};
`;
const getCursorTooltips = (state) => {
  return state.selection.ranges
    .filter((range) => range.empty)
    .map((range) => {
      let line = state.doc.lineAt(range.head);
      let text = line.number + ":" + (range.head - line.from);
      return {
        pos: range.head,
        above: true,
        strictSide: true,
        class: "cm-cursor-tooltip",
        create: () => {
          let dom = document.createElement("div");
          dom.textContent = text;
          return { dom };
        },
      };
    });
};
const cursorTooltipBaseTheme = EditorView.baseTheme({
  ".cm-tooltip.cm-tooltip-hover": {
    backgroundColor: "#66b",
    color: "white",
    // transform: "translate(-50%, -7px)",
    border: "none",
    padding: "2px 7px",
    borderRadius: "10px",
    minHeight: "0rem",
    width: "10rem",
    position: "fixed",
    right: "1rem !important",
    top: "1rem",
    display: "none",
    "&:after": {
      position: "fixed",
      content: "Astitva",
      right: "1rem",
      bottom: "1rem",
      minHeight: "10rem",
      minWidth: "10rem",
      display: "block",
    },
  },
  ".cm-tooltip-section": {
    left: "1000px !important",
  },
});
function cursorTooltip() {
  return [wordHover, cursorTooltipBaseTheme];
}
const handleAddTooltip = (text) => {
  let doc = document.getElementById("trust");

  let _tooltip = document.createElement("div");
  _tooltip.style.position = "absolute";
  _tooltip.style.height = "80%";
  _tooltip.style.width = "20%";
  _tooltip.style.right = "2%";
  _tooltip.style.bottom = "10%";
  _tooltip.style.background = "transparent";

  _tooltip.innerHTML = renderToString(<SliceInfo text={text} />);
  doc.appendChild(_tooltip);

  //removing info tooltip on hover remove
  const intervalRef = setInterval(() => {
    if (!document.getElementById("tooltipDom")) {
      doc.removeChild(_tooltip);
      clearInterval(intervalRef);
    }
  }, 100);
};

const wordHover = hoverTooltip((view, pos, side) => {
  console.log("check", view.viewState);
  let { from, to, text } = view.state.doc.lineAt(pos);
  let start = pos,
    end = pos;
  while (start > from && /\w/.test(text[start - from - 1])) start--;
  while (end < to && /\w/.test(text[end - from])) end++;
  if ((start == pos && side < 0) || (end == pos && side > 0)) return null;
  return {
    pos: end,
    end: 0,
    above: true,
    create: (view) => {
      let dom = document.createElement("div");
      dom.id = "tooltipDom";
      return {
        dom,
        mount: () => handleAddTooltip(text.split(":")?.[0] || ""),
      };
    },
  };
});
const cursorTooltipField = StateField.define({
  create: getCursorTooltips,

  update(tooltips, tr) {
    if (!tr.docChanged && !tr.selection) return tooltips;
    return getCursorTooltips(tr.state);
  },

  provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
});

export default class YamlEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yaml: "",
      error: false,
      fileName: "",
    };
    this.editor = React.createRef();
  }
  componentDidMount() {
    this.prefillYaml();
  }
  initializeEditor = (yamlValue) => {
    const state = EditorState.create({
      doc: yamlValue,
      extensions: [
        basicSetup,
        StreamLanguage.define(yaml),
        ...cursorTooltip(),
        // tooltips({ position: "absolute" }),
      ],
    });
    this.view = new EditorView({ state, parent: this.editor.current });
    // return () => {
    //   view.destroy();
    //   // editor.current.removeEventListener("input", log);
    // };
  };
  componentWillUnmount() {
    this.view.destroy();
  }

  prefillYaml = () => {
    const {
      sliceName,
      sliceDisplayName,
      sliceSubnet,
      sliceType,
      namespaceIsolationProfile,
      sliceGatewayProvider: {
        sliceGatewayType,
        sliceCaType,
        sliceIdp,
        maxGateways,
        gatewaySubnetSize,
      },
      sliceQosProfile,
      sliceIpamType,
      schSliceClusterSite,
    } = this.props.slice;
    const slice = {
      slice: {
        name: sliceName,
        displayName: sliceDisplayName,
        subnet: sliceSubnet,
        type: sliceType,
        namespaceIsolationProfile,
        gatewayProvider: {
          type: sliceGatewayType,
          caType: sliceCaType,
          idp: sliceIdp,
          maxGateways,
          subnetSize: gatewaySubnetSize,
        },
        qosProfile: sliceQosProfile,
        ipamType: sliceIpamType,
        site: schSliceClusterSite,
      },
    };
    const doc = new YAML.Document();
    doc.contents = slice;
    this.setState({ yaml: doc.toString() }, () =>
      this.initializeEditor(doc.toString())
    );
  };
  handleDownload = () => {
    const yaml = this.view.viewState.state.doc.toString();
    if (!this.verifyYaml(yaml)) {
      this.setState({ error: true });
      return;
    }
    const blob = new Blob([yaml]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState({ fileDownloadUrl: fileDownloadUrl, error: false }, () => {
      this.dofileDownload.click();
      URL.revokeObjectURL(fileDownloadUrl);
      this.setState({ fileDownloadUrl: "" });
    });
  };
  verifyYaml = (yaml) => {
    try {
      YAML.parse(yaml);
      return true;
    } catch (e) {
      return false;
    }
  };
  render() {
    const { yaml, error, fileName } = this.state;
    return (
      <Paper style={{ padding: "1rem", position: "relative" }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container justifyContent="space-between" wrap="nowrap">
            <Grid item xs>
              <Typography variant="h6">Slice Configuration Editor</Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              spacing={2}
              xs
              justifyContent="flex-end"
            >
              <Grid item>
                <Input
                  placeholder="File Name"
                  value={fileName}
                  onChange={(e) => this.setState({ fileName: e.target.value })}
                  endAdornment={
                    <InputAdornment position="end">.yaml</InputAdornment>
                  }
                  style={{ width: "20vw" }}
                />
              </Grid>
              <Grid item>
                <a
                  style={{ display: "none" }}
                  download={fileName + ".yaml"}
                  href={this.state.fileDownloadUrl}
                  ref={(e) => (this.dofileDownload = e)}
                >
                  download it
                </a>
                <Button
                  variant="contained"
                  disabled={!fileName}
                  onClick={this.handleDownload}
                  disableElevation
                >
                  Download File
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid id="trust" item xs style={{ position: "relative" }}>
            <EditorContainer ref={this.editor} error={error}></EditorContainer>
            {/* <ControlledEditor
              onBeforeChange={this.handleYAMLChange}
              value={yaml}
              options={{
                lineWrapping: true,
                lint: true,
                mode: "yaml",
                theme: "eclipse",
                lineNumbers: true,
              }}
              style={{ border: error ? "solid red 1px" : null }}
            /> */}
            {/* <AceEditor
              mode="yaml"
              theme="tomorrow"
              name="editor"
              value={yaml}
              onChange={this.handleYAMLChange}
              fontSize={14}
              editorProps={{ $blockScrolling: true }}
              style={{ width: "100%", border: error ? "solid red 1px" : null }}
            /> */}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
