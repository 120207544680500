import React from "react";
import { NavContainer, MenuContainer, MenuOuterContainer } from "./NavBarStyles";
import { withRouter } from "react-router-dom";

import MenuItems from "./MenuItems";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: "",
    };
  }

  handleInputChange = (key, event) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  render() {
    const { history, location, menuOpen, handleDrawerToggle } = this.props;

    return (
      <NavContainer>
        <MenuContainer>
          <MenuOuterContainer>
            <MenuItems history={history} location={location} open={menuOpen} handleDrawerToggle={handleDrawerToggle} />
          </MenuOuterContainer>
        </MenuContainer>
      </NavContainer>
    );
  }
}

export default withRouter(NavBar);
