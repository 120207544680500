import React from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 95%;
  display: flex;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: fit-content;
  :hover {
    cursor: pointer;
  }
`;

const Details = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  min-width: 20rem;
  height: fit-content;
`;

const IconContainer = styled.div`
  /* padding: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
`;

function SliceCard(props) {
  
  const getServices = () => {
    const policy = props.policy;
    const { permissions } = policy;
    let services = new Set();
    permissions.forEach((permission) => {
      if (permission?.action?.length > 0) {
        let action = permission.action[0];
        if (action == ".*") {
          services.add("All Access")
          return;
        } else {
          services.add(action.split(":")[0]);
        }
      }
    });
    return services
  };

  return (
    <Container onClick={props.onClick} data-cy={`av-policy-${props.index + 1}`}>
      <Details>
        <h4 style={{ margin: 5, color: "green" }}>{props?.policy?.policyName}</h4>
      </Details>
      <Details>
        <h5 style={{ margin: 5, color: "gray", paddingBottom: "2px" }}>{[...getServices()].join(', ')}</h5>
        <h5 style={{ margin: 5, color: "gray" }}>{props?.policy?.permissions?.length}</h5>
      </Details>
      <IconContainer>
        <ArrowForwardIosIcon
          style={{
            fontSize: "30px",
            color: "gray",
          }}
        />
      </IconContainer>
    </Container>
  );
}
export default withRouter(SliceCard);
