import React, { Component } from "react";
import { connect } from "react-redux";
import { BodyRoot } from "../Body/BodyStyles";
import SliceCard from "./SliceCard";
import styled from "styled-components";
import {
  Toolbar,
  InputBase,
  AppBar,
} from "./SliceStyles";
import Filters from "./Filters/Filters";
import { Button } from "@material-ui/core";
import {
  createSlice,
  getAllSlices,
  getSliceGateway,
  getSliceServices,
} from "../../actions/SliceActions";
import { updateFilter } from "../../actions/SliceFilterActions";
import AddSliceDialog from "./AddSlicesDialog";
import { FilterButton } from "../Commons/IconButtons";
import SliceTable from "./SliceTable";
import { createSearchRegex } from "../../utils/functions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
`;

class SliceList extends Component {
  constructor() {
    super();

    this.state = {
      showFilters: false,
      openSliceDialog: false,
      searchQuery: "",
      showCluster: false,
      indexSelected: {},
      renderData: [],
    };
  }

  componentDidMount() {
    this.setState({
      renderData: this.props.sliceList,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery, renderData } = this.state;
    const { filters, sliceList } = this.props;
    if (
      searchQuery != prevState.searchQuery ||
      JSON.stringify(prevProps.filters) != JSON.stringify(filters)
    ) {
      const data = this.filterSlice();
      this.setState({
        renderData: data,
      }, () => this.setIndex(0, data[0]?.sliceId));
    }
    if (prevProps.sliceList.length != sliceList.length) {
      this.setState({
        renderData: this.props.sliceList,
      });
    }
  }

  handleSliceDialogSubmit = async (sliceDetails) => {
    let res = await this.props.createSlice(sliceDetails);
    await this.props.getAllSlices();
  };

  filterSlice = () => {
    let sliceList = [...this.props.sliceList];
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    sliceList = sliceList.filter((slice) => search.test(slice.sliceName));
    const filterCategories = Object.keys(this.props.filters);
    for (let i = 0; i < filterCategories.length; i++) {
      for (let filter in this.props.filters[filterCategories[i]].values) {
        if (this.props.filters[filterCategories[i]].values[filter] == false) {
          if (filterCategories[i] === "sliceGatewayType") {
            sliceList = sliceList.filter(
              (slice) =>
                slice.sliceGatewayProvider[filterCategories[i]] !== filter
            );
          } else {
            sliceList = sliceList.filter(
              (slice) => slice[filterCategories[i]] !== filter
            );
          }
        }
      }
    }
    return sliceList;
  };

  setIndex = (index, id) => {
    this.setState({
      indexSelected: { index, id },
    });
  };

  OpenCluster = () => {
    this.setState({ showCluster: !this.state.showCluster });
  };

  render() {
    const {
      showFilters,
      renderData,
      openSliceDialog,
      showCluster,
    } = this.state;

    return (
      <BodyRoot>
        <AddSliceDialog
          open={openSliceDialog}
          handleClose={() => this.setState({ openSliceDialog: false })}
          handleSubmit={this.handleSliceDialogSubmit}
        />
        <Filters
          updateFilter={this.props.updateFilter}
          filters={this.props.filters}
          open={showFilters}
          onClose={() => this.setState({ showFilters: false })}
        />
        <AppBar position="static">
          <Toolbar>
            <InputBase
              placeholder="Search"
              onChange={(e) =>
                this.setState({ searchQuery: e?.nativeEvent?.target?.value })
              }
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton
                style={{ color: "black", marginRight: 20 }}
                onClick={() => this.setState({ showFilters: true })}
              />
              <Button
                variant="outlined"
                onClick={() => this.setState({ openSliceDialog: true })}
              >
                Create Slice
              </Button>
              <Button
                variant="outlined"
                style={{
                   marginLeft: "10px", 
                   backgroundColor: this.state.showCluster && "#0c9bce",
                   color: this.state.showCluster && "#fff" 
                }}
                onClick={() =>
                  this.setState({ showCluster: !this.state.showCluster })
                }
              >
                Service Discovery
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        {!showCluster && (
          <>
            {renderData && renderData.length > 0 ? (
              <Container>
                {renderData.map((slice, index) =>
                  !this.state.showCluster ? <SliceCard slice={slice} index={index} /> : null
                )}
              </Container>
            ) : (
              <div
                style={{ textAlign: "center", margin: "50px", color: "gray" }}
              >
                <h3>
                  No data present or you don't have the permissions to view this
                  data.
                </h3>
              </div>
            )}
          </>
        )}
        {showCluster && (
          <SliceTable
            {...this.props}
            sliceList={renderData}
            indexSelected={this.state.indexSelected}
            setIndex={this.setIndex}
          />
        )}
      </BodyRoot>
    );
  }
}

const mapStateToProps = (state) => ({
  sliceList: state.slices.sliceList,
  filters: state.SliceFilterReducer,
});

const mapDispatchToProps = {
  createSlice,
  getAllSlices,
  getSliceGateway,
  getSliceServices,
  updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SliceList);
