import React, { Component } from "react";
import { renderToString } from "react-dom/server";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import CapacityTooltip, { ClusterRow } from "./CapacityTooltip";
import Marker from "./Marker";
import { Grid, Paper, Typography } from "@material-ui/core";
import LatencyChart from "./LatencyChart";
import { connect } from "react-redux";
const FallbackMsg = () => (
  <Grid
    container
    style={{ padding: "0.5rem 1rem", height: "100%" }}
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h6">No Cluster Data Available</Typography>
  </Grid>
);
class SvgClusterMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clusterCompare: {
        cluster1: null,
        cluster2: null,
      },
      clusters: [],
    };
  }

  componentDidMount() {
    this.mapClusterCapacity();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.clusterDetail?.length != this.props.clusterDetail?.length ||
      prevProps.clusterCapacityDetail?.length !==
        this.props.clusterCapacityDetail?.length
    )
      this.mapClusterCapacity();
  }

  removeLatencyChart = () => {
    const { cluster1, cluster2 } = this.state.clusterCompare;
    cluster1.target.fill = "#2868F3";
    cluster2.target.fill = "#2868F3";
    this.setState({
      clusterCompare: {
        cluster1: null,
        cluster2: null,
      },
    });
  };
  handleClusterClick = (ev) => {
    const { cluster2, cluster1 } = this.state.clusterCompare;
    if (cluster1 && cluster2) {
      cluster1.target.fill = "#2868F3";
      cluster2.target.fill = "#2868F3";
      this.setState({
        clusterCompare: {
          cluster1: null,
          cluster2: null,
        },
      });
      return;
    }
    ev.target.fill = "rgb(255, 121, 91)";
    if (cluster1) {
      if (
        ev.target.dataItem.dataContext.clusters?.[0]?.clusterId ==
        cluster1.target.dataItem.dataContext.clusters?.[0]?.clusterId
      ) {
        if (ev.target.dataItem.dataContext.clusters.length == 1) {
          ev.target.fill = "#2868F3";
          this.setState({
            clusterCompare: {
              cluster1: null,
              cluster2: null,
            },
          });
        } else {
          this.setState({
            clusterCompare: {
              ...this.state?.clusterCompare,
              cluster2: ev,
            },
          });
        }
      } else {
        this.setState({
          clusterCompare: {
            ...this.state?.clusterCompare,
            cluster2: ev,
          },
        });
      }
    } else {
      this.setState({
        clusterCompare: {
          ...this.state?.clusterCompare,
          cluster1: ev,
        },
      });
    }
  };
  updateCustomMarkers = (event, imageSeries) => {
    imageSeries.mapImages.each(function (image) {
      // if (image?.dummyData?.externalElement) {
      //   let xy = this.map.geoPointToSVG({
      //     longitude: image.longitude,
      //     latitude: image.latitude,
      //   });
      //   image.dummyData.externalElement.style.top = xy.y + "px";
      //   image.dummyData.externalElement.style.left = xy.x + "px";
      // }
    });
  };
  showClusterSlice = (ev) => {
    let imageSeries2 = this.map.series.push(new am4maps.MapImageSeries());
    let imageSeriesTemplate2 = imageSeries2.mapImages.template;
    imageSeriesTemplate2.propertyFields.latitude = "latitude";
    imageSeriesTemplate2.propertyFields.longitude = "longitude";
    imageSeriesTemplate2.propertyFields.dx = "dx";
    imageSeriesTemplate2.propertyFields.dy = "dy";
    // imageSeriesTemplate2.propertyFields
    imageSeriesTemplate2.propertyFields.fill = "fill";
    const sliceCircle = imageSeriesTemplate2.createChild(am4core.Circle);
    sliceCircle.radius = 12;
    sliceCircle.nonScaling = true;
    const clusterData = ev.target.dataItem.dataContext.clusterData;
    imageSeries2.data = clusterData;
    // debugger;
    // ev.target.parent.dummyData = { externalElement: '<div>Hello World</div>' };
  };
  initiateMap = () => {
    // debugger
    const newCoord = (coord, dx, dy) => {
      const r_earth = 6378;
      const pi = Math.PI;
      const latitude = coord.latitude + (dy / r_earth) * (180 / pi);
      const longitude =
        coord.longitude +
        ((dx / r_earth) * (180 / pi)) / Math.cos((coord.latitude * pi) / 180);
      return { latitude, longitude };
    };
    const { clusters } = this.state;
    if (!clusters?.length) return;
    // debugger;
    let map = am4core.create("chartdiv", am4maps.MapChart);
    map.geodata = am4geodata_worldLow;
    map.projection = new am4maps.projections.Miller();
    map.zoomControl = new am4maps.ZoomControl();
    map.zoomControl.slider.height = 100;

    // map.seriesContainer.draggable = false;

    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.draggable = false;
    polygonSeries.calculateVisualCenter = true;
    polygonSeries.mapPolygons.template.fill = am4core.color("#EBECF6");
    map.series.push(polygonSeries);
    let lineSeries = map.series.push(new am4maps.MapLineSeries());
    lineSeries.mapLines.template.line.strokeDasharray = "1,1";
    lineSeries.mapLines.template.line.stroke = "#A6A8CB";
    let linePoints = new Set();
    for (let clusterIndex in clusters) {
      const cluster = clusters[clusterIndex];
      if (cluster?.location?.latitude && cluster?.location?.longitude) {
        const { latitude, longitude } = cluster?.location;
        linePoints.add({ longitude, latitude });
      }
    }
    linePoints = [...linePoints];
    if (linePoints.length) {
      linePoints = [...linePoints, linePoints[0]];
    }

    // const linePoints = clusters?.length
    //   ? clusters.map(({ location: { latitude, longitude } }) => ({
    //       latitude,
    //       longitude,
    //     }))
    //   : [];
    lineSeries.data = [
      {
        multiGeoLine: [linePoints],
      },
    ];

    let imageSeries = map.series.push(new am4maps.MapImageSeries());
    let imageSeriesTemplate = imageSeries.mapImages.template;
    // imageSeriesTemplate.layout="horizontal"
    // imageSeries.tooltip.pointerOrientation="right"
    imageSeriesTemplate.showTooltipOn = "always";
    imageSeriesTemplate.tooltip = new am4core.Tooltip();
    imageSeriesTemplate.tooltip.getFillFromObject = false;
    imageSeriesTemplate.tooltip.background.fill = am4core.color("white");
    imageSeriesTemplate.showTooltipOn = "always";
    imageSeriesTemplate.tooltipHTML = `${renderToString(<Marker />)}`;
    imageSeriesTemplate.tooltip.label.padding(-1, -1, -1, -1);
    imageSeriesTemplate.tooltip.background.fill = "#2868F3";
    imageSeriesTemplate.tooltip.background.cornerRadius = 30;
    let circle2 = imageSeriesTemplate.createChild(am4core.Circle);
    circle2.radius = 20;
    circle2.fill = am4core.color("#ffffff");
    circle2.fillOpacity = 0.5;
    circle2.nonScaling = true;
    let circle = imageSeriesTemplate.createChild(am4core.Circle);
    let shadow = circle.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0.1;
    shadow.dx = -2;
    shadow.dy = 2;
    circle.radius = 7;
    circle.fill = am4core.color("#2868F3");
    circle.stroke = am4core.color("#ffffff");
    circle.strokeWidth = 3;
    circle.tooltip = new am4core.Tooltip();
    circle.tooltip.pointerOrientation = "up";
    circle.tooltip.background.cornerRadius = 20;
    circle.tooltip.background.strokeOpacity = 0;
    circle.tooltip.label.interactionsEnabled = true;
    circle.tooltip.keepTargetHover = true;
    // circle.tooltipHTML = `${renderToString(<CapacityTooltip />)}`;
    circle.tooltip.label.padding(0, 0, 0, 0);
    circle.nonScaling = true;
    circle.tooltip.getFillFromObject = false;
    circle.tooltip.background.fill = am4core.color("white");
    circle.propertyFields.tooltipHTML = "capacityTooltip";
    // map.events.on("ready", (event) =>
    //   this.updateCustomMarkers(event, imageSeries)
    // );
    // map.events.on("mappositionchanged", (event) =>
    //   this.updateCustomMarkers(event, imageSeries)
    // );
    circle.events.on("hit", (ev) => {
      this.handleClusterClick(ev);
      // this.showClusterSlice(ev);
      console.log(ev.target.parent);
    });
    // imageSeriesTemplate.propertyFields.tooltipHTML = "capacityToolip";
    imageSeriesTemplate.propertyFields.latitude = "latitude";
    imageSeriesTemplate.propertyFields.longitude = "longitude";
    imageSeriesTemplate.propertyFields.id = "clusterId";

    let imageData = [];
    let coordMap = {};
    for (let clusterIndex in clusters) {
      const cluster = clusters[clusterIndex];
      if (cluster?.location?.latitude && cluster?.location?.longitude) {
        if (
          coordMap[
            `${parseInt(cluster?.location?.latitude)}&${parseInt(
              cluster?.location?.longitude
            )}`
          ]
        ) {
          const index = imageData.findIndex(
            ({ latitude, longitude }) =>
              latitude == parseInt(cluster?.location?.latitude) &&
              longitude == parseInt(cluster?.location?.longitude)
          );
          if (index != -1) {
            imageData[index].clusters.push(cluster);
          }
        } else {
          coordMap[
            `${parseInt(cluster?.location?.latitude)}&${parseInt(
              cluster?.location?.longitude
            )}`
          ] = true;
          imageData.push({
            latitude: parseInt(cluster?.location?.latitude),
            longitude: parseInt(cluster?.location?.longitude),
            clusters: [cluster],
          });
        }
      }
    }
    imageData = imageData.map(({ clusters, ...data }) => ({
      ...data,
      clusters,
      clusterCount: clusters.length,
      capacityTooltip: renderToString(<CapacityTooltip data={clusters} />),
    }));
    imageSeries.data = imageData;

    this.map = map;
  };

  mapClusterCapacity = () => {
    let clusters = {};
    const { clusterDetail, clusterCapacityDetail } = this.props;
    for (let clusterIndex in clusterDetail) {
      const cluster = clusterDetail[clusterIndex];
      clusters[cluster.clusterId] = cluster;
    }
    for (let clusterIndex in clusterCapacityDetail) {
      let cluster = clusterCapacityDetail[clusterIndex];
      cluster = {
        ...cluster,
        usedCapacity: Math.ceil(cluster.usedCapacity),
      };
      clusters[cluster.clusterId] = {
        ...clusters[cluster.clusterId],
        capacity: { ...cluster },
      };
    }
    clusters = Object.values(clusters);
    this.setState({ clusters }, this.initiateMap);
  };
  componentWillUnmount() {
    if (this.map) {
      this.map.dispose();
    }
  }
  render() {
    const { cluster1, cluster2 } = this.state.clusterCompare;
    return (
      <div style={{ position: "relative", height: "70vh" }}>
        {this.props.clusterDetail?.length ? (
          <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
        ) : (
          <FallbackMsg />
        )}
        {cluster1 && cluster2 && (
          <LatencyChart
            clusters={this.state.clusterCompare}
            onCancel={this.removeLatencyChart}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  dashboard: { clusterDetail, clusterCapacityDetail },
}) => ({
  clusterDetail,
  clusterCapacityDetail,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SvgClusterMap);
