import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_QOS_PROFILES } from "./types";
const meshBackend = getMeshBackend();

export const getAllQOSProfiles = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getQOSProfiles(getState(), dispatch);
    if(response) dispatch({ type: SET_QOS_PROFILES, payload: response });
  };
};

export const createQosProfile = (qosProfileDetails, sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.postQosProfile(getState(), dispatch, qosProfileDetails);
  };
};

export const editQosProfile = (qosProfileDetails, sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.editQosProfile(getState(), dispatch, qosProfileDetails);
    dispatch(getAllQOSProfiles());
  };
};