import { ACTION_LOGOUT_INITIATED, SET_USER_DETAILS } from "../actions/types";

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DETAILS:
      return { ...state, ...payload };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
