const AVESHA_SYSTEMS_REGEX = /(.*):\/\/(.*)\.console\.aveshasystems\.com.*/g;
const AVESHA_LABS_REGEX = /(.*):\/\/console\.(.*)\.aveshalabs\.io.*/g;
const AVESHA_LABS_REGEX_NEW = /(.*):\/\/(.*)\.(.*)\.aveshalabs\.io/g;
const SMART_AVESHA_PROD = /(.*):\/\/(.*)\.avesha\.io/g;

const STAGE_HASH_LOOKUP = {
  aveshasystems: {
    dev: "4gO6txdFKc1mel7KB3UpL5uyUC34zBYH39uPwvoQ",
    stg: "4gO6txdFKc1mel7KB3UpL5uyUC34zBYH39uPwvoQ",
    demo: "4gO6txdFKc1mel7KB3UpL5uyUC34zBYH39uPwvoQ",
    ci1: "D4LnFzV909aOq3nYvZcHo374x80tB2wsaOxTioKU",
    sand: "vG24JOwNdd52qYBXx0Sl35jKckXGmz645i2ndDjk",
  },
  aveshalabs: {
    dev: "stqpIYKKq41TkGydVjplG2Az8Vn4VJAu5i5num55",
    preprod: "i35o6VwkbX5HhmZjcPaJ06EodCS2fVI65kzNQ0bD",
    prod: "by6ugHjng93UXGoqH5fEe7fD82HRD95z1MNNRMP8",
  },
  aveshalabsNew: {
    ml: "FqNyrvTvnY6cyRXlYFSB96M9Ry3O33FK243Jd0BU",
    backend: "C9o672sTE42gScYWeWwbq9i5fHHMY6R6aB0U4sY4",
    ui: "G4p6z3Zd5Q4thE6yBecPI36GvwtjZOv9ancXHgWK",
    infra: "r4PO3ELKmjvWQctHa79v4BgFPHl0kvM34DS06Twc",
  },
  smartmesh: "by6ugHjng93UXGoqH5fEe7fD82HRD95z1MNNRMP8",
};

export const { API_HOST, API_KEY, API_BASE_URL } = (function () {
  const origin = window.location.origin;
  const systemsComponents = AVESHA_SYSTEMS_REGEX.exec(origin);
  const labsComponents = AVESHA_LABS_REGEX.exec(origin);
  const labsNewComponents = AVESHA_LABS_REGEX_NEW.exec(origin);
  const smartMeshComponents = SMART_AVESHA_PROD.exec(origin);
  // let backendHost = "https://mesh.aveshasystems.com/dev/v1";
  // let stageHash = STAGE_HASH_LOOKUP.aveshasystems.dev;
  let backendHost = "https://mesh.dev.aveshalabs.io/dev/v1";
  let stageHash = STAGE_HASH_LOOKUP.aveshalabs.dev;
  let apiBaseUrl = "https://mesh.dev.aveshalabs.io/";
  if (systemsComponents) {
    const stage = systemsComponents[2];
    backendHost = "https://mesh.aveshasystems.com/" + stage + "/v1";
    stageHash = STAGE_HASH_LOOKUP.aveshasystems[stage];
    apiBaseUrl = "https://mesh.aveshasystems.com/" + stage;
  } else if (labsComponents) {
    const stage = labsComponents[2];
    backendHost = "https://mesh." + stage + ".aveshalabs.io/" + stage + "/v1";
    stageHash = STAGE_HASH_LOOKUP.aveshalabs[stage];
    apiBaseUrl = "https://mesh." + stage + ".aveshalabs.io/";
  } else if (labsNewComponents) {
    const stage = labsNewComponents[2];
    backendHost = "https://mesh.dev.aveshalabs.io/" + stage + "/v1";
    stageHash = STAGE_HASH_LOOKUP.aveshalabsNew[stage];
    apiBaseUrl = "https://mesh.dev.aveshalabs.io/";
  } else if (smartMeshComponents) {
    const stage = smartMeshComponents[2];
    backendHost = "https://mesh.prod.aveshalabs.io/prod/v1";
    stageHash = STAGE_HASH_LOOKUP.smartmesh;
    apiBaseUrl = "https://mesh.prod.aveshalabs.io/";
  }
  return {
    API_HOST: backendHost,
    API_KEY: stageHash,
    API_BASE_URL: apiBaseUrl,
  };
})();
