import React, { Component } from "react";
import PolicyCard from "./PolicyCard";
import styled from "styled-components";
import { Toolbar, InputBase, AppBar } from "./PolicyStyles";
import { Button } from "@material-ui/core";
import AddPolicyDialog from "./AddSlicePolicyDialog";
import { BodyRoot } from "../Body/BodyStyles";
import Filters from "../Slices/Filters/Filters";
import { FilterButton } from "../Commons/IconButtons";
import { createSearchRegex } from "../../utils/functions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

class PolicyList extends Component {
  constructor() {
    super();

    this.state = {
      showFilters: false,
      openOperatorDialog: false,
      searchQuery: "",
      editPolicy: false
    };
  }

  filterOperator = () => {
    let operatorList = [...this.props.operatorList];
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    operatorList = operatorList.filter((operator) => search.test(operator?.name));
    return operatorList;
  };

  render() {
    const { showFilters, openOperatorDialog } = this.state;

    return (
      <BodyRoot>
        <AddPolicyDialog editPolicy={this.state.editPolicy} open={openOperatorDialog}
          handleClose={() => this.setState({ openOperatorDialog: false, editPolicy: false })} />
        <Filters open={showFilters} onClose={() => this.setState({ showFilters: false })} />
        <AppBar position="static">
          <Toolbar>
            <InputBase placeholder="Search" onChange={(e) => this.setState({ searchQuery: e?.nativeEvent?.target?.value })} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton style={{ color: "black", marginRight: 20 }} onClick={() => this.setState({ showFilters: true })} />
              <Button variant="outlined" onClick={() => this.setState({ openOperatorDialog: true })}>
                Create Slice Policy
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Container>
          <PolicyCard
            onClick={() => {
              this.setState({ openOperatorDialog: true, editPolicy: true });
            }}
          />
        </Container>
      </BodyRoot>
    );
  }
}

export default PolicyList;
