import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { ScrollBarStyle } from "../../AppStyles";
import { InputBase } from "./SliceStyles";
import Paper from "@material-ui/core/Paper";
import { Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import YAML from "yaml";
import NetworkPolicyYaml from "./NetworkPolicyYaml";
import { connect } from "react-redux";
import { updateNamespaceIsolationProfile } from "../../actions/namespaceActions";
import EditIcon from "@material-ui/icons/Edit";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import { verifyNamespaceFormat } from "../../utils/functions";
const TrimGrid = styled(Grid)`
  padding: 0 !important;
  margin: 0 !important;
`;
const ListContainer = styled(Paper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: auto;
  overflow-x: hidden;
  /* padding: 0.5rem; */
  height: 100%;
`;
const ListItem = styled(Grid)`
  font-size: 17px;
  position: relative;
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: solid #e3e3e3 1px;
  /* background: ${(props) => (props.index % 2 ? "#e3e3e3" : "white")}; */
`;
const ListLabel = styled.p`
  font-size: 20px;
  margin-bottom: 1rem;
`;
const SubContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30vh;
  ${ScrollBarStyle}
`;
const ListHeader = styled.div`
  /* width: 100%; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  border-bottom: solid 1px grey;
  padding: 0.5rem;
  justify-content: space-between;
`;
const SearchInput = styled(InputBase)`
  height: 30px;
`;
const IconContainer = styled(IconButton)`
  position: absolute;
  right: 1rem;
  padding: 4px;
  top: 2px;
`;
const RenameSave = styled(Button)`
  /* position: absolute;
  right: 1rem; */
`;
const ActionContainer = styled(Grid)``;
const NamespaceItem = ({ defaultValue, index, onSave }) => {
  const [value, setValue] = useState(defaultValue);
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleSave = () => {
    if (verifyNamespaceFormat(value)) {
      onSave(value, index);
      setEditable(false);
    } else {
      setError("Invalid Format!");
    }
  };
  return (
    <ListItem container index={index} wrap="nowrap" alignItems="center">
      <Grid item xs>
        {editable ? (
          <TextField
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setError("");
            }}
            variant="outlined"
            style={{ width: "90%" }}
            error={error}
            helperText={error}
          />
        ) : (
          <div>{value}</div>
        )}
      </Grid>
      <Grid item>
        {editable ? (
          <ActionContainer container alignItems="center" spacing={2}>
            <Grid item xs>
              <RenameSave variant="outlined" onClick={handleSave}>
                Save
              </RenameSave>
            </Grid>
            <Grid item xs>
              <IconButton
                onClick={() => {
                  setEditable(false);
                  setValue(defaultValue);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </ActionContainer>
        ) : (
          <IconContainer onClick={() => setEditable(true)}>
            <EditIcon />
          </IconContainer>
        )}
      </Grid>
    </ListItem>
  );
};
const List = ({ listItems, label, onRename }) => {
  const [search, setSearch] = useState("");
  const filterList = () => {
    const searchReg = new RegExp(search, "i");
    const _list = listItems.filter((item) => searchReg.test(item));
    return _list;
  };

  return (
    <ListContainer>
      <ListHeader>
        <ListLabel>{label}</ListLabel>
        <SearchInput
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </ListHeader>
      <SubContainer>
        {filterList().map((item, index) => (
          <NamespaceItem defaultValue={item} index={index} onSave={onRename} />
        ))}
      </SubContainer>
    </ListContainer>
  );
};

const Container = styled(Grid)`
  height: 52vh;
`;
const Divider = styled.div`
  height: 100%;
  border: 0.5px solid grey;
  margin: 0px 0.5rem;
`;
class NamespaceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileDownloadUrl: null,
      showDialog: false,
    };
  }

  generateYAML = () => {
    const jsonObject = {
      apiVersion: "networking.k8s.io/v1",
      kind: "NetworkPolicy",
      metadata: {
        name: "slice-netpol-iperf",
        namespace: "iperf",
      },
      spec: {
        podSelector: {},
        policyTypes: ["Ingress", "Egress"],
        egress: [
          {
            to: [
              {
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/slice": "slice-netpol",
                  },
                },
              },
            ],
          },
        ],
        ingress: [
          {
            from: [
              {
                namespaceSelector: {
                  matchLabels: {
                    "avesha.io/slice": "slice-netpol",
                  },
                },
              },
            ],
          },
        ],
      },
    };

    const doc = new YAML.Document();
    doc.contents = jsonObject;

    console.log(doc.toString());
    const blob = new Blob([doc.toString()]); // Step 3
    const fileDownloadUrl = URL.createObjectURL(blob); // Step 4
    this.setState(
      { fileDownloadUrl: fileDownloadUrl }, // Step 5
      () => {
        this.dofileDownload.click(); // Step 6
        URL.revokeObjectURL(fileDownloadUrl); // Step 7
        this.setState({ fileDownloadUrl: "" });
      }
    );

    // The hidden anchor element:
  };
  handleToggleIsolation = () => {
    this.props.updateNamespaceIsolationProfile(this.props.slice.sliceName, {
      namespaceIsolationProfile: {
        ...this.props.slice.namespaceIsolationProfile,
        isolationEnabled:
          !this.props.slice.namespaceIsolationProfile.isolationEnabled,
      },
    });
  };
  handleRenameNamespace = (item, index, listName) => {
    // const { allowedNamespaces, applicationNamespaces, isolationEnabled } =
    // this.props.slice.namespaceIsolationProfile;
    let allowedNamespaces = [],
      applicationNamespaces = [],
      isolationEnabled = false;
    if (this.props?.slice?.namespaceIsolationProfile) {
      ({ allowedNamespaces, applicationNamespaces, isolationEnabled } =
        this.props.slice.namespaceIsolationProfile);
    }
    let list;
    let namespaceIsolationProfile;
    if (listName == "application") {
      list = [...applicationNamespaces];
      list[index] = item;
      namespaceIsolationProfile = {
        ...this.props.slice.namespaceIsolationProfile,
        applicationNamespaces: list,
      };
    } else {
      list = [...allowedNamespaces];
      list[index] = item;
      namespaceIsolationProfile = {
        ...this.props.slice.namespaceIsolationProfile,
        allowedNamespaces: list,
      };
    }
    this.props.updateNamespaceIsolationProfile(this.props.slice.sliceName, {
      namespaceIsolationProfile,
    });
  };
  render() {
    let allowedNamespaces = [],
      applicationNamespaces = [],
      isolationEnabled = false;
    if (this.props?.slice?.namespaceIsolationProfile) {
      ({ allowedNamespaces, applicationNamespaces, isolationEnabled } =
        this.props.slice.namespaceIsolationProfile);
    }
    const { showDialog } = this.state;
    return (
      <Container item container xs direction="column" spacing={2} wrap="nowrap">
        <NetworkPolicyYaml
          open={showDialog}
          onClose={() => this.setState({ showDialog: false })}
          slice={this.props.slice}
          applicationNamespaces={applicationNamespaces}
          allowedNamespaces={allowedNamespaces}
        />
        <Paper style={{ padding: "0rem 1rem" }}>
          <TrimGrid item container wrap="nowrap" alignItems="center">
            <Grid item>
              <a
                style={{ display: "none" }}
                download="myYaml.yaml"
                href={this.state.fileDownloadUrl}
                ref={(e) => (this.dofileDownload = e)}
              >
                download it
              </a>
              <Button
                variant="contained"
                onClick={() => this.setState({ showDialog: true })}
              >
                Generate Network Policy
              </Button>
            </Grid>
            <TrimGrid
              item
              xs
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <p>
                  Namespace Isolation:{" "}
                  {isolationEnabled ? "Enabled" : "Disabled"}
                </p>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={this.handleToggleIsolation}>
                  {isolationEnabled ? "Disable" : "Enable"}
                </Button>
              </Grid>
            </TrimGrid>
          </TrimGrid>
        </Paper>
        <TrimGrid item xs container spacing={2}>
          <Grid item xs>
            <List
              listItems={applicationNamespaces}
              label="Application Namespaces"
              onRename={(item, index) =>
                this.handleRenameNamespace(item, index, "application")
              }
            />
          </Grid>
          <Grid item xs>
            <List
              listItems={allowedNamespaces}
              label="Allowed Namespaces"
              onRename={(item, index) =>
                this.handleRenameNamespace(item, index, "allowed")
              }
            />
          </Grid>
        </TrimGrid>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateNamespaceIsolationProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(NamespaceList);
