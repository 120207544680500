import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import { FormField } from "../../Commons/formUtils";
import { usePrevious } from "../../Commons/hooksUtils";
import { FormContainer } from "../FormStyles";
import Permission from "./Permission";
import { PermissionForm } from "./PolicyFormStyles";
import { connect } from "react-redux";
import {
  getAllActions,
  createPolicy,
  clearSelectedPolicy,
  editPolicy,
} from "../../../actions/PolicyActions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddSliceDialog(props) {
  //creating css classes from jss
  const classes = useStyles();

  const prevOpen = usePrevious(props.open);

  useEffect(() => {
    if (!prevOpen && props.open) {
      props.getAllActions();
      setErrorPolicyNameMes("");
      handlePolicyData();
    }
  });
  const [permissions, setPermissions] = useState([
    { actions: null, resources: null },
  ]);
  const [policyName, setPolicyName] = useState("");
  const [errorPolicyNameMes, setErrorPolicyNameMes] = useState("");
  const [errorPermissionsMes, setErrorPermissionsMes] = useState('')
  
  const clearData = () => {
    props.clearSelectedPolicy();
    setPermissions([]);
    setPolicyName("");
  };

  const addPermission = () => {
    setPermissions([...permissions, { actions: null, resources: null }]);
  };

  const handlePermissionChange = (data, index) => {
    let newPermissions = [...permissions];
    newPermissions[index] = data;
    setPermissions(newPermissions);
  };

  const handleSave = () => {
    let finalPermission = permissions.filter(
      (permission) => permission !== null
    );
    let isResourcePermission = permissions.every(permission => permission?.resources?.length > 0);
    let isAdmin = permissions.some(permission => permission?.action?.[0] == '.*');

    let value = { policyName, permissions: [...finalPermission] };
    if (!value.policyName.trim()) {
      setErrorPolicyNameMes("Policy Name is required");
    } else if(!permissions || (permissions && permissions.length === 0)) {
      setErrorPermissionsMes('No Permission added');
    } else if(permissions && !isResourcePermission && !isAdmin) {
      setErrorPermissionsMes('No Resource in Permission added')
    } else {
      const { selectedPolicy } = props;
      if (selectedPolicy?.policyId) {
        props.editPolicy(selectedPolicy.policyId, value);
      } else {
        props.createPolicy(value);
      }
      clearData();
      props.handleClose();
      props.clearSelectedPolicy();
    }
  };
  const handlePolicyData = () => {
    const { selectedPolicy } = props;
    if (selectedPolicy) {
      
      setPolicyName(selectedPolicy.policyName);
      setPermissions([...selectedPolicy.permissions]);
    }
  };

  const handleSnackClose = () => {
    setErrorPermissionsMes('');
  };

  const deletePermission = (index) => {
    let newPermissions = [...permissions];
    if (newPermissions.length > 1 && newPermissions[index]) {
      newPermissions = [
        ...newPermissions.slice(0, index),
        ...newPermissions.slice(index + 1),
      ];
      setPermissions(newPermissions);
    }
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
          props.clearSelectedPolicy();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage:
              "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                props.handleClose();
                props.clearSelectedPolicy();
                clearData();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.selectedPolicy?.policyId ? "Edit" : "New"} Policy
            </Typography>
            <Button
              variant="contained"
              autoFocus
              color="secondary"
              onClick={addPermission}
              style={{ marginRight: "1rem" }}
              tabIndex="-1"
            >
              Add Permission
            </Button>
            <Button
              variant="contained"
              autoFocus
              color="secondary"
              onClick={handleSave}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex", width: "100vw" }}>
          <div
            style={{
              flex: 1,
              borderRight: "solid black 1px",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <Snackbar
                style={{ zIndex: 100000 }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorPermissionsMes !== ''}
                autoHideDuration={3000}
                onClose={handleSnackClose}
              >
                <Alert onClose={handleSnackClose} severity="error">
                  {errorPermissionsMes}
                </Alert>
              </Snackbar>
              <h3>
                {props.selectedPolicy?.policyId ? "Edit" : "Create a New"}{" "}
                Policy
              </h3>
              <h5>
                {" "}
                A policy defines the permissions that you can assign to a user,
                group, or role. You can create and edit a policy in the visual
                editor{" "}
              </h5>
              <h4>General Details</h4>
              <FormField
                error={errorPolicyNameMes}
                helperText={!policyName && errorPolicyNameMes}
                style={{ width: "100%" }}
                label="Policy Name"
                value={policyName}
                onChange={(e) => setPolicyName(e.nativeEvent.target.value)}
              />
            </FormContainer>
          </div>
          <PermissionForm>
            {permissions.map(
              (permission, index) =>
                permission && (
                  <Permission
                    index={index}
                    permission={permission}
                    onChange={(data) => {
                      handlePermissionChange(data, index);
                    }}
                    deletePermission={() => deletePermission(index)}
                  />
                )
            )}
          </PermissionForm>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({ PolicyReducer: { actions, selectedPolicy } }) => ({
  actions,
  selectedPolicy,
});

export default connect(mapStateToProps, {
  getAllActions,
  createPolicy,
  clearSelectedPolicy,
  editPolicy,
})(AddSliceDialog);
