import React from "react";
import styled from "styled-components";
import { Dialog } from "./SliceStyles";

const DialogWrapper = styled.div`
  padding: 8px 16px;
`;

const FilterWrapper = styled.div`
  width: max-content;
  padding: 4px;
`;

const FilterLabel = styled.span`
  font-weight: bold;
`;

const FilterCheckbox = styled.div`
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  width: inherit;
`;

const Checkbox = styled.input``;

const FilterText = styled.span`
  margin: 0 10px;
`;

const FilterTitle = styled.div`
  width: 200px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

const Filters = ({
  showMainFilter,
  setAllFilters,
  mainFilterData,
  setSelectedFilter,
}) => {
  return (
    <Dialog open={showMainFilter} onClose={() => setAllFilters()}>
      <DialogWrapper>
        <FilterTitle>Filters</FilterTitle>
        {Object.keys(mainFilterData).map((ele) => (
          <>
            <FilterWrapper>
              <FilterLabel>{ele.split("_").join(" ")}</FilterLabel>
              {mainFilterData[ele].values.map((filter) => (
                <FilterCheckbox>
                  <Checkbox
                    onChange={() => setSelectedFilter(ele, filter)}
                    type="checkbox"
                    checked={mainFilterData[ele].selected.includes(filter)}
                  />
                  <FilterText>{filter}</FilterText>
                </FilterCheckbox>
              ))}
            </FilterWrapper>
          </>
        ))}
      </DialogWrapper>
    </Dialog>
  );
};

export default Filters;
