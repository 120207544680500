import React from 'react'

import styled from "styled-components";
import MuiClose from "@material-ui/icons/Close";
import { FormField } from "../../Commons/formUtils";
import { ScrollBarStyle } from '../../../AppStyles';

export const CustomFormField = styled(FormField)`
  margin: 0px;
  margin-bottom: 1rem;
`;
export const Resource = styled.div`
  margin-bottom: 0.4rem;
  width: 38vw;
  max-width: 38vw;
  /* background-color: #e8e8e8; */
  padding: 8px;
  /* padding-left: 1rem; */
  border-bottom: 1px grey solid;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const Scrollable = styled.div`
  max-height: 10rem;
  width: fit-content;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow-y: scroll;
  border-bottom: 1px solid grey;
  ${ScrollBarStyle}
  /* border: solid black 1px; */
`;

export const Close = styled(MuiClose)`
  :hover {
    cursor: pointer;
  }
`;
