import { getMeshBackend } from "../backend/MeshBackendProxy";
import {
  SET_ACTIONS,
  SET_POLICIES,
  SELECT_POLICY,
  CLEAR_SELECTED_POLICY,
} from "./types";
const meshBackend = getMeshBackend();

export const getAllPolicies = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllPolicies(getState(), dispatch);
    if(response) dispatch({ type: SET_POLICIES, payload: response });
  };
};
export const getAllActions = () => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllActions(getState(), dispatch);
    if(response) dispatch({ type: SET_ACTIONS, payload: response });
  };
};

export const createPolicy = (policyDetails) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.postPolicy(getState(), dispatch, { policy: { ...policyDetails } });
    if (response) {
      dispatch(getAllPolicies());
    }
  };
};

export const editPolicy = (policyId, policyDetails) => {
  return async (dispatch, getState) => {
    const customerId=getState().SessionReducer.customerId
    let response = await meshBackend.editPolicy(
      getState(),
      dispatch,
      { policy: { ...policyDetails, customerId } },
      policyId
    );
    if (response) {
      dispatch(getAllPolicies());
    }
  };
};

export const selectPolicy = (payload) => ({
  type: SELECT_POLICY,
  payload,
});

export const clearSelectedPolicy = () => ({
  type: CLEAR_SELECTED_POLICY,
});
