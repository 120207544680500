import { SET_USERS, SET_USER_GROUPS, SET_ROLES, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  roles: [],
  users: [],
  userGroups: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_USER_GROUPS:
      return {
        ...state,
        userGroups: action.payload,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
