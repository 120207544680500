import styled from "styled-components";
import { ScrollBarStyle } from "../../AppStyles";

export const BodyRoot = styled.div`
  overflow-y: auto;
  /* height: calc(100vh - 3rem); */
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${ScrollBarStyle}
`;

export const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const BodyHeader = styled.div`
  max-height: 25vh;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #5b6467;
  background-image: linear-gradient(
    90deg,
    rgba(18, 21, 62, 1) 0%,
    rgba(40, 104, 242, 1) 100%
  );
  max-width: calc(100% - 2rem);
  padding: 1rem;
  resize: vertical;
  ${ScrollBarStyle}
`;

export const HeaderTitle = styled.div`
  font-size: 2rem;
  color: white;
  font-family: Roboto;
`;

export const GatewayContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  flex-wrap: wrap;
`;

export const FieldContainer = styled.div`
  display: flex;
  padding: 10px;
  width: 12.5rem;
  overflow: hidden;
  flex-direction: column;
`;

export const FieldHeading = styled.div`
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12.5rem;
  height: 20px;
  font-weight: 500;
  white-space: nowrap;
  color: ${(props) => props.color || "grey"};
`;

export const FieldContent = styled.div`
  font-size: 1.1rem;
  flex-wrap: wrap;
  white-space: normal;
  width: 12.5rem;
  overflow-wrap: break-word;
`;

export const Heading = styled.div`
  font-size: 1.5rem;
  padding-left: 1rem;
`;

export const SubHeading = styled.div`
  font-size: 1.2rem;
  padding: 1.4rem;
  font-weight: 600;
`;

export const AppName = styled.div`
  font-size: 1rem;
  padding: 1.6rem;
`;

export const Divider = styled.div`
  height: 0px;
  width: auto;
  margin: 1.5rem;
  border: solid lightgrey 1px;
  border-top-style: none;
`;

export const SelectWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const SaveButton = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: white;
  position: absolute;
  right: -65px;
  top: 25px;
  border: 1px solid;
  padding: 2px 10px;
  border-radius: 4px;
`;
