import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import colors from "./colors.json";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
const theme = createTheme({
  colors: {
    powderWhite: "#FFFDF9",
    persianGreen: "#06B49A",
    lightBlue: "#AFDBD2",
    onyx: "#36313D",
    primary: colors.primary,
    secondary: colors.secondary,
    white: "#FFFFFF",
  },
  fonts: ["Bai Jamjuree"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree",
        },
        input: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          textAlign: "left",
        },
        title: {
          fontFamily: "Bai Jamjuree",
        },
        subheader: {
          fontFamily: "Bai Jamjuree",
        },
      },
    },
  },
});
const styledTheme = {
  colors: {
    powderWhite: "#FFFDF9",
    persianGreen: "#06B49A",
    lightBlue: "#AFDBD2",
    onyx: "#36313D",
    primary: colors.primary,
    secondary: colors.secondary,
    white: "#FFFFFF",
  },
  fonts: ["Bai Jamjuree"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};
export const MuiTheme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
export const Theme = ({ children }) => (
  <StyledThemeProvider theme={styledTheme}>{children}</StyledThemeProvider>
);
