import fetch from "./fetchWithTimeout";
import { updateSnackbar } from "./../actions/SnackbarActions";
import { ACTION_LOGOUT_INITIATED, START_SPINNER, STOP_SPINNER } from "./../actions/types";

export default class BaseBackend {
  constructor(apiBaseUrl, apiKey) {
    this.API_BASE_URL = apiBaseUrl;
    this.API_KEY = apiKey;
  }

  _fetchInternal(baseURL, httpMethod, endpoint, data, session, dispatch, showSpinner) {
    let timeoutInMillis = 10000;
    let sessiontoken = null;
    if (session) {
      sessiontoken = session;
    }
    let opts = {
      method: httpMethod,
      mode: "cors",
      cache: "no-store",
      withCredentials: true,
    };
    if (httpMethod) opts.method = httpMethod;
    opts.url = baseURL + endpoint;
    opts.headers = {};
    opts.headers["Content-Type"] = "application/json";
    opts.headers["x-api-key"] = this.API_KEY;

    if (sessiontoken) opts.headers["Authorization"] = "Bearer " + sessiontoken;

    if (data) {
      opts.body = JSON.stringify(data);
    }
    if (showSpinner) {
      dispatch({ type: START_SPINNER });
    }
    return fetch(opts.url, opts, timeoutInMillis)
      .then((resp) => {
        if (!resp.ok) {
          throw resp;
        }
        return resp.json();
      })
      .then((body) => {
        if (body.message === "The incoming token has expired") {
          dispatch({ type: ACTION_LOGOUT_INITIATED });
          return Promise.reject(new Error("User not authorized"));
        }
        if (showSpinner) {
          dispatch({ type: STOP_SPINNER });
        }
        return Promise.resolve(body);
      })
      .catch(async (err) => {
        if (err.status == "400") {
          let msg = await err.text();

          if (msg !== undefined) msg = JSON.parse(msg);
          msg = msg?.message;

          if (msg === undefined)
           msg = "Unauthorised";

          dispatch(updateSnackbar(true, msg));
        } else if (err.status == "401") {
          dispatch({ type: ACTION_LOGOUT_INITIATED });
        }
        if (showSpinner) {
          dispatch({ type: STOP_SPINNER });
        }
        console.error("api error", err);
        return Promise.reject(err);
      });
  }

  async _fetch(httpMethod, endpoint, data, session, dispatch, showSpinner = true) {
    return await this._fetchInternal(this.API_BASE_URL, httpMethod, endpoint, data, session, dispatch, showSpinner);
  }
}
