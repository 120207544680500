import { getMeshBackend } from "../backend/MeshBackendProxy";
import { getAllSlices } from "./SliceActions";
const meshBackend = getMeshBackend();
export const updateNamespaceIsolationProfile = (sliceName, value) => {
  //debugger;
  return async (dispatch, getState) => {
    let response = await meshBackend.updateSliceNamespaceIsolationProfile(
      getState(),
      dispatch,
      sliceName,
      value
    );
    dispatch(getAllSlices());
    return response;
  };
};
