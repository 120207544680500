import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TableData from "./TableComponent";
import Console from "./Console";
import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from '@mui/icons-material/Launch';
import EventTableGrid from "./EventTableGrid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '60px',
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    ":disabled": {
      backgroundColor: 'red'
    }
  }

}));


export default function EventTable({open,setOpen,handleClickOpen}) {
//   const [open, setOpen] = React.useState(false);
const classes = useStyles();
  const [slide, setSlide] = React.useState(false);
  const [tableHeight, setTableHeight] = React.useState("88vh");
  const [selectedId, setSelectecId] = React.useState(null);
  const handleClose = () => {
    setSelectecId(null);
    setOpen(false);
    setSlide(false);
    setTableHeight("88vh");

  };
  
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
  const onClickHandle = () => {
    
  };
//   const handleClose = () => {
//     setOpen(false);
//   };

  const handleClickSlide = () => {
    setSlide(!slide);
    if (tableHeight === "88vh") setTableHeight("40vh");
    else setTableHeight("88vh");
  };

  return (
    <div>
      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="notification-table"
        open={open}
      >
        
        <AppBar
          className={classes.appBar}
          style={{
            backgroundColor: '#12153e',
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" 
            className={classes.title}
            >
              Notifications
              {/* {props.edit ? `Edit Slice` : `New Slice`} */}
            </Typography>
            <Button
              variant="contained"
              autoFocus
              className = {{disabled: classes.button}}
              style = {{
                backgrounColor: '#2868f2#',
                textTransform: 'none'
              }}
              onClick={handleClickSlide}
              // disabled = {selectedId === null}
            >
               KubeCTL  {slide ===true ? <CloseIcon/> : <LaunchIcon/>}
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent
          style={{
            height: `${tableHeight}`,
            overflow: "auto",
            marginTop: '58px',
            marginLeft: "0px",
            marginRight: "0px"
          }}
          dividers
        >
          {/* <TableData onClickHandle={onClickHandle} setSlide = {setSlide} tableHeight={tableHeight} selectedId={selectedId} setSelectecId={setSelectecId}/> */}
          <EventTableGrid onClickHandle={onClickHandle} setSlide = {setSlide} tableHeight={tableHeight} selectedId={selectedId} setSelectecId={setSelectecId}/>
         
          {slide && (
          
            <Console />
          )}
        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}
