import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";
import { Divider, styled, Typography } from "@mui/material";
import { ScrollBarStyle } from "../../../AppStyles";
const Container = styled(Paper)`
  ${ScrollBarStyle}
`;
export const ClusterRow = ({ data }) => {
  return (
    <Grid container spacing={2} style={{ width: "300px" }}>
      <Grid item container justifyContent="space-between" xs>
        <Grid
          item
          xs
          style={{
            textAlign: "left",
            fontFamily: "Roboto",
            fontSize: "16px",
            color: "#828490",
          }}
        >
          {data.clusterName}
        </Grid>
        <Grid item xs justifyContent="flex-end" container>
          <div
            style={{
              height: "10px",
              width: "10px",
              borderRadius: "20px",
              background: "#05CD99",
            }}
          />
        </Grid>
      </Grid>
      {data?.capacity?.usedCapacity ? (
        <>
          <Grid item container justifyContent="space-between">
            <Grid
              item
              xs
              style={{
                textAlign: "left",
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {data?.capacity?.usedCapacity}% Used
            </Grid>
            <Grid
              item
              xs
              style={{
                textAlign: "right",
                fontFamily: "Roboto",
                fontSize: "20px",
              }}
            >
              {100 - data?.capacity?.usedCapacity}% free
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item xs justifyContent="center" container>
              <div
                style={{
                  height: "10px",
                  width: "100%",
                  background: "#C4C4C4",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    height: "10px",
                    width: "90%",
                    background: "linear-gradient(to right,#2868F3,#70A9FF)",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item container justifyContent="space-between">
          <Typography
            style={{
              textAlign: "left",
              fontFamily: "Roboto",
              fontSize: "12px",
              color: "#828490",
            }}
          >
            Capacity Information not available
          </Typography>
        </Grid>
      )}
      <Grid item container justifyContent="space-between">
        <Grid
          item
          xs
          style={{
            textAlign: "left",
            fontFamily: "Roboto",
            fontSize: "16px",
            color: "#828490",
          }}
        >
          VIEW DETAILS
        </Grid>
      </Grid>
    </Grid>
  );
};
const CapacityTooltip = ({ data }) => {
  console.log("cluster", data);
  return (
    <Container
      style={{
        padding: "1rem",
        borderRadius: "20px",
        maxHeight: "200px",
        overflow: "auto",
      }}
    >
      {data.map((cluster, index) => (
        <>
          <ClusterRow data={cluster} />
          {index < data.length - 1 && <Divider sx={{ my: 1 }} />}
        </>
      ))}
    </Container>
  );
};

export default CapacityTooltip;
