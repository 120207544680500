import { SET_SLICE_NAMESPACE_COUNT } from "../actions/types";
const initialState = {
  slices: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SLICE_NAMESPACE_COUNT:
      return {
        ...state,
        slices: { ...state.slices, [payload.sliceName]: payload },
      };

    default:
      return state;
  }
};
