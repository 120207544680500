import React, { Component } from "react";
import { FormCheck } from "../../Commons/formUtils";
import { Close, Resource, Scrollable,CustomFormField } from "./styles";
export default class ResourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      errorResourceName: ""
    };
  }
  handleEnterPress = (e) => {
    if (e.key === "Enter") { 
      if(e?.nativeEvent?.target?.value) {
        this.props.addToResource(e?.nativeEvent?.target?.value);
        this.setState({ value: "" });
      } else {
        this.setState({ errorResourceName: 'Resource name is required'  });
      }
    }
  };
  render() {
    const { value, errorResourceName } = this.state;
    const { resources, allResources, handleAllClick } = this.props;
    return (
      <div>
        <div style={{ paddingLeft: "1rem" }}>
          <FormCheck label="All Resources" checked={allResources} onChange={handleAllClick} />
        </div>
        {
          !allResources && (
            <CustomFormField
              error={!allResources && !value && errorResourceName}
              helperText={!allResources && !value && errorResourceName}
              disabled={allResources}
              value={value}
              onChange={(e) => this.setState({ value: e.nativeEvent.target.value })}
              style={{ width: "38vw" }}
              label="Resource Name"
              onKeyDown={this.handleEnterPress}
            />
          )
        }
        
        <Scrollable>
          {resources.map((resource) => (
            <Resource>
              <div style={{ color: "grey" }}>{resource}</div>
              <Close color="grey" onClick={() => this.props.deleteResource(resource)} />
            </Resource>
          ))}
        </Scrollable>
      </div>
    );
  }
}
