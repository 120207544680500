import { Paper, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar, ResponsiveContainer } from "recharts";
import Brush from "recharts/lib/cartesian/Brush";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import BarChart from "recharts/lib/chart/BarChart";
import Tooltip from "recharts/lib/component/Tooltip";
import styled from "styled-components";

const Dot = styled.div`
  width: 5px;
  background: ${(props) => props.color};
  border-radius: 100%;
  aspect-ratio: 1;
  height: 5px;
  margin: 0.5rem;
`;
const Container = styled(Paper)`
  padding: 1rem;
  border-radius: 1rem;
  height: 45vh;
  box-sizing: border-box;
  overflow: auto;
`;
const FallbackMsg = () => (
  <Grid
    container
    style={{ padding: "0.5rem 1rem", height: "75%" }}
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h6">No Cluster Data Available</Typography>
  </Grid>
);

class ClusterCapacity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.initializeData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.clusterCapacityDetail?.length !==
      this.props.clusterCapacityDetail?.length
    ) {
      this.initializeData();
    }
  }

  initializeData = () => {
    if (
      this.props.clusterCapacityDetail &&
      this.props.clusterCapacityDetail.length > 0
    ) {
      let data = this.props.clusterCapacityDetail.map(
        ({ clusterName, usedCapacity }) =>
          usedCapacity
            ? {
                name: clusterName,
                Free: Math.ceil(100 - usedCapacity),
                Used: 100 - Math.ceil(100 - usedCapacity),
              }
            : null
      );
      data = data.filter((point) => point !== null);
      data.sort((a,b)=>b.usedCapacity-a.usedCapacity)
      this.setState({ data });
    }
  };
  render() {
    const { data } = this.state;
    return (
      <Container
        id="cluster-capacity-graph"
        style={{
          padding: "30px 1rem 1rem 30px",
          borderRadius: "1rem",
          height: "45vh",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "2rem" }}
        >
          <Grid item xs={6}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              Cluster Capacity
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            container
            spacing={1}
            style={{ flexWrap: "nowrap" }}
          >
            <Grid
              container
              item
              xs
              alignItems="center"
              style={{ color: "#2868F3", fontSize: "12px", flexWrap: "nowrap" }}
            >
              <Dot color="#2868F3" /> Free
            </Grid>
            <Grid
              container
              item
              xs
              alignItems="center"
              style={{ color: "#F4BA64", fontSize: "12px", flexWrap: "nowrap" }}
            >
              <Dot color="#F4BA64" />
              Used
            </Grid>
          </Grid>
        </Grid>
        {data?.length ? (
          <div style={{ width: "100%", height: "80%", marginLeft: "-2rem" }}>
            <ResponsiveContainer height="100%" width="100%">
              <BarChart width={730} height={250} data={data} barCategoryGap={0}>
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop
                      offset="0%"
                      style={{ "stop-color": "#7DA6FD", "stop-opacity": "1" }}
                    />
                    <stop
                      offset="100%"
                      style={{ "stop-color": "#2868F3", "stop-opacity": "1" }}
                    />
                  </linearGradient>
                  <linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop
                      offset="0%"
                      style={{ "stop-color": "#FFD89F", "stop-opacity": "1" }}
                    />
                    <stop
                      offset="100%"
                      style={{ "stop-color": "#F4BA64", "stop-opacity": "1" }}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 10, fill: "#AEAFB6" }}
                  tickLine={{ stroke: "#AEAFB6" }}
                  stroke="#AEAFB6"
                  tickCount={10}
                  interval={0}
                />
                <YAxis
                  tick={{ fontSize: 10, fill: "#AEAFB6" }}
                  tickLine={{ stroke: "#AEAFB6" }}
                  stroke="#AEAFB6"
                  domain={[0, 100]}
                />
                <Tooltip />
                {data?.length > 4 && (
                  <Brush
                    dataKey="nill"
                    tickFormatter={() => null}
                    height={30}
                    stroke="#AEAFB6"
                    endIndex={3}
                  />
                )}
                <Bar
                  barSize={27}
                  dataKey="Free"
                  fill="url(#grad1)"
                  radius={[5, 5, 0, 0]}
                />
                <Bar
                  barSize={27}
                  dataKey="Used"
                  fill="url(#grad2)"
                  radius={[5, 5, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <FallbackMsg />
        )}
      </Container>
    );
  }
}
const mapStateToProps = ({ dashboard: { clusterCapacityDetail } }) => ({
  clusterCapacityDetail,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterCapacity);
