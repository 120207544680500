import React, { Component } from "react";
import styled from "styled-components";
import SearchBox from "./SearchBox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRight from "@material-ui/icons/ArrowRight";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { ScrollBarStyle } from "../../../AppStyles";
const Container = styled.div`
  position: relative;
  width: ${(props) => (props.expanded ? "20vw" : "1.5rem")};
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-right: solid #e3e3e3 1px;
  justify-content: center;
  :hover {
    /* background-color: ${(props) => (!props.expanded ? "#e3e3e3" : "white")}; */
    cursor: ${(props) => (!props.expanded ? "pointer" : "unset")};
    svg {
      background: ${(props) => (!props.expanded ? "silver" : null)};
    }
  }
  ${ScrollBarStyle}
`;
const ListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ${ScrollBarStyle}
`;
const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  border-bottom: solid silver 1px;
  border-radius: 2px;
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid silver 1px;
  :hover {
    cursor: pointer;
  }
`;
const OpenMenu = styled(ArrowRightIcon)`
  position: absolute;
  right: -0.5em;
  background: #e3e3e3;
  z-index: 3;
  border-radius: 100px;
  :hover {
    cursor: pointer;
    /* background: silver; */
  }
`;
export default class ModuleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };
  }

  render() {
    const {
      moduleList: { slices, policies, operators, operatorGroups },
      onItemClick,
    } = this.props;
    const { expanded } = this.state;

    let autoCompleteList = [];
    if (slices?.length) autoCompleteList = [...autoCompleteList, ...slices.map(({ label, objectId }) => ({ label, objectId, type: "slice" }))];
    if (operators?.length)
      autoCompleteList = [...autoCompleteList, ...operators.map(({ label, objectId }) => ({ label, objectId, type: "operators" }))];
    if (operatorGroups?.length)
      autoCompleteList = [...autoCompleteList, ...operatorGroups.map(({ label, objectId }) => ({ label, objectId, type: "operator_groups" }))];
    if (policies?.length)
      autoCompleteList = [...autoCompleteList, ...policies.map(({ label, objectId }) => ({ label, objectId, type: "access_policy" }))];

    return (
      <Container expanded={expanded} onClick={() => !expanded && this.setState({ expanded: true })}>
        <SearchBox expand={expanded} toggleExpand={() => this.setState({ expanded: !expanded })} list={autoCompleteList} onItemClick={onItemClick} />
        {expanded ? (
          <ListContainer>
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Slices</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                {slices?.length &&
                  slices.map(({ label, objectId }) => (
                    <ListItem onClick={() => onItemClick({ type: "slice", objectId })}>
                      {label}
                      <ArrowRight />
                    </ListItem>
                  ))}
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Access Polices</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                {policies?.length &&
                  policies.map(({ label, objectId }) => (
                    <ListItem onClick={() => onItemClick({ type: "access_policy", objectId })}>
                      {label}
                      <ArrowRight />
                    </ListItem>
                  ))}
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Operators</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                {operators?.length &&
                  operators.map(({ label, objectId }) => (
                    <ListItem onClick={() => onItemClick({ type: "operators", objectId })}>
                      {label}
                      <ArrowRight />
                    </ListItem>
                  ))}
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Operator Groups</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                {operatorGroups?.length &&
                  operatorGroups.map(({ label, objectId }) => (
                    <ListItem onClick={() => onItemClick({ type: "operator_groups", objectId })}>
                      {label}
                      <ArrowRight />
                    </ListItem>
                  ))}
              </StyledAccordionDetails>
            </StyledAccordion>
          </ListContainer>
        ) : (
          <>
            <OpenMenu expanded={expanded} />
          </>
        )}
      </Container>
    );
  }
}
