import styled from "styled-components";

export const ContentContainer = styled.div`
  flex-direction: column;
  width: calc(100% - 4rem);
  align-items: center;
  display: flex;
  margin: 2rem;
`;

export const Title = styled.div`
  font-size: 1rem;
  color: black;
  padding: 10px;
  border: solid black 1px;
`;

export const Tile = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: 10px;
  box-shadow: 0px 0px 10px 0px lightgrey;
  border-radius: 10px;
`;
export const ClusterCard = styled(Tile)`
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
  flex-direction: row !important;
  max-width: 28rem;
  padding: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => (props.noWrap ? null : "wrap")};
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
`;
