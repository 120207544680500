import { UPDATE_SNACKBAR_SUCCESS, UPDATE_SNACKBAR_ERROR, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  show: false,
  message: null,
  severity: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SNACKBAR_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        severity: "error",
      };
    case UPDATE_SNACKBAR_SUCCESS:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        severity: "success",
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
