import { getMeshBackend } from "../backend/MeshBackendProxy";
import { SET_ENFORCEMENT_POINTS, SET_ENFORCEMENT_POINT_SLICE } from "./types";
const meshBackend = getMeshBackend();

export const getEnforcementPoints = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getEnforcementPoints(getState(), dispatch, sliceName);
    if (response) dispatch({ type: SET_ENFORCEMENT_POINTS, payload: response });
    console.log(response);
  };
};

export const getEnforcementByCanonical = (sliceName, canonicalName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getEnforcementByCanonical(getState(), dispatch, sliceName, canonicalName);
    if (response) dispatch({ type: SET_ENFORCEMENT_POINT_SLICE, payload: response });
  };
};

export const clearEnforcementSlice = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ENFORCEMENT_POINT_SLICE,
      payload: { enforcementPoint: null },
    });
  };
};
export const clearEnforcementPoints = () => ({
  type: SET_ENFORCEMENT_POINTS,
});

export const updateEnforcementPointMetric = (sliceName, canonicalName, data) => {
  return async (dispatch, getState) => {
    await meshBackend.updateEnforcementPointMetric(getState(), dispatch, sliceName, canonicalName, data);
    dispatch(getEnforcementPoints(sliceName));
  };
};
export const updateCustomerOverlay = (sliceName, canonicalName, data) => {
  return async (dispatch, getState) => {
    await meshBackend.updateCustomerOverlay(getState(), dispatch, sliceName, canonicalName, data);
    dispatch(getEnforcementPoints(sliceName));
  };
};
export const toggleEnforcementPoint = (sliceName, canonicalName, value) => {
  return async (dispatch, getState) => {
    await meshBackend.toggleEnforcementPoint(getState(), dispatch, sliceName, canonicalName, { enabled: value });
    dispatch(getEnforcementPoints(sliceName));
  };
};
