import styled from "styled-components";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
`;

export const ClusterCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 1rem;
  height: 16rem;
  width: 10rem;
  border-radius: 0.5rem;
  background-color: lightblue;
  opacity: 0.8;
  margin: 1rem;
  cursor: pointer;
  font-size: 1.15rem;
  text-transform: capitalize;
  align-self: center;

  &:hover {
    box-shadow: 0 0 10px 4px grey;
  }
`;
