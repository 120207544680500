import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RootContainer, ClusterCard } from "./ClustersTabStyled";
import { getAllSlices, addClusters } from "./../../actions/SliceActions";
import LoadBalancerImg from "./../../load_balancing.png";

class ClusterTab extends React.Component {
  constructor() {
    super();
    this.state = {
      slice: {
        sliceGatewayProvider: {},
        attachedClusters: {},
      },
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let sliceId = query.get("id");
    if (Array.isArray(this.props.sliceList)) {
      this.props.sliceList.forEach((slice) => {
        if (slice?.sliceId === sliceId) {
          this.setState({ slice });
          return;
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const query = new URLSearchParams(this.props.location?.search);
    let sliceId = query.get("id");

    if (
      this.state.slice?.sliceId !== sliceId &&
      Array.isArray(this.props.sliceList)
    ) {
      this.props.sliceList.forEach((slice) => {
        if (slice?.sliceId === sliceId) {
          this.setState({ slice: slice });
          return;
        }
      });
    }

    if (prevProps.sliceList !== this.props.sliceList) {
      this.props.sliceList.forEach((slice) => {
        if (slice.sliceId === sliceId) {
          this.setState({ slice: slice });
          return;
        }
      });
    }
  }

  handleClusterAttach = async () => {
    await this.props.addClusters(this.state.slice?.sliceName);
    await this.props.getAllSlices();
  };

  render() {
    let ClusterList = (
      <div style={{ textAlign: "center", color: "gray" }}>
        <h3>No Clusters Attached ! </h3>
      </div>
    );

    if (
      this.state.slice?.attachedClusters !== undefined &&
      Object.keys(this.state.slice?.attachedClusters).length > 0
    ) {
      ClusterList = [];
      const attachedClusters = this.state.slice?.attachedClusters;
      for (let key in attachedClusters) {
        ClusterList.push(
          <ClusterCard
            key={key}
            onClick={() =>
              this.props.selectedCluster(attachedClusters[key]?.clusterName)
            }
          >
            <img
              style={{ height: "4rem", width: "4rem", alignSelf: "center" }}
              src={LoadBalancerImg}
            />
            <p>{attachedClusters[key]?.clusterName}</p>
            <p>inclustertab</p>
            <p>{attachedClusters[key]?.siteName}</p>
          </ClusterCard>
        );
      }
    }
    return (
      <RootContainer>
        {ClusterList}
      </RootContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sliceList: store.slices.sliceList,
  };
};

export default withRouter(
  connect(mapStateToProps, { getAllSlices, addClusters })(ClusterTab)
);
