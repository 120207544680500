import MeshBackend from './MeshBackend';

let meshBackend = new MeshBackend();
let apiMethods = {}

const getInstanceMethodNames = (obj) => {
    const proto = Object.getPrototypeOf(obj);
    const names = Object.getOwnPropertyNames(proto);
    return names.filter(name => typeof obj[name] === 'function' && obj[name] !== 'constructor');
}

getInstanceMethodNames(meshBackend).forEach(methodName => {
    apiMethods[methodName] = async(...args) => {
        return await meshBackend[methodName](...args);
    }
});

export const getMeshBackend = () => {
    return apiMethods;
}