import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Delete, Edit as MuiEdit } from "@material-ui/icons";
import blue from "@material-ui/core/colors/blue";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { Divider, FieldContainer, FieldContent, FieldHeading } from "../Body/BodyStyles";
import { selectSlicePolicy } from "../../actions/SlicePolicyActions";
import TableFilters from "./filters";
import { FilterButton } from "../Commons/IconButtons";
import { Accordion, AccordionSummary, AccordionDetails } from "./EnforcementPointStyles";
import { isValidValue } from "../Body/FormFields";
import { clearSelectedTrainingEvent } from "../../actions/trainingEventsActions";


const minWidth = 140;

const actionButton = {
  minWidth: minWidth,
  background: "white",
  fontWeight: 500,
  textTransform: "capitalize",
  borderColor: blue[500],
  borderStyle: "solid",
  borderWidth: "2px",
  borderRadius: 4,
  paddingTop: 8,
  paddingBottom: 8,
  "& > span": {
    fontSize: "0.9em",
  },
};

const actionIcon = {
  color: blue[400],
  userSelect: "none",
  pointerEvents: "none",
  marginRight: 8,
};

const downIcon = {
  color: blue[400],
  userSelect: "none",
  pointerEvents: "none",
};

const upIcon = {
  color: blue[400],
  userSelect: "none",
  pointerEvents: "none",
  transform: "rotate(180deg)",
};

const paper = {
  minWidth: minWidth,
  borderRadius: 4,
  marginTop: 8,
};

const SectionContainer = styled.div`
  min-height: 7rem;
  margin-top: 1rem;
`;
const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const ClearFilter = styled.span`
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin: 0 8px 0;
`;
const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;


const SectionHeading = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => (props.enabled ? "#004285" : "grey")};
`;

const SectionDivider = styled(Divider)`
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MetricContainer = styled.div`
  border: solid silver 0.1px;
  border-radius: 10px;
  padding: 1rem;
`;
const MetricLabel = styled.div`
  margin-bottom: 1rem;
  font-size: 17px;
  font-weight: 500;
`;
const MetricFieldContainer = styled(FieldContainer)`
  flex-direction: row;
  width: unset;
  flex: 1;
`;
const MetricFieldHeading = styled(FieldHeading)`
  width: unset;
  margin-right: 0.5rem;
`;
const MetricFieldContent = styled(FieldContent)``;
const EnforcementPointSection = ({
  openDialog,
  point,
  showTrainingEvents,
  showMetricConfiguration,
  showCustomerOverlay,
  toggleEnforcementPoint,
  clearSelectedTrainingEvent,
}) => {
  const { name, kind, description, canonicalName, slicePolicyName, configured, enabled, enforcementPointMetrics } = point;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionHeading enabled={enabled}>Service: {name}</SectionHeading>
       
      </SectionHeader>
      <MainContent>
        <SectionContent>
          <FieldContainer>
            <FieldHeading>Kind</FieldHeading>
            <FieldContent>{kind}</FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Description</FieldHeading>
            <FieldContent>{description}</FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Slice Policy Name</FieldHeading>
            <FieldContent>{slicePolicyName}</FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Canonical Name</FieldHeading>
            <FieldContent>{canonicalName}</FieldContent>
          </FieldContainer>
        </SectionContent>
        <div>
         
          <Button style={actionButton} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreVertIcon style={actionIcon} />
            <span>Actions</span>
            <ExpandMoreIcon style={anchorEl ? downIcon : upIcon} />
          </Button>
          <Menu
            id="simple-menu"
            style={paper}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              disabled={!configured}
              onClick={() => {
                toggleEnforcementPoint(canonicalName, enabled ? "false" : "true");
                handleClose();
              }}
            >
              {" "}
              {enabled ? "Disable" : "Enable"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                clearSelectedTrainingEvent()
                showTrainingEvents(canonicalName, name);
                handleClose();
              }}
            >
              Show Training Events
            </MenuItem>
            <MenuItem
              onClick={() => {
                showMetricConfiguration(point);
                handleClose();
              }}
            >
              Configure Thresholds
            </MenuItem>
            <MenuItem
              onClick={() => {
                showCustomerOverlay(point);
                handleClose();
              }}
            >
              {" "}
              Configure Algorithms
            </MenuItem>
          </Menu>
        </div>
      </MainContent>
      <MetricContainer>
        <MetricLabel>Metrics</MetricLabel>
        {enforcementPointMetrics.map(({ serviceName, metricValues }) => (
          <Accordion>
            <AccordionSummary>
              <SectionHeading>{serviceName}</SectionHeading>
            </AccordionSummary>
            <AccordionDetails>
              <SectionContent style={{ flexDirection: "column" }}>
                {metricValues.map(({ name, value }) => (
                  <MetricFieldContainer>
                    <MetricFieldHeading>{name} : </MetricFieldHeading>
                    <MetricFieldContent>{`${isValidValue(value?.high, "NA")} <-> ${isValidValue(value?.low, "NA")}`}</MetricFieldContent>
                  </MetricFieldContainer>
                ))}
              </SectionContent>
            </AccordionDetails>
          </Accordion>
        ))}
      </MetricContainer>
    </SectionContainer>
  );
};

const ActionBar = styled.div`
  display: flex;
  padding: 0.5rem;
  width: inherit;
  justify-content: space-between;
  align-items: center;
`;

function EnforcementPointsList({
  enforcementList,
  openDialog,
  showTrainingEvents,
  showMetricConfiguration,
  showCustomerOverlay,
  toggleEnforcementPoint,
  clearSelectedTrainingEvent,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [filtersData, setFiltersData] = useState({});

  const getUnique = (data, filterObj) => {
    const result = {};
    Object.keys(filterObj).forEach((ele) => {
      const filterType = [...new Set(data.map((eleData) => eleData[ele]))];
      result[filterObj[ele]] = { selected: [], values: filterType };
    });
    return result;
  };

  useEffect(() => {
    const filters = getUnique(enforcementList, {
      canonicalName: "Canonical_Name",
    });
    setFiltersData(filters);
  }, []);

  const setSelectedFilter = (type, filter) => {
    const selectedFilterData = filtersData;
    const data = [...selectedFilterData[type].selected];
    const index = data.indexOf(filter);
    if (index > -1) data.splice(index, 1);
    else data.push(filter);
    const filterData = {
      ...selectedFilterData,
      [type]: {
        ...selectedFilterData[type],
        selected: data,
      },
    };
    setFiltersData(filterData);
  };

  const getFilterApplied = (filData) => {
    return Object.keys(filData)
      .map((filter) => {
        if (filData[filter].selected.length > 0) return true;
        return false;
      })
      .some((ele) => ele === true);
  };

  const formatText = (filter) => {
    let fil = filter.split("_");
    fil.forEach((ele, index) => {
      if (index === 0) fil[index] = ele.toLowerCase();
      else fil[index] = ele.charAt(0).toUpperCase() + ele.slice(1);
    });
    fil = fil.join("");
    if (fil === "nameSpace") fil = "namespace";
    return fil;
  };

  const getFilteredData = (data, filterObj) => {
    return data.filter((ele) => {
      const data = Object.keys(filterObj).map((filter) => {
        const fil = formatText(filter);
        if (filterObj[filter].selected.length === 0) return true;
        if (filterObj[filter].selected.includes(ele[fil])) return true;
        return false;
      });
      if (data.every((ele) => ele === true)) return ele;
    });
  };

  const FilterApplied = getFilterApplied(filtersData);

  const clearFilters = (clearfilterData, setData) => {
    const data = Object.keys(clearfilterData).map((filter) => ({
      [filter]: { selected: [], values: clearfilterData[filter].values },
    }));
    let filtersData = {};
    data.forEach((ele) => {
      filtersData = { ...filtersData, ...ele };
    });
    setData(filtersData);
  };

  const data = getFilteredData(enforcementList, filtersData);

  return (
    <div>
      {showFilters && (
        <TableFilters
          showMainFilter={showFilters}
          setAllFilters={() => setShowFilters(false)}
          mainFilterData={filtersData}
          setSelectedFilter={setSelectedFilter}
        />
      )}
      <Paper>
        <ActionBar>
          <div>Enforcement Points</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FilterButton
              fill={FilterApplied ? "blue" : "black"}
              onMouseDown={() => setShowFilters(true)}
              style={{
                margin: "0 8px",
              }}
            />
            {FilterApplied && <ClearFilter onMouseDown={() => clearFilters(filtersData, setFiltersData)}>clear</ClearFilter>}
            {/* <AttachButton variant="outlined" onClick={() => openDialog()}>
              Add Enforcement
            </AttachButton> */}
          </div>
        </ActionBar>
      </Paper>
      {data.map((point) => (
        <>
          <EnforcementPointSection
            openDialog={openDialog}
            point={point}
            showTrainingEvents={showTrainingEvents}
            showMetricConfiguration={showMetricConfiguration}
            showCustomerOverlay={showCustomerOverlay}
            toggleEnforcementPoint={toggleEnforcementPoint}
            clearSelectedTrainingEvent={clearSelectedTrainingEvent}
          />
          <SectionDivider />
        </>
      ))}
    </div>
  );
}

const mapStateToProps = (store) => {
  return {
    enforcementList: store.enforcementPoints.enforcementPointsList,
  };
};

export default connect(mapStateToProps, { selectSlicePolicy, clearSelectedTrainingEvent })(EnforcementPointsList);
