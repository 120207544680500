import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import styled from "styled-components";

const SvgIcon = styled(MuiSvgIcon)`
  :hover {
    cursor: pointer;
  }
`;
export function FilterButton(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill={props.fill}
        d="M15,19.88C15.04,20.18 14.94,20.5 14.71,20.71C14.32,21.1 13.69,21.1 13.3,20.71L9.29,16.7C9.06,16.47 8.96,16.16 9,15.87V10.75L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L15,10.75V19.88M7.04,5L11,10.06V15.58L13,17.58V10.05L16.96,5H7.04Z"
      />
    </SvgIcon>
  );
}

export const TableIcon = () => (
  <svg
    enable-background="new 0 0 24 24"
    viewBox="0 0 24 24"
    fill="black"
    width="24px"
    height="24px"
  >
    <g>
      <rect fill="none" height="24" width="24" />
      <path d="M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V9C21,7.9,20.1,7,19,7z M19,10L19,10c0,0.55-0.45,1-1,1h-8 c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h8C18.55,9,19,9.45,19,10z M13,15v-2h2v2H13z M15,17v2h-2v-2H15z M11,15H9v-2h2V15z M17,13 h2v2h-2V13z M9,17h2v2H9V17z M17,19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2v1h-2V5H5v10h1V17z" />
    </g>
  </svg>
);
