import { CLEAR_SELECTED_SLICE_POLICY, SET_SLICE_POLICIES, ACTION_LOGOUT_INITIATED, SET_SELECTED_SLICE_POLICY } from "../actions/types";

const initialState = {
  policies: [],
  selectedSlicePolicy: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SLICE_POLICIES:
      return {
        ...state,
        // sliceList: action.payload.slices === undefined ? []:action.payload.slices,
        policies: [...action.payload.slicePolicies],
      };
    case SET_SELECTED_SLICE_POLICY:
      return {
        ...state,
        selectedSlicePolicy: { ...action.payload },
      };
    case CLEAR_SELECTED_SLICE_POLICY:
      return { ...state, selectedSlicePolicy: null };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
