import React from "react";
import styled from "styled-components";

const VerticalLine = styled.span`
  position: relative;
  border-left: 2.6px solid #5f5;
  display: block;
  height: 50px;
  margin: 0 auto;
  width: 1px;
  border-color: #d7d7e1;
  cursor: default;
`;
const SliceName = styled.div`
  /* position: absolute; */
  color: rgb(103 94 94);
  font-weight: 600;
  font-size: 18px;
  /* padding-top: 20px; */
  /* padding-left: 20px; */
  font-family: Bai Jamjuree;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;
`;
const Slice = styled.div`
  position: relative;
  min-height: 100px;
  width: 250px;
  border-left: solid #4285f4 8px;
  border-radius: 7px;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  cursor: default;
  z-index: 12;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 30px;
  padding:0px 18px ;
  box-sizing: border-box;
`;
const Namespace = styled.div`
  /* position: absolute; */
  color: rgb(103 94 94);
  font-weight: 300;
  font-size: 14px;
  /* padding-top: 60px; */
  /* padding-left: 20px; */
  font-family: Bai Jamjuree;
`;

const SliceNode = ({ slices }) => {
  const getBorderColor = (index) => {
    switch (index % 3) {
      case 0:
        return "#8433D6";
      case 1:
        return "#D528AF";
      case 2:
        return "#D66E3E";
      default:
        return "black";
    }
  };

  const getBackgroundColor = (index) => {
    switch (index % 3) {
      case 0:
        return "#F7EFFF";
      case 1:
        return "#FFEBFB";
      case 2:
        return "#FFF6EF";
      default:
        return "#fff";
    }
  };

  return slices.map((slice, index) => (
    <div  style={{cursor: 'default'}}key={slice.id}>
      <VerticalLine />
      <Slice
        borderColor={getBorderColor(index)}
        backgroundColor={getBackgroundColor(index)}
      >
        <SliceName>{slice.name}</SliceName>
        <Namespace>
          {slice.namespace} {slice.namespace === 1 ? "namespace" : "namespaces"}
        </Namespace>
      </Slice>
    </div>
  ));
};

export default SliceNode;
