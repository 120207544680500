import styled from "styled-components";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiList from "@material-ui/core/List";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";
import { ScrollBarStyle } from "../../AppStyles";

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? "#2868F3" : "#12153E"} !important;
  :hover {
    cursor: pointer;
  }
`;
export const ListItemText = styled(MuiListItemText)`
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    background-color: ${(props) =>
      props.selected ? "#2868F3" : "#12153E"} !important;
    color: #ffffff;
  }
`;
export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 0px !important;
  background-color: unset !important;
  //margin-right: 1rem;
  color: ${(props) => (props.selected ? "white" : "#757D8A")};
`;
export const List = styled(MuiList)`
  width: 100%;
  /* height: 100%; */
  overflow: auto;
  scrollbar-width: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display:none; */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #12153E;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: #12153E;
  }
`;
export const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  background-color: pink;
  width: 100%;
`;
export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: unset;
  min-height: 0px;
  .MuiAccordionSummary-content {
    margin: 0px !important;
  }
  & div.muiiconbutton-edgeend: {
    background-color: ${(props) =>
      props.selected ? "#2868F3 !important" : "transparent"};
  }
  background-color: ${(props) =>
    props.selected ? "#2868F3 !important" : "transparent"};
`;
export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: unset;
`;
