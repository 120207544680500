import {UPDATE_SNACKBAR_SUCCESS, UPDATE_SNACKBAR_ERROR } from "./types";

export const updateSnackbar = (show,message) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SNACKBAR_ERROR, payload: {show,message} });
  };
};
export const updateSnackbarSuccess = (show,message) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SNACKBAR_SUCCESS, payload: {show,message} });
  };
};