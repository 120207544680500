import React, { Component } from "react";
import { FormField, FormSelect } from "../../Commons/formUtils";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { ButtonContainer, FormContainer, Title } from "../FormStyles";

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      roles: ["Admin", "Reviewer", "Observer", "Developer"],
    };
  }
  arrToObj = (arr) => {
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
      obj[arr[i]] = arr[i];
    }
    return obj;
  };
  submitForm = () => {
    let value = { ...this.state.value };
    alert(value.group_name + " " + value.role);
  };
  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };
  render() {
    const { cancelListener } = this.props;
    const { value, roles } = this.state;

    return (
      <FormContainer>
        <Title>New User Group</Title>
        <FormField
          label="Group Name"
          value={value.group_name}
          onChange={(e) =>
            this.setValue("group_name", e.nativeEvent.target.value)
          }
        />
        <FormSelect
          label="Role"
          value={value.role}
          onChange={(e) => this.setValue("role", e.nativeEvent.target.value)}
          options={this.arrToObj(roles)}
        />
        <ButtonContainer>
          <Button variant="contained" color="primary" onClick={this.submitForm}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelListener}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </FormContainer>
    );
  }
}
