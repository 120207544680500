import { UPDATE_MENU,ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  menu: "item 1",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
