import React from "react";
import { withRouter } from "react-router-dom";
import ReactFlow, { MiniMap, Controls, ReactFlowProvider, ControlButton, useZoomPanHelper } from "react-flow-renderer";
import MeshSelectorNode from "./MeshSelectorNode.jsx";
import { connect } from "react-redux";
import { getSliceGateway } from "../../actions/SliceActions.js";
import AllOutIcon from "@material-ui/icons/AllOut";
import styled, { keyframes } from "styled-components";
import Paper from "@material-ui/core/Paper";
import { getCompareClusterStats } from "../../actions/dashboardActions.js";
import CustomEdge from "./CustomEdge.jsx";

const popup = keyframes`
  0%{
    position: fixed;
    height: inherit;
    right: 15px;
    bottom: 20px;
    left: calc(15rem + 15px);
    z-index: 5;
  }
  
  100% {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    left:0px;
    height:100vh;
  }
`;
const contract = keyframes`
  0%{
    background-color: aliceblue;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 5;
    left:0px;
    height:100vh;
  }
  
  99% {
    background-color: aliceblue;
  position: fixed;
  right: 15px;
  bottom: 20px;
  z-index: 5;
    left: calc(15rem + 15px);
    height:inherit;
  }
  100%{
    position: relative;
    width:100%;
  }
`;

const Container = styled(Paper)`
  background-color: aliceblue;
  width: 100%;
  animation: ${(props) => (props.expand ? (props.expand == "popup" ? popup : contract) : null)} 0.5s linear forwards;
`;

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [16, 16];
const nodeTypes = {
  selectorNode: MeshSelectorNode,
};

class FlowGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      sliceGatewayMap: {},
      expand: null,
      pollingArguments: [],
      pollStats: true,
    };
  }

  componentDidMount() {
    this.getElements();
  }
  componentWillUnmount() {
    this.setState({ pollStats: false });
  }

  fitElementsToView = () => {
    this.props.fitView({ padding: 0.1, includeHiddenNodes: true });
  };
  
  getAdjoiningGateway = (cluster1, cluster2) => {
    const cluster2Ids = Object.keys(cluster2);
    const cluster1Ids = Object.keys(cluster1);
    for (let i = 0; i < cluster1Ids.length; i++) {
      let id1 = cluster1Ids[i];
      console.log(
        cluster2Ids,
        cluster1[id1].adjacentSliceGatewayIds[0],
        cluster2Ids.includes(cluster1[id1].adjacentSliceGatewayIds[0]),
        cluster2Ids.includes(cluster1[id1].adjacentSliceGatewayIds[0]?.toString())
      );
      if (cluster2Ids.includes(cluster1[id1].adjacentSliceGatewayIds[0]?.toString())) {
        return [id1, cluster1[id1].adjacentSliceGatewayIds[0]];
      }
    }
    return null;
  };

  getCurrentSlice = () => {
    const query = new URLSearchParams(this.props.location.search);
    let sliceId = query.get("id");
    if (Array.isArray(this.props.sliceList)) {
      return this.props.sliceList.find((slice) => slice.sliceId === sliceId);
    }
    return null;
  };

  startPollingStats = async (cluster1Id, cluster2Id, edgeId, sliceName) => {

    const pollStats = async (cluster1Id, cluster2Id, edgeId, sliceName) => {
      let ipoc = await this.props.getCompareClusterStats(cluster1Id, cluster2Id);
      //update edge point
      let elements = [...this.state.elements];
      if (!elements?.length) return;
      const edgeIndex = elements.findIndex((element) => element.id == edgeId);
      let edge = elements[edgeIndex];
      const stats = ipoc.slices[sliceName]?.stats;
      // const label = stats?.length && stats[0]?.latency && stats[1]?.latency ? `${stats[0]?.latency}ms, ${stats[1].latency}ms` : "NA";
      if (stats?.length && stats[0]?.latency && stats[1]?.latency) {
        if (edge.label == undefined) {
          edge.style = { stroke: "#000", strokeWidth: "3px" };
          edge.animated = false;
          edge.labelStyle = { fontSize: "18px", fontWeight: "600" };
          edge.labelShowBg = true;
          edge.labelBgStyle = { fill: "#dcf9ff" };
          edge.labelBgPadding = [4, 8];
        }
        edge.label = `${stats[0]?.latency}ms, ${stats[1].latency}ms`;
      } else {
        edge.label = undefined;
        edge.style = { stroke: "orange", strokeWidth: "3px" };
        edge.animated = true;
      }
      elements[edgeIndex] = { ...edge };
      this.setState({ elements }, () => {
        if (this.state.pollStats) {
          setTimeout(() => pollStats(cluster1Id, cluster2Id, edgeId, sliceName), 5000);
        }
      });
    };
    pollStats(cluster1Id, cluster2Id, edgeId, sliceName);
  };

  getElements = () => {
    const reactFlowElement = document.getElementsByClassName("react-flow")[0];
    const height = reactFlowElement.offsetHeight;
    const width = reactFlowElement.offsetWidth;

    const slice = this.getCurrentSlice();

    if (slice.attachedClusters !== undefined) {
      let sliceGatewayMap = {};
      let clusterIds, clusters;
     
      //normal flow
      clusterIds = Object.keys(slice.attachedClusters);
      clusters = slice.attachedClusters !== undefined ? slice.attachedClusters : {};

      const getNodeData = () => {
        let promises = [];
        for (let i = 0; i < clusterIds.length; i++) {
          const sliceId = slice.sliceName;
          const clusterId = clusterIds[i];
          const gateways = clusters[clusterIds[i]].sliceGateways;
          let gatewayKeys = Object.keys(gateways);
          promises.push(
            Promise.all(
              gatewayKeys.map((id) => {
               
                return this.props.getSliceGateway(sliceId, clusterId, id);
              })
            ).then((data) => {
              let applist = [];
              for (let i in data) {
                applist.push(data[i].gateway?.health?.appStatus !== undefined ? data[i].gateway.health.appStatus : []);
              }
              return {
                gateways: data,
                applist,
              };
            })
          );
        }
        return Promise.all(promises);
      };
      const createElements = (nodeData) => {
        const getGatewayFromNodeData = (data, gatewayId) => {
          return data.gateways.find(({ gateway }) => gateway.sliceGatewayId == gatewayId)?.gateway;
        };
        const verifyContainsAppPods = (data, gatewayId) => {
          const gateway = getGatewayFromNodeData(data, gatewayId);
          if (!gateway) return false;
          if (!gateway?.health?.appStatus?.length) return false;
          return true;
        };
        let elements = [];
        let pollingArguments = [];
        let theta = 0;
        let n = clusterIds.length;
        let r = Math.min(height, width) * (1 + (n - 2) / (2 * n));
        let cx = width;
        let cy = r;
        let ix = cx;
        let iy = 10;
        for (let i = 0; i < n; i++) {
          theta = i * ((2 * Math.PI) / n);
          let h = 2 * Math.sin(theta / 2) * r;
          let x = Math.cos(theta / 2) * h;
          let y = Math.sin(theta / 2) * h;
          elements.push({
            id: (i + 1).toString(),
            type: "selectorNode",
            data: {
              label: clusters[clusterIds[i]].clusterName,
              site: clusters[clusterIds[i]].siteName,
              orientation: "right",
              handlePosition: "top",
              sliceId: slice.sliceName,
              clusterId: clusterIds[i],
              gateways: clusters[clusterIds[i]].sliceGateways,
              gap1: 150,
              gap2: 80,
              nodeData: nodeData[i],
              cluster: clusters[clusterIds[i]],
            },
            style: {
              background: "lightblue",
            },
            position: { x: ix + x, y: iy + y },
          });
          for (let j = i + 1; j < n; j++) {
            const gateways = this.getAdjoiningGateway(clusters[clusterIds[i]].sliceGateways, clusters[clusterIds[j]].sliceGateways);
            pollingArguments.push([clusterIds[i], clusterIds[j], `e${(i % n) + 1}-${(j % n) + 1}`, slice.sliceName]);
            if (gateways == null || !verifyContainsAppPods(nodeData[i], gateways[0]) || !verifyContainsAppPods(nodeData[j], gateways[1])) {
              sliceGatewayMap[`e${(i % n) + 1}-${(j % n) + 1}`] = {
                [`${(i % n) + 1}`]: null,
                [`${(j % n) + 1}`]: null,
              };
              elements.push({
                id: `e${(i % n) + 1}-${(j % n) + 1}`,
                source: `${(i % n) + 1}`,
                target: `${(j % n) + 1}`,
                style: { stroke: "orange", strokeWidth: "3px" },
                type: "straight",
                animated: true,
              });
            } else {
              sliceGatewayMap[`e${(i % n) + 1}-${(j % n) + 1}`] = {
                [`${(i % n) + 1}`]: gateways[0],
                [`${(j % n) + 1}`]: gateways[1],
              };
              elements.push({
                id: `e${(i % n) + 1}-${(j % n) + 1}`,
                source: `${(i % n) + 1}`,
                target: `${(j % n) + 1}`,
                style: { stroke: "#000", strokeWidth: "3px" },
                type: "straight",
                label: "NA",
                labelStyle: { fontSize: "18px", fontWeight: "600" },
                labelShowBg: true,
                labelBgStyle: { fill: "#dcf9ff" },
                labelBgPadding: [4, 8],
              });
            }
          }
        }
        this.setState({ elements, sliceGatewayMap, pollStats: true }, () => {
          setTimeout(() => this.fitElementsToView(), 500);
          for (let i in pollingArguments) {
            this.startPollingStats(...pollingArguments[i]);
          }
        });
      };

      getNodeData()
        .then((nodeData) => createElements(nodeData))
        .catch((e) => {
          console.log(e);
        });
    }
    return;
  };

  handleEdgeClose = (elementId) => {
    let newElements = [...this.state.elements];
    newElements = newElements.map((elem) => {
      return {
        ...elem,
        data: {
          ...elem.data,
          clicked: false,
        },
      };
    });
    this.setState({ elements: newElements });
  };
  handleEdgeClick = (element) => {
    let newElements = [...this.state.elements];
    let { source, target } = element;
    newElements = newElements.map((elem) => {
      if (elem.id == element.id) {
        if (elem.animated) return { ...elem, style: { stroke: "red", strokeWidth: "5px" } };
        return { ...elem, style: { stroke: "#2fa767", strokeWidth: "5px" } };
      } else if (elem.id == source || elem.id == target) {
        return {
          ...elem,
          data: {
            ...elem.data,
            highlight: this.state.sliceGatewayMap[element.id][elem.id],
          },
        };
      } else {
        if (elem.type == "selectorNode") return { ...elem, data: { ...elem.data, highlight: false } };
        if (elem.animated) return { ...elem, style: { stroke: "orange", strokeWidth: "3px" } };
        return { ...elem, style: { stroke: "#000", strokeWidth: "3px" } };
      }
    });
    this.setState({ elements: newElements });
  };

  onElementClick = (event, element) => (element.type == "straight" ? this.handleEdgeClick(element) : null);

  render() {
    const { expand, elements } = this.state;
    return (
      <Container expand={expand}>
        <ReactFlow
          style={{ backgroundColor: "aliceblue" }}
          elements={elements}
          onElementClick={this.onElementClick}
          onEdgeMouseEnter={this.onElementClick}
          nodeTypes={nodeTypes}
          connectionLineStyle={connectionLineStyle}
          snapToGrid={true}
          snapGrid={snapGrid}
          defaultZoom={0.01}
          edgeTypes={{ special: CustomEdge }}
          minZoom={0.01}
        >
          <MiniMap
            nodeColor={(n) => {
              if (n.type === "input") return "blue";
              if (n.type === "output") return "green";
            }}
          />
          <Controls>
            <ControlButton
              onClick={() => {
                this.setState({ expand: expand == "contract" || expand == null ? "popup" : "contract" });
                setTimeout(() => this.fitElementsToView(), 1000);
              }}
            >
              <AllOutIcon />
            </ControlButton>
          </Controls>
        </ReactFlow>
      </Container>
    );
  }
}
const Wrapper = (props) => {
  const { fitView } = useZoomPanHelper();
  return <FlowGraph {...props} fitView={fitView} />;
};
const FlowWrapper = (props) => {
  return (
    <ReactFlowProvider>
      <Wrapper {...props} />
    </ReactFlowProvider>
  );
};
const mapStateToProps = (store) => {
  return {
    sliceList: store.slices.sliceList,
  };
};

export default withRouter(connect(mapStateToProps, { getSliceGateway, getCompareClusterStats })(FlowWrapper));
