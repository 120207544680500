import React, { Component } from "react";
import { BodyRoot } from "../Body/BodyStyles";
import styled from "styled-components";
import Roles from "./Roles/Roles";
import Users from "./Users/Users";
import Groups from "./UserGroup/Groups";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import SvgClusterMap from "../Dashboard/SvgClusterMap";
export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default class RoleManager extends Component {
  render() {
    return (
      <BodyRoot>
        <Paper style={{flex:1}}>
          <SvgClusterMap />
        </Paper>
      </BodyRoot>
    );
  }
}
