import { getMeshBackend } from "../backend/MeshBackendProxy";
import { CLEAR_SELECTED_SLICE_POLICY, SET_SLICE_POLICIES, SET_SELECTED_SLICE_POLICY } from "./types";
import { getAllSlices } from "./SliceActions";

const meshBackend = getMeshBackend();

export const createSlicePolicy = (slicePolicyDetails, sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.postSlicePolicy(getState(), dispatch, slicePolicyDetails, sliceName);
    dispatch(getAllPoliciesForSlice(sliceName));
  };
};
export const editSlicePolicy = (slicePolicyDetails, sliceName, slicePolicyId) => {
  return async (dispatch, getState) => {
    await meshBackend.editSlicePolicy(getState(), dispatch, slicePolicyDetails, sliceName, slicePolicyId);
    dispatch(getAllPoliciesForSlice(sliceName));
  };
};

export const attachSlicePolicy = (slicePolicyId, sliceName) => {
  return async (dispatch, getState) => {
    await meshBackend.attachSlicePolicy(getState(), dispatch, sliceName, slicePolicyId);
    dispatch(getAllSlices());
  };
};

export const detachSlicePolicy = (sliceName, value) => {
  return async (dispatch, getState) => {
    await meshBackend.detachSlicePolicy(getState(), dispatch, sliceName, value);
    dispatch(getAllSlices());
    dispatch(getAllPoliciesForSlice(sliceName));
  };
};

export const getAllPoliciesForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await meshBackend.getAllPoliciesForSlice(getState(), dispatch, sliceName);
    dispatch({ type: SET_SLICE_POLICIES, payload: response });
  };
};

export const selectSlicePolicy = (payload) => ({
  type: SET_SELECTED_SLICE_POLICY,
  payload,
});

export const clearSelectedSlicePolicy = () => ({
  type: CLEAR_SELECTED_SLICE_POLICY,
});

export const deleteSlicePolicy = (sliceName, slicePolicyId) => {
  return async (dispatch, getState) => {
    await meshBackend.deleteSlicePolicy(getState(), dispatch, sliceName, slicePolicyId);
    dispatch(getAllPoliciesForSlice(sliceName));
  };
};
