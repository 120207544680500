import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { updateCustomerOverlay } from "../../../actions/enforcementActions";
const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

class CustomerOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { stepWindowDelay: null },
    };
  }

  componentDidMount() {
    this.parseData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps?.selectedEnforcementPoint && this.props.selectedEnforcementPoint) this.parseData();
  }
  parseData = () => {
    const stepWindowDelay = this.props.selectedEnforcementPoint?.customerOverlay?.policy_client_config?.step_window_delay;
    if (stepWindowDelay) this.setValue("stepWindowDelay", stepWindowDelay);
  };
  handleSave = () => {
    const {
      value: { stepWindowDelay },
    } = this.state;
    let data = {
      customerOverlay: {
        policy_client_config: {
          step_window_delay: stepWindowDelay,
        },
      },
    };
    this.props.updateCustomerOverlay(this.props.sliceName, this.props.selectedEnforcementPoint.canonicalName, data);
    this.setValue("stepWindowDelay", 0);
    this.props.handleClose();
  };
  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };
  render() {
    const { open, handleClose } = this.props;
    const { handleSave } = this;
    const { value } = this.state;
    return (
      <div>
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md">
          <StyledTitle>
            <h3>Customer Overlay</h3>
          </StyledTitle>
          <DialogContent dividers style={{ width: "40vw" }}>
            <TextField
              //   InputLabelProps={{ shrink: true, type: "number" }}
              size="small"
              label="Step Window Delay"
              value={value?.stepWindowDelay}
              type="number"
              onChange={(e) => this.setValue("stepWindowDelay", Number(e.target.value))}
              variant="filled"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(null, { updateCustomerOverlay })(CustomerOverlay);
