import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { ACTION_LOGOUT_INITIATED, ADD_SESSION, START_SPINNER, STOP_SPINNER } from "./actions/types";

const reduxStateSyncConfig = {
  whitelist: [ACTION_LOGOUT_INITIATED, ADD_SESSION],
};

const middleware = [thunk, createStateSyncMiddleware(reduxStateSyncConfig)];
const store = createStore(rootReducer, undefined, composeWithDevTools(applyMiddleware(...middleware)));
export const persistor = persistStore(store);

export default store;
