import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
const Container = styled(Paper)`
  padding: 1rem;
  border-radius: 1rem;
  max-height: 100%;
  width: 100%;
  background: #8b939b;
  color: white;
`;

export default function SliceInfo({ text }) {
  // return <div>{text}</div>;
  return (
    <Container>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">{text}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2">
            Here we explain what does the above key means
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
