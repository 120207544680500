import React, { useState, useRef, useEffect } from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import AveshaIcon from "./../../assets/icons/AveshaLoginLogo.svg";
import { connect } from "react-redux";
import background from "./../../assets/images/login_mesh_background.png";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { loginUser, changeUserPassword, removeError } from "./../../actions/loginAction";
const paperStyle = {padding:20,minHeight:'75vh',width:'40vw',margin:"40px 60px", borderRadius: '14px'}

const mainGridStyle = { 
                         backgroundImage: `url(${background})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         height: '100vh',
                         overflow: 'auto'
 
                     }
const headStyle = { fontFamily: 'Bai Jamjuree',color: '#03051E',fontSize: '36px',lineHeight:'120%',fontWeight:'bold',fontStyle:'normal', margin: "2px auto"}
const textStyle = { fontFamily: 'Bai Jamjuree',color: '#03051E',fontSize: '20px',lineHeight:'30px',fontWeight:'normal',fontStyle:'normal', margin: "2px auto",opacity:'0.8'}

const CustomTextField = styled(TextField)({
    color: "#03051E",
    backgroundColor: "#fff",
    fontFamily: "Bai Jamjuree !important",
    fontWeight: "normal",
    "& label.Mui-focused": {
      color: "#797A86",
      fontFamily: "Bai Jamjuree !important",
      fontStyle: "normal",
      fontWeight: "normal"
    },
    "& .MuiInput-underline:after": {
      fontFamily: "Bai Jamjuree !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#797A86",
        fontFamily: "Bai Jamjuree !important",
        border: "1px solid #EBEBEB"
      },
      "&:hover fieldset": {
        borderColor: "silver",
        fontFamily: "Bai Jamjuree !important",
      },
      "&.Mui-focused fieldset": {
        color: "#797A86",
        fontFamily: "Bai Jamjuree !important",
        border: "1px solid #EBEBEB"
      }
    }
  });

const LoginScreen = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showUsernameInChangePassword,setShowUsernameInChangePassword] = useState(false);
  const loginRef = useRef();
  const changePassRef = useRef();

  useEffect(()=>{
    if(props.changePassword && !showUsernameInChangePassword && !username){
        setShowUsernameInChangePassword(true);
    }
  },[props.changePassword,showUsernameInChangePassword,username]);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        let e = new Event('click', { bubbles: true });
        props.changePassword ? changePassRef.current.dispatchEvent(e) : loginRef.current.dispatchEvent(e)
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleLogin = () => {
    props.loginUser({
        username,
        password
    })
  };

  const handleChangePassword = () => {
    props.changeUserPassword({
      username,
      password,
      newPassword,
      confirmNewPassword,
    });
  };
  const handleRemoveError = () => {
    if (props.error) {
      props.removeError();
    }
  };


  const changePasswordForm = (
    <>
    
      {
          showUsernameInChangePassword && (
            <Box mt={3}>
            <CustomTextField
            id="email"
            label="Enter email address"
            name="email"
            value={username}
            variant="outlined"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter email address"
            fullWidth
            required
          />
          </Box>

          )
      }
      <Box mt={3}>
      <CustomTextField
        label="Old Password"
        value={password}
        variant="outlined"
        onChange={(e) => setPassword(e.target.value)}
        id="oldPassword"
        name="oldPassword"
        type="password"
        fullWidth
        required
      />
    </Box>
    <Box mt={3}>
      <CustomTextField
        error= {props.error ? true : false}
        helperText = {props.error}
        label="New Password"
        value={newPassword}
        variant="outlined"
        onChange={(e) => setNewPassword(e.target.value)}
        id="newPassword"
        name="newPassword"
        type="password"
        fullWidth
        required
        autoComplete="new-password"
      />
    </Box>
    <Box mt={3}>
      <CustomTextField
        label="Confirm new password"
        value={confirmNewPassword}
        onChange={(e) => {
                          setConfirmNewPassword(e.target.value)
                          }
                      }
        type="password"
        id="confirmNewPassword"
        name="confirmNewPassword"
        variant="outlined"
        fullWidth
        required
        autoComplete="confirm-new-password"
        InputLabelProps={{style: {fontFamily: "Bai Jamjuree"}}} 
      />
    </Box>
    <Box mt={3}>
      <Button ref={changePassRef} onClick={handleChangePassword} style={{backgroundColor:'#2868F3',height:'50px'}}type="submit" color="primary" variant="contained" fullWidth>
        Change Password
      </Button>
    </Box>
    </>
  )

  const loginForm = (
      <>
            <Box mt={2}>
            <p style={textStyle}>You build apps.</p>
            <p style={textStyle}>We simplify your journey.</p>
            </Box>
            <Box mt={5}>
              <CustomTextField
                label="Enter email address"
                value={username}
                variant="outlined"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter email address"
                fullWidth
                required
              />
            </Box>
            <Box mt={4}>
              <CustomTextField
                error={props.error ? true : false}
                helperText={props.error}
                id="password"
                name="password"
                label="Enter password"
                value={password}
                onChange={(e) => {
                                  handleRemoveError();
                                  setPassword(e.target.value)
                                  }
                              }
                type="password"
                placeholder="Enter password"
                variant="outlined"
                InputLabelProps={{style: {fontFamily: "Bai Jamjuree"}}} 
                fullWidth
                required
              />
            </Box>
            <Box mt={3}>
              <Button ref={loginRef} onClick={handleLogin} style={{backgroundColor:'#2868F3',height:'50px'}} color="primary" variant="contained" fullWidth>
                Sign In
              </Button>
            </Box>
            <Box mt={3}>
              <Typography style={{fontFamily: "Bai Jamjuree"}}>
                  Not registered yet? Create an account 
              </Typography>
            </Box>
            </>

  )
  return (
    <>
      <div style={mainGridStyle}>
      <Grid>
    <Box>
      <Paper elevation={9} style={paperStyle}>
        <Box pl={2} pr={2}>
          <Grid>
            <Box mt={4}>
              <img alt="Avesha Logo" src={AveshaIcon}></img>
            </Box>
            <Box mt={3}>
            <p style={headStyle}>Avesha Global</p>
            <p style={headStyle}>Application Slice</p>
            </Box>
            {props.changePassword ? changePasswordForm : loginForm}
            </Grid>
            </Box>
            </Paper>
            </Box>
            </Grid>
      </div>
    </>
  );
};

const mapStoreToProps = (store) => {
    return {
      changePassword: store.SessionReducer.changePassword,
      error: store.ErrorReducer.errorMessage,
    };
  };

export default connect(mapStoreToProps, { 
    loginUser,
    changeUserPassword,
    removeError,
})(LoginScreen);