import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { FormControl, FormHelperText, InputLabel, makeStyles, Select, MenuItem, FilledInput, Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckList, Label, CheckListContainer } from "../RoleManager/FormStyles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import ReactInputMask from "react-input-mask";

export const useFormField = (initialValue = "") => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback((e) => setValue(e.target.value), []);
  const data = value || initialValue;
  // console.log('value', value, 'initialValue', initialValue, 'data', data);

  return { value: data, onChange, setValue };
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <ReactInputMask
      mask="999.999.0.0/16"
      maskPlaceholder="XXX.XXX.0.0/16"
      defaultValue={'0'}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
      
  );
}

export const FormMaskField = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();
  const { label, value, onChange, disabled } = props;
  return (
    <FormControl
      style={{
        width: props.width || "30rem",
        alignSelf: "center",
        margin: "1rem",
      }}
      size="small"
      variant="filled"
      disabled={disabled}
      error={props.error}
    >
      <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
      <FilledInput value={value} onChange={onChange} name="textmask" id="component-filled" inputComponent={TextMaskCustom} />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export const FormField = styled(({ ...otherProps }) => <TextField size="small" variant="filled" color="primary" {...otherProps} />)`
  && {
    margin: 1rem;
    min-width: 30rem;
    // align-self: center;
  }
`;

export const FormSelectN = (props) => {
  const useStyles = makeStyles(
    props.useStyles
      ? {
          whiteColor: {
            color: "white",
            "&.MuiPaper-root": {
              top: "225px",
            },
          },
          label: {
            color: "white",
            "&.Mui-focused": {
              color: "white",
            },
          },
          select: {
            color: "white",
            "&:before": {
              borderBottomColor: "white",
            },
            "&:after": {
              borderBottomColor: "white",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
        }
      : {}
  );

  const MenuProps = {
    PaperProps: {
      style: {
        width: "inherit",
        top: 225,
        maxHeight: 200,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom" },
  };

  const classes = useStyles();
  const options = [];

  for (let item in props.options) {
    options.push(
      <MenuItem key={item} value={item}>
        {props.options[item]}
      </MenuItem>
    );
  }
  
  return (
    <FormControl
      style={{
        width: props.width || "30rem",
        alignSelf: "center",
        margin: "1rem",
      }}
      size="small"
      variant="filled"
      error={props.error}
    >
      <InputLabel className={classes && classes.label}>{props.label}</InputLabel>
      <Select
        classes={
          classes && {
            root: classes.whiteColor,
            icon: classes.whiteColor,
          }
        }
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        inputProps={{
          name: props.label,
          id: props.label,
        }}
        MenuProps={MenuProps}
      >
        <MenuItem aria-label="None" disabled value=""></MenuItem>
        {options}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export const FormSelect = (props) => {
  const options = [];
  for (let item in props.options) {
    options.push(
      <option key={item} value={item}>
        {props.options[item]}
      </option>
    );
  }
  return (
    <FormControl
      style={{
        width: props.width || "30rem",
        alignSelf: "center",
        margin: "1rem",
      }}
      size="small"
      variant="filled"
      error={props.error}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        native
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        inputProps={{
          name: props.label,
          id: props.label,
        }}
        disabled={props.disabled}
      >
        <option aria-label="None" disabled value=""></option>
        {options}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export const FormCheck = (props) => {
  return <FormControlLabel style={props.style} control={<Checkbox checked={props.checked} onChange={props.onChange} />} label={props.label} />;
};

export const FormCheckList = (props) => {
  const { list } = props;
  const getChecks = () => {
    let check = [];
    for (let option in list) {
      check.push(<FormCheck label={option} checked={list[option]} onChange={() => handleChange(option)} />);
    }
    return check;
  };
  const handleChange = (option) => {
    props.onChange({ ...list, [option]: !list[option] });
  };
  return (
    <CheckListContainer>
      <Label>{props.label}</Label>
      <CheckList>{getChecks()}</CheckList>
    </CheckListContainer>
  );
};
const ClipboardField = styled.div`
  flex: 1;
  width: 0rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CopyIcon = styled(FileCopyIcon)`
  position: relative;
  /* left: 8px;
  margin-right: 0.5rem !important; */
  /* border: solid silver 1px;
  padding: 0.3rem;
  border-radius: 100%; */
  /* :hover {
    cursor: pointer;
  } */
`;
const ClipboardContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  flex: 1;
`;
export const CopyToClipboard = (props) => {
  return (
    <ClipboardContainer>
      <ClipboardField variant="outlined" className={props.className}>
        {props.children}
      </ClipboardField>
      <IconButton>
        <CopyIcon
          onClick={() => {
            navigator.clipboard.writeText(props.children);
          }}
        />
      </IconButton>
    </ClipboardContainer>
  );
};
