import { Chip, Grid, Paper, Typography } from "@mui/material";
import React, { Component } from "react";
import styled from "styled-components";
import { ScrollBarStyle } from "../../../AppStyles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { connect } from "react-redux";
const RowContainer = styled(Grid)`
  overflow-y: auto;
  ${ScrollBarStyle}
  height: 200px;
`;
const Container = styled(Paper)`
  /* padding: 1rem; */
  border-radius: 14px !important;
  overflow: hidden;
  height: 45vh;
`;
const StatusChip = styled(Chip)`
  padding: 8px;
  background: ${({ active }) => (active ? "#e6faf5" : "#FCE8E8")};
  max-width: fit-content;
  color: ${({ active }) => (active ? "#05cd99" : "#E31B1B")} !important;
  background-color: ${({ active }) =>
    active ? "#e6faf5" : "#FCE8E8"} !important;
`;
const DataRow = ({ data }) => (
  <Grid item container style={{ padding: "18px 30px" }} alignItems="center">
    <Grid xs item>
      <Typography fontWeight={500} fontSize="16px">
        {data.clusterName}
      </Typography>
    </Grid>
    <Grid xs item>
      <Typography fontWeight={500} fontSize="16px">
        {data?.location?.region || "Not Available"}
      </Typography>
    </Grid>
    <Grid xs item container justifyContent="center">
      <StatusChip
        active={data.status === "CLUSTER_STATUS_ACTIVE"}
        icon={
          <FiberManualRecordIcon
            style={{
              color:
                data.status === "CLUSTER_STATUS_ACTIVE" ? "#05cd99" : "#E31B1B",
              fontSize: "10px",
            }}
          />
        }
        label={data.status === "CLUSTER_STATUS_ACTIVE" ? "Active" : "Inactive"}
      />
    </Grid>
  </Grid>
);
const FallbackMsg = () => (
  <Grid
    item
    xs
    container
    style={{ padding: "0.5rem 1rem" }}
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h6">No Cluster Data Available</Typography>
  </Grid>
);
class ClusterDetails extends Component {
  render() {
    const { clusterDetail } = this.props;
    return (
      <Container id="cluster-details-table">
        <Grid
          container
          direction="column"
          // spacing={2}
          style={{ height: "100%" }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: "30px 1rem 1rem 30px" }}
          >
            <Grid item xs>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                Cluster Details
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ background: "#E1EBFF", padding: "18px 30px" }}
          >
            <Grid xs item >
              <Typography fontWeight={500} fontSize="16px">
                Cluster Name
              </Typography>
            </Grid>
            <Grid xs item >
              <Typography fontWeight={500} fontSize="16px">
                Location
              </Typography>
            </Grid>
            <Grid xs item container justifyContent="center">
              <Typography fontWeight={500} fontSize="16px">
                Mesh Status
              </Typography>
            </Grid>
          </Grid>
          <RowContainer
            item
            xs
            container
            direction="column"
            wrap="nowrap"
            style={{ rowGap: "0.2rem" }}
          >
            {clusterDetail && clusterDetail.length > 0 ? (
              clusterDetail.map((cluster) => <DataRow data={cluster} />)
            ) : (
              <FallbackMsg />
            )}
          </RowContainer>
        </Grid>
      </Container>
    );
  }
}
const mapStateToProps = ({ dashboard: { clusterDetail } }) => ({
  clusterDetail,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterDetails);
