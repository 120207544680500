// import React from "react";
// import { Terminal } from "xterm";
// import { FitAddon } from "xterm-addon-fit";
// import ResizeObserver from "react-resize-observer";
// import "./xterm.css";
// // import "./App.css";
// // import c from "ansi-colors";

// let term;
// const fitAddon = new FitAddon();

// export default class Console extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       logs: ""
//     };
//   }

//   componentDidMount() {
//     term = new Terminal({
//       convertEol: true,
//       fontFamily: `'Fira Mono', monospace`,
//       fontSize: 15,
//       fontWeight: 900
//       // rendererType: "dom" // default is canvas
//     });

//     //Styling
//     term.setOption("theme", {
//       background: "black",
//       foreground: "white"
//     });

//     term.setOption('cursorBlink', true)

//     // Load Fit Addon
//     term.loadAddon(fitAddon);

//     // Open the terminal in #terminal-container
//     term.open(document.getElementById("xterm"));

//     //Write text inside the terminal
//     // term.write(c.magenta("I am ") + c.blue("Blue") + c.red(" and i like it"));

//     // Make the terminal's size and geometry fit the size of #terminal-container
//     fitAddon.fit();

//     term.onKey((key) => {
//       const char = key.domEvent.key;
//       if (char === "Enter") {
//         this.prompt();
//       } else if (char === "Backspace") {
//         term.write("\b \b");
//       } else {
//         term.write(char);
//       }
//     });

//     this.prompt();
//   }

//   prompt = () => {
//     var shellprompt = "user@guest ~ $ ";
//     term.write("\r\n" + shellprompt);
//   };

//   render() {
//     return (
//       <div className="App" style={{ width: "100%", bottom:0 }}>
//         {/* <h1>Xterm.js</h1> */}
//         <div
//           // width={350}
//           // height={350}
//           style={{
//             height: "400px"
//             // width: "100%"
//             // background: "firebrick",
//             // padding: "0.4em",
//             // margin: "1em"
//           }}
//         >
//           <div id="xterm" style={{ height: "100%", idth: "100%" }} />
//           <ResizeObserver
//             onResize={(rect) => {
//               // fitAddon.fit();
//               console.log("Resized. New bounds:", rect.width, "x", rect.height);
//             }}
//             onPosition={(rect) => {
//               console.log("Moved. New position:", rect.left, "x", rect.top);
//             }}
//           />
//         </div>
//       </div>
//     );
//   }
// }

import React from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { AttachAddon } from 'xterm-addon-attach';
import ResizeObserver from "react-resize-observer";
import "./xterm.css";
// import "./App.css";
// import c from "ansi-colors";

let term;
let protocol;
let socketURL;
let socket;
let pid;
let terminalContainer;
const fitAddon = new FitAddon();

function runRealTerminal(){
    console.log("real terminal")
  // addons.attach.instance = new AttachAddon(socket);
  // term.loadAddon(addons.attach.instance);
   term._initialized = true;

//     import { Terminal } from 'xterm';


// const term = new Terminal();
// const socket = new WebSocket('wss://docker.example.com/containers/mycontainerid/attach/ws');
      const attachAddon = new AttachAddon(socket);

      // Attach the socket to term
      term.loadAddon(attachAddon);
  // initAddons(term);
}

function runFakeTerminal(){
  console.log("unreal terminal")
  if (term._initialized) {
    return;
  }
  term.loadAddon(fitAddon);
  fitAddon.fit();
  term._initialized = true;
  // initAddons(term);

  term.prompt = () => {
    term.write('\r\n$ ');
  };

  term.writeln('Welcome to Avesha terminal');
  term.writeln('Connection to server failed');
  term.writeln('Try refresh again');
  term.writeln('');
  term.prompt();

  term.onKey((e) => {
    const ev = e.domEvent;
    const printable = !ev.altKey && !ev.ctrlKey && !ev.metaKey;

    if (ev.keyCode === 13) {
      term.prompt();
    } else if (ev.keyCode === 8) {
     // Do not delete the prompt
      if (term._core.buffer.x > 2) {
        term.write('\b \b');
      }
    } else if (printable) {
      term.write(e.key);
    }
  });
}


function createTerminal(){
    // Clean terminal
    while (terminalContainer.children.length) {
      terminalContainer.removeChild(terminalContainer.children[0]);
    }
  
    const isWindows = ['Windows', 'Win16', 'Win32', 'WinCE'].indexOf(navigator.platform) >= 0;
    term = new Terminal({
      windowsMode: isWindows,
      fontFamily: 'Fira Code, courier-new, courier, monospace'
    });
  
    window.term = term;  // Expose `term` to window for debugging purposes
    term.onResize((size) => {
      if (!pid) {
        return;
      }
      const cols = size.cols;
      const rows = size.rows;
      const url = '/terminals/' + pid + '/size?cols=' + cols + '&rows=' + rows;
  
      fetch(url, {method: 'POST'});
    });
    protocol = (window.location.protocol === 'https:') ? 'wss://' : 'ws://';
    socketURL = protocol + window.location.hostname + ((window.location.port) ? (':' + window.location.port) : '') + '/terminals/';
    let dummySocketUrl = protocol + '0.0.0.0:3002/terminals/'
    // console.log(socketURL)
    // console.log(dummySocketUrl)
    term.open(terminalContainer);
    // addons.fit.instance.fit();
    term.focus();
  
    // fit is called within a setTimeout, cols and rows need this.
    setTimeout(() => {

      fetch('http://0.0.0.0:3002/terminals?cols=' + term.cols + '&rows=' + term.rows, {method: 'POST'})
          .then((res) => {
            
            return res.text().then((processId) => {
              pid = processId;
              dummySocketUrl += processId;
              console.log('Socket url',dummySocketUrl)
              socket = new WebSocket(dummySocketUrl);
              socket.onopen = runRealTerminal;
              socket.onclose = runFakeTerminal;
              socket.onerror = runFakeTerminal;
            });
          })
          .catch((error)=>{
            runFakeTerminal()
            console.log('Post call failed')
          })
        }, 0);
  }

export default class Console extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: ""
    };
  }

  componentDidMount() {
    terminalContainer = document.getElementById('xterm');
    createTerminal();

//     const term = new Terminal();
// const socket = new WebSocket('wss://docker.example.com/containers/mycontainerid/attach/ws');
// const attachAddon = new AttachAddon(socket);

// // Attach the socket to term
// term.loadAddon(attachAddon);

    //Styling
    // term.setOption("theme", {
    //   background: "black",
    //   foreground: "white"
    // });

    // term.setOption('cursorBlink', true)

    // // Load Fit Addon
    // term.loadAddon(fitAddon);

    // Open the terminal in #terminal-container
   // const  terminalContainer = document.getElementById('xterm');
   // term.open(terminalContainer);

    //Write text inside the terminal
    // term.write(c.magenta("I am ") + c.blue("Blue") + c.red(" and i like it"));

    // Make the terminal's size and geometry fit the size of #terminal-container
    // fitAddon.fit();

    // term.onKey((key) => {
    //   const char = key.domEvent.key;
    //   if (char === "Enter") {
    //     this.prompt();
    //   } else if (char === "Backspace") {
    //     term.write("\b \b");
    //   } else {
    //     term.write(char);
    //   }
    // });

    // this.prompt();
  }

  prompt = () => {
    var shellprompt = "user@guest ~ $ ";
    term.write("\r\n" + shellprompt);
  };

  render() {
    return (
      <div className="App" style={{ width: "100%", bottom:0 }}>
        {/* <h1>Xterm.js</h1> */}
        <div
          // width={350}
          // height={350}
          style={{
            height: "400px"
            // width: "100%"
            // background: "firebrick",
            // padding: "0.4em",
            // margin: "1em"
          }}
        >
          <div id="xterm" style={{ height: "100%", idth: "100%" }} />
          <ResizeObserver
            onResize={(rect) => {
              // fitAddon.fit();
              // console.log("Resized. New bounds:", rect.width, "x", rect.height);
            }}
            onPosition={(rect) => {
              // console.log("Moved. New position:", rect.left, "x", rect.top);
            }}
          />
        </div>
      </div>
    );
  }
}

