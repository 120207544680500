import React, { Component } from "react";
import PopUp from "../../Commons/popUtils";
import UserForm from "./UserForm";
import TableCard from "../TableCard";
import People from "@material-ui/icons/People";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { getAllUsers } from "../../../actions/RolesActions";
class Users extends Component {
  constructor() {
    super();
    this.state = {
      showForm: false,
      
      config: {
        columns: [
          {
            id: "user_name",
            name: "User Name",
            icon: true,
            iconPath: (
              //todo: refactor to img source
              <People
                color="primary"
                style={{ marginRight: "10px", alignSelf: "center" }}
              />
            ),
          },
          {
            id: "email",
            name: "Email",
            icon: false,
          },
          {
            id: "status",
            name: "Status",
            icon: false,
          },
          {
            id: "invite_status",
            name: "Invite",
            icon: true,
            iconPath: (
              //todo: refactor to img source
              <Button variant="contained">Send</Button>
            ),
          },
        ],
      },
    };
  }
  componentDidMount() {
    this.props.getAllUsers();
  }
  render() {
    const { showForm, config } = this.state;
    const { heading,users } = this.props;

    return (
      <>
        <PopUp open={showForm}>
          <UserForm cancelListener={() => this.setState({ showForm: false })} />
        </PopUp>
        <TableCard
          addListener={() => this.setState({ showForm: !showForm })}
          heading={heading || "Users"}
          config={config}
          data={users}
          //editable={true}
          //deleteEnable={true}
        />
      </>
    );
  }
}
const mapStateToProps=(store)=>{
  return{
    users:store.roles.users,
  }
}
export default connect(mapStateToProps,{getAllUsers})(Users)