import styled from "styled-components";
import MuiDialog from "@material-ui/core/Dialog";
import { FormField as MuiFormField } from "../../Commons/formUtils";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Dialog = styled(MuiDialog)`
  .MuiDialog-paperFullWidth {
    overflow: auto;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #e3e3e3;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: grey;
    }
  }
`;

export const FormField = styled(MuiFormField)`
  && {
    align-self: flex-start;
  }
`;