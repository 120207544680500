import React from "react";
import { BodyRoot } from "../Body/BodyStyles";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import { FormSelect } from "../Commons/formUtils";
import Paper from "@material-ui/core/Paper";

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-left: 1rem;
  margin-top: 1rem;
`;
const Box = styled(Paper)`
  margin: 1rem;
  /* border: solid black 1px; */
`;
const BoxHeader = styled.div`
  background-color: silver;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid black; */
  padding: 0.5rem;
`;
const BoxTitle = styled.div`
  font-weight: 500;
`;
const SwitchContainer = styled.div``;
const BoxContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
`;
const Field = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex: 1;
`;
const FieldHeader = styled.div``;
const FieldValue = styled.div`
  font-weight: 600;
`;
const EnvRow = styled.div`
  display: flex;
  border: solid 1px;
  flex: 1;
  margin-bottom: ${(props) => (props.last ? "" : "1rem")};
`;
const EnvBanner = styled.div`
  border-right: solid black 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
const EnvRowContent = styled.div`
  flex: 1;
`;
const TableRow = styled.div`
  display: flex;
  border-bottom: ${(props) => (props.last ? "" : "solid black 1px")};
`;
const ColumnHeader = styled.div`
  flex: 1;
  font-weight: 500;
  border-right: ${(props) => (props.last ? "" : "solid black 1px")};
  padding: 0.4rem;
`;
const ColumnValues = styled.div`
  flex: 1;
  padding: 0.4rem;
`;
export default function ServiceRouteDetails() {
  return (
    <BodyRoot>
      <Title>Service Name</Title>
      <Box>
        <BoxHeader>
          <BoxTitle>Details</BoxTitle>
          <SwitchContainer>
            <label>Proxy</label>
            <Switch checked={true} />
          </SwitchContainer>
        </BoxHeader>
        <BoxContent>
          <div style={{ display: "flex" }}>
            <Field>
              <FieldHeader>Organization</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
          </div>
          <div style={{ display: "flex" }}>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
            <Field>
              <FieldHeader>Application Key</FieldHeader>
              <FieldValue>Some Long Value with no meaning</FieldValue>
            </Field>
          </div>
        </BoxContent>
      </Box>
      <Box>
        <BoxHeader>
          <BoxTitle>Environments</BoxTitle>
        </BoxHeader>
        <BoxContent style={{ flexDirection: "column", flexWrap: "nowrap" }}>
          <EnvRow>
            <EnvBanner>Endpoints</EnvBanner>
            <EnvRowContent>
              <TableRow>
                <ColumnHeader>POD Name</ColumnHeader>
                <ColumnHeader>Cluster ID</ColumnHeader>
                <ColumnHeader>IP Addr</ColumnHeader>
                <ColumnHeader last>URL</ColumnHeader>
              </TableRow>
              <TableRow>
                <ColumnValues>POD Name</ColumnValues>
                <ColumnValues>Cluster ID</ColumnValues>
                <ColumnValues>IP Addr</ColumnValues>
                <ColumnValues>URL</ColumnValues>
              </TableRow>
              <TableRow>
                <ColumnValues>POD Name</ColumnValues>
                <ColumnValues>Cluster ID</ColumnValues>
                <ColumnValues>IP Addr</ColumnValues>
                <ColumnValues>URL</ColumnValues>
              </TableRow>
              <TableRow last>
                <ColumnValues>POD Name</ColumnValues>
                <ColumnValues>Cluster ID</ColumnValues>
                <ColumnValues>IP Addr</ColumnValues>
                <ColumnValues>URL</ColumnValues>
              </TableRow>
            </EnvRowContent>
          </EnvRow>
          <EnvRow last>
            <EnvBanner>Ports</EnvBanner>
            <EnvRowContent>
              <TableRow>
                <ColumnHeader>Name</ColumnHeader>
                <ColumnHeader>Protocol</ColumnHeader>
                <ColumnHeader last>Port</ColumnHeader>
              </TableRow>
              <TableRow>
                <ColumnValues>Name</ColumnValues>
                <ColumnValues>Protocol</ColumnValues>
                <ColumnValues>Port</ColumnValues>
              </TableRow>
              <TableRow>
                <ColumnValues>Name</ColumnValues>
                <ColumnValues>Protocol</ColumnValues>
                <ColumnValues>Port</ColumnValues>
              </TableRow>
              <TableRow last>
                <ColumnValues>Name</ColumnValues>
                <ColumnValues>Protocol</ColumnValues>
                <ColumnValues>Port</ColumnValues>
              </TableRow>
            </EnvRowContent>
          </EnvRow>
        </BoxContent>
      </Box>
      <Box>
        <BoxHeader>
          <BoxTitle>Properties</BoxTitle>
        </BoxHeader>
        <BoxContent style={{ flexDirection: "column" }}>
          <FormSelect width="95%" label="LB Policy" options={{ key1: "value1", key2: "value2", key3: "value3" }} />
          <FormSelect width="95%" label="SLA" options={{ key1: "value1", key2: "value2", key3: "value3" }} />
          <FormSelect width="95%" label="Downstream Dependencies" options={{ key1: "value1", key2: "value2", key3: "value3" }} />
          <FormSelect width="95%" label="Upstream Dependencies" options={{ key1: "value1", key2: "value2", key3: "value3" }} />
        </BoxContent>
      </Box>
    </BodyRoot>
  );
}
