import React, { Component } from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Tables from "../Commons/Tables";
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: whitesmoke;
  /*border-bottom: solid black 1px;*/
`;
const Card = styled(Paper)`
  margin: 2rem;
  width: 40%;
  height: 25rem;
  overflow-y: auto;
`;
export default class TableCard extends Component {
  render() {
    const { heading, addListener, config, data } = this.props;
    //let data=1;
    return (
      <Card elevation={3}>
        <Header>
          <div>{heading}</div>
          <img
            src={require("../../assets/icons/add.svg")}
            onClick={addListener}
          />
        </Header>
        {data?.length > 0 ? (
          <Tables config={config} data={data} {...this.props} />
        ) : (
          <div style={{ margin: "10px" }}>No {heading} Available</div>
        )}
      </Card>
    );
  }
}
