import { SHOW_USER_DETAILS, HIDE_USER_DETAILS, ACTION_LOGOUT_INITIATED } from "../actions/types";

const initialState = {
  showDetails: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_USER_DETAILS:
      return {
        ...state,
        showDetails: true,
      };
    case HIDE_USER_DETAILS:
      return {
        ...state,
        showDetails: false,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
