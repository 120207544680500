import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { usePrevious } from "../../Commons/hooksUtils";
import { FormContainer } from "../FormStyles";
import { connect } from "react-redux";
import {
  createOperatorGroup,
  clearSelectedOperatorGroup,
  editOperatorGroup,
} from "../../../actions/OperatorGroupActions";
import PolicyList from "./PolicyList";
import { InputContainer, Dialog, FormField } from "./AddOperatorStyles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddGroupDialog(props) {
  //creating css classes from jss
  const classes = useStyles();
  const [operatorGroupName, setOperatorGroupName] = useState("");
  const [policies, setPolicies] = useState([]);
  const [policyNames, setPolicyNames] = useState([]);
  const [errorOperatorGroupNameMes, setErrorOperatorGroupNameMes] =
    useState("");
  const [errorPoliciesMes, setErrorPoliciesMes] = useState("");
  const prevOpen = usePrevious(props.open);
  const prevPolicies = usePrevious(policies);

  useEffect(() => {
    if (!prevOpen && props.open) {
      setErrorOperatorGroupNameMes("");
      setErrorPoliciesMes("");
      handlePolicyData();
    }
    if (prevPolicies != policies || prevPolicies?.length != policies?.length) {
      setPolicyNames(
        filterNames(props.policies, policies, "policyId", "policyName")
      );
    }
  });

  const clearData = () => {
    setOperatorGroupName("");
    setPolicies([]);
    props.clearSelectedOperatorGroup();
  };

  const addToPolicy = async (index) => {
    const policy = await props.policies.find(
      (item) => item.policyName == index
    );
    if (policies.indexOf(policy.policyId) == -1)
      setPolicies([...policies, policy.policyId]);

    if (errorPoliciesMes) setErrorPoliciesMes("");
  };
  const deletePolicy = (index) => {
    setPolicies([...policies.splice(0, index), ...policies.splice(index + 1)]);
  };

  const handleSave = () => {
    if (!operatorGroupName.trim()) {
      setErrorOperatorGroupNameMes("Operator Group Name is required");
      return;
    }
    if (!policies.length) {
      setErrorPoliciesMes("Policy Name is required");
      return;
    }

    const obj = props.data.find(
      (value) => value.operatorGroupName === operatorGroupName.trim()
    );
    if (!!obj) {
      setErrorOperatorGroupNameMes("Name already exits");
      return;
    }

    const { selectedOperatorGroup } = props;
    if (selectedOperatorGroup?.operatorGroupId) {
      let value = {
        operatorGroupName,
        policyIds: policies,
      };
      props.editOperatorGroup(selectedOperatorGroup.operatorGroupId, value);
    } else {
      let value = {
        groupName: operatorGroupName,
        policyIds: policies,
      };
      props.createOperatorGroup(value);
    }
    props.handleClose();
    clearData();
  };

  const handlePolicyData = () => {
    const { selectedOperatorGroup } = props;
    if (selectedOperatorGroup) {
      setOperatorGroupName(selectedOperatorGroup.operatorGroupName);
      setPolicies(selectedOperatorGroup.policyIds);
    }
  };

  const getPolicyNames = () => {
    return props.policies.map((policy) => policy.policyName);
  };

  const filterNames = (list, ids, idKey, nameKey) => {
    let names = [];
    ids.forEach((id) => {
      for (let i = 0; i < list.length; i++) {
        if (list[i][idKey] == id) {
          names.push(list[i][nameKey]);
          break;
        }
      }
    });
    return names;
  };
  const handleChangeName = (e) => {
    setOperatorGroupName(e.nativeEvent.target.value);
    if (errorOperatorGroupNameMes) {
      setErrorOperatorGroupNameMes("");
    }
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage:
              "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                props.handleClose();
                props.clearSelectedOperatorGroup();
                clearData();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.selectedOperatorGroup?.operatorGroupId ? "Edit" : "New"}{" "}
              Operator Group
            </Typography>

            <Button
              variant="contained"
              autoFocus
              color="secondary"
              onClick={handleSave}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <div
            style={{
              boxSizing: "border-box",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <h3>
                {props.selectedOperatorGroup?.operatorGroupId
                  ? "Edit"
                  : "Create a New"}{" "}
                Operator Group
              </h3>
              <h4>General Details</h4>
              <InputContainer>
                <FormField
                  error={errorOperatorGroupNameMes}
                  helperText={errorOperatorGroupNameMes}
                  label="Operator Group Name"
                  value={operatorGroupName}
                  onChange={handleChangeName}
                />
                <PolicyList
                  options={getPolicyNames()}
                  error={errorPoliciesMes && !policies.length}
                  helperText={!policies.length && errorPoliciesMes}
                  label="Policy"
                  list={policyNames}
                  addToList={addToPolicy}
                  deleteItem={deletePolicy}
                />
              </InputContainer>
            </FormContainer>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({
  OperatorGroupReducer: { selectedOperatorGroup },
  PolicyReducer: { policies },
}) => ({
  selectedOperatorGroup,
  policies,
});

export default connect(mapStateToProps, {
  createOperatorGroup,
  editOperatorGroup,
  clearSelectedOperatorGroup,
})(AddGroupDialog);
