import { SET_OPERATORS, SELECT_OPERATOR, CLEAR_SELECTED_OPERATOR, ACTION_LOGOUT_INITIATED } from "./../actions/types";
const initialState = {
  operators: [],
  selectedOperator: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPERATORS:
      return { ...state, ...payload };
    // case SET_ACTIONS:
    //   return { ...state, actions: { ...payload } };
    case SELECT_OPERATOR:
      return { ...state, selectedOperator: { ...payload } };
    case CLEAR_SELECTED_OPERATOR:
      return { ...state, selectedOperator: null };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
};
