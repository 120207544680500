import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import GridCellExpand from "./GridCellExpand";

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

const EventTableGrid = ({
  tableHeight,
  onClickHandle,
  systemEvents,
  selectedId,
  setSelectecId,
  setSlide,
}) => {
  const columns = [
    // {
    //   field: "id",
    //   headerName: "Event ID",
    //   flex: 1,
    // },
    {
      field: "severity",
      headerName: "Severity",
      flex: 0.5,
      renderCell: (params) => handleSeverity(params.value),
      editable: false,
    },
    {
      field: "creationDate",
      headerName: "Creation date",
      flex: 1,
      renderCell: (params) => moment(params.value).format("lll"),
      sortable: true,
    },
    {
      field: "source",
      headerName: "Cluster",
      flex: 1,
      editable: false,
    },
    {
      field: "entityId",
      headerName: "Slice",
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => handleStatus(params.value),
    },

    {
      field: "description",
      headerName: "Description",
      // width:150,
      flex: 1.5,
      renderCell: renderCellExpand
     // renderCell: (params) => handleDescription(params),
    },
  ];

  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (systemEvents && systemEvents.length > 0) {
      let data = systemEvents.map((event) => {
        return {
          id: event.eventId,
          entityId: event.entityId,
          severity: event.severity,
          source: event.source,
          status: event.status,
          creationDate: event.createdAtTime, //moment(event.createdAtTime).format("lll"),
          description: event.description,
        };
      });
      if (data && data.length > 0) {
        const sortedData = data.sort(
          (a, b) =>
            moment(b.creationDate).valueOf() -
            new moment(a.creationDate).valueOf()
        );
        setRows(sortedData);
      }
    }
  }, [systemEvents]);

  const handleSeverity = (severity) => {
    switch (severity) {
      case "SEVERITY_INVALID":
        return <Chip label="Invalid" color="default" />;
      case "SEVERITY_INFO":
        return <Chip label="Info" color="info" />;
      case "SEVERITY_WARNING":
        return <Chip label="Warning" color="secondary" />;
      case "SEVERITY_CRITICAL":
        return <Chip label="Critical" color="error" />;
      case "SEVERITY_ERROR":
        return <Chip label="Error" color="warning" />;
      default:
        return "";
    }
  };
  const handleDescription = (desc) => (
    <Typography whiteSpace="initial">{desc}</Typography>
  );
  const handleStatus = (status) => {
    switch (status) {
      case "STATUS_INVALID":
        return <Chip label="Invalid" color="default" />;
      case "STATUS_OPEN":
        return <Chip label="Open" color="secondary" />;
      case "STATUS_IN_PROGRESS":
        return <Chip label="In Progress" color="info" />;
      case "STATUS_RESOLVED":
        return <Chip label="Resolved" color="success" />;
      case "STATUS_DISCARDED":
        return <Chip label="Discarded" color="warning" />;
      case "STATUS_IGNORED":
        return <Chip label="Ignored" color="error" />;
      default:
        return "";
    }
  };

  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      {/* <div style={{ height:'100%', display: 'flex', width: '100%' }}>
    <div style={{ flexGrow: 1 }}> */}
      <DataGrid
        sx={{ maxHeight: tableHeight }}
        columns={columns}
        rows={rows}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        rowHeight= {80}
        density="compact"
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          // Toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No events available
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No events available
            </Stack>
          ),
        }}
      />
      {/* </div> */}

      {/* // </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    systemEvents: state.dashboard.systemEvents,
  };
};

export default connect(mapStateToProps)(EventTableGrid);
