import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "@material-ui/core";

export default function PopUp(props) {
  const { open, cancelListener } = props;
  return (
    <Dialog style={{ zIndex: 3000 }} open={open} onClose={cancelListener}>
      {props.children}
    </Dialog>
  );
}

export function Confirmation(props) {
  return (
    <PopUp open={props.open} onClose={props.onCancel}>
      <div style={{ minHeight: "30%",width:"fit-content", overflow: "hidden" }}>
        <div style={{ borderBottom: "solid #f1f1f1 1px", padding: "1rem" }}>
          <div style={{ fontWeight: "bold" }}>{props.title || "Confirm"}</div>
        </div>
        <div style={{ borderBottom: "solid #f1f1f1 1px", padding: "2rem" }}>
          <div>{props.message || "Are you sure you want to proceed?"}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem",
            overflow: "hidden",
          }}
        >
          <Button
            onClick={props.onConfirm}
            variant="contained"
            style={{
              backgroundColor: "Green",
              color: "white",
              marginRight: "0.5rem",
            }}
          >
            {props.confirmLabel || "Yes"}
          </Button>
          <Button
            onClick={props.onCancel}
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "white",
            }}
          >
            {props.cancelLabel || "Cancel"}
          </Button>
        </div>
      </div>
    </PopUp>
  );
}
