import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import { usePrevious } from "../../Commons/hooksUtils";
import { getRandomColor } from "../../../utils/functions";
import {
  addTrainingEvents,
  clearSelectedTrainingEvent,
  getTrainingEvents,
  selectTrainingEvent,
} from "../../../actions/trainingEventsActions";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Divider } from "@material-ui/core";
import { SectionDivider } from "./ListStyles";
import { FilterButton as FilterIcon } from "./../../Commons/IconButtons";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LineFilter from "./LineFilter";
const ActionBar = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
`;
const GraphContainer = styled.div`
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
`;
const DateTimePicker = styled(TextField)`
  margin: 1rem;
  margin-left: 0px;
`;
const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const FilterButton = styled(Button)`
  height: max-content;
  width: max-content;
  margin-left: auto;
  margin-right: 1rem;
`;
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomToolTip = ({ payload, label, active, xKey }) => {
  if (active) {
    return (
      <Paper
        className="custom-tooltip"
        style={{ backgroundColor: "white", padding: "1rem" }}
      >
        <p className="label">
          {payload?.length
            ? payload?.[0]?.payload[xKey == "time" ? "step" : "time"]
            : ""}
        </p>
        {payload?.length &&
          payload.map((item) => (
            <p
              className="intro"
              style={{ color: item.color }}
            >{`${item.dataKey}:${item.value}`}</p>
          ))}
      </Paper>
    );
  }

  return null;
};

const Graph = ({
  data,
  lines,
  domain,
  fallbackMsg,
  show,
  title,
  xLabel = "Time",
  yLabel,
  xKey = "time",
}) => {
  //   ;
  const [showFilter, setShowFilter] = useState(false);
  const [_lines, setLines] = useState([]);
  useEffect(() => {
    setLines([...lines]);
  }, [lines]);
  const handleLineFilter = (newLines) => {
    setLines(newLines);
    setShowFilter(false);
  };
  if (!show) {
    return null;
  }
  if (!data?.length) {
    return (
      <div style={{ textAlign: "center", margin: "50px", color: "gray" }}>
        <h3>{fallbackMsg}</h3>
      </div>
    );
  }
  return (
    <Paper style={{ marginBottom: "1rem", padding: "1rem" }}>
      <LineFilter
        open={showFilter}
        handleClose={() => setShowFilter(false)}
        handleSave={handleLineFilter}
        lines={lines}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>{title}</h3>
        <FilterIcon
          style={{ color: "black", marginRight: 20 }}
          onClick={() => setShowFilter(true)}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 5, bottom: 20, left: 5 }}
        >
          {_lines.map(({ dataKey, stroke }) => (
            <Line type="monotone" dataKey={dataKey} stroke={stroke} />
          ))}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey={xKey}
            label={{ value: xLabel, position: "insideBottom" }}
            height={50}
          />
          <YAxis
            type="number"
            domain={domain}
            label={{
              value: yLabel,
              angle: -90,
              offset: 10,
              position: "insideLeft",
            }}
          />
          <Brush dataKey={xKey} data={data} />
          <Tooltip
            active
            content={(props) => <CustomToolTip {...props} xKey={xKey} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

function TrainingEventGraphs({
  events,
  selectTrainingEvent,
  openDetailsDialog,
  enforcementCanonicalName,
  sliceName,
  open,
  paginationResponse,
  ...props
}) {
  //creating css classes from jss
  const classes = useStyles();
  const handleClose = () => {};

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (prevOpen === undefined || prevOpen === false) {
      handleClose();
    }
  });
  const [lines, setLines] = useState([]);
  const [data, setData] = useState([]);
  const [batchSize, setBatchSize] = useState(50);
  // const [inclAllStatus, setInclAllStatus] = useState(true);
  const [stateData, setStateData] = useState([]);
  const [stateLines, setStateLines] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [rewardLines, setRewardLines] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [actionLines, setActionLines] = useState([]);
  const [qosData, setQosData] = useState([]);
  const [qosLines, setQosLines] = useState([]);
  const [showWeightGraph, setShowWeightGraph] = useState(true);
  const [showStateGraph, setShowStateGraph] = useState(false);
  const [showActionGraph, setShowActionGraph] = useState(false);
  const [showRewardGraph, setShowRewardGraph] = useState(false);
  const [showQosGraph, setShowQosGraph] = useState(false);
  const [xAxisKey, setXAxisKey] = useState("step");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startStep, setStartStep] = useState(null);
  const [endStep, setEndStep] = useState(null);
  useEffect(() => {
    if (open) getTrainingEventsData();
    // getTrainingEventsData(sliceName,enforcementCanonicalName,batchSize,inclAllStatus)
    // props.getTrainingEvents(sliceName, enforcementCanonicalName, {
    //   batchSize,
    //   includeAllStatuses: inclAllStatus,
    // });
  }, [open]);
  // useEffect(() => {
  //   setDa

  // }, [input])
  const getTrainingEventsData = () => {
    let params = {
      batchSize,
      includeAllStatuses: false,
      reverse: true,
    };
    if (startTime && endTime) {
      params = {
        ...params,
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
      };
      // params = { ...params, startTime: moment(new Date(startTime)).unix(), endTime:  moment(new Date(endTime)).unix() }
    } else if (startStep && endStep) {
      params = { ...params, startStep, endStep };
    }
    props.getTrainingEvents(sliceName, enforcementCanonicalName, params);
  };
  const addTrainingEventsData = () => {
    let params = {
      batchSize,
      includeAllStatuses: false,
      reverse: true,
    };
    if (startTime && endTime) {
      params = {
        ...params,
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
      };
      // params = { ...params, startTime: moment(new Date(startTime)).unix(), endTime:  moment(new Date(endTime)).unix() }
    } else if (startStep && endStep) {
      params = { ...params, startStep, endStep };
    }
    props.addTrainingEvents(sliceName, enforcementCanonicalName, params);
  };
  useEffect(() => {
    setLines(getLines());
    setData(getData());
    setStateData(getStateData());
    setStateLines(getStateLines());
    setRewardData(getRewardData());
    setRewardLines(getRewardLines());
    setActionData(getActionData());
    setActionLines(getActionLines());
    setQosData(getQosData())
    setQosLines(getQosLines())
  }, [events]);
  //   useEffect(() => getDialogDimensions(), [data]);
  const getLines = () => {
    let lines = {};
    if (events?.length)
      events.forEach((event) => {
        Object.keys(event?.weights).forEach(
          (host) => (lines[host] = { dataKey: host, stroke: getRandomColor() })
        );
        return;
      });
    return Object.values(lines);
  };
  const getData = () => {
    let data = [];
    data = events?.filter((event) => Object.keys(event.weights).length);
    data = data?.map((event) => ({
      ...event.weights,
      step: event.currentStep,
      time: moment(event.createdAtTime).format("HH:mm Do"),
    }));
    data.sort((item1, item2) => (item1.step < item2.step ? -1 : 1));
    return data;
  };
  const getRewardLines = () => {
    let lines = {};
    if (events?.length)
      events.forEach((event) => {
        if (event?.result?.reward)
          Object.keys(event.result.reward).forEach(
            (host) =>
              (lines[host] = { dataKey: host, stroke: getRandomColor() })
          );
        return;
      });
    return Object.values(lines);
  };
  const getRewardData = () => {
    let data = [];
    data = events?.filter(
      (event) =>
        event?.result?.reward && Object.keys(event?.result?.reward).length
    );
    data = data?.map((event) => ({
      ...event.result.reward,
      step: event.currentStep,
      time: moment(event.createdAtTime).format("HH:mm Do"),
    }));
    data.sort((item1, item2) => (item1.step < item2.step ? -1 : 1));
    return data;
  };
  const getActionLines = () => {
    let lines = {};
    if (events?.length)
      events.forEach((event) => {
        if (event?.result?.action)
          Object.keys(event.result.action).forEach(
            (host) =>
              (lines[host] = { dataKey: host, stroke: getRandomColor() })
          );
        return;
      });
    return Object.values(lines);
  };
  const getActionData = () => {
    let data = [];
    data = events?.filter(
      (event) =>
        event?.result?.action && Object.keys(event?.result?.action).length
    );
    data = data?.map((event) => ({
      ...event.result.action,
      step: event.currentStep,
      time: moment(event.createdAtTime).format("HH:mm Do"),
    }));
    data.sort((item1, item2) => (item1.step < item2.step ? -1 : 1));
    return data;
  };
  const getStateLines = () => {
    let lines = {};
    if (events?.length)
      events.forEach((event) => {
        if (event?.result?.state)
          Object.keys(event?.result?.state).forEach(
            (host) =>
              (lines[host] = { dataKey: host, stroke: getRandomColor() })
          );
        return;
      });
    return Object.values(lines);
  };
  const getStateData = () => {
    let data = [];
    data = events?.filter(
      (event) => event?.result?.state && Object.keys(event.result.state).length
    );
    data = data?.map((event) => ({
      ...event.result.state,
      step: event.currentStep,
      time: moment(event.createdAtTime).format("HH:mm Do"),
    }));
    data.sort((item1, item2) => (item1.step < item2.step ? -1 : 1));
    return data;
  };
  const getQosLines = () => {
    return [{ dataKey: "qosRatio", stroke: getRandomColor() }];
  };
  const getQosData = () => {
    let data = [];
    data = events?.filter(
      (event) => event?.result?.state && Object.keys(event.result.state).length
    );
    data = data?.map((event) => ({
      qosRatio: event.qosRatio,
      step: event.currentStep,
      time: moment(event.createdAtTime).format("HH:mm Do"),
    }));
    data.sort((item1, item2) => (item1.step < item2.step ? -1 : 1));
    return data;
  };
  const handleReset = () => {
    setBatchSize(50);
    // setInclAllStatus(true);
    setStartTime(null);
    setEndTime(null);
    setStartStep(null);
    setEndStep(null);
    setShowWeightGraph(true);
    setShowStateGraph(false);
    setShowRewardGraph(false);
    setShowActionGraph(false);
    setShowQosGraph(false);
    // getTrainingEventsData()
  };
  const isMoreDataAvailable = () => {
    let paginationParamsCount = 0;
    for (let i in paginationResponse) {
      if (paginationResponse[i] || paginationResponse[i] === 0) {
        paginationParamsCount += 1;
      }
    }
    return paginationParamsCount > 1;
  };
  //   const getDialogDimensions = () => {
  //     const dialogElement = document.getElementsByClassName("MuiPaper-root")[0];
  //     const height = dialogElement?.offsetHeight;
  //     const width = dialogElement?.offsetWidth;
  //     if (height && width) setDimensions({ height, width });
  //   };
  return (
    <div>
      <Dialog
        style={{ zIndex: 4000 }}
        fullScreen
        open={open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          style={{
            backgroundImage: "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
          }}
        >
          <Toolbar style={{ color: "white" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Training Events
            </Typography>
            {/* <Button
              variant="contained"
              autoFocus
              color="secondary"
              onClick={handleSubmit}
            >
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <GraphContainer>
          {/* <h3 style={{ alignSelf: "center", textAlign: "center" }}>Training Events</h3> */}
          <Paper>
            <ActionBar>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                Include all Statuses
                <Checkbox checked={inclAllStatus} onChange={() => setInclAllStatus(!inclAllStatus)} />
              </div> */}
              <div>Batch Size</div>
              <Slider
                defaultValue={50}
                step={25}
                marks
                min={10}
                max={1000}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, val) => setBatchSize(val)}
                style={{ width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show Weights Graph
                    <Checkbox
                      checked={showWeightGraph}
                      onChange={() => setShowWeightGraph(!showWeightGraph)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show State Graph
                    <Checkbox
                      checked={showStateGraph}
                      onChange={() => setShowStateGraph(!showStateGraph)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show Reward Graph
                    <Checkbox
                      checked={showRewardGraph}
                      onChange={() => setShowRewardGraph(!showRewardGraph)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show Action Graph
                    <Checkbox
                      checked={showActionGraph}
                      onChange={() => setShowActionGraph(!showActionGraph)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show QoS Graph
                    <Checkbox
                      checked={showQosGraph}
                      onChange={() => setShowQosGraph(!showQosGraph)}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>STEPS</div>
                  <Switch
                    checked={xAxisKey == "time"}
                    onChange={() =>
                      setXAxisKey(xAxisKey == "time" ? "step" : "time")
                    }
                  />
                  <div>TIME</div>
                </div>
              </div>
              <TimeContainer>
                {xAxisKey == "time" ? (
                  <>
                    <DateTimePicker
                      label="Start Time"
                      type="datetime-local"
                      value={startTime || ""}
                      onChange={(e) => setStartTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <DateTimePicker
                      label="End Time"
                      type="datetime-local"
                      value={endTime || ""}
                      onChange={(e) => setEndTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateTimePicker
                      label="Start Step"
                      value={startStep || ""}
                      onChange={(e) => setStartStep(e.target.value)}
                      InputProps={{
                        // value: startStep,
                        endAdornment: (
                          <InputAdornment position="start">Step</InputAdornment>
                        ),
                      }}
                    />
                    <DateTimePicker
                      label="End Step"
                      value={endStep || ""}
                      onChange={(e) => setEndStep(e.target.value)}
                      InputProps={{
                        // value: endStep,
                        endAdornment: (
                          <InputAdornment position="start">Step</InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
                <FilterButton
                  variant="outlined"
                  onClick={getTrainingEventsData}
                >
                  Filter
                </FilterButton>
                <Button variant="outlined" onClick={handleReset}>
                  Reset
                </Button>
              </TimeContainer>
              <TimeContainer>
                <p style={{ marginRight: "1rem" }}>
                  Number of Events: <b>{events?.length}</b>
                </p>
                {isMoreDataAvailable() && (
                  <Button variant="outlined" onClick={addTrainingEventsData}>
                    Add More
                  </Button>
                )}
              </TimeContainer>
            </ActionBar>
          </Paper>
          <Graph
            title="Weight Graph"
            yLabel="Weight"
            show={showWeightGraph}
            data={data}
            lines={lines}
            domain={[0, 100]}
            fallbackMsg="No Training Events Present."
            xKey={xAxisKey}
            xLabel={xAxisKey == "time" ? "TIME" : "STEPS"}
          />
          <Graph
            title="State Graph"
            show={showStateGraph}
            data={stateData}
            lines={stateLines}
            domain={[0, 2]}
            fallbackMsg="No Training Events Present."
            yLabel="State"
            xKey={xAxisKey}
            xLabel={xAxisKey == "time" ? "TIME" : "STEPS"}
          />
          <Graph
            title="Reward Graph"
            show={showRewardGraph}
            data={rewardData}
            lines={rewardLines}
            domain={[0, 5]}
            fallbackMsg="No Training Events Present."
            yLabel="Reward"
            xKey={xAxisKey}
            xLabel={xAxisKey == "time" ? "TIME" : "STEPS"}
          />
          <Graph
            title="Action Graph"
            show={showActionGraph}
            data={actionData}
            lines={actionLines}
            domain={[0, 5]}
            fallbackMsg="No Training Events Present."
            yLabel="Action"
            xKey={xAxisKey}
            xLabel={xAxisKey == "time" ? "TIME" : "STEPS"}
          />
          <Graph
            title="QoS Ratio"
            show={showQosGraph}
            data={qosData}
            lines={qosLines}
            domain={[0, 1]}
            fallbackMsg="No Training Events Present."
            yLabel="QoS Ratio"
            xKey={xAxisKey}
            xLabel={xAxisKey == "time" ? "TIME" : "STEPS"}
          />
        </GraphContainer>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({
  trainingEventsReducer: { events, paginationResponse },
}) => ({
  events,
  paginationResponse,
});

export default connect(mapStateToProps, {
  getTrainingEvents,
  addTrainingEvents,
  selectTrainingEvent,
  clearSelectedTrainingEvent,
})(TrainingEventGraphs);
