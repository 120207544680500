import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllServiceDependencyRequest } from "../../actions/dashboardActions";
import SankeyChart from "./Sankey";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid, Box } from "@mui/material";
import styled from "styled-components";
import { Autocomplete, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import HorizontalTimeline from "react-horizontal-timeline";
import moment from "moment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const Square = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
const LabelText = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
var today = new Date();

const ServiceLatency = ({ clusterDetail, getAllServiceDependencyRequest }) => {
  const [cluster, setCluster] = useState("");
  const [slice, setSlice] = useState("");
  const [sliceList, setSliceList] = useState([]);
  const [data, setData] = useState({});
  const clusterInfo = clusterDetail;
  const [period, setPeriod] = React.useState(1);
  const [curIdx, setCurIdx] = React.useState(11); // as interval is 12 (60 minutes and 5 step each)
  const [prevIdx, setPrevIdx] = React.useState(-1);
  const [startCalendarTime, setStartCalendarTime] = React.useState("");
  const [endCalendarTime, setEndCalendarTime] = React.useState("");
  const [startTime, setStartTime] = React.useState(
    new Date().getTime() - 60000
  );
  const [endTime, setEndTime] = React.useState(new Date().getTime());
  const [timeline, setTimeline] = React.useState({});
  const periods = [
    {
      index: 1,
      label: "Last hour",
      step: 5,
      total: 12,
      type: "minute",
    },
    {
      index: 2,
      label: "Last day",
      step: 2,
      total: 12,
      type: "hour",
    },
    {
      index: 3,
      label: "Last week",
      step: 1,
      total: 7,
      type: "day",
    },
    {
      index: 4,
      label: "Last month",
      step: 4,
      total: 7,
      type: "day",
    },
    {
      index: 5,
      label: "Custom range",
      step: 0,
      total: 0,
      type: "custom",
    },
  ];

  const generateTimeLine = (currTime, periods) => {
    const result = {};

    periods.forEach((eachPeriod) => {
      const step = eachPeriod.step;
      const total = eachPeriod.total;
      const type = eachPeriod.type;

      const periodList = [];

      let i = 0;
      let offSet = 0;
      while (i < total) {
        offSet = (total - i - 1) * step;

        if (type === "minute") {
          const endDate = new Date().setMinutes(currTime.getMinutes() - offSet);
          const startDate = new Date().setMinutes(
            currTime.getMinutes() - offSet - step
          );
          periodList.push({
            endDate,
            startDate: startDate,
          });
        } else if (type === "hour") {
          const endDate = new Date().setHours(currTime.getHours() - offSet);
          const startDate = new Date().setHours(
            currTime.getHours() - offSet - step
          );
          periodList.push({
            endDate,
            startDate,
          });
        } else {
          const endDate = new Date().setDate(currTime.getDate() - offSet);
          const startDate = new Date().setDate(
            currTime.getDate() - offSet - step
          );
          periodList.push({
            endDate,
            startDate,
          });
        }

        i++;
      }
      result[eachPeriod.index] = periodList;
    });

    return result;
  };

  const handleChange = (event) => {
    if (event.target.value === 5) {
      setPeriod(event.target.value);
    } else {
      setPeriod(event.target.value);
      const currentIndex = timeline[event.target.value].length - 1;
      setCurIdx(currentIndex);
      setEndTime(timeline[event.target.value][currentIndex].endDate);
      setStartTime(timeline[event.target.value][currentIndex].startDate);
      const params = {
        startTime: timeline[event.target.value][currentIndex].startDate,
        endTime: timeline[event.target.value][currentIndex].endDate,
      };
      if (!cluster || !slice) return;
      getSankeyData(cluster, slice, params);
    }
  };

  const clusterChange = (cluster) => {
    setCluster(cluster);
    setSlice("");
    const attachedSlice = cluster?.attachedSlices || [];
    setSliceList(attachedSlice);
  };

  const sliceChange = (slice) => {
    setSlice(slice);
    const params = {
      startTime,
      endTime,
    };
    if (!cluster || !slice) return;
    getSankeyData(cluster.clusterId, slice, params);
  };

  const handleRefresh = (event) => {
    event.preventDefault();
    const params = {
      startTime,
      endTime,
    };
    if (!cluster || !slice) return;
    getSankeyData(cluster.clusterId, slice, params);
  };

  const getSankeyData = async (clusterId, sliceName, params) => {
    const dummyData = {
      nodes: [],
      links: [],
    };

    try {
      if (clusterId.length > 0 && sliceName.length > 0) {
        const response = await getAllServiceDependencyRequest(
          clusterId,
          sliceName,
          params
        );
        response && setData(response);
      }
    } catch (err) {
      setData(dummyData);
      //write logic in future as required
    }
  };
  const handleCustomRange = () => {
    setStartTime(startCalendarTime.getTime());
    setEndTime(endCalendarTime.getTime());
    if (cluster?.clusterId && slice) {
      const params = {
        startTime: startCalendarTime.getTime(),
        endTime: endCalendarTime.getTime(),
      };
      getSankeyData(cluster?.clusterId, slice, params);
    }
  };

  //useEffect
  useEffect(() => {
    const clusterInfo = clusterDetail;
    setTimeline(generateTimeLine(new Date(), periods));
    if (clusterInfo.length > 0 && period !== 5) {
      const clusterID = clusterInfo[0].clusterId;
      const sliceName =
        clusterInfo[0]?.attachedSlices[
          Object.keys(clusterInfo[0]?.attachedSlices)[0]
        ]?.sliceName;
      let attachSliceList = clusterInfo[0]?.attachedSlices;
      if (clusterID && attachSliceList && sliceName) {
        setCluster(clusterInfo[0]); //default cluster
        setSliceList(attachSliceList); //default sliceList
        setSlice(sliceName); //default sliceName
        setTimeline(generateTimeLine(new Date(), periods));
        const params = {
          startTime,
          endTime,
        };
        getSankeyData(clusterID, sliceName, params); //generate Sankey Data
      }
    }
  }, []);
  const sliceOptions = Object.keys(sliceList);
  return (
    <>
      {clusterDetail.length > 0 ? (
        <div>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={6} flexWrap="nowrap" container>
              <Autocomplete
                disableClearable
                options={clusterInfo}
                getOptionLabel={(option) => option.clusterName}
                disablePortal
                value={cluster}
                onChange={(e, newValue) => clusterChange(newValue)}
                sx={{ mr: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ margin: "1rem", minWidth: "10rem" }}
                    variant="outlined"
                    label="Cluster"
                  />
                )}
              />
              <Autocomplete
                disableClearable
                options={sliceOptions}
                getOptionLabel={(option) => option}
                disablePortal
                value={slice}
                onChange={(e, newValue) => sliceChange(newValue)}
                sx={{ mr: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ margin: "1rem", minWidth: "10rem" }}
                    variant="outlined"
                    label="Slice"
                  />
                )}
              />
              <IconButton
                style={{ marginTop: "15px", marginLeft: "10px" }}
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ marginLeft: "5%", height: "100%", width: "100%" }}>
            {!(
              Object.keys(data).length === 0 && data.constructor === Object
            ) ? ( //check object is empty
              <>
                <SankeyChart data={data} />

                <div style={{ display: "flex", marginTop: "10px" }}>
                  <Square color="#12153E" />
                  <LabelText>Services</LabelText>

                  <Square color="#2868F3" style={{ marginLeft: "15px" }} />
                  <LabelText>Gateways</LabelText>
                  <Square color="#06cd99" style={{ marginLeft: "15px" }} />
                  <LabelText>Ingress Gateways</LabelText>
                  <Square color="#69bdfa" style={{ marginLeft: "15px" }} />
                  <LabelText>Egress Gateways</LabelText>
                  <Square color="#DD9F82" style={{ marginLeft: "15px" }} />
                  <LabelText>NS/EW Egress Gateways</LabelText>
                  <Square color="#614d75" style={{ marginLeft: "15px" }} />
                  <LabelText>NS/EW Ingress Gateways</LabelText>
                </div>
                <Box p={2}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={9}>
                      {period === 5 ? (
                        <>
                          <div
                            style={{
                              //width: "80%"
                              //height: "100px",
                              margin: "0 auto",
                              //marginTop: "20px",
                              fontSize: "12px",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                label="Start"
                                value={startCalendarTime}
                                onChange={(time) => setStartCalendarTime(time)}
                                maxDateTime={new Date()}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                              <DateTimePicker
                                disabled={startCalendarTime === ""}
                                label="End"
                                value={endCalendarTime}
                                onChange={(time) => setEndCalendarTime(time)}
                                minDateTime={startCalendarTime}
                                maxDateTime={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    //   helperText={handleError}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <IconButton
                              onClick={handleCustomRange}
                              disabled={!(startCalendarTime && endCalendarTime)}
                            >
                              <ArrowForwardIosSharpIcon fontSize="large" />
                            </IconButton>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            //width: "80%",
                            height: "100px",
                            margin: "0 auto",
                            //marginTop: "20px",
                            fontSize: "12px",
                          }}
                        >
                          <HorizontalTimeline
                            labelWidth={100}
                            getLabel={(date) => {
                              switch (period) {
                                case 1:
                                  return moment(date).format("lll");
                                case 2:
                                  return moment(date).format("lll");
                                case 3:
                                  return moment(date).format("ll");
                                default:
                                  return moment(date).format("ll");
                              }
                            }}
                            styles={{
                              background: "#f8f8f8",
                              foreground: "#1A79AD",
                              outline: "#dfdfdf",
                            }}
                            index={curIdx}
                            indexClick={(index) => {
                              const curIdX = curIdx;
                              setCurIdx(index);
                              setEndTime(timeline[period][index].endDate);
                              setStartTime(timeline[period][index].startDate);
                              setPrevIdx(curIdX);
                              const params = {
                                startTime: timeline[period][index].startDate,
                                endTime: timeline[period][index].endDate,
                              };
                              getSankeyData(cluster?.clusterId, slice, params);
                            }}
                            values={timeline[period].map((x) => x.endDate)}
                          />
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <FormControl>
                        <InputLabel id="select-period">Period</InputLabel>
                        <Select
                          labelId="select-period"
                          id="select-time-period"
                          value={period}
                          label="Period"
                          onChange={handleChange}
                        >
                          {periods.map((each) => {
                            return (
                              <MenuItem key={each.index} value={each.index}>
                                {each.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                No service dependency detail available
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          No cluster detail available
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
  };
};

export default connect(mapStateToProps, { getAllServiceDependencyRequest })(
  ServiceLatency
);
