export const SAMPLE = "/sample";
export const SLICE = "/slice";
export const GATEWAY = "/slice-gw";
export const SLICE_GATEWAYS = "/slice-gateways";
export const QOSPROFILE = "/qos-profile";
export const CLUSTER_COMPARE = "/compare-clusters";
export const POLICY = "/policy";
export const ACTION = "/action";
export const OPERATOR = "/operator";
export const OPERATORGROUPS = "/operatorgroup";
export const SLICE_POLICY = "/slice-policy";
export const LIMITED_SLICE_POLICY = "/limited-slice-policy";
export const ENFORCEMENT_POINTS = "/enforcement-point";
export const TELEMETRY = "/telemetry";
export const AUDIT_LOGS = "/audit-logs";
export const PROFILE = "/profile";
export const METRICS_URL = "/metrics-urls";
export const CLUSTER = "/cluster";
export const EVENT_COUNT = "/event-count";
export const ENTITY_COUNT = "/entity-count";
export const CAPACITY = "/capacity"
export const NODE ="/node"
export const NAMESPACE ="/namespace"
export const SERVICE_DEPENDENCY_GRAPH = "/service-dependency-graph"
export const SYSTEM_EVENT = "/system-event"