import React, { Component } from "react";
import { FormField } from "../../Commons/formUtils";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 100%;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
`;
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 1rem;
`;
export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
    };
  }
  submitForm = () => {
    let value = { ...this.state.value };
    alert(value.user_name + " " + value.email);
  };
  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };
  render() {
    const { cancelListener } = this.props;
    const { value } = this.state;
    return (
      <FormContainer>
        <Title>New User</Title>
        <FormField
          label="User Name"
          value={value.user_name}
          onChange={(e) =>
            this.setValue("user_name", e.nativeEvent.target.value)
          }
        />
        <FormField
          label="Email"
          value={value.email}
          onChange={(e) => this.setValue("email", e.nativeEvent.target.value)}
        />
        <ButtonContainer>
          <Button variant="contained" color="primary" onClick={this.submitForm}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelListener}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </FormContainer>
    );
  }
}
