import React, { Component } from "react";
import { connect } from "react-redux";
import PolicyCard from "./PolicyCard";
import styled from "styled-components";
import { Toolbar, InputBase, AppBar } from "./PolicyStyles";
import { Button } from "@material-ui/core";
import AddPolicyDialog from "./AddPolicyDialog";
import { BodyRoot } from "./../../Body/BodyStyles";
import Filters from "./../../Slices/Filters/Filters";
import { FilterButton } from "./../../Commons/IconButtons";
import { getAllPolicies, selectPolicy } from "./../../../actions/PolicyActions";
import { createSearchRegex } from "../../../utils/functions";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

class PolicyList extends Component {
  constructor() {
    super();

    this.state = {
      showFilters: false,
      openSliceDialog: false,
      searchQuery: "",
      filters: null,
      policyList: [],
    };
  }
  componentDidMount() {
    this.props.getAllPolicies();
    let filters = { ...this.getFilters() };
    this.setState({ filters });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.policyList) !== JSON.stringify(prevProps.policyList)) {
      await this.props.getAllPolicies();
      let filters = { ...this.getFilters() };
      this.setState({ filters });
    }
  }

  handleSliceDialogSubmit = async (sliceDetails) => {
    console.log("received", sliceDetails);
    // let res = await this.props.createSlice(sliceDetails);
    // await this.props.getAllSlices();
    // console.log(res);
  };

  getServices = () => {
    let policyList = [...this.props.policyList];
    let services = new Set();
    for (let i = 0; i < policyList.length; i++) {
      let policy = policyList[i];
      policyList[i].services = [];
      const { permissions } = policy;
      permissions.forEach((permission) => {
        if (permission?.action?.length > 0) {
          let action = permission.action[0];
          let selectedService;
          if (action == ".*") {
            services.add("All Access");
            policyList[i].services = ["All Access"];
            return;
          } else {
            services.add(action.split(":")[0]);
            policyList[i].services.push(action.split(":")[0]);
          }
        }
      });
    }
    this.setState({ policyList });
    return services;
  };

  getFilters = () => {
    let values = {};
    let services = this.getServices();
    services.forEach((service) => (values[service] = true));
    let filters = { services: { label: "Services", values } };
    return filters;
  };

  filterSlice = () => {
    let policyList = [...this.state.policyList];
    let query=createSearchRegex(this.state.searchQuery)
    let search = new RegExp(query, "i");
    policyList = policyList.filter((slice) => search.test(slice.policyName));
    if (this.state.filters) {
      const filterCategories = Object.keys(this.state.filters);
      for (let i = 0; i < filterCategories.length; i++) {
        for (let filter in this.state.filters[filterCategories[i]].values) {
          if (this.state.filters[filterCategories[i]].values[filter] == false) {
            if (filterCategories[i] === "services") {
              policyList = policyList.filter((slice) => !slice.services?.includes(filter));
            }
          }
        }
      }
    }
    return policyList;
  };
  
  render() {
    const { showFilters, openSliceDialog, filters } = this.state;
    const policyList = this.filterSlice();
    return (
      <BodyRoot>
        <AddPolicyDialog
          open={openSliceDialog}
          handleClose={() => this.setState({ openSliceDialog: false })}
          handleSubmit={this.handleSliceDialogSubmit}
        />
        {filters && (
          <Filters
            open={showFilters}
            onClose={() => this.setState({ showFilters: false })}
            filters={filters}
            updateFilter={(filters) => this.setState({ filters: { ...filters } })}
          />
        )}
        <AppBar position="static">
          <Toolbar>
            <InputBase placeholder="Search" onChange={(e) => this.setState({ searchQuery: e?.nativeEvent?.target?.value })} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterButton style={{ color: "black", marginRight: 20 }} onClick={() => this.setState({ showFilters: true })} />
              <Button variant="outlined" onClick={() => this.setState({ openSliceDialog: true })}>
                Create Policy
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        {policyList?.length > 0 ? (
          <Container>
            {policyList.map((policy, index) => (
              <PolicyCard
                policy={policy}
                index={index}
                onClick={() => {
                  this.props.selectPolicy(policy);
                  this.setState({ openSliceDialog: true });
                }}
              />
            ))}
          </Container>
        ) : (
          <div style={{ textAlign: "center", margin: "50px", color: "gray" }}>
            <h3>No Slices Present. Create New Policy</h3>
          </div>
        )}
      </BodyRoot>
    );
  }
}

const mapStateToProps = (state) => ({
  policyList: state.PolicyReducer.policies,
  filters: state.SliceFilterReducer,
});

const mapDispatchToProps = { getAllPolicies, selectPolicy };

export default connect(mapStateToProps, mapDispatchToProps)(PolicyList);
