import styled from "styled-components";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

export const AccordionSummary = styled(MuiAccordionSummary)`
  /* border: solid black 1px; */
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  height: 1rem;
`;

export const AccordianHeader= styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;