import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React from "react";
import colors from "./colors.json";
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    white: {
      main: "#FFFFFF",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: "Bai Jamjuree !important",
      },
      put: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
    MuiTab: {
      root: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
    MuiTypography: {
      root: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
    MuiCardHeader: {
      content: {
        textAlign: "left",
      },
      title: {
        fontFamily: "Bai Jamjuree !important",
      },
      subheader: {
        fontFamily: "Bai Jamjuree !important",
      },
    },
  },
});

export const MuiTheme = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
