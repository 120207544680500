import { SET_ROLES, SET_USERS, SET_USER_GROUPS } from "./types";

export const getAllRoles = () => {
  return async (dispatch, getState) => {
    //  let response = await meshBackend.getAllSlices(getState(), dispatch);
    let response = [
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
      {
        role: "Administrator",
        permissions: 12,
        environment: "Production",
      },
    ];
    dispatch({ type: SET_ROLES, payload: response });
  };
};
export const getAllUserGroups = () => {
  return async (dispatch, getState) => {
    let response = [
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
      {
        group_name: "Devops",
        role_attached: 1,
        no_of_users: 12,
      },
    ];
    dispatch({ type: SET_USER_GROUPS, payload: response });
  };
};
export const getAllUsers = () => {
  return async (dispatch, getState) => {
    let response = [
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
      {
        user_name: "Astitva Singhal",
        email: "astitvasinghal@gmail.com",
        status: "Pending",
      },
    ];
    dispatch({ type: SET_USERS, payload: response });
  };
};
