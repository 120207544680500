import React, { Component } from "react";
import { FormContainer, Label } from "../../FormStyles";
import ActionCategory from "./ActionCategory";
import styled from "styled-components";
import { FormCheck } from "../../../Commons/formUtils";
import "./ActionList.css";
export const FormCont = styled(FormContainer)`
  max-height: 10rem;
  overflow-y: auto;
  scrollbar-width: thin;
  width: 38vw;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
export default class ActionList extends Component {
  getCategories = () => {
    if (!this.props.actions) return null;
    const { actions } = this.props;
    let actionCategories = [];
    for (let category in actions) {
      actionCategories.push(
        <ActionCategory
          allChecked={actions[category]?.all}
          onAllClick={this.props.onAllCategoryClick}
          onChange={(action) => this.props.onChange(category, action)}
          key={category}
          label={category}
          options={actions[category]?.actions || {}}
        />
      );
    }
    return actionCategories;
  };

  render() {
    const { allActions } = this.props;
    return (
      <FormCont style={{ width: "100%", padding: "1rem" }}>
        <div className="AllContainer">
          <FormCheck label="All Actions" checked={allActions} onChange={this.props.onAllClick} />
        </div>
        {this.getCategories()}
      </FormCont>
    );
  }
}
