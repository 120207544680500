import React, { Component } from "react";
import { FormCheck, FormField, FormSelectN, useFormField } from "../../Commons/formUtils";
import styled from "styled-components";
import { Close, Resource, Scrollable } from "./ListStyles";


export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      options: [],
    };
  }
  componentDidMount() {
    this.setState({ options: this.props.options });
  }
  selectOption = (e) => {
    let index = e?.target?.value;
    this.props.addToList(this.state.options[index]);
    let options = [...this.state.options.slice(0, index), ...this.state.options.slice(1 + parseInt(index))];
    this.setState({ options });
  };
  render() {
    const { value, options } = this.state;
    const { list, addToList, label, error, helperText } = this.props;
    return (
      <div>
        <FormSelectN
          error={error}
          helperText={helperText}
          width="40rem"
          label={label + " Name"}
          value=""
          options={options}
          onChange={this.selectOption}
        />
        <Scrollable>
          {list.map((policy, index) => (
            <Resource key={index.toString()}>
              <div style={{ color: "grey" }}>{policy}</div>
              <Close
                color="grey"
                className="CloseIcon"
                onClick={() => {
                  this.props.deleteItem(index);
                  this.setState({ options: [...options, policy] });
                }}
              />
            </Resource>
          ))}
        </Scrollable>
      </div>
    );
  }
}
