import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, { Controls } from "react-flow-renderer";
import { connect } from "react-redux";
import ClusterLogicalNode from "./ClusterLogicalCustomNode";

const onElementClick = (event, element) => {};

const generateSlice = (slices, sliceListDetail) => {
  const sliceList = [];
  for (let slice in slices) {
    let nameSpaceCount = 0;
    if (sliceListDetail[slice])
      nameSpaceCount = Object.keys(
        sliceListDetail[slice]?.sliceNamespace
      )?.length;
    sliceList.push({
      id: slices[slice].sliceId,
      name: slices[slice].sliceName,
      namespace: nameSpaceCount,
    });
  }
  return sliceList;
};
const mapClusterCapacity = (clusterDetail, clusterCapacityDetail) => {
  let clusters = {};
  for (let clusterIndex in clusterDetail) {
    const cluster = clusterDetail[clusterIndex];
    clusters[cluster.clusterId] = cluster;
  }
  for (let clusterIndex in clusterCapacityDetail) {
    let cluster = clusterCapacityDetail[clusterIndex];
    cluster = {
      ...cluster,
      usedCapacity: Math.ceil(cluster.usedCapacity),
    };
    clusters[cluster.clusterId] = {
      ...clusters[cluster.clusterId],
      capacity: { ...cluster },
    };
  }
  clusters = Object.values(clusters);
  return clusters;
};
const generateElements = (
  clusterDetail,
  sliceListDetail,
  clusterCapacityDetail
) => {
  let x = 0;
  let clusterId = [];
  const edges = [];
  const clusters = mapClusterCapacity(clusterDetail, clusterCapacityDetail);
  const nodes =
    clusters.length > 0
      ? clusters.map((cluster, index) => {
          if (index !== 0) {
            x += 300;
          }
          clusterId.push(cluster.clusterId);
          return {
            id: cluster.clusterId,
            type: "clusterLogicalNode",
            position: { x: x, y: 100 },
            draggable: false,
            data: {
              clusterId: cluster.clusterId,
              clusterName: cluster.clusterName,
              progress: cluster?.capacity?.usedCapacity,
              status:
                cluster.status === "CLUSTER_STATUS_INACTIVE"
                  ? "#f44336"
                  : "#05CD99",
              link: "",
              place: cluster?.location?.city?.length > 0 ?  cluster.location.city + ", " + cluster.location.region : cluster?.location?.region,
              slices: generateSlice(cluster.attachedSlices, sliceListDetail),
            },
          };
        })
      : [];
  for (let i = 1; i < clusterId.length; i++) {
    edges.push({
      id: i,
      source: clusterId[i - 1],
      target: clusterId[i],
      style: { stroke: "#A6A8CB", strokeWidth: "2.6" },
    });
  }
  return [...nodes, ...edges];
};

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
const nodeTypes = {
  clusterLogicalNode: ClusterLogicalNode,
};

const ClusterLogicalView = (props) => {
  const { clusterDetail, sliceListDetail, clusterCapacityDetail } = props;
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [elements, setElements] = useState([]);

  useEffect(() => {
    setElements(
      generateElements(clusterDetail, sliceListDetail, clusterCapacityDetail)
    );
  }, []);

  useEffect(() => {
    if (reactflowInstance && elements.length < 10) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, elements.length]);

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        // rfi.fitView();
      }
    },
    [reactflowInstance]
  );
  return (
    <>
      <div style={{ height: "52vh" }}>
        {elements.length > 0 ? (
            <ReactFlow
              elements={elements}
              onElementClick={onElementClick}
              onLoad={onLoad}
              nodeTypes={nodeTypes}
              zoomOnScroll={false}
              style={{overflow: "auto"}}
              preventScrolling={false}
              connectionLineStyle={connectionLineStyle}
              snapToGrid={true}
              snapGrid={snapGrid}
              defaultZoom={0.75}
              minZoom={.01}
              nodesDraggable={false}
              selectNodesOnDrag={false}
            >
              <Controls showInteractive={false} />
            </ReactFlow>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            No clusters available
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
    clusterCapacityDetail: state.dashboard.clusterCapacityDetail,
    sliceListDetail: state.dashboard.slices,
  };
};
export default connect(mapStateToProps)(ClusterLogicalView);
