import React, { Component } from "react";
import { FormCheckList, FormField, FormSelect } from "../../Commons/formUtils";
import Button from "@material-ui/core/Button";
import {
  ButtonContainer,
  CheckList,
  FormContainer,
  Label,
  Title,
} from "../FormStyles";
export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      environmentOptions: ["Production", "Development", "Test", "POC"],
      permissionOptions: ["Review", "Edit", "Delete"],
      actions: {
        "slice:read": false,
        "slice:write": false,
        "slice:update": false,
        "slice:delete": false
      },
    };
  }
  arrToObj = (arr) => {
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
      obj[arr[i]] = arr[i];
    }
    return obj;
  };
  submitForm = () => {
    let value = { ...this.state.value };
    alert(value);
  };
  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };
  
  render() {
    const { cancelListener } = this.props;
    const { environmentOptions, permissionOptions, value,actions } = this.state;
    return (
      <FormContainer>
        <Title>New Policy</Title>
        <FormField
          label="Name"
          value={value.role_name}
          onChange={(e) =>
            this.setValue("role_name", e.nativeEvent.target.value)
          }
        />
        <FormField
          label="Resource"
          value={value.role_name}
          onChange={(e) =>
            this.setValue("resource", e.nativeEvent.target.value)
          }
        />
        {/* <FormSelect
          label="Environment"
          options={this.arrToObj(environmentOptions)}
          value={value.environment}
          onChange={(e) => this.setValue("environment",  e.nativeEvent.target.value)}
        />
        <FormSelect
          label="Permissions"
          options={this.arrToObj(permissionOptions)}
          value={value.permissions}
          onChange={(e) => this.setValue("permissions",  e.nativeEvent.target.value)}
        /> */}
        <FormCheckList label="Actions" list={actions} onChange={(actions)=>this.setState({actions})} />
        <ButtonContainer>
          <Button variant="contained" color="primary" onClick={this.submitForm}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelListener}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </FormContainer>
    );
  }
}
