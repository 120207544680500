import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";
import { Tab } from "@material-ui/core";
import styled from "styled-components";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const thCellStyle = {
  color: "black",
  fontWeight: "600",
  background: "whitesmoke",
};

const TableTitle = styled.div`
  font-weight: bold;
  color: #a9a9a9;
  margin: 10px 15px;
  font-size: 16px;
`;

const getBox = (typo, tableHeaders, tableBodyKeys, tableBody) => {
  return (
    <Box margin={1}>
      <TableTitle>{typo}</TableTitle>
      <Table size="small">
        <TableHead>
          <TableRow>
            {tableHeaders.map((ele) => (
              <TableCell style={thCellStyle} align="left">
                {ele}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((tableBodyRow) => (
            <TableRow key={typo}>
              {tableBodyKeys.map((ele) => (
                <TableCell align="left">{tableBodyRow[ele]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.serviceName}
        </TableCell>
        <TableCell align="left">{row.namespace}</TableCell>
        <TableCell align="left">{row.loadBalancingPolicy}</TableCell>
        <TableCell align="left">
          {/* {row.createdAtTime} */}
          {moment(row.createdAtTime).fromNow()}
        </TableCell>
        <TableCell align="left">
          {/* {row.updatedAtTime} */}
          {moment(row.updatedAtTime).fromNow()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {getBox(
              "Endpoints",
              ["POD Name", "Cluster ID", "IP", "URL"],
              ["podName", "clusterId", "ip", "url"],
              row.endpoints
            )}
            {getBox(
              "Ports",
              ["Name", "Port", "Protocol"],
              ["name", "port", "protocol"],
              row.ports
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable({ data }) {
  const rows = data;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={thCellStyle} align="left">
              Service Name
            </TableCell>
            <TableCell style={thCellStyle} align="left">
              Name Space
            </TableCell>
            <TableCell style={thCellStyle} align="left">
              Load Balancing Policy
            </TableCell>
            <TableCell style={thCellStyle} align="left">
              Created At
            </TableCell>
            <TableCell style={thCellStyle} align="left">
              Updated At
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.serviceName} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
