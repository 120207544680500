import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const DialogHeader = ({ ...otherProps }) => {
  const classes = useStyles();
  return (
    <AppBar
      className={classes.appBar}
      style={{
        backgroundImage:
          "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
      }}
    >
      <Toolbar style={{ color: "white" }}>{otherProps.children}</Toolbar>
    </AppBar>
  );
};
export default DialogHeader;
