import React, { useState, useEffect, useCallback } from "react";

import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
} from "react-flow-renderer";

import ClusterNode from "./ClusterNode";
import SliceNode from "./SliceNode";

const onNodeDragStop = (event, node) => console.log("drag stop", node);
const onElementClick = (event, element) => console.log("click", element);

const initBgColor = "#fff";

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
const nodeTypes = {
  clusterNode: ClusterNode,
  sliceNode: SliceNode,
};

const ClusterFlow = ({ cluster1, cluster2 }) => {
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [bgColor, setBgColor] = useState(initBgColor);
  console.log(cluster1);

  const generateColor = (index) => {
    switch (index) {
      case 0:
        return "#8433D6";
      case 1:
        return "#D528AF";
      default:
        return "#D66E3E";
    }
  };

  const generateClusterData = (cluster, currentX = 50, currentY = 0) => {
    //generate slice data
    const elementData = [];
    let moveX = currentX;
    console.log(cluster, "cluster");
    if (cluster.attachedSlices != null) {
      console.log("Attaced slices");
      let index = 0;
      for (let slice of Object.keys(cluster.attachedSlices)) {
        //   cluster.attachedSlices.forEach((slice)=>{

        elementData.push({
          id: `${cluster.attachedSlices[slice].sliceId}-${cluster.clusterId}`,
          type: "sliceNode",
          data: {
            sliceName: cluster.attachedSlices[slice].sliceName,
            backgroundColor: generateColor(index),
          },
          style: {},
          position: { x: moveX, y: currentY },
          draggable: false,
        }); //push the slice  data
        elementData.push({
          id: `${cluster.attachedSlices[slice].sliceName}-${cluster.clusterName}`,
          source: cluster.clusterId,
          target: `${cluster.attachedSlices[slice].sliceId}-${cluster.clusterId}`,
          sourceHandle: "b",
          style: {},
        }); //push slice-cluster edge data
        moveX = moveX + 100;
        index = (index + 1) % 3;
      }
    }

    //push the cluster node data
    const centerX = (currentX + (moveX === currentX ? moveX : moveX - 100)) / 2;
    elementData.push({
      id: cluster.clusterId,
      type: "clusterNode",
      data: { clusterName: cluster.clusterName },
      style: {},
      position: { x: centerX, y: currentY + 125 },
      draggable: false,
    });
    return [elementData, centerX, currentY + 125];
  };
  const generateElement = (cluster1, cluster2) => {
    //  console.log("generate element")
    //  console.log(generateClusterData(cluster1))
    const [cluster1ElementData, cluster1currentX, cluster1currentY] =
      generateClusterData(cluster1);
    console.log(cluster1ElementData);
    const [cluster2ElementData, cluster2currentX, cluster2currentY] =
      generateClusterData(
        cluster2,
        cluster1currentX + 125,
        cluster1currentY + 75
      );
    console.log(cluster2ElementData);
    setElements([
      ...cluster2ElementData,
      ...cluster1ElementData,
      {
        id: `${cluster1.clusterName}-${cluster1.clusterName}`,
        source: cluster1.clusterId,
        target: cluster2.clusterId,
        animated: true,
        sourceHandle: "b",
        style: { strokeWidth: 5 },
      },
    ]);
  };
  useEffect(() => {
    generateElement(cluster1, cluster2);
  }, [cluster1, cluster2]);

  useEffect(() => {
    if (reactflowInstance && elements.length > 0) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, elements.length]);

  const onElementsRemove = useCallback(
    (elementsToRemove) =>
      setElements((els) => removeElements(elementsToRemove, els)),
    []
  );
  const onConnect = useCallback(
    (params) =>
      setElements((els) =>
        addEdge({ ...params, animated: true, style: { stroke: "#fff" } }, els)
      ),
    []
  );

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        console.log("flow loaded:", rfi);
      }
    },
    [reactflowInstance]
  );

  return (
    <ReactFlow
      elements={elements}
      onElementClick={onElementClick}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onNodeDragStop={onNodeDragStop}
      style={{ background: bgColor }}
      onLoad={onLoad}
      nodeTypes={nodeTypes}
      connectionLineStyle={connectionLineStyle}
      //   snapToGrid={true}
      snapGrid={snapGrid}
      //   defaultZoom={.75}
      zoomOnScroll={false}
    >
      <Controls showInteractive={false} />
    </ReactFlow>
  );
};

export default ClusterFlow;
