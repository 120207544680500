import React from "react";
import styled from "styled-components";
import MuiEdit from "@material-ui/icons/Edit";
import MuiDelete from "@material-ui/icons/Delete";

import MuiButton from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { Divider, FieldContainer, FieldContent, FieldHeading } from "../Body/BodyStyles";
import { deleteSlicePolicy, detachSlicePolicy, selectSlicePolicy } from "../../actions/SlicePolicyActions";
const SectionContainer = styled.div`
  min-height: 7rem;
  margin-top: 1rem;
`;
const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
// const ContentCell = styled.div`
//   padding: 0.5rem;
//   width: 10rem;
//   flex-wrap: wrap;
//   display: flex;
//   flex-direction: column;
// `;
// const ContentLabel = styled.div`
//   font-size: 18px;
//   font-weight: 400;
// `;
// const Content = styled.div`
//   overflow-wrap: anywhere;
// `;
const ActionContainer = styled.div`
  width: 15rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const SectionHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => (props.attached ? "#004285" : "black")};
`;
const AttachButton = styled(MuiButton)`
  width: 13.5rem;
`;
const SectionDivider = styled(Divider)`
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
const Edit = styled(MuiEdit)`
  :hover {
    cursor: pointer;
  }
`;
const Delete = styled(MuiDelete)`
  :hover {
    cursor: pointer;
  }
`;
const SlicePolicySection = ({ editSlicePolicy, attached, attachSlicePolicy, deleteSlicePolicy, policy,detachSlicePolicy }) => {
  const {
    slicePolicyId,
    slicePolicyName,
    telemetryAccessPoints,
    enforcementPointProfiles,
    enableEnforcement,
    slicePolicyEnabled,
    sliceTelemetryEnabled,
    enforcementPointConfig
  } = policy;
  const handleDelete = () => {
    deleteSlicePolicy(slicePolicyId);
  };
  const handleEdit = () => {
    editSlicePolicy(policy);
  };

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionHeading attached={attached}>{slicePolicyName}</SectionHeading>
        <ActionContainer>
          <MuiButton variant="outlined" onClick={() => (attached ? detachSlicePolicy() : attachSlicePolicy(slicePolicyId))}>
            {attached ? "Detach from slice" : "Attach to Slice"}
          </MuiButton>
          <Edit onClick={handleEdit} />
          <Delete onClick={handleDelete} />
        </ActionContainer>
      </SectionHeader>
      <SectionContent>
        <FieldContainer>
          <FieldHeading>Telemetry Access Points</FieldHeading>
          <FieldContent>{telemetryAccessPoints?.join(", ")}</FieldContent>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Enforcement Point Profiles</FieldHeading>
          <FieldContent>{enforcementPointConfig?.length}</FieldContent>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Enforcement Enabled</FieldHeading>
          <FieldContent>{enableEnforcement ? "True" : "False"}</FieldContent>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Slice Policy Enabled</FieldHeading>
          <FieldContent>{slicePolicyEnabled ? "True" : "False"}</FieldContent>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Slice Telemetry Enabled</FieldHeading>
          <FieldContent>{sliceTelemetryEnabled ? "True" : "False"}</FieldContent>
        </FieldContainer>
      </SectionContent>
    </SectionContainer>
  );
};
const ActionBar = styled.div`
  display: flex;
  padding: 0.5rem;
  width: inherit;
  justify-content: space-between;
  align-items: center;
`;
function SlicePolicyList({ selectSlicePolicy, openDialog, policies, attachSlicePolicy, attachedSlicePolicy, sliceName, deleteSlicePolicy,detachSlicePolicy }) {
  const editSlicePolicy = (policy) => {
    selectSlicePolicy(policy);
    openDialog();
  };
  const handleDeleteSlicePolicy = (slicePolicyId) => {
    debugger;
    deleteSlicePolicy(sliceName, slicePolicyId);
  };
  return (
    <div>
      <Paper>
        <ActionBar>
          <div>Attached Slice Policy</div>
          <AttachButton variant="outlined" onClick={openDialog}>
            Add Slice Policy
          </AttachButton>
        </ActionBar>
      </Paper>
      {policies.map((policy) => (
        <>
          <SlicePolicySection
            policy={policy}
            attachSlicePolicy={attachSlicePolicy}
            attached={attachedSlicePolicy == policy.slicePolicyId}
            editSlicePolicy={editSlicePolicy}
            deleteSlicePolicy={handleDeleteSlicePolicy}
            detachSlicePolicy={detachSlicePolicy}
          />
          <SectionDivider />
          {/* <div style={{ border: "solid black 1px", width: "100%" }} /> */}
        </>
      ))}
    </div>
  );
}

const mapStateToProps = ({ SlicePolicyReducer: { policies } }) => ({
  policies,
});

export default connect(mapStateToProps, { selectSlicePolicy, deleteSlicePolicy })(SlicePolicyList);
