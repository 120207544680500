import React, { Component } from "react";
import PopUp from "../../Commons/popUtils";
import GroupForm from "./GroupForm";
import TableCard from "../TableCard";
import People from "@material-ui/icons/People";
import { getAllUserGroups } from "../../../actions/RolesActions";
import { connect } from "react-redux";

 class Groups extends Component {
  constructor() {
    super();
    this.state = {
      showForm: false,
      groups: [],
      config: {
        columns: [
          {
            id: "group_name",
            name: "Group Name",
            icon: true,
            iconPath: (
              //todo: refactor to img source
              <People
                color="primary"
                style={{ marginRight: "10px", alignSelf: "center" }}
              />
            ),
          },
          {
            id: "role_attached",
            name: "Role Attached",
            icon: false,
          },
          {
            id: "no_of_users",
            name: "Number of Users",
            icon: false,
          },
        ],
      },
    };
  }
  componentDidMount() {
    this.props.getAllUserGroups();
  }
  render() {
    const { showForm, config } = this.state;
    const { heading,groups } = this.props;

    return (
      <>
        <PopUp open={showForm}>
          <GroupForm
            cancelListener={() => this.setState({ showForm: false })}
          />
        </PopUp>
        <TableCard
          addListener={() => this.setState({ showForm: !showForm })}
          heading={heading || "User Groups"}
          config={config}
          data={groups}
          editable={true}
          deleteEnable={true}
        />
      </>
    );
  }
}
const mapStateToProps=(store)=>{
  return{
    groups:store.roles.userGroups,
  }
}
export default connect(mapStateToProps,{getAllUserGroups})(Groups)