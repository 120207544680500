import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import styled from "styled-components";
const Cross = styled(ClearIcon)`
  position: absolute;
  right: 0.1rem;
  :hover {
    cursor: pointer;
  }
`;
export default class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  render() {
    const { value } = this.state;
    const { toggleExpand, expand, list, onItemClick } = this.props;
    return (
      <div style={{ position: "relative" }}>
        {expand && (
          <>
            <Cross fontSize="small" onClick={toggleExpand} />
            <Autocomplete
              value={value}
              options={list || []}
              getOptionLabel={(option) => option.label}
              onChange={(event, value) => {
                // debugger;
                if (value) {
                  onItemClick({ objectId: value.objectId, type: value.type });
                  this.setState({ value });
                }
              }}
              renderInput={(params) => <TextField {...params} label="Search" margin="normal" />}
            />
          </>
        )}
      </div>
    );
  }
}
