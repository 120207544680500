import React, { Component } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/yaml/yaml";
import { Controlled as ControlledEditor } from "react-codemirror2";
import YAML from "yaml";

import styled from "styled-components";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { ScrollBarStyle } from "../../../AppStyles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { setNamespaceList } from "../../../actions/HeirarchicalNamespaceActions";
import { updateNamespaceIsolationProfile } from "../../../actions/namespaceActions";
const EditorContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: 100%;
  ${ScrollBarStyle}
  .code-mirror-wrapper {
    width: 100%;
    height: 100%;
    ${ScrollBarStyle}
    .CodeMirror {
      height: 100%;
      ${ScrollBarStyle}
      .CodeMirror-scroll {
        ${ScrollBarStyle}
      }
    }
  }
`;
class SliceEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: "HNC",
      resetting: true,
    };
    this.editor = React.createRef();
    this.fileUpload = React.createRef();
  }

     componentDidMount(){
    if(this?.props?.slice && Object.keys(this?.props?.slice).length > 0)
         this.prefillYaml(this.props.slice)
     }

    componentDidUpdate(prevProps, prevState){
      
      if(prevProps.slice !== this.props.slice){
        if(this?.props?.slice && Object.keys(this?.props?.slice).length > 0)
          this.prefillYaml(this.props.slice)
    }
      }  
  componentWillUnmount() {
    if (this?.view?.destroy) this.view.destroy();
  }

  changeServiceStructure = (sliceDetail) => {
    const cluster = Object.keys(sliceDetail.clusters).map((key) => {
      const clusterData = sliceDetail.clusters[key];
      const service = Object.keys(clusterData.services).map((serviceKey) => {
        return clusterData.services[serviceKey];
      });
      return {
        ...clusterData,
        services: service,
      };
    });
    return {
      ...sliceDetail,
      clusters: cluster,
    };
  };

  handleYamlUpload = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const yaml = e.target.result;
      if (this.verifyYaml(yaml)) {
        const doc = new YAML.Document();
        doc.contents = yaml;
        this.setState({ yaml: doc.toString() }, this.handleRefresh);
        this.setState({ resetting: false }, () =>
          this.setState({ resetting: true })
        );
      }
    };
    fileReader.readAsText(file);
  };

  prefillYaml = (sliceDetail) => {
    //convert sliceDetail data to proper values
    const {
      sliceName,
      sliceDisplayName,
      sliceSubnet,
      sliceType,
      namespaceIsolationProfile:{
        applicationNamespaces,
        sliceNamespaceHierarchy
      },
      sliceGatewayProvider: {
        sliceGatewayType,
        sliceCaType,
        sliceIdp,
        maxGateways,
        gatewaySubnetSize,
      },
      sliceQosProfile,
      sliceIpamType,
      schSliceClusterSite,
    } = sliceDetail;
    const slice = {
      slice: {
        name: sliceName,
        displayName: sliceDisplayName,
        subnet: sliceSubnet,
        type: sliceType,
        gatewayProvider: {
          type: sliceGatewayType,
          caType: sliceCaType,
          idp: sliceIdp,
          maxGateways,
          subnetSize: gatewaySubnetSize,
        },
      },
      qosProfile: sliceQosProfile,
      ipamType: sliceIpamType,
      site: schSliceClusterSite,
      namespaceIsolationProfile:{
        applicationNamespaces: applicationNamespaces,
        sliceNamespaceHierarchy: sliceNamespaceHierarchy
      },
    };
    //console.log(slice);
    const doc = new YAML.Document();
    doc.contents = slice;
    this.setState({ yaml: doc.toString() }, this.handleInitialLoad);
  };

  verifyYaml = (yaml) => {
    try {
      YAML.parse(yaml);
      return true;
    } catch (e) {
      return false;
    }
  };
  handleDownload = () => {
    const { yaml } = this.state;
    if (!this.verifyYaml(yaml)) {
      this.setState({ error: true });
      return;
    }
    const blob = new Blob([yaml]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    this.setState({ fileDownloadUrl: fileDownloadUrl, error: false }, () => {
      this.dofileDownload.click();
      URL.revokeObjectURL(fileDownloadUrl);
      this.setState({ fileDownloadUrl: "" });
    });
  };

  handleInitialLoad = () =>{
    const { yaml } = this.state;
    try {
      const config = YAML.parse(yaml)
      if (
        config?.namespaceIsolationProfile?.sliceNamespaceHierarchy && Array.isArray(config?.namespaceIsolationProfile?.sliceNamespaceHierarchy)
      ){
        this.props.setNamespaceList(config?.namespaceIsolationProfile?.sliceNamespaceHierarchy);
      }
    } catch (e) {
      return false;
    }
  }
  
  handleRefresh = () => {
    const { yaml } = this.state;
    try {
      const config = YAML.parse(yaml)
      if (
        config?.namespaceIsolationProfile?.sliceNamespaceHierarchy &&
        Array.isArray(
          config?.namespaceIsolationProfile?.sliceNamespaceHierarchy
        )
      ) {
        // Redux update namespaces
        this.props.setNamespaceList(config?.namespaceIsolationProfile?.sliceNamespaceHierarchy);
        this.updateNamespace(config?.namespaceIsolationProfile)
      }
    } catch (e) {
      return false;
    }
  };

  updateNamespace = (namespaceIsolationProfile) =>{
      // const { allowedNamespaces, applicationNamespaces, isolationEnabled } =
      // this.props.slice.namespaceIsolationProfile;
      this.props.updateNamespaceIsolationProfile(this.props.slice.sliceName, {
        namespaceIsolationProfile,
      });
  }
  handleChange = (editor, data, value) => {
    this.setState({ yaml: value });
  };
  render() {
    const { fileName, yaml, resetting } = this.state;
    return (
      <>
        <IconButton
          // disabled={!yaml}
          onClick={this.handleRefresh}
          style={{
            position: "absolute",
            right: "7rem",
            top: "1rem",
            border: "solid white 1px",
            borderRadius: "0.5rem",
            zIndex: 5,
          }}
        >
          <RefreshIcon style={{ color: "white" }} />
        </IconButton>
        <a
          style={{ display: "none" }}
          download={fileName + ".yaml"}
          href={this.state.fileDownloadUrl}
          ref={(e) => (this.dofileDownload = e)}
        >
          download it
        </a>
        <IconButton
          onClick={this.handleDownload}
          style={{
            position: "absolute",
            right: "4rem",
            top: "1rem",
            border: "solid white 1px",
            borderRadius: "0.5rem",
            zIndex: 5,
          }}
        >
          <DownloadIcon style={{ color: "white" }} />
        </IconButton>
        {resetting && (
          <input
            type="file"
            ref={this.fileUpload}
            style={{ display: "none" }}
            onInput={this.handleYamlUpload}
          />
        )}
        <IconButton
          onClick={() => {
            this.fileUpload.current.click();
          }}
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            border: "solid white 1px",
            borderRadius: "0.5rem",
            zIndex: 5,
          }}
        >
          <UploadIcon style={{ color: "white" }} />
        </IconButton>
        <EditorContainer>
          <ControlledEditor
            onBeforeChange={this.handleChange}
            value={yaml}
            className="code-mirror-wrapper"
            options={{
              lineWrapping: true,
              lint: true,
              mode: "yaml",
              theme: "material",
              lineNumbers: true,
            }}
          />
        </EditorContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //  slices: state.slices.sliceList,
  //   currentSlice: state.AppOnboardingReducer.currentSlice,
});

const mapDispatchToProps = { setNamespaceList, updateNamespaceIsolationProfile, };

export default connect(mapStateToProps, mapDispatchToProps)(SliceEditor);
