export const keyMap = {
  sliceSubnet: {
    ["10.2.0.0/16"]: "10.2.0.0/16",
    ["10.3.0.0/16"]: "10.3.0.0/16",
  },
  sliceType: {
    SLICE_TYPE_APPLICATION: "Application",
  },
  sliceGatewayType: {
    SLICE_GATEWAY_TYPE_OPEN_VPN: "Open VPN",
  },
  sliceGatewayType: {
    SLICE_GATEWAY_TYPE_OPEN_VPN: "Open VPN",
  },
  sliceGatewayStatus: {
    SLICE_GATEWAY_STATUS_REGISTERED: "Registered",
  },
  sliceGatewayHostType: {
    SLICE_GATEWAY_HOST_TYPE_CLIENT: "Client",
    SLICE_GATEWAY_HOST_TYPE_SERVER: "Server",
  },
  sliceGatewayRegistration: {
    SLICE_GATEWAY_REGISTRATION_REGISTERED: "Registered",
  },
  sliceCAType: {
    SLICE_CA_TYPE_LOCAL: "local",
  },
  sliceIDP: {
    SLICE_GATEWAY_IDP_COGNITO: "Cognito",
    // SLICE_GATEWAY_IDP_OKTA: "Okta",
    // SLICE_GATEWAY_IDP_OAUTH: "OAuth",
    // SLICE_GATEWAY_IDP_OPEN_ID: "Open ID",
  },
  clusterSubnetSize: {
    ["128"]: 128,
    ["256"]: 256,
  },
  sliceQosProfile: {
    LOW_PRIORITY_PROFILE: "Low Priority Profile",
    MED_PRIORITY_PROFILE: "Med Priority Profile",
    HIGH_PRIORITY_PROFILE: "High Priority Profile",
  },
  sliceIPAMType: {
    SLICE_IPAM_TYPE_LOCAL: "local",
  },
  dscpClass: {
    QOS_PROFILE_DSCP_CLASS_DEFAULT: "Qos Profile DSCP Class DEFAULT",
    QOS_PROFILE_DSCP_CLASS_AF11: "Qos Profile DSCP Class AF11",
    QOS_PROFILE_DSCP_CLASS_AF12: "Qos Profile DSCP Class AF12",
    QOS_PROFILE_DSCP_CLASS_AF13: "Qos Profile DSCP Class AF13",
    QOS_PROFILE_DSCP_CLASS_AF21: "Qos Profile DSCP Class AF21",
    QOS_PROFILE_DSCP_CLASS_AF22: "Qos Profile DSCP Class AF22",
    QOS_PROFILE_DSCP_CLASS_AF23: "Qos Profile DSCP Class AF23",
    QOS_PROFILE_DSCP_CLASS_AF31: "Qos Profile DSCP Class AF31",
    QOS_PROFILE_DSCP_CLASS_AF32: "Qos Profile DSCP Class AF32",
    QOS_PROFILE_DSCP_CLASS_AF33: "Qos Profile DSCP Class AF33",
    QOS_PROFILE_DSCP_CLASS_AF41: "Qos Profile DSCP Class AF41",
    QOS_PROFILE_DSCP_CLASS_AF42: "Qos Profile DSCP Class AF42",
    QOS_PROFILE_DSCP_CLASS_AF43: "Qos Profile DSCP Class AF43",
    QOS_PROFILE_DSCP_CLASS_EF:"Qos Profile DSCP Class EF",
  },
};
