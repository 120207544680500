import React, { Component } from "react";
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FilterCategory } from "../../SliceStyles";
import CheckBox from "@material-ui/core/Checkbox";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Filter from "./Filter";
import Group from "./Group";
export default class Content extends Component {
  // getContent = () => {
  //   const { type, groupBy, groupOrder, filterBy, filterCategory } = this.props;
  //   switch (type) {
  //     case "group":
  //       return (
  //         <div style={{ paddingLeft: "1rem" }}>
  //           <h5 style={{ marginTop: "16px", marginBottom: "8px" }}>
  //             Group Order
  //           </h5>
  //           <RadioGroup
  //             name="groupOrder"
  //             value={groupOrder}
  //             onChange={(e) => this.setState({ groupOrder: e.target.value })}
  //           >
  //             <FormControlLabel
  //               value="ascending"
  //               control={<Radio style={{ padding: 0 }} />}
  //               label="Ascending"
  //             />
  //             <FormControlLabel
  //               value="desceneding"
  //               control={<Radio style={{ padding: 0 }} />}
  //               label="Desceneding"
  //             />
  //           </RadioGroup>
  //           <h5 style={{ marginTop: "16px", marginBottom: "8px" }}>Group By</h5>
  //           <RadioGroup
  //             name="groupBy"
  //             value={groupBy}
  //             onChange={(e) => this.setState({ groupBy: e.target.value })}
  //           >
  //             <FormControlLabel
  //               value="aplhabetically"
  //               control={<Radio />}
  //               label="Aplhabetically"
  //             />
  //             <FormControlLabel
  //               value="category"
  //               control={<Radio />}
  //               label="Category"
  //             />
  //             <FormControlLabel
  //               value="category2"
  //               control={<Radio />}
  //               label="Category"
  //             />
  //             <FormControlLabel
  //               value="category3"
  //               control={<Radio />}
  //               label="Category"
  //             />
  //             <FormControlLabel
  //               value="category4"
  //               control={<Radio />}
  //               label="Category"
  //             />
  //           </RadioGroup>
  //         </div>
  //       );
  //       break;
  //     case "filter":
  //       const categories = Object.keys(filterBy);
  //       const options = filterCategory
  //         ? Object.keys(filterBy[filterCategory])
  //         : null;
  //       return (
  //         <div>
  //           {filterCategory ? (
  //             <>
  //               <div style={{ display: "flex", alignItems: "center" }}>
  //                 <KeyboardArrowLeftIcon
  //                   style={{ marginTop: "3px" }}
  //                   onClick={() => this.setState({ filterCategory: null })}
  //                 />
  //                 <p style={{ fontWeight: "bold" }}>{filterCategory}</p>
  //               </div>
  //               <FormGroup style={{ paddingLeft: "1rem" }}>
  //                 {options.map((option) => (
  //                   <FormControlLabel
  //                     control={
  //                       <CheckBox
  //                         checked={filterBy[filterCategory][option]}
  //                         onChange={() =>
  //                           this.setState({
  //                             filterBy: {
  //                               ...filterBy,
  //                               [filterCategory]: {
  //                                 ...filterBy[filterCategory],
  //                                 [option]: !filterBy[filterCategory][option],
  //                               },
  //                             },
  //                           })
  //                         }
  //                         name={option}
  //                       />
  //                     }
  //                     label={option}
  //                   />
  //                 ))}
  //               </FormGroup>
  //             </>
  //           ) : (
  //             <div>
  //               <h5
  //                 style={{
  //                   marginLeft: "1rem",
  //                   marginTop: "16px",
  //                   marginBottom: "8px",
  //                 }}
  //               >
  //                 Filter By
  //               </h5>
  //               {categories.map((cat) => (
  //                 <FilterCategory
  //                   onClick={() => this.setState({ filterCategory: cat })}
  //                 >
  //                   {cat}
  //                 </FilterCategory>
  //               ))}
  //             </div>
  //           )}
  //         </div>
  //       );
  //     default:
  //       break;
  //   }
  // };
  render() {
    return this.props.type=="filter"?<Filter {...this.props}/>:<Group {...this.props}/>;
  }
}
