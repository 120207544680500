import React, { Component } from "react";
import moment from "moment";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Label, Legend, Tooltip, CartesianGrid } from "recharts";

const colorScheme = ["#a38101", "#e1cf01", "#10732c", "#4ed47b", "#8234d8", "#8224d8", "#1284d8"];

export default class Graph extends Component {

  render() {
    const Lines = [];
    const slices = this.props.data?.length ? this.props.data[this.props.data.length-1]?.slices : null;
    const getKey = this.props.getKey;
    const getName = this.props.getName;
    if (slices) {
      let color = 0;
      for (let slice in slices) {
        if (Object.keys(slices[slice]).length !== 0) {
          const name1 = getName(this.props.data[this.props.data.length-1], slice, 0);
          Lines.push(
            <Line
              type="monotone"
              legendType="circle"
              dot={false}
              name={name1}
              dataKey={(data) => getKey(data, slice, 0)}
              stroke={colorScheme[color % 7]}
              strokeWidth={3}
            />
          );
          const name2 = getName(this.props.data[this.props.data.length-1], slice, 1);
          color += 1;
          Lines.push(
            <Line
              type="monotone"
              name={name2}
              dot={false}
              legendType="line"
              dataKey={(data) => getKey(data, slice, 1)}
              stroke={colorScheme[color % 7]}
              strokeDasharray="5 5"
              strokeWidth={3}
            />
          );
        }
        color += 1;
      }
    }

    return (
      <ResponsiveContainer height="100%" width="100%">
        <LineChart data={this.props.data}>
          {Lines}
          {this.props.legend && (
            <Legend
              content={this.props.legend}
              formatter={this.props.legendFormatter}
              onClick={this.props.onItemClick}
              verticalAlign="bottom"
              align="center"
              layout="horizontal"
            />
          )}
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={(value) => {
              return moment(value).format("HH:mm:ss");
            }}
          />
          <XAxis
            dataKey="time"
            domain={("dataMin", "dataMax")}
            name="Time"
            tickFormatter={(unixTime) => moment(unixTime).format("HH:mm:ss")}
            type="number"
          >
            <Label offset={-5} position="insideBottom">
              {this.props.XLabel}
            </Label>
          </XAxis>
          <YAxis
            type="number"
            domain={[
              0,
              (dataMax) => {
                return Math.ceil(dataMax * 1.2);
              },
            ]}
            allowDataOverflow={true}
            label={{
              value: this.props.YLabel,
              angle: -90,
              position: "insideLeft",
              offset: 25,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
