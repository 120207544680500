import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
const Field = styled(TextField)`
  margin-right: 1rem;
`;
const FieldHeading = styled.h5``;
const FieldContainer = styled.div``;
const FieldContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const FieldLabel = styled.div``;
const FieldValue = styled.input``;
const MetricField = ({ metric: { valueType, name, value }, onChange }) => {
  const setValue = (key, val) => {
    let _value = { ...value, [key]: val };
    onChange(_value);
  };
  switch (valueType) {
    case "integer":
      return (
        <FieldContainer>
          <FieldHeading>{snakeToText(name)}</FieldHeading>
          <FieldContent>
            <Field
              InputLabelProps={{ shrink: true, type: "number" }}
              size="small"
              label="ABC"
              value={value?.abc || ""}
              type="number"
              onChange={(e) => setValue("abc", Number(e.target.value))}
              variant="filled"
            />
          </FieldContent>
        </FieldContainer>
      );
    case "qos_thresholds":
    default:
      return (
        <FieldContainer>
          <FieldHeading>{snakeToText(name)}</FieldHeading>
          <FieldContent>
            <Field
              InputLabelProps={{ shrink: true, type: "number" }}
              size="small"
              label="High"
              value={!isNaN(value?.high) ? value.high : ""}
              type="number"
              onChange={(e) => setValue("high", Number(e.target.value))}
              variant="filled"
            />
            <Field
              InputLabelProps={{ shrink: true, type: "number" }}
              type="number"
              size="small"
              label="Low"
              value={!isNaN(value?.low) ? value.low : ""}
              onChange={(e) => setValue("low", Number(e.target.value))}
              variant="filled"
            />
          </FieldContent>
        </FieldContainer>
      );
      break;
  }
};
export default MetricField;
const snakeToText = (inp) => {
  if (!inp) {
    return "";
  }
  let words = inp.split("_");
  let text = "";
  words.forEach((word) => {
    text += word[0].toUpperCase() + word.slice(1) + " ";
  });
  //   text = text[0].toUpperCase() + text.slice(1);
  return text.trim();
};
