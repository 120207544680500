import React, { Component } from "react";
import AboutUs from "../AboutUs";
import { connect } from "react-redux";
import { openPopUp, closePopUp } from "../../../actions/PopUpActions";
import UserDetails from "./../UserDetails";
import { ABOUT_US, PROFILE } from "./types";

class PopUp extends Component {
  render() {
    const { popup, closePopUp } = this.props;
    switch (popup) {
      case ABOUT_US:
        return <AboutUs open={popup == ABOUT_US} closeContactModal={closePopUp} />;
        break;
      case PROFILE:
        return <UserDetails open={popup == PROFILE} handleClose={closePopUp} />;
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }
}
const mapStateToProps = ({ popup: { popup } }) => ({ popup });
export default connect(mapStateToProps, { openPopUp, closePopUp })(PopUp);
