import { 
  SET_DASHBOARD_CLUSTER_DATA, 
  ACTION_LOGOUT_INITIATED, 
  SET_LEGEND_DATA, 
  CLEAR_LEGEND_DATA, 
  SET_CLUSTER_DETAIL, 
  SET_CLUSTER_METADATA, 
  SET_SLICE_NAMESPACE_COUNT, 
  SET_CLUSTER_CAPACITY_DETAIL, 
  SET_SYSTEM_EVENT, 
  CLEAR_SYSTEM_EVENT } from "../actions/types";

const initialState = {
  compareClusterData: {},
  data: {},
  filteredData: {},
  isValueSet: false,
  clusterDetail:{},
  clusterMetaData: {},
  clusterCapacityDetail:{},
  slices:{},
  systemEvents: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_CLUSTER_DATA:
      return {
        ...state,
        compareClusterData: action.payload,
      };
    case SET_LEGEND_DATA:
      return {
        ...state,
        data: action.payload.data,
        filteredData: action.payload.filteredData,
        isValueSet: true,
      };
    case CLEAR_LEGEND_DATA:
      return {
        ...initialState,
      };
    
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };

    case SET_CLUSTER_DETAIL:
      return{
        ...state,
        clusterDetail: action.payload.clusters
    }
    
    case SET_CLUSTER_METADATA:

      return {
        ...state,
        clusterMetaData: action.payload
      }
    case SET_CLUSTER_CAPACITY_DETAIL:
      return{
        ...state,
        clusterCapacityDetail: action.payload.clusterCapacity
      }
    case SET_SLICE_NAMESPACE_COUNT:
      return {
        ...state,
        slices: {...state.slices,[action.payload.sliceName]:action.payload},
        
      };
    case SET_SYSTEM_EVENT:
      return{
        ...state,
        systemEvents: [...state.systemEvents,...action.payload.events]
      }
      case CLEAR_SYSTEM_EVENT:
        return{
          ...state,
          systemEvents: []
        }
    default:
      return state;
  }
}
