import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TabPanelDashboard from "./TabPanelDashboard";
import { BodyRoot } from "../Body/BodyStyles";
import Notification from "./Notification";
import Grid from "@mui/material/Grid";
import ClusterCapacity from "./ClusterCapacity";
import ClusterDetails from "./ClusterDetailsTable";
import {
  getClusterDetail,
  getClusterCapacityDetail,
  getAllSliceNamespaces,
  getSystemEvent,
  clearSystemEvent,
} from "../../actions/dashboardActions";
import { connect, useSelector } from "react-redux";
import ClusterMetadata from "./ClusterMetadata";
import { getAllSlices } from "../../actions/SliceActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    top: "1px",
    marginLeft: "2%",
    marginRight: "2%",
    padding: "0% 2% 2% 2%",
  },
  welcomeContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "3%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cardList: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardContainer: {
    margin: "5px",
    width: "200px",
    borderRadius: "14px",
  },
  cardContent: {
    display: "flex",
  },
  fab: {
    margin: "0 5px",
    marginTop: "0px",
    marginLeft: "0px",
    transform: "scale(0.8)",
    textTransform: "none",
  },
  buttonAction: {
    textAlign: "left",
    fontWeight: 500,
  },
  btnClass: {
    borderRadius: "12px",
    color: "#05CD99",
    backgroundColor: "#dfefeb",
    width: "80px",
    "&:hover": {
      backgroundColor: "#c3e2da",
    },
  },
  btnInactive: {
    borderRadius: "12px",
    color: "red",
    backgroundColor: "#f9dbdb",
    width: "80px",
    "&:hover": {
      backgroundColor: "#f9dbdb",
    },
  },
}));
const severity = [
  "SEVERITY_INFO",
  "SEVERITY_WARNING",
  "SEVERITY_ERROR",
  "SEVERITY_CRITICAL",
];
function Dashboard(props) {
  const classes = useStyles();
  const {
    getClusterDetail,
    getClusterCapacityDetail,
    getAllSliceNamespaces,
    slices,
    getAllSlices,
    getSystemEvent,
    clearSystemEvent,
  } = props;
  const userDetail = useSelector((state) => state.UserReducer);
  const [value, setValue] = useState(0);

  useEffect(() => {
    getClusterDetail();
    getClusterCapacityDetail();
    getAllSlices();
    clearSystemEvent(); //clear all system event from state before calling the getsystemevent
    //calling all system events of slice
    severity.forEach((severe) => {
      getSystemEvent({
        entityType: "ENTITY_TYPE_SLICES",
        severity: severe,
      });
    });
    slices.map(({ sliceName }) => getAllSliceNamespaces(sliceName));
  }, []);

  useEffect(() => {
    slices.map(({ sliceName }) => getAllSliceNamespaces(sliceName));
  }, [slices]);

  return (
    <BodyRoot
      style={{ backgroundColor: "#f4f7fe", fontFamily: "Bai Jamjuree" }}
    >
      <div className={classes.root}>
        <div style={{ display: "flex", paddingTop: "20px" }}>
          <div id="greetings" className={classes.welcomeContent}>
            <Typography
              style={{
                fontFamily: "Bai Jamjuree",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "34px",
                lineHeight: "42px",
                color: "#12153E",
                letterSpacing: "-0.02em",
              }}
            >{`Hello, ${
              typeof userDetail.operatorName != "undefined"
                ? userDetail.operatorName
                : ""
            }`}</Typography>
            <Typography
              style={{
                fontFamily: "Bai Jamjuree",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "24px",
                opacity: "0.5",
                color: "#12153E",
              }}
              variant="subtitle1"
            >
              Welcome to your dashboard
            </Typography>
          </div>
          <Notification />
        </div>
        <ClusterMetadata />
        <Paper
          id="topology"
          elevation={6}
          style={{
            height: "fit-content",
            borderRadius: "18px",
            marginTop: "32px",
          }}
        >
          <Typography
            
            style={{
              padding: "30px 0px 0px 30px",
              fontFamily: "Bai Jamjuree",
              fontSize:"20px",
              fontWeight:"bold",
              lineHeight:'24px'
            }}
          >
            Topology
          </Typography>
          <TabPanelDashboard
            value={value}
            onChange={(_value) => {
              setValue(_value);
            }}
          />
        </Paper>
        {value == 0 && (
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "2rem" }}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ClusterDetails />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ClusterCapacity />
            </Grid>
          </Grid>
        )}
      </div>
    </BodyRoot>
  );
}

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
    slices: state.slices.sliceList,
  };
};

export default connect(mapStateToProps, {
  getClusterDetail,
  getClusterCapacityDetail,
  getAllSliceNamespaces,
  getAllSlices,
  getSystemEvent,
  clearSystemEvent,
})(Dashboard);
