import styled from "styled-components";
export const HeaderContainer = styled.div`
  padding: 0.5rem;
  padding-left: 1rem;
  box-shadow: 0px 0px 1px 0px black;
`;
export const Title = styled.div`
  /* margin-bottom: 1rem; */
  font-size: 18px;
  font-weight: bold;
`;
export const HorizontalBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
export const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
