import { Grid, Paper, Typography } from "@mui/material";

import React, { Component } from "react";
import { connect } from "react-redux";
import Editor from "./Editor";
import NamespaceTopology from "./NamespaceTopology";
import { Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
class NamespaceConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadNotification: false,
      selectedCluster: "",
      sliceDetail: {}
    };
  }

  componentDidMount(){
    this.getSliceDetail();
}

componentDidUpdate(prevProps, prevState){
  if(prevProps.slices !== this.props.slices){
    this.getSliceDetail();
  }
}


getSliceDetail = () =>{
  const sliceId = this.props.slice.sliceId
   if (this.props.sliceId !== null && this.props.slices.length > 0) {
    const sliceIndex = this.props.slices.findIndex(
    (slice) => slice.sliceId === sliceId
  );
  if (sliceIndex !== -1) {
    const slice = this.props.slices[sliceIndex];
    this.setState({ sliceDetail: slice });
  }
}
}

  render() {
    const clusterOptions = Object.values(this.props.slice?.attachedClusters);
    return (
      <Grid
        key = {this.props?.slice?.sliceId}
        container
        style={{ width: "100%", height: "52vh" }}
        bgcolor="#fFF"
        direction="column"
        alignItems="center"
        // p={4}
        justifyContent="space-between"
        rowGap={2}
        flexWrap="nowrap"
      >
        <Paper elevation={2} style={{ height: "100%", width: "100%" }}>
          <Grid
            style={{ height: "100%", width: "100%" }}
            container
            item
            xs={12}
            borderRadius="10px"
            overflow="auto"
          >
            <Grid
              style={{ height: "100%", width: "100%" }}
              item
              order={{ xs: 2, md: 1 }}
              xs={12}
              md={10}
              direction="column"
              borderRight="solid 1px silver"
              // borderRadius="0 10px 0 0"
            >
              <Grid
                style={{ height: "60%", width: "100%", overflow: "auto" }}
                item
                xs={12}
              >
                {/* <SliceTopology slices={slices} /> */}

                {
                   this.props?.slice?.sliceName &&
                    <NamespaceTopology slice={this.state.sliceDetail} selectedCluster={this.state?.selectedCluster?.clusterName} sliceName={this.props.slice.sliceName}/>
                }
              </Grid>
              <Grid
                style={{ height: "40%", width: "100%" }}
                border="solid black 1px"
                item
                xs={12}
                container
                position='relative'
              >
                <Editor slice = {this.state.sliceDetail}/>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              order={{ xs: 1, md: 2 }}
              borderRadius="0 10px 0 0"
              
              //height="100%"
              style={{ overflow: "auto" }}
              p={2}
            >
              <Grid item>
                  
                   <Autocomplete
                      options={clusterOptions}
                      getOptionLabel={(option) => option.clusterName}
                      disablePortal
                      value={this.selectedCluster}
                      onChange={(e, newValue) => {
                        this.setState({selectedCluster:newValue})}
                      }
                      sx={{ mr: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ 
                          // margin: "1rem",
                          //  minWidth: "10rem"
                           }}
                          variant="outlined"
                          label="Cluster"
                    />
                )}
              />
                 
                  
                </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  slices: state.slices.sliceList,
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(NamespaceConfig);
