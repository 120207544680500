import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Fab from "@material-ui/core/Fab";
import MuiCardContent from "@material-ui/core/CardContent";
import MuiCard from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getClusterMetaData } from "../../actions/dashboardActions";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Skeleton } from "@mui/material";

const CardContent = styled(MuiCardContent)`
  padding: 0px !important;
`;
const CardLabel = styled.div`
  font-weight: bold;
  font-size: 32px;
`;
const Card = styled(MuiCard)`
  height: 114px;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  border-radius: 18px;
  box-shadow: "0px 5px 25px rgba(40, 104, 243, 0.04)";
  width: ${(props) => `calc(${100 / props.noOfCards}% - ${50 / props.noOfCards}px)};`};
  //width: auto;
  min-width: 160px;
`;
const Loader = (
  <Paper
    sx={{
      height: "114px",
      margin: "5px",
      borderRadius: "14px",
    }}
  >
    <Skeleton
      height="100%"
      width="100%"
      variant="rectangular"
      sx={{ borderRadius: "14px", bgcolor: "#f5f5f5" }}
    />
  </Paper>
);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    top: "1px",
    marginLeft: "2%",
    marginRight: "2%",
    padding: "0% 2% 2% 2%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cardList: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardContainer: {
    margin: "5px",
    width: "200px",
    borderRadius: "14px",
  },
  cardContent: {
    display: "flex",
  },
  fab: {
    margin: "0 5px",
    marginTop: "0px",
    marginLeft: "0px",
    transform: "scale(0.8)",
    textTransform: "none",
    borderRadius: "2px",
  },
  buttonAction: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: "15px",
  },
}));

export default function ClusterMetadata() {
  const [headerExpand, setHeaderExpand] = useState(true);
  const classes = useStyles();
  const toggleHeader = () => setHeaderExpand(!headerExpand);
  const { eventCount, entityCount } = useSelector(
    (state) => state.dashboard.clusterMetaData
  );
  const dispatch = useDispatch();
  let cardDetails = [];

  useEffect(() => {
    dispatch(getClusterMetaData());
  }, []);

  const error = {
    icon: <ErrorIcon fontSize="small" />,
    style: {
      color: "#E31A1A",
      backgroundColor: "#FCE8E8",
    },
  };

  const success = {
    icon: <CheckCircleIcon fontSize="small" />,
    style: {
      color: "#05CD99",
      backgroundColor: "#E6FAF5",
    },
  };

  if (eventCount && entityCount) {
    cardDetails = [
      {
        label: entityCount.clusterCount,
        icon: eventCount.clusters > 0 ? error.icon : success.icon,
        iconLabel:
          eventCount.clusters > 0
            ? `${eventCount.clusters} errors`
            : `All good`,
        actionLabel: "Clusters",
        fabStyle: eventCount.clusters > 0 ? error.style : success.style,
      },
      {
        label: entityCount.sliceCount,
        icon: eventCount.slices > 0 ? error.icon : success.icon,
        iconLabel:
          eventCount.slices > 0 ? `${eventCount.slices} errors` : `All good`,
        actionLabel: "Slices",
        fabStyle: eventCount.slices > 0 ? error.style : success.style,
      },
      {
        label: entityCount.nodeCount,
        icon: eventCount.nodes > 0 ? error.icon : success.icon,
        iconLabel:
          eventCount.nodes > 0 ? `${eventCount.nodes} errors` : `All good`,
        actionLabel: "Nodes",
        fabStyle: eventCount.nodes > 0 ? error.style : success.style,
      },
      {
        label: entityCount.namespaceCount,
        icon: eventCount.namespace > 0 ? error.icon : success.icon,
        iconLabel:
          eventCount.namespace > 0
            ? `${eventCount.namespace} errors`
            : `All good`,
        actionLabel: "Namespaces",
        fabStyle: eventCount.namespace > 0 ? error.style : success.style,
      },
      {
        label: entityCount.serviceCount,
        icon: eventCount.services > 0 ? error.icon : success.icon,
        iconLabel:
          eventCount.services > 0
            ? `${eventCount.services} errors`
            : `All good`,
        actionLabel: "Services",
        fabStyle: eventCount.services > 0 ? error.style : success.style,
      },
    ];
  }

  return (
    <div>
      <div className={classes.headerContainer}>
        <div id="metadata-card-container" className={classes.cardList}>
          {cardDetails.length ? (
            cardDetails.map((card, index) => (
              <Card
                key={index}
                className={classes.cardContainer}
                classes={{ root: classes.card }}
                noOfCards={cardDetails?.length || 1}
              >
                <CardContent className={classes.cardContent}>
                  <CardLabel>{card.label}</CardLabel>
                </CardContent>

                <CardContent>
                  <Typography className={classes.buttonAction} variant="p">
                    {card.actionLabel}
                  </Typography>
                </CardContent>

                <CardContent style={{ marginLeft: "-8px" }}>
                  <Fab
                    style={{ ...card.fabStyle, borderRadius: "4px" }}
                    disabled={true}
                    variant="extended"
                    size="small"
                    aria-label="add"
                    className={classes.fab}
                  >
                    {card.icon}
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "700",
                        marginLeft: "5px",
                      }}
                    >
                      {card.iconLabel}
                    </Typography>
                  </Fab>
                </CardContent>
              </Card>
            ))
          ) : (
            <>
              {Loader}
              {Loader}
              {Loader}
              {Loader}
              {Loader}
            </>
          )}
        </div>
        {/* <ExpandLessIcon onClick={toggleHeader} /> */}
      </div>
      {/* {headerExpand ? (
        
      ) : (
        <div className={classes.headerContainer}>
          <div className={classes.cardList}>
            {cardDetails.map((card, index) => (
              <Card key={index} className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1">{`${card.actionLabel} (${card.label})`}</Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          {/* <ExpandMoreIcon onClick={toggleHeader} /> 
        </div>
       */}
    </div>
  );
}
