import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAllGatewaysForSlice, getSliceGateway } from "../../actions/SliceActions";
import Tables from "../Commons/Tables";
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';

class GatewayList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gatewayData: [],
      selectedCluster: null,
      showFilter: false,
      filters: {},
    };
    this.sliceId = null;
  }

  getSelectedCluster = () => {
    if (this.state.gatewayData !== null) {
      let data = this.state.gatewayData.filter((value) => {
        return value.clusterName == this.props.cluster;
      });
      this.setState({ selectedCluster: data });
    }
  };

  getUnique = (data, filterObj) => {
    const result = {};
    Object.keys(filterObj).forEach((ele) => {
      const filterType = [...new Set(data.map((eleData) => eleData[ele]))];
      result[filterObj[ele]] = { selected: [], values: filterType };
    });
    return result;
  };

  setSelectedFilter = (type, filter) => {
    const selectedFilterData = this.state.showFilter ? this.state.filters : {};
    const data = [...selectedFilterData[type].selected];
    const index = data.indexOf(filter);
    if (index > -1) data.splice(index, 1);
    else data.push(filter);
    const filterData = {
      ...selectedFilterData,
      [type]: {
        ...selectedFilterData[type],
        selected: data,
      },
    };
    this.state.showFilter && this.setState({ filters: filterData });
  };

  clearFilters = (clearfilterData, setData) => {
    const data = Object.keys(clearfilterData).map((filter) => ({
      [filter]: { selected: [], values: clearfilterData[filter].values },
    }));
    let filtersData = {};
    data.forEach((ele) => {
      filtersData = { ...filtersData, ...ele };
    });
    this.setState({ filters: filtersData });
  };

  getFilteredData = (data, filterObj) => {
    return data.filter((ele) => {
      const data = Object.keys(filterObj).map((filter) => {
        if (filterObj[filter].selected.length === 0) return true;
        if (filterObj[filter].selected.includes(ele[filter])) return true;
        return false;
      });
      if (data.every((ele) => ele === true)) return ele;
    });
  };

  getFilterApplied = (filData) => {
    return Object.keys(filData)
      .map((filter) => {
        if (filData[filter].selected.length > 0) return true;
        return false;
      })
      .some((ele) => ele === true);
  };

  handlePrometheusButton = (e, clusterName) => {
    const { openPrometheusTab } = this.props;
    e.stopPropagation();
    openPrometheusTab(clusterName)
  }

  getGateWays = () => {
    const result = this.props.gateways;
    
    if(!result?.length) return;
    let gatewayData = [];
    for (let i = 0; i < result?.length; i++) {
      if (result[i].sliceGatewayRemotePodIp) {
        let siteName = null;
        if (result[i].sliceName && result[i].sliceGatewayRemoteClusterId && result[i].sliceGatewayRemoteGatewayId) {
          siteName = this.props.gateways.find(
            (gateway) =>
              gateway.sliceName == result[i].sliceName &&
              gateway.sliceGatewayRemoteClusterId == result[i].sliceGatewayRemoteClusterId &&
              gateway.sliceGatewayRemoteGatewayId == result[i].sliceGatewayRemoteGatewayId
          );
        }
        //debugger
        const clusterName = this.props.slice?.attachedClusters[result[i]?.sliceClusterId]?.clusterName;
        
        gatewayData.push({
          ...result[i],
          clusterInfo: clusterName,
          remoteSliceSiteName: siteName ? siteName?.sliceSiteName : "Not Available",
          prometheusButton: <IconButton onClick={(e) => this.handlePrometheusButton(e, clusterName)} size="small" aria-label="Open Prometheus"><LaunchIcon /></IconButton>
        });
      }
    }
    const filters = this.getUnique(gatewayData, {
      clusterInfo: "clusterInfo",
    });
    this.setState({ filters, gatewayData });
    this.getSelectedCluster();
  };

  getData = async () => {
    //fetches slice id from url
    let sliceId = this.getSliceId();
    //stores cluster id
    //stores promises returned by gateway api
    let { sliceList } = this.props;
    const sliceName = sliceList.find((slice) => slice.sliceId == sliceId).sliceName;
    if (sliceName != -1) {
      this.props.getAllGatewaysForSlice(sliceName);
    }
  };

  getSliceId = () => {
    const query = new URLSearchParams(this.props.location?.search);
    let sliceId = query.get("id");
    this.sliceId = sliceId;
    return sliceId;
  };

  compare = (a, b) => {
    if (a.clusterInfo < b.clusterInfo) {
      return -1;
    }
    if (a.clusterInfo > b.clusterInfo) {
      return 1;
    }
    return 0;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.search != this.props.location?.search) {
      this.getData();
    }
    if (prevProps.cluster != this.props.cluster) {
      this.getSelectedCluster();
    }
    if (prevProps.gateways?.length != this.props.gateways?.length) {
      this.getGateWays();
    }
  }

  componentDidMount() {
    this.getData();
    this.getSelectedCluster();
    this.getGateWays();
  }

  render() {
    const { gatewayData } = this.state;
    const { addToTab } = this.props;

    const config = {
      columns: [
        {
          id: "clusterInfo",
          name: "Cluster  Name"
        },
        {
          id: "sliceGatewayName",
          name: "Slice Gateway",
          default: "Name not available",
        },
        {
          id: "sliceSiteName",
          name: "Site",
        },
        {
          id: "remoteSliceSiteName",
          name: "Remote Site",
        },
        {
          id: "health.appStatus.length",
          name: "APP Pods",
          default: 0,
        },
        {
          id: "prometheusButton",
          name: "Prometheus"
        }
      ],
    };

    if (gatewayData.length > 0) {
      gatewayData.sort(this.compare);
    }
    
    return Array.isArray(gatewayData) && gatewayData.length > 0 ? (
      <Tables
        config={config}
        data={gatewayData}
        rowListener={(row) => {
          addToTab({
            name: row?.sliceGatewayName || "Gateway-" + row?.sliceGatewayId,
            data: row,
          });
        }}
      />
    ) : (
      <div>
        <br />
        <h3 style={{ color: "white" }}>No Gateways Available</h3>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sliceList: store.slices.sliceList,
    gateways: store.slices?.sliceGateways?.gateways,
  };
};

export default withRouter(connect(mapStateToProps, { getSliceGateway, getAllGatewaysForSlice })(GatewayList));
